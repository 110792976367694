import React,{ useEffect,useState } from "react";
import Navbar from "../../Navbar";
import LeftArrow from "../../../assets/Jobs/LeftArrow.svg";
import Footer from "../../Footer";
import BottomButtonPanel from "./BottomButtonPanel";
import ReactPlayer from 'react-player';
import { useLocation ,useNavigate } from "react-router-dom";
import Moment from 'moment';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import mapStyles from './mapStyles'

import ImageModal from './imageModal'
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



function Map(props) {
  const [selectedPark, setSelectedPark] = useState(null);

  useEffect(() => {
    const listener = e => {
      if (e.key === "Escape") {
        setSelectedPark(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

console.log("lksdn123ckf",props.dealerData)
  return (
   props.dealerData != null ?
    <GoogleMap
      defaultZoom={4}
      defaultCenter={{ lat: 48.8114633 , lng: 34.8990059}}
      options={{ styles: mapStyles }}>
        <Marker
          key={props.dealerData.ID}
          position={{
            lat: props.dealerData.position.lat,
            lng:props.dealerData.position.lng
          }}
          onClick={() => {
            setSelectedPark(props.dealerData.Employer_Map);
          }}
          icon={{
            url:  "https://img.icons8.com/color/48/000000/map-pin.png",
            scaledSize: new window.google.maps.Size(50, 50)
          }}
        >

<InfoWindow
          onCloseClick={() => {
            setSelectedPark(null);
          }}
          position={{
            lat: props.dealerData.position.lat,
            lng: props.dealerData.position.lng
          }}
        >
          <div>
            <h2 className="font-[Gotham-Medium] ">{props.dealerData.currentCity}</h2>
          </div>
        </InfoWindow>
        </Marker>

    </GoogleMap> : null 
    
  );
}


const JobsDetail = (props) => {
  const [mapdata1, setMapData] = useState({
    mapDataFetch:null
  })

  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();
  const [list, setList] = useState([]);

  const [listLiked, setListLiked] = useState([]);
  const [getImageVisible, setImageModalVisible] = useState(false);
  const [getImageUrl, setImageUrl] = useState();

  
  useEffect(() => {
    const _getList= async() => {
      try{
        let loginData = JSON.parse(localStorage.getItem('dataLogin'))

        setMapData({
          mapDataFetch:{
            ID :  state._item.ID,
                position:{
                  lat:  state._item.Employer_Map.Job_Employer_Map_Lat,
                  lng:  state._item.Employer_Map.Job_Employer_Map_Lng
            },
            currentCity:  state._item.Employer_Map.Job_Employer_Map_City,
            currentLatitude : state._item.Employer_Map.Job_Employer_Map_Lat,
            currentLongitude :  state._item.Employer_Map.Job_Employer_Map_Lng}
          })

        await axios.get(`https://getjob.stechomeyazilim.info:5101/getNotificationEmployer/select/${loginData.ID}/${state._item.ID}`)
         .then((res) => {
          console.log("klsdf123",res.data)
           setList(res.data)   
          })
  }
  catch (error) {
    console.log("errorccAS" + error)
  }
    }

    _getList()

    const _getListFav= async() => {
      try{
        let loginData = JSON.parse(localStorage.getItem('dataLogin'))

        await axios.get(`https://getjob.stechomeyazilim.info:5101/getListFavEmployer/select/${loginData.ID}`)
         .then((res) => {
          setListLiked(res.data)   
          })
  }
  catch (error) {
    console.log("errorccAS" + error)
  }
    }

    window.scrollTo(0, 0);

    _getListFav()

  }, [])

 const _goBack= () => {
    navigate(-1);
  }




  const unlikeChange= async() => {
    let rspUserDetail =  JSON.parse(localStorage.getItem('dataLogin'))
    var testDateUtc = Moment.utc(new Date());

    var localDate = Moment(testDateUtc).local();

    let data = {
      Users_ID:rspUserDetail.ID,
      Employer_ID:state._item.ID,
      Created_DateTime:localDate
    }

    await axios.post(`https://getjob.stechomeyazilim.info:5101/postUnlikeEmployer/send`, data) .then((res) => {
      showToast('GetJob', "İlanı Beğenmekten Vazgeçtiniz!", 'false')
       })
    //navigate(-1);
  }

 const showToast= (event, value, type) => {
    if(type == "false"){
      toast.error(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
        setList([{"ID": 10}])

    }else{
      toast.success(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });

        setList([{"ID": 10}])
       // navigate('/')
  
    }
  
  }
  
  const _setImageModal=(imgUrl)=> {
    setImageUrl(imgUrl)
    setImageModalVisible(true)
  }

  const showToastLiked= (event, value, type) => {
    if(type == "false"){
      toast.error(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
        setListLiked([{"ID": 10}])

    }else{
      toast.success(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });

        setListLiked([{"ID": 10}])
       // navigate('/')
  
    }
  
  }
  const favChange = async() => {
    try {
      console.log("sdkfn123",state._item)
      var testDateUtc = Moment.utc(new Date());
    
      var localDate = Moment(testDateUtc).local();
      let rspUserDetail =  JSON.parse(localStorage.getItem('dataLogin'))

      let data = {
            Employer_ID:state._item.ID,
            Users_ID:parseInt(rspUserDetail.ID),
            Created_DateTime:localDate,
      }
      
      console.log("data1231",data)
      await axios.post(`https://getjob.stechomeyazilim.info:5101/postLikedFavJobSeeker/send`, data) .then((res) => {
        showToastLiked('GetJob', "Başarıyla İş Vereni Beğendiniz!", 'success')
         })
          

    } catch (err) {
        console.log("likeChange",err)
      }
  
   
  }


  const likeChange = async() => {
    try {
      console.log("sdkfn123",state._item)
      var testDateUtc = Moment.utc(new Date());
    
      var localDate = Moment(testDateUtc).local();
      let rspUserDetail =  JSON.parse(localStorage.getItem('dataLogin'))

      let data = {
        Notification_Text:'Size Mülakat Talebi Gönderdi',
        Notifacation_Type_ID:2,
        Receiver_Users_ID:state._item.Users_ID,
        Sender_Users_ID: parseInt(rspUserDetail.ID),
        Notification_CreatedDateTime:localDate,
        Employer_ID:state._item.ID,
        Notification_IsActive:true
      }
      
      console.log("data1231",data)
      await axios.post(`https://getjob.stechomeyazilim.info:5101/insertNotificationEmployer/send`, data) .then((res) => {
        showToast('GetJob', "Başarıyla Talebiniz Gönderildi!", 'success')
         })
          

    } catch (err) {
        console.log("likeChange",err)
      }
  
   
  }

 const _closeImageModal= () => {
    setImageModalVisible(false)
  }
  

  console.log("bsdljfsd",state._item)
  return (
    <div>
      <Navbar />
      <div className="w-full flex flex-col">
        <div className="max-w-full mt-36 flex flex-col bg-[#F7F7F7] h-full  items-center  ">
          <div className=" py-7 flex justify-center items-center">
            <img
              src={LeftArrow}
              alt=""
              className="  flex justify-center items-center w-3 h-3 mr-3"
            />
            <button onClick={()=> _goBack()} className="w-full text-center text-xl text-[#204686] py-3 font-bold">
              Job's Detail
            </button>
          </div>

   

          <div className="w-5/6 h-full lg:w-3/5 md:w-4/5 px-2 pb-4 pt-2 items-center flex flex-col rounded-xl bg-white">
            <div className="w-full h-52  bg-gradient-to-r from-[#081F43]   via-[#f8d581] to-white flex justify-center items-center rounded-t-xl  ">
            {state._item.Employer_Company.Employer_Company_Image != null ? <button onClick={()=> _setImageModal(state._item.Employer_Company.Employer_Company_Image)} className="flex my-auto mx-auto  rounded-lg ">
                <img src={"https://getjob.stechomeyazilim.com/Uploads/"+ state._item.Employer_Company.Employer_Company_Image} alt="" className="w-40 h-40 rounded-2xl border-4 border-[white]" />
              </button> : <div className="bg-[#081F43] flex justify-center items-center p-4 rounded-lg"><FontAwesomeIcon icon={faBuilding} style={{margin:10}}  size={"5x"} color={"white"} /></div>}
            </div>
            <div className="w-full bg-[#081F43] pl-3 flex flex-col text-white">
              <h2 className="text-xl  pt-2 font-bold ">{state._item.Employer_Company.Job_Employer_CompanyName}</h2>
              <p className="text-xs pb-2">{state._item.Employer_Company.Job_Employer_Company_FoundingYear}</p>
            </div>
            <div className="flex flex-col py-3 px-2 w-full">
              <h3 className="text-[#071E44] font-[Gotham-Medium] text-sm pt-3 ">Firma Bilgileri</h3>
            </div>
            <div className="w-full flex flex-col px-2 pb-2 ">
              <div className="w-full flex justify-between text-xs py-1">
                <p className="text-[#868686] font-[Gotham-Medium]">Firma Adı</p>
                <p className="text-[#062CBC] font-[Gotham-Medium]">{state._item.Employer_Company.Job_Employer_CompanyName}</p>
              </div>
              <div className="w-full flex justify-between text-xs py-1">
                <p className="text-[#868686] font-[Gotham-Medium]">Sektör</p>
                <p className="text-[#062CBC] font-[Gotham-Medium]">{state._item.Employer_Company.Job_Employer_SectorName}</p>
              </div>
              <div className="w-full flex justify-between text-xs py-1">
                <p className="text-[#868686] font-[Gotham-Medium]">Kuruluş Yılı</p>
                <p className="text-[#062CBC] font-[Gotham-Medium]">{state._item.Employer_Company.Employer_Company_Foundation_Year}</p>
              </div>
              <div className="w-full flex justify-between text-xs py-1 ">
                <p className="text-[#868686] font-[Gotham-Medium]">Çalışan Sayısı</p>
                <p className="text-[#062CBC] font-[Gotham-Medium]">{state._item.Employer_Company.Job_Employer_EmployeesNumber}</p>
              </div>

              <div className="w-full flex justify-between text-xs py-1 ">
                <p className="text-[#868686] font-[Gotham-Medium]">Şirket Numarası</p>
                { state._item.Employer_Company.Employer_Company_PhoneNumber != null ?  <p className="text-[#062CBC] font-[Gotham-Medium]">{state._item.Employer_Company.Employer_Company_PhoneNumber}</p>:
                  <p className="text-[#062CBC] font-[Gotham-Medium]">-</p>}
              </div>

              <div className="w-full flex justify-between text-xs py-1">
                <p className="text-[#868686] font-[Gotham-Medium]">Şirket Mail</p>
               { state._item.Employer_Company.Employer_Company_Mail != null ? <p className="text-[#062CBC] font-[Gotham-Medium]">{state._item.Employer_Company.Employer_Company_Mail}</p> :
                <p className="text-[#062CBC] font-[Gotham-Medium]">-</p>}
              </div>


              <div className="w-full flex justify-between text-xs py-1 ">
                <p className="text-[#868686] font-[Gotham-Medium]">Şirket Çalışan Sayısı</p>
                { state._item.Employer_Company.Employer_Number != null ?  <p className="text-[#062CBC] font-[Gotham-Medium]">{state._item.Employer_Company.Employer_Number}</p>:
                                   <p className="text-[#062CBC] font-[Gotham-Medium]">-</p>}

              </div>

              <div className="w-full flex justify-between text-xs py-1 border-b-2 pb-6">
                <p className="text-[#868686] font-[Gotham-Medium]">Yetkili Kişi</p>
                { state._item.Employer_Company.Employer_Company_OfficalUser != null ? <p className="text-[#062CBC] font-[Gotham-Medium]">{state._item.Employer_Company.Employer_Company_OfficalUser}</p> :
                   <p className="text-[#062CBC] font-[Gotham-Medium]">-</p>}

              </div>

            </div>
            <div className="flex flex-col pb-3 pt-1 px-2 w-full">
              <h3 className="text-[#071E44] font-[Gotham-Medium] text-sm pt-3 ">Gerekli Şartlar</h3>
            </div>
            <div className="w-full flex flex-col px-2 pb-2 ">
              <div className="w-full flex justify-between text-xs py-1">
                <p className="text-[#868686] font-[Gotham-Medium]">Aranılan Pozisyon</p>
                <p className="text-[#062CBC] font-[Gotham-Medium]">{state._item.Job.Job_Text}</p>
              </div>
              <div className="w-full flex justify-between text-xs py-1">
                <p className="text-[#868686] font-[Gotham-Medium] text-right">Dil</p>
                <div className="flex-col text-center">

                {state._item.Employer_Language.length > 0 ? state._item.Employer_Language.map((item, index) => (
        
        <p className="text-[#062CBC] font-[Gotham-Medium]">{item.Job_Employer_Language}</p> )):null}
                      </div>
                      </div>
              <div className="w-full flex justify-between text-xs py-1">
                <p className="text-[#868686] font-[Gotham-Medium]">Meslek</p>
                <p className="text-[#062CBC] font-[Gotham-Medium]">{state._item.Job.Job_Text}</p>
              </div>
              <div className="w-full flex justify-between text-xs py-1">
                <p className="text-[#868686] font-[Gotham-Medium]">Deneyim yılı </p>
                <p className="text-[#062CBC] font-[Gotham-Medium]">{state._item.Job_Experience.Job_Experience_Text}</p>
              </div>
              <div className="w-full flex justify-between text-xs py-1">
                <p className="text-[#868686] font-[Gotham-Medium]">Maaş</p>
                <p className="text-[#062CBC] font-[Gotham-Medium]">{state._item.Job_Salery_Expection.Job_Salery_Expection_Text}</p>
              </div>
              <div className="w-full flex justify-between text-xs py-1">
                <p className="text-[#868686] font-[Gotham-Medium]" >Eğitim Durumu</p>
              <div className="flex-col text-center">
                
                {state._item.Employer_Education.map((item, index) => (
        
        <p className="text-[#062CBC] font-[Gotham-Medium] text-right">{item.Employer_Education_Status}</p> ))}

            </div></div>
              <div className="w-full flex justify-between text-xs py-1 border-b-2 pb-6">
                <p className="text-[#868686]  font-[Gotham-Medium]">Çalışma Şekli</p>
                <div className="flex-col text-center		">
                {state._item.Employer_JobWay.map((item, index) => (
        
        <p className="text-[#062CBC] font-[Gotham-Medium] text-right">{item.Employer_JobWay_Text}</p> ))}
              </div>
            </div>

            
            </div>
            <div className="w-full flex flex-col px-2 pb-2 flex-wrap ">
              <h3 className="text-[#071E44] text-sm pt-3 pb-2 font-[Gotham-Medium] ">Ön Yazı</h3>
              <p className="text-[#868686] text-xs border-b-2 pb-6 font-[Gotham-Medium] ">
              {state._item.Employer_Desc}
              </p>
            </div>
            
            <div className="w-full flex flex-col px-2 pb-2 flex-wrap ">
              <h3 className="text-[#071E44] text-sm pt-3 pb-2 font-[Gotham-Medium] ">Çalışma Modeli </h3>
              <p className="text-[#868686] text-xs border-b-2 pb-6 font-[Gotham-Medium] ">
            { state._item.Employer_JobModal.length > 0 ? <div className="flex-col text-center font-[Gotham-Medium]">
            {state._item.Employer_JobModal.map((item, index) => (
    
    <p className="text-[#062CBC] font-[Gotham-Medium]">{item.Employer_JobModal_Text}</p> ))}
          </div> : <div className="text-[#071E44] font-[Gotham-Medium]">Veri Yok</div>}
              </p>
            </div>
            




            
           { state._item.Employer_Company.Employer_Company_Video != null ? 
           <div className="w-full flex flex-col px-2 pb-2 flex-wrap ">
                          <h3 className="mb-6 mt-3   text-blue-900 text-lg text-left  font-[Gotham-Medium] ">Video</h3>
              <div className="w-full h-full bg-zinc-500 rounded-xl"> <ReactPlayer width="100%" height="100%" url={"https://getjob.stechomeyazilim.com/Uploads/" + state._item.Employer_Company.Employer_Company_Video}/> </div>
            </div> :  <h3 className="text-[#071E44] text-sm pt-3 pb-2 ">Video Yok</h3>}

            <h3 className="mb-6 mt-3   text-blue-900 text-lg text-left  font-[Gotham-Medium] ">Konumum</h3>
      <div style={{ height: '50vh', width: '100%',zIndex:10 }} className="z-10 ex-collection-box mb-xl-20">

<MapWrapped
   googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCm32U9QFgSBe_8pJG_2Z3G61pL6ipeQtU&v=3.exp&libraries=geometry,drawing,places}`}
   loadingElement={<div style={{ height: `100%` }} />}
   containerElement={<div style={{ height: `100%` }} />}
   mapElement={<div style={{ height: `100%` }} />}
   dealerData = {mapdata1.mapDataFetch != null ? mapdata1.mapDataFetch : null}
   currentLat={state._item.Employer_Map.Job_Employer_Map_Lat}
   currentLng={state._item.Employer_Map.Job_Employer_Map_Lng}
 />
</div> 
          </div>
          <Footer/>
        </div>

        

      </div>
      <BottomButtonPanel favChange={favChange} list={list}  listLiked={listLiked} likeChange={likeChange} unlikeChange={unlikeChange}/>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>

<ImageModal _imageUrl={getImageUrl} _imageModalVisible={getImageVisible} _closeImageModal={_closeImageModal}/>
    </div>
  );
};

const MapWrapped = withScriptjs(withGoogleMap(Map));



export default JobsDetail;
