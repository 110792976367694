import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navmethod: false,
            visibleFilter:false,
            password:this.props.jobsText
        };
    }
    // Navigation
   
   
    _setPassword= async(password) => {
        try{
    this.setState({password: password})
    this.props.filterList(password)
        } catch (error) {
          console.log("getList" + error)
        }
    
      }

    toggleNav = async () => {
        this.setState({visibleFilter: !this.state.visibleFilter })
        this.props._openNav()
    }

    

    render() {
        return (
            <div className="catring parent-megamenu p-4 flex justify-between items-center">
                  <div className="flex w-full  shadow-xl  mb-1">
              <div
                className="w-full flex py-3 bg-zinc-200  justify-center items-center   "
                onClick={""}
              >
                <input
                  type="text"
                  value={this.state.password}
                  className="bg-zinc-200 w-full font-[Gotham-Medium]  outline-none focus:border-b-2 focus:border-blue-500 "
                  placeholder={this.props.t("aradıgınızisler")}
                  onChange={e => this._setPassword(e.target.value)}
                />
              
              </div>
            </div>
                
            </div>
        );
    }
}

export default withTranslation()(Menu);