import React,{useState} from "react";
import Select from "react-select";

const SelectOption = ({ selectLabel,value,type,fullvalue,handleChange }) => {
  const CustomStyle = {
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#081F43' : 'white',
      color:  state.isSelected ? 'white' : '#081F43',
      menu: base => ({
        ...base,
        zIndex: 100,
        backgroundColor:'red'
      }),
    })
  }


  const options = [
    { value: "Erkek", label: "Erkek" },
    { value: "Kadın", label: "Kadın" },
  ];

  const optionsMilitary = [
    { value: true, label: "Yapıldı" },
    { value: false, label: "Yapılmadı" },
  ];

  const optionsGraduation = [{ value: 0, label: "İlkokul Mezuniyeti" }, { value: 1, label: "Ortakul Mezuniyeti" }, { value: 2, label: "Lise Mezuniyeti" }, { value: 3, label: "Ön Lisans Mezuniyeti" }, { value: 4, label: "Lisans Mezuniyeti" }, { value: 5, label: "Yüksek Lisans Mezuniyeti" }, { value: 6, label: "Doktora Mezuniyeti" }];

  const optionsGraduationSchool = [{ value: "İZMİR", label: "İZMİR" }];

  const saleryData = [{value : 1 , label: "5500 ₺-7000 ₺"},{value : 2 , label: "7000 ₺-10000 ₺"},{value : 3 , label: "10000 ₺-15000 ₺"},{value : 4 , label: "15000 ₺-20000 ₺"},{value : 5 , label: "20000 ₺+"}]

  const jobExpreince = [{value : 1 , label: "0-1 Yıl"},{value : 2 , label: "1-3 Yıl"},{value :3 , label: "3-5 Yıl"},{value : 4 , label: "5+ Yıl"}]

  const optionsJobWay = [{ value: 1, label: "Tam Zamanlı" }, { value: 2, label: "Yarım Zamanlı" }, { value:  3, label: "Proje Bazlı" }, { value:4, label: "Geçici" }, { value: 5, label: "Stajyer" }];

  const employeeNumber = [{value : 1 , label: "1"},{value : 2 , label: "2"},{value :3 , label: "3"},{value : 4 , label: "4"},{value : 5 , label: "5"}]

  return ( 
    type == "employeeNumber"?
    <div class="relative">
    <Select
     defaultValue={employeeNumber.filter(x=> x.label ==value)}
      onChange={e => handleChange(e,'employeeNumber')}
      options={employeeNumber}
      styles={CustomStyle}
      placeholder="Kaç Kişi İşe Alacaksınız?"
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      for="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>:
    type == "historyJobWayOutWorkink"?
    <div class="relative">
    <Select
     defaultValue={optionsJobWay.filter(x=> x.label ==value)}
      onChange={e => handleChange(e)}
      options={optionsJobWay}
      styles={CustomStyle}
      placeholder="Çalışma Şeklini Seçiniz"
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      for="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>: 
    type == "highSchool"?
    <div class="relative">
    <Select
     defaultValue={fullvalue.filter(x=> x.label ==value)}
      onChange={e => handleChange(e)}
      options={fullvalue}
      styles={CustomStyle}
      placeholder="Üniversite Seçiniz."
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      for="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>: 
    type == "cityEducation"?
    <div class="relative">
    <Select
     defaultValue={fullvalue.filter(x=> x.label ==value)}
      onChange={e => handleChange(e,"cityEducation")}
      options={fullvalue}
      styles={CustomStyle}
      placeholder="İl Seçiniz."
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      for="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>: 
    type == "educationprogram"?
    <div class="relative">
    <Select
     defaultValue={fullvalue.filter(x=> x.label ==value)}
      onChange={e => handleChange(e,"educationprogram")}
      options={fullvalue}
      styles={CustomStyle}
      placeholder="Program Seçiniz."
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      for="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>: 
    type == "countries"?
    <div class="relative">
    <Select
     defaultValue={fullvalue.filter(x=> x.label ==value)}
      onChange={e => handleChange(e,"countries")}
      options={fullvalue}
      styles={CustomStyle}
      placeholder="Ülke Seçiniz."
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      for="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>: 
    type == "city"?
    <div class="relative">
    <Select
         defaultValue={fullvalue.filter(x=> x.label ==value)}
       onChange={e => handleChange(e,"city")}
      options={fullvalue}
      styles={CustomStyle}
      placeholder="Aradığınız işte ki ili seçiniz"
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      for="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>: 
    type == "district"?
    <div class="relative">
    <Select
         defaultValue={fullvalue.filter(x=> x.label ==value)}
                onChange={e => handleChange(e,"district")}
      options={fullvalue}
      styles={CustomStyle}
      placeholder="Aradığınız işte ki ilçeyi seçiniz"
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      for="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>: 
    type == "military"?
    <div class="relative">
    <Select
      onChange={e => handleChange(e,"military")}
      defaultValue={optionsMilitary.filter(x=> x.value ==value)}
      options={optionsMilitary}
      styles={CustomStyle}
      placeholder="Askerlik Durumunuzu Seçiniz"
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      for="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>: 
    type == "district"?
    <div class="relative">
    <Select
                onChange={e => handleChange(e,"district")}
                defaultValue={fullvalue.filter(x=> x.value ==value)}
      options={fullvalue}
      styles={CustomStyle}
      placeholder="İşinizi Seçiniz."
      id="multi-select"
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      for="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>: 
    type == "city"?
    <div class="relative">
    <Select
                onChange={e => handleChange(e,"city")}
                defaultValue={fullvalue.filter(x=> x.value ==value)}
      options={fullvalue}
      styles={CustomStyle}
      placeholder="İşinizi Seçiniz."
      id="multi-select"
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      for="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>: 
    type == "job"?
  <div class="relative">
  <Select
              onChange={e => handleChange(e,"job")}
              defaultValue={fullvalue.filter(x=> x.value ==value)}
    options={fullvalue}
    placeholder="İşinizi Seçiniz."
    id="multi-select"
    className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
  />
  <label
    for="multi-select"
    className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
  >{selectLabel}
  </label>
</div>:  
    type == "exprience"?
    <div class="relative">
    <Select
      onChange={e => handleChange(e,"exprience")}
      defaultValue={jobExpreince.filter(x=> x.value ==value)}
      options={jobExpreince}
      placeholder="Aranılan Deneyim Yılı"
      id="multi-select"
      styles={CustomStyle}
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      for="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>:  
    type == "gender"?
      <div class="relative">
      <Select
        defaultValue={options.filter(x=> x.value ==value)}
        options={options}
        onChange={e => handleChange(e,"gender")}
        placeholder="Cinsiyet Seçiniz."
        id="multi-select"
        styles={CustomStyle}
        className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
      />
      <label
        for="multi-select"
        className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
      >{selectLabel}
      </label>
    </div>:     
    type == "military"? 
    <div class="relative">
    <Select
      defaultValue={optionsMilitary.filter(x=> x.value ==value)}
      options={optionsMilitary}
      placeholder="Askerlik Durumunu Seçiniz..."
      id="multi-select"
      styles={CustomStyle}
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      for="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div> :
  type == "educationgra"? 
  
  <div class="relative">
  <Select
    defaultValue={optionsGraduation.filter(x=> x.label ==value)}
    options={optionsGraduation}
    placeholder="Mezuniyet Durumunu Seçiniz..."
    id="multi-select"
    onChange={e => handleChange(e,"educationgra")}
    styles={CustomStyle}
    className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
  />
  <label
    for="multi-select"
    className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
  >{selectLabel}
  </label>
</div>:
type == "educationschool"? 
  
<div class="relative">
<Select
  defaultValue={optionsGraduationSchool.filter(x=> x.value ==value)}
  options={optionsGraduationSchool}
  placeholder="Mezun Olunan Okulu Seçiniz..."
  id="multi-select"
  styles={CustomStyle}
  className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
/>
<label
  for="multi-select"
  className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
>{selectLabel}
</label>
</div> : type == "jobs"? 
    <div class="relative">
    <Select
      onChange={e => handleChange(e,"jobs")}
      defaultValue={fullvalue.filter(x=> x.value ==value)}
      options={fullvalue}
      placeholder="Aranılan Pozisyon..."
      id="multi-select"
      styles={CustomStyle}
      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      for="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>
  : type == "salery"? 
  <div class="relative">
  <Select
    onChange={e => handleChange(e,"salery")}
    defaultValue={saleryData.filter(x=> x.value ==value)}
    options={saleryData}
    placeholder="Maaş Beklentisi Seçiniz..."
    id="multi-select"
    styles={CustomStyle}
    className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
  />
  <label
    for="multi-select"
    className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
  >{selectLabel}
  </label>
</div>
  :
    <div class="relative">
    <Select
      options={options}
      placeholder="Select Option..."
      id="multi-select"
      styles={CustomStyle}

      className="block  w-full text-sm text-gray-900 bg-transparent rounded-lg  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer my-6 "
    />
    <label
      for="multi-select"
      className="absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-0  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "
    >{selectLabel}
    </label>
  </div>
      
 
  );
  };
export default SelectOption;
