import React, { useState, useEffect,Component } from "react";
import InputField from "./InputField";
import MultiSelectOption from "./MultiSelectOption";
import SelectOption from "./SelectOption";
import axios from 'axios';
import ReactPlayer from 'react-player';
import Autocomplete from "react-google-autocomplete";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraAlt, faSave, faVideoCamera} from '@fortawesome/free-solid-svg-icons';
import { withParamsAndNavigate } from "./getParamsAndNavigate.js";
import { ToastContainer, toast } from 'react-toastify';
import LoadingSpinner from "../../LoadingSpinner";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import ProgressBar from "./ProgressBar";
import { Formik } from 'formik';
import Moment from 'moment';

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import mapStyles from './mapStyles'

function Map(props) {
  const [selectedPark, setSelectedPark] = useState(null);

  useEffect(() => {
    const listener = e => {
      if (e.key === "Escape") {
        setSelectedPark(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

console.log("lksdnkf",props)

  return (
    props.dealerData != null ?
      <GoogleMap
      defaultZoom={4}
      defaultCenter={{ lat: 48.8114633 , lng: 34.8990059}}
      options={{ styles: mapStyles }}
    >
        <Marker
          key={props.dealerData.ID}
          position={{
            lat: props.currentLat,
            lng: props.currentLng
          }}
          onClick={() => {
            setSelectedPark(props.dealerData.Employer_Map);
          }}
          icon={{
            url:  "https://img.icons8.com/color/48/000000/map-pin.png",
            scaledSize: new window.google.maps.Size(50, 50)
          }}
        >

<InfoWindow
          onCloseClick={() => {
            setSelectedPark(null);
          }}
          position={{
            lat: props.currentLat,
            lng: props.currentLng
          }}
        >
          <div>
            <h2 className="font-[Gotham-Medium] ">{props.dealerData.currentCity}</h2>
          </div>
        </InfoWindow>
        </Marker>
    </GoogleMap> : <div><text>Veri Yok!</text> </div>
    
  );
}

class PersonelInformation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listDriverLicense:[],
      jobListNew:[],
      saleryData:[],
      dealerData:[],
      selectedLat:37.8604039,
      selectedLng:32.5372508,
      selectedImageVideo:[],
      isLoading:true,
     defaultProps : {
         center: {
           lat: 37.8604039,
           lng: 32.5372508
         },
         zoom: 11
       },
       showingInfoWindow: false,
     activeMarker: {},
     selectedPlace: {},


      companyName : '',
      sector: '',
      foundationYear: '',
      employeesNumer:'',

      languageData:[],
      mapData:null,
      educationStatus:[],
      jobwayout:[],
      jobListID:3,
      jobExpreinceID:null,
      desc:null,
      companyVideo:null,
      listCountries:[],
      listCity:[],
      listDiscrict:[],
      listCityEducation:[],
      selectedImagePhoto:[],
      checkGender:false,
      checkMilitary:false,
      checkDriverLicense:false,
      checkGradient:false,
      checkCv:false,
      checkVideo:false,
      checkDesc:false,
      checkCertifica:false,

      loadingPercentageVideo:0,
      loadingPercentagePhoto:0,
      employeeNumber:0,

      fields: {},
      errors: {},

    }
    }

   _updateForm = async () => {
      try{
  
        let loginData =  JSON.parse(localStorage.getItem('dataLogin'))
        
        var testDateUtc = Moment.utc(new Date());
      
        var localDate = Moment(testDateUtc).local();
  
        let companyData = {
          Job_Employer_CompanyName: this.state.companyName,
          Job_Employer_SectorName: this.state.sector,
          //Job_Employer_Company_FoundingYear:companyData.Employer_Company_Foundation_Year,
          Job_Employer_EmployeesNumber:parseInt(this.state.employeesNumer),
          Employer_Company_Tax_Number:this.state.employeesTaxNumber,
          Employer_Company_Image:this.state.companyPhoto,
          Employer_Company_Video:this.state.companyVideo,
          Employer_Company_Foundation_Year:this.state.foundationYear,
          Users_ID: parseInt(loginData.ID),
          Employer_Company_Countries:this.state.countriesName,
          Employer_Company_City:this.state.cityName,
          Employer_Company_District: this.state.districtName,
        
           Employer_Company_PhoneNumber: this.state.employeesNumber,
           Employer_Company_AddressText: this.state.Employer_Company_AddressText,
           Employer_Company_OfficalUser: this.state.officalUser,
           Employer_Company_Mail: this.state.employeesMail,
          // Employer_Number: parseInt(this.state.officalNumber)
          Employer_Number: parseInt(this.state.employeeNumber)
        }

        console.log("msd123123fk", companyData)

           await axios.post(`https://getjob.stechomeyazilim.info:5101/insertEmployerCompany/send`, companyData)
            .then(async(res) => {

               let filterData = { 
                  Employer_Filter_Gender:this.state.userGender,
                  Employer_Military:this.state.checkMilitary == true ?  "Yapıldı" : "Yapılmadı",
                  Employer_Start_Age:this.state.startAge,
                  Employer_Finish_Age:this.state.finishAge,
                  Employer_Driving_License:this.state.checkDriverLicense,
                  Employer_Gradient:this.state.checkGradient,
                  Employer_CV:this.state.checkCv,
                  Video_Check:this.state.checkVideo,
                  Employer_Filter_Desc:this.state.checkDesc,
                  Employer_Certifica:this.state.checkCertifica,
                 
                }

              axios.post('https://getjob.stechomeyazilim.info:5101/insertEmployerFilter/send', filterData) .then(async(resFilter) => {
  
              let mapData = {
                Job_Employer_Map_Lat: this.state.mapData!= null ? this.state.mapData.currentLatitude : null,
                Job_Employer_Map_Lng: this.state.mapData!= null ? this.state.mapData.currentLongitude : null,
               // Job_Seeker_Map_Distance: this.state.sliderValue,
               Job_Employer_Map_City: this.state.mapData!= null ? this.state.mapData.currentCity : null,
              }

  
              axios.post('https://getjob.stechomeyazilim.info:5101/insertEmployerMap/send', mapData).then(async(resMap) => {
                

              let employerData = {
                Employer_Company_ID: res.data.value[0].ID,
                Job_ID:this.state.jobListID,
                Job_Experience_ID:this.state.jobExpreinceID,
                Employer_Salery_Expection_ID:this.state.jobSaleryID,
                Employer_Desc:this.state.desc,
                Employer_Map_ID:resMap.data.value[0].ID,
                Employer_Created_Date:localDate,
              //  Job_WayOfWorking_ID:Employer.Job_WayOfWorking_ID,
                Employer_CheckActive:true,
                Users_ID:loginData.ID,
                Employer_Filter_ID:resFilter.data.value[0].ID
              }
  
   await axios.post(`https://getjob.stechomeyazilim.info:5101/insertEmployer/send`, employerData) .then(async(resEmpoyer) => {
  

   this.state.educationStatus.map(async (data) => {
      let educationData = {
        Employer_Education_Status: data.value,
       Employer_ID: resEmpoyer.data.value[0].ID
      }
      axios.post('https://getjob.stechomeyazilim.info:5101/insertEmployerEducation/send', educationData)

    })
  

    this.state.jobwayout.map(async (data) => {
      let jobWayData = {
        Employer_JobWay_Text: data.label,
        Employer_ID: resEmpoyer.data.value[0].ID
      }
      axios.post('https://getjob.stechomeyazilim.info:5101/insertEmployerJobWayOut/send', jobWayData)
    })
  
          
    this.state.languageData.map(async (data) => {
      let languageData = {
        Job_Employer_Language: data.value,
       Job_Employer_ID: resEmpoyer.data.value[0].ID
      }
      axios.post('https://getjob.stechomeyazilim.info:5101/insertEmployerLanguage/send', languageData)
      })
  
      this.state.jobModal.map(async (data) => {
        let jobModalData = {
          Employer_JobModal_Text	: data.label,
          Employer_ID: resEmpoyer.data.value[0].ID
        }
        axios.post('https://getjob.stechomeyazilim.info:5101/postEmployerJobModal/send', jobModalData)
        })
    
  
             this.showToast('Başarıyla Bilgilerinizi Eklediniz',true)
            this.props.navigate('/employeeınfo/' + parseInt(loginData.ID), { state: { _item: { ID: parseInt(loginData.ID) } } })
          })
               })
              })
          }).catch(function (error) {
          })
      }
      catch (error) {
      }
    }

onMarkerClick = (props, marker, e) =>{
      this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
      })
  }
  
    onClose = props => {
      if (this.state.showingInfoWindow) {
        this.setState({
          showingInfoWindow: false,
          activeMarker: null
        });
      }
    };
  
    _changeMaps= async (lat,long) => {
      this.setState({defaultProps: {
          center: {
            lat: lat,
            lng: long
          },
          zoom: 11
        } ,
        selectedLat:lat,
        selectedLng:long,
  
      
      },()=> {
      })
      }
      _setMap = async (item) => {
  
      this.setState({
          openDetail:true,
          mapDetail:item
      })
  }
      
async componentDidMount() {

  await this._getJob()
  await this._getCountries()
}

_getCountries= async () => {
  await axios.get(`https://getjob.stechomeyazilim.info:5101/getCountries/`)
  .then((res) => {


    res.data.map((item, index) => (
    this.setState(prevState => ({
      listCountries: [...prevState.listCountries, {label:item.name,value:item.iso2}]
    }))
    ))
     })

}

_getCity= async (city,cityName) => {
  
  this.setState({
    countries:city,
    countriesName:cityName
  })
  await axios.get(`https://getjob.stechomeyazilim.info:5101/getCity/${city}`)
  .then((res) => {
    this._getDistrict()

    let newCityList = res.data.sort(function (a, b) {
      return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
    })

    newCityList.map((item, index) => (
      
    this.setState(prevState => ({
      listCity: [...prevState.listCity, {label:item.name,value:item.iso2}]
    }))
    ))

     })


     await axios.get(`https://getjob.stechomeyazilim.info:5101/getCity/${city}`)
  .then((res) => {

    let newCityList = res.data.sort(function (a, b) {
      return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
    })


    newCityList.map((item, index) => (
    this.setState(prevState => ({
      listCityEducation: [...prevState.listCityEducation, {label:item.name,value:item.iso2}]
    }))
    ))
     })
     
}

_getDistrict= async (ID,cityName) => {
  this.setState({
    cityName:cityName,
    listDiscrict : []
  })
  try{

  await axios.get(`https://getjob.stechomeyazilim.info:5101/getDistrict/${this.state.countries}/${ID}`)
  .then((res) => {

    let newCityList = res.data.sort(function (a, b) {
      return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
    })

    newCityList.map((item, index) => (
    this.setState(prevState => ({
      listDiscrict: [...prevState.listDiscrict, {label:item.name,value:item.name}]
    }))
    ))
     })
    }
    catch (error) {
     console.log("_updateForm" + error)
    }
}

_getJob= async () => {
  try{
  await axios.get('https://getjob.stechomeyazilim.info:5101/getJob/select')
  .then((res) => {

    res.data.map((item, index) => (
    this.setState(prevState => ({
      jobListNew: [...prevState.jobListNew, {label:item.Job_Text,value:item.ID}]
    }))
    ))

    this.setState({isLoading:false})
     })
    }
    catch (error) {
     console.log("_getJob123" + error)
    }
}


  handleChange= async (val,type) => {
        
    console.log()
    if(type=="employeeNumber"){
      this.setState({
        employeeNumber:val.value
      })
    }


    if(type=="Employer_Company_AddressText"){
      this.setState({
        Employer_Company_AddressText:val.target.value
      })
    }

    if(type=="jobModal"){
      this.setState({
        jobModal:val
      })
    }

    if(type=="startAge"){
      if(val<100){
        this.setState({
          startAge:val
        })
    }else{
      this.showToast('GetJob', "3 haneli rakam giremezsiniz!", 'false')

    }
    }
    if(type=="finishAge"){
      if(val<100){
        this.setState({
          finishAge:val
        })
    }else{
      this.showToast('GetJob', "3 haneli rakam giremezsiniz!", 'false')

    }
    }

    if(type=="name"){
      this.setState({
        companyName:val
      })
    }

    if(type=="sector"){      
      this.setState({
        sector:val
      })
    }

    if(type=="foundationYear"){
       this.setState({
        foundationYear:val
      })
    }

    if(type=="employeesNumer"){
      this.setState({
        employeesNumer:val
      })
    }

    if(type =="jobwayout"){
      this.setState({
        jobwayout:val
      })
    }
    
    if(type =="educationStatus"){
      this.setState({
        educationStatus:val
      })
    }

    if(type =="language"){
      this.setState({
        languageData:val
      })
    }  

    if(type =="jobs"){
      this.setState({
        jobListID:val.value
      })
    } 

    if(type =="desc"){
      this.setState({
        desc:val.target.value
      })
    }  

    if(type =="exprience"){
      this.setState({
        jobExpreinceID:val.value
      })
    }  

    if(type =="salery"){
      this.setState({
        jobSaleryID:val.value
      })
    }  
    
    
  }


_handleChange= async (value,type) => { 

  console.log("kjanfvalue",value)
  if(type=="officalNumber"){
    this.setState({officalNumber:value})
  }
  if(type=="officalUser"){
    this.setState({officalUser:value})
  }

  if(type=="gender"){
    this.setState({userGender:value.label})
  }
  if(type=="district"){
    this.setState({districtName:value.label})
  }
  if(type=="employeesTaxNumber"){
    this.setState({employeesTaxNumber:value})
  }
  if(type=="employeesMail"){
    this.setState({employeesMail:value})
  }
  if(type=="employeesNumber"){
    this.setState({employeesNumber:value})
  }
  if(type =="jobwayout"){
    this.setState({jobwayout:value})
  }
  if(type =="educationStatus"){
    this.setState({educationStatus:value})
  }
  if(type =="language"){
    this.setState({languageData:value})
  }  

  if(type =="employeesOfficalUser"){

    this.setState({employeesOfficalUser:value})
  }  
  
}

onFileChangePortfolyo = async (e,type) => { 
  let file = e.target.files[0];

  console.log("123123file",file)
  if (file.size > 15e6) {
    window.alert("Lütfen dosya yüklerken 15 MB'ı geçmemeli!");
    return false;
  }else{
  try{
  this.setState({isLoading:true})

  if(type=="photo"){
    let files = e.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    //fileReader.readAsText(files[0]);
  
    fileReader.addEventListener('loadstart', this.changeStatus('Start Loading'));
    fileReader.addEventListener('load', this.changeStatus('Loaded'));
    fileReader.addEventListener('loadend', this.loaded);
    fileReader.addEventListener('progress', this.setProgress);
    fileReader.addEventListener('error', this.errorHandler);
    fileReader.addEventListener('abort', this.changeStatus('Interrupted'));
    
  
    fileReader.onload = (event) => {
        this.setState({
        isPhotoLoading:true,
        isLoading: false,
        selectedImagePhoto: [{ ID: parseInt(this.state.selectedImagePhoto.length+1), data: parseInt(this.state.selectedImagePhoto.length+1) + ". Belge", type : 'image' , imageData : event.target.result,type : files[0].type == "image/png" ? "photo"  :  files[0].type == "application/pdf" ? "document"  :  files[0].type == "video/quicktime" ? "video" :  files[0].type == "video/quicktime" ? "video" : null }]
      })
  
     
    }
  }
  if(type=="video"){
    this.setState({isVideoLoading: true,isLoading:true})
    let files = e.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    //fileReader.readAsText(files[0]);
  
    fileReader.addEventListener('loadstart', this.changeStatus('Start Loading'));
    fileReader.addEventListener('load', this.changeStatus('Loaded'));
    fileReader.addEventListener('loadend', this.loaded);
    fileReader.addEventListener('progress', this.setProgress);
    fileReader.addEventListener('error', this.errorHandler);
    fileReader.addEventListener('abort', this.changeStatus('Interrupted'));
    
  
    fileReader.onload = (event) => {
      this.setState({
        isVideoLoading:true,
        isLoading: false,
        selectedImageVideo: [{ ID: parseInt(this.state.selectedImageVideo.length+1), data: parseInt(this.state.selectedImageVideo.length+1) + ". Belge", type : 'image' , imageData : event.target.result,type : files[0].type == "image/png" ? "photo"  :  files[0].type == "application/pdf" ? "document"  :  files[0].type == "video/quicktime" ? "video" :  files[0].type == "video/quicktime" ? "video" : null }]
      })
    }
  }

}
catch (error) {
  this.setState({isLoading:false})
}
}

}


saveCheckBox= async (type) => {
  if(type == "military"){
    this.setState({checkMilitary: !this.state.checkMilitary})
  }
  if(type == "driverlicense"){
    this.setState({checkDriverLicense: !this.state.checkDriverLicense})
  }
  if(type == "gradient"){
    this.setState({checkGradient: !this.state.checkGradient})
  }
  if(type == "cv"){
    this.setState({checkCv: !this.state.checkCv})
  }
  if(type == "video"){
    this.setState({checkVideo: !this.state.checkVideo})
  }
  if(type == "desc"){
    this.setState({checkDesc: !this.state.checkDesc})
  }
  if(type == "certifica"){
    this.setState({checkCertifica: !this.state.checkCertifica})
  }
  //this.setState({isLoading: false,companyVideo:res.data})
}


_setShow= async () => {

}

changeStatus = (status) => {
//document.getElementById('status').innerHTML = status;
}

enableInputFile= (e,id) => {
document.getElementById(id).click();
}

setProgress = (e,type) => {
  const loadingPercentage = 100 * e.loaded / e.total;

  if(type == "portfolyo"){

    this.setState({
      loadingPercentagePortfolyo : loadingPercentage
    })
  }
  if(type == "video"){
    this.setState({
      loadingPercentageVideo : loadingPercentage
    })
  }
  if(type == "cv"){
    this.setState({
      loadingPercentageCv : loadingPercentage
    })
  }
  if(type == "gradient"){
    this.setState({
      loadingPercentageGradint : loadingPercentage
    })
  }
  if(type == "photo"){
    this.setState({
      loadingPercentagePhoto : loadingPercentage
    })
  }

}


loaded = (e) => {
const fr = e.target;
var result = fr.result;

this.changeStatus('Yükleme Tamamlandı!');
console.log('Result:', result);
}

errorHandler = (e) => {
this.changeStatus('Error: ' + e.target.error.name);
}

onSubmit= (type) => {
  this.setState({isLoading: true})
  if(type == "video"){
    const formData = { image: this.state.selectedImageVideo[0].imageData }
    let endpoint = "https://getjob.stechomeyazilim.info:5101/fileUpload/insert/";
     axios.post(endpoint, formData, {
     }).then(async(res) => {

        console.log('Fileploaded!',res.data);

        this.setState({isLoading: false,companyVideo:res.data})

        this.showToast('GetJob', "Başarıyla Görseller Yüklendi!", 'success')

    })
  }
  if(type == "photo"){
    console.log("sdjkfndlwks123",this.state.selectedImagePhoto)
    const formData = { image: this.state.selectedImagePhoto[0].imageData }
    let endpoint = "https://getjob.stechomeyazilim.info:5101/fileUpload/insert/";
     axios.post(endpoint, formData, {
     }).then(async(res) => {

        console.log('Fileploade123123d!',res.data);

        this.setState({isLoading: false,companyPhoto:res.data})

        this.showToast('GetJob', "Başarıyla Görseller Yüklendi!", 'success')

    })
  }


}

showToast= (event, value, type) => {
  if(type == "false"){
    toast.error(value, {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

  }else{
    toast.success(value, {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
   
  }

}



  render (){ 
  return (
    <div className="px-9 py-9">
     <Formik
       initialValues={{ companyName: '', password: '' }}
       validate={values => {
         const errors = {};
         if (!values.email) {
           errors.email = 'Required';
         } else if (
           !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
         ) {
           errors.email = 'Invalid email address';
         }
         return errors;
       }}
       onSubmit={(values, { setSubmitting }) => {
         setTimeout(() => {
           alert(JSON.stringify(values, null, 2));
           setSubmitting(false);
         }, 400);
       }}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         /* and other goodies */
       }) => (  
       <form
          onSubmit={handleSubmit}
        >

        <h3 className="mb-6 mt-3  text-[#081F43] font-[Gotham-Medium] text-lg  ">
          Firma Bilgileri
        </h3>
        
        <div className="w-full">
          <InputField  onBlur={handleBlur} handleChange={(e)=> this.handleChange(e,'name')} type="text" typeCheck = {"name"} value={this.state.companyName} labelFiled={"Firma Adı"} />

          <InputField  handleChange={(e)=> this.handleChange(e,'sector')} type="text" typeCheck = {"sector"} value={this.state.sector} labelFiled={"Sektör"} />

          <InputField  handleChange={(e)=> this.handleChange(e,'foundationYear')} type="number" typeCheck = {"foundationYear"} disabled={this.state.foundationYearLength == 4 ? true : false} value={this.state.foundationYear} labelFiled={"Kuruluş Yılı"} />

          <InputField  handleChange={(e)=> this.handleChange(e,'employeesNumer')} type="number" typeCheck = {"employeesNumer"} disabled={this.state.foundationYearLength == 4 ? true : false} value={this.state.employeesNumer} labelFiled={"Çalışan Sayısı"} />

          <InputField  handleChange={(e)=> this._handleChange(e,'employeesNumber')} type="number" typeCheck = {"employeesNumber"}  value={this.state.employeesNumber} labelFiled={"Telefon Numarası"} />

          <InputField  handleChange={(e)=> this._handleChange(e,'employeesMail')} type="employeesMail" typeCheck = {"employeesMail"}  value={this.state.employeesMail} labelFiled={"Mail Adresi"} />

          {/* <InputField  handleChange={(e)=> this._handleChange(e,'employeesOfficalUser')} type="employeesOfficalUser" typeCheck = {"employeesOfficalUser"}  value={this.state.employeesOfficalUser} labelFiled={"Yetkili Kişi"} /> */}
          <InputField  handleChange={(e)=> this._handleChange(e,'employeesTaxNumber')} type="number" typeCheck = {"employeesTaxNumber"}  value={this.state.employeesTaxNumber} labelFiled={"Vergi Numarası"} />


          <InputField  handleChange={(e)=> this._handleChange(e,'officalUser')} type="officalUser" typeCheck = {"officalUser"}  value={this.state.officalUser} labelFiled={"Yetkili Kişi"} />

          <InputField  handleChange={(e)=> this._handleChange(e,'officalNumber')} type="number" typeCheck = {"officalNumber"}  value={this.state.officalNumber} labelFiled={"Yetkili Telefon"} />



          <h3 className="mt-8 mb-4 text-[#081F43] font-[Gotham-Medium] text-lg">Firma Adresiniz</h3>
        <div className="w-full ">
          <textarea
         onChange={(e)=> this.handleChange(e,"Employer_Company_AddressText")}
            className="border-2 border-[#EBECF8] h-[171px] w-full rounded-lg"
            value={this.state.Employer_Company_AddressText}
            placeholder={"Firma Adresinizi Giriniz"}
          />
        </div>


        <h3 className="mt-8 mb-4 text-[#081F43] font-[Gotham-Medium] text-lg">
          Firma Adresiniz
        </h3>
        
        {this.state.listCountries.length > 0  ?  <SelectOption   fullvalue={this.state.listCountries} handleChange={(e)=> this._getCity(e.value,e.label)} value={this.state.countriesName} selectLabel={"Ülke"} type={"countries"}  />: null}
          {this.state.listCity.length > 0  ? <SelectOption fullvalue={this.state.listCity} handleChange={(e)=> this._getDistrict(e.value,e.label)}  value={this.state.cityName} selectLabel={"İl"} type={"city"}  /> : null}
          {this.state.listDiscrict.length > 0  ? <SelectOption fullvalue={this.state.listDiscrict}  handleChange={(e)=> this._handleChange(e,"district")} value={this.state.districtName} selectLabel={"İlçe"} type={"district"}/> : null }


          <h3 className="mt-6 mb-8 text-[#081F43] font-[Gotham-Medium] text-lg text-center">
          İLAN BİLGİLERİ
        </h3>

        <h3 className="mt-6 mb-8 text-[#081F43] font-[Gotham-Medium] text-lg">
          Çalışma Şekli
        </h3>
       
        <div className="w-full">
        <MultiSelectOption  handleChange={this.handleChange}  value={this.state.jobwayout} type={"jobwayout"} labelFiled="Çalışma Şekli" /> 
        </div> 


        <h3 className="mt-6 mb-8 text-[#081F43] font-[Gotham-Medium] text-lg">
         Eğitim Durumu
        </h3>
        <MultiSelectOption handleChange={this.handleChange} value={this.state.educationStatus} type={"educationStatus"} selectLabel="Eğitim Durumu" />

        <h3 className="mt-6 mb-8 text-[#081F43] font-[Gotham-Medium] text-lg">
          Çalışma Modeli
        </h3>
       
         <div className="w-full">
        <MultiSelectOption  handleChange={this.handleChange}  value={this.state.jobModal} type={"jobModal"} labelFiled="Çalışma Modeli" /> 
        </div> 
        
        <h3 className="mt-6 mb-8 text-[#081F43] font-[Gotham-Medium] text-lg">
         Bu Pozisyon İçin Kaç Kişi İşe Alacaksınız?
        </h3>

        <div className="w-full">
          <SelectOption  handleChange={this.handleChange} value={this.state.employeeNumber} type={"employeeNumber"}  labelFiled="Kaç Kişi İşe Alacaksınız" />
        </div> 

        <h3 className="mt-6 mb-8 text-[#081F43] font-[Gotham-Medium] text-lg">
          Aranılan Pozisyon
        </h3>
        {this.state.isLoading == false && this.state.jobListNew.length > 0 ? <div className="w-full">
          <SelectOption fullvalue={this.state.jobListNew}  handleChange={this.handleChange} value={this.state.jobListID} type={"jobs"}  labelFiled="Aranılan Pozisyon" />
        </div> : null }

        <h3 className="mt-6 mb-8 text-[#081F43] font-[Gotham-Medium] text-lg">
        Aranılan Deneyim Yılı
        </h3>
        <div className="w-full">
         <SelectOption  fullvalue={[{value : "deneme"}]} handleChange={this.handleChange} value={this.state.jobExpreinceID} selectLabel="Aranılan Deneyim Yılı" type={"exprience"}/> 
        </div>

        <h3 className="mt-6 mb-8 text-[#081F43] font-[Gotham-Medium] text-lg">
          Maaş Beklentisi
        </h3>

        <div className="w-full">
      <SelectOption handleChange={this.handleChange} selectLabel="Maaş" value={this.state.jobSaleryID} type={"salery"}/> 
        </div>

        <h3 className="mt-6 mb-8 text-[#081F43] font-[Gotham-Medium] text-lg">
          Yabancı Dil
        </h3>
        <div className="w-full">
        <MultiSelectOption  handleChange={this.handleChange} labelFiled="Dil" value={this.state.languageData} type={"language"}  />
        </div>




        <h3 className="mt-8 mb-4 text-[#081F43] font-[Gotham-Medium] text-lg">Gerekli Nitelikler</h3>
        <div className="w-full ">
          <textarea
         onChange={(e)=> this.handleChange(e,"desc")}
            className="border-2 border-[#EBECF8] h-[171px] w-full rounded-lg"
            value={this.state.desc}
            placeholder={"Lütfen Gerekli Nitelikleri Giriniz"}
          />
        </div>

                </div>
       
        <h3 className="mt-8 mb-4 text-[#081F43] font-[Gotham-Medium] text-lg">
          Kurumsal Logo
        </h3>

                <div className="w-full">
                <div className="w-full  bg-white p-2 rounded-md border-2 border-[#EBECF8] relative items-center text-center">
            <div className="grid w-full h-full bg-zinc-200 border rounded-md ">
            <button className="flex justify-center items-center" onClick={e => this.enableInputFile(e, "photo")}>

                <div  style={{ borderWidth: 0.3, borderColor: '#081F43', justifyContent: 'center', alignItems: 'center', borderRadius: 100 }}>
                  <FontAwesomeIcon icon={faCameraAlt} size={"xl"} color={'#081F43'} style={{ margin: 20 }} />
                </div>
                
           
             <p className="flex justify-center items-center text-[#081F43] text-lg hover:bg-white border-0 rounded-lg p-2 font-[Gotham-Medium]">
                  Fotoğraf Ekle
                </p>
              
              </button>
              <input
              key={"photo"}
              type="file"
              id={"photo"}
              className="hidden"
              onChange={e=> this.onFileChangePortfolyo(e,"photo")}
              data-name={"photo"}
              accept="image/png, image/jpeg,image/webp"/>
                       

         <ProgressBar value="0" max="100" key={1} bgcolor={'#081F43'} completed={this.state.loadingPercentagePhoto}  />


         <div className="flex grid-rows-4 grid-flow-col gap-2">
            {this.state.selectedImagePhoto.map((item, index) => ( 
              <div className="bg-white border-2 m-1 rounded-md ">
                <text className=" p-1 font-[Gotham-Medium]">{item.data}</text>
                <div  className="w-40 justify-center items-center">
                <img src={item.imageData} alt="" className="w-full px-2" />
                </div>
            </div>
             ))}

{this.state.isPhotoLoading == true ? <button type="submit" className="bg-[#081F43] p-2 text-white rounded-lg" onClick={()=>this.onSubmit("photo")}>Dosyaları Yükle</button>: null}

              </div>
              
                          </div>


          </div>
         
        </div>

        <h3 className="mt-8 mb-4 text-[#081F43] font-[Gotham-Medium] text-lg">
          Kurumsal Video
        </h3>
        <div className="w-full  bg-white p-2 rounded-md border-2 border-[#EBECF8] relative items-center text-center">
            <div className="grid w-full h-full bg-zinc-200 border rounded-md  ">
            <button className="flex justify-center items-center" onClick={e => this.enableInputFile(e, "video")}>

            <div  style={{ borderWidth: 0.3, borderColor: '#081F43', justifyContent: 'center', alignItems: 'center', borderRadius: 110 }}>
                  <FontAwesomeIcon icon={faVideoCamera} size={"xl"} color={'#081F43'} style={{ margin: 20 }} />
                </div>
                
           
                 <p className="flex justify-center items-center text-[#081F43] text-lg hover:bg-white border-0 rounded-lg p-2 font-[Gotham-Medium]">
                  Video Ekle
                </p>
             
              </button>
              <input
              key={"video"}
              type="file"
              id={"video"}
              className="hidden"
              onChange={e=> this.onFileChangePortfolyo(e,"video")}
              data-name={"video"}
              accept="video/mp4,video/x-m4v,video/*,video/quicktime,video/3g2, video/3gp, video/3gp2, video/asf, video/asx, video/avi, video/m4v, video/mpe, video/mpg,video/wmv"/>
                       

               <ProgressBar value="0" max="100" key={1} bgcolor={'#081F43'} completed={this.state.loadingPercentageVideo}  />


               <div className="flex  justify-center items-center">
            {this.state.selectedImageVideo.map((item, index) => ( 
              <div className="bg-white border-2 m-1 rounded-md justify-center items-center text-center">
                <text className=" p-1 font-[Gotham-Medium] text-center">{item.data}</text>
                {item.type == "video" ? 
                 <div style={{ height: '40vh', width: '100%',zIndex:10 }} className="z-10 ex-collection-box mb-xl-20">
                  <ReactPlayer controls={true} width="100%" height="100%" url={item.imageData}/>
                  </div> :
                null}
            </div>
             ))}

{this.state.isVideoLoading == true ? <button type="submit" className="bg-[#081F43] p-2 text-white rounded-lg" onClick={()=>this.onSubmit("video")}>Video'yu Yükle</button>: null}

              </div>
              
                          </div>


          </div>
          

                <h3 className="mt-8 mb-4 text-[#081F43] font-[Gotham-Medium] text-lg">
          Konumunuz
        </h3>
        
  <div className="z-11">
<Autocomplete
  apiKey={"AIzaSyCm32U9QFgSBe_8pJG_2Z3G61pL6ipeQtU"}
  style={{ width: "100%" }}

  onPlaceSelected={(place) => {
    console.log("place123", place.address_components[1]);
    this.setState({
      currentLatitude:place.geometry.location.lat(),
      currentLongitude:place.geometry.location.lng(),

      mapData:{
        ID :  1,
            position:{
              lat:  place.geometry.location.lat(),
              lng:  place.geometry.location.lng(),
        },
      currentLatitude :  place.geometry.location.lat(),
      currentLongitude :  place.geometry.location.lng(),
      currentCity : place.address_components[1].short_name
    },
    currentCity : place.address_components[1].short_name,
    })
  }}
  options={{
    types: ["(regions)"],
    //componentRestrictions: { country: "tr" },
  }}
  defaultValue="Konya"
/>
</div> 
{this.state.mapData != null ? <div style={{ height: '80vh', width: '100%' }} className="z-10 ex-collection-box mb-xl-20">

<MapWrapped
   googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCm32U9QFgSBe_8pJG_2Z3G61pL6ipeQtU&v=3.exp&libraries=geometry,drawing,places}`}
   loadingElement={<div style={{ height: `100%` }} />}
   containerElement={<div style={{ height: `100%` }} />}
   mapElement={<div style={{ height: `100%` }} />}
   dealerData = {this.state.mapData != null ? this.state.mapData : null}
   currentLat={this.state.currentLatitude}
   currentLng={this.state.currentLongitude}
 />
 </div> : null}
      </form>
      )}
     </Formik>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>

<h3 className="mb-6 mt-3  text-[#081F43] font-[Gotham-Medium] text-lg  ">
          Filtre
        </h3>
        
                  <FormGroup className="justify-center items-center">
          <div className="grid xxs:grid-cols-3 xs:grid-cols-4 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-6">

          <SelectOption  handleChange={(e)=> this._handleChange(e,"gender")} value={this.state.userGender}  type={"gender"} />
          {this.state.userGender == "Kadın" ? null :  <FormControlLabel onClick={()=> this.saveCheckBox('military')} control={<Checkbox checked={this.state.checkMilitary}/>} label={this.state.checkMilitary == true ?  "Askerlik" : "Askerlik"}  />}
          <FormControlLabel onClick={()=> this.saveCheckBox("driverlicense")} control={<Checkbox checked={this.state.checkDriverLicense}/>} label={this.state.checkDriverLicense == true ? "Ehliyet" : "Ehliyet"}  />
          <FormControlLabel onClick={()=> this.saveCheckBox("gradient")} control={<Checkbox checked={this.state.checkGradient}/>} label={this.state.checkGradient == true ? "Mezuniyet": "Mezuniyet" } />
          <FormControlLabel onClick={()=> this.saveCheckBox("cv")} control={<Checkbox checked={this.state.checkCv}/>} label={this.state.checkCv == true ? "Cv": "Cv" } />
          <FormControlLabel onClick={()=> this.saveCheckBox("video")} control={<Checkbox checked={this.state.checkVideo}/>} label={this.state.checkVideo == true ? "Videolu Özgeçmiş": "Videolu Özgeçmiş" } />
          <FormControlLabel onClick={()=> this.saveCheckBox("desc")} control={<Checkbox checked={this.state.checkDesc}/>} label={this.state.checkDesc == true ? "Ön Yazı": "Ön Yazı" } />
          <FormControlLabel onClick={()=> this.saveCheckBox("certifica")} control={<Checkbox checked={this.state.checkCertifica}/>} label={this.state.checkCertifica == true ? "Sertifika&Portfolyo": "Sertifika&Portfolyo" } />

          </div>



          <h3 className="mt-3  text-[#081F43] font-[Gotham-Medium] text-lg  ">
          Yaş Aralığı
        </h3>
        

        <div className="flex justify-center items-center w-full">
          <div className="pl-2 w-full">
          <InputField handleChange={(e)=> this.handleChange(e,'startAge')} type="number" typeCheck = {"startAge"} value={this.state.startAge} labelFiled={"Başlangıç Yaşı"} />
         </div>
         <div className="pl-2 w-full">
          <InputField handleChange={(e)=> this.handleChange(e,'finishAge')} type="number" typeCheck = {"finishAge"} value={this.state.finishAge} labelFiled={"Bitiş Yaşı"}  />
          </div>
          </div>
        
        
              </FormGroup>

          <button className="bg-[#284B84] rounded-lg m-1 justify-center w-full items-center flex" onClick={(e)=> this._updateForm(e)}>
          <div className="bg-white rounded-lg justify-center item p-1">
          <FontAwesomeIcon icon={ faSave }   color={'#284B84'}/> 
          </div>  
          <a className="text-center text-white p-2 font-[Gotham-Medium]">Bilgileri Kaydet</a>
          </button>
          
<LoadingSpinner show={this.state.isLoading} setShow={this._setShow}/> 

    </div>
  )
}
};

const MapWrapped = withScriptjs(withGoogleMap(Map));

export default withParamsAndNavigate(PersonelInformation);


