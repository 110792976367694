import React,{ useEffect } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { BsQuestionCircle } from "react-icons/bs";
import { FaKey } from "react-icons/fa";
import { AiFillEyeInvisible } from "react-icons/ai";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { GrClose } from "react-icons/gr";
import { useState } from "react";
import ForgotPassword from "./ForgotPassword";
import axios from "axios";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { ToastContainer, toast } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  boxShadow: 24,
};

const Autharizatiom = (props) => {
  const [forgotPassword, setForgotPassword] = useState(false);
  const [check, setCheck] = useState(false);
  const handleForgotPassword = () => {
    setForgotPassword(true);
  };

  useEffect(() => {
    let data =  JSON.parse(localStorage.getItem("dataLoginText"))
    if(data != null){
      console.log("1213ata",data.phone)
      setCheck(true)

      setPassword(data.password)
      setPhone(data.phone)
    }else{
      setCheck(false)
    }
   

  },[]);


 const showToast= (event, value, type) => {
    if(type == "false"){
      toast.error(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });

    }else{
      toast.success(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
        window.location.reload(false);

    }
  
  }



  
  const saveData = async() => {
    try{

     let loginData = {
      phone:phone,
      password:password
     } 
      setCheck(!check)
      localStorage.setItem('dataLoginText', JSON.stringify(loginData));

    } catch (error) {
      console.log("getList" + error)
    }

  }
  const getList = async() => {
    try{
         await axios.get(`https://getjob.stechomeyazilim.info:5101/getUserDetail/select/${phone}/${password}`)
          .then(async(res) => {
              console.log("kfnkj",res.data.length)
              if(res.data.length ==0){
                showToast('GetJob', "Giriş Bilgileriniz Yanlış!", 'false')
              }else{

                if(res.data[0].Users_Active == false){
                  try{                
                   let user = {
                    Users_Active :true
                   }
                   
                   await axios.patch(`https://getjob.stechomeyazilim.info:5101/usersUpdate/${res.data[0].ID}`, user)
                   .then(async (res1) => {
                    showToast('GetJob', "Hesabınız Pasif Durumdan Aktif Duruma Geçirildi!", 'success')
                    localStorage.setItem('dataLogin', JSON.stringify(res.data[0]));
                   })
                  } catch (error) {
                    console.log("_getList" + error)
                  }

                
                }else{
                  localStorage.setItem('dataLogin', JSON.stringify(res.data[0]));
                  showToast('GetJob', "Başarıyla Giriş Yaptınız!", 'success')
                }

              

              }
             })
             
   }
   catch (error) {
     console.log("getList" + error)
   }
  }
  
  
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');


  return (
    <Modal
  open={props.openAuthModalVisible}
  onClose={props._closeAuthModal}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
<Box className='' sx={style}>

    <div className="w-full">
      <div
        className={`${
          forgotPassword
            ? "hidden"
            : "flex flex-col  justify-center items-center"
        } bg-white shadow-2xl    rounded-lg w-[470px] px-2  `}
      >
        {!forgotPassword && (
          <div>
          <div className="flex justify-end z-50 mr-[-50px] ">
              <button
                className="bg-[#f8d581] rounded-full p-2"
                onClick={props.handleLogIn}
              >
                <GrClose className="" />
              </button>
            </div>
            <div className="w-full flex justify-between  py-3 px-2">
              <h2 className="text-xl font-[Gotham-Medium] text-gray-500 ">Giriş Yap</h2>
              <button onClick={props.handleForgotPassword}>
                <BsQuestionCircle />
              </button>
            </div>
            <div className="flex w-full  shadow-xl  mb-4">
              <div
                className="w-full flex py-3 bg-zinc-200 font-[Gotham-Medium] justify-center items-center   "
                onClick={""}
              >
                <BsFillPersonFill className="ml-3 mr-7 flex justify-center items-center w-7 h-7 " />
                <input
                  type="email"
                  value={phone}
                  className="bg-zinc-200 w-full font-[Gotham-Medium] outline-none focus:border-b-2 focus:border-blue-500 mr-3"
                  placeholder="E-posta veya Telefon"
                  onChange={e => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div className="flex w-full  shadow-xl  mb-1">
              <div
                className="w-full flex py-3 bg-zinc-200  justify-center items-center   "
                onClick={""}
              >
                <FaKey className="ml-3 mr-7 flex justify-center items-center w-6 h-6 fill-green-600" />
                <input
                  type="password"
                  value={password}
                  className="bg-zinc-200 w-full font-[Gotham-Medium]  outline-none focus:border-b-2 focus:border-blue-500 "
                  placeholder="Şifreniz"
                  onChange={e => setPassword(e.target.value)}
                />
                <div className=" px-3 flex ">
                  <div className="rounded-full hover:bg-white mr-2 justify-center items-center p-1 ">
                    <AiFillEyeInvisible className="w-6 h-6" />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex mb-3 ">
              <FormGroup>
                <FormControlLabel  onClick={()=> saveData()} control={<Checkbox checked={check}/>} label="Beni Hatırla"  />
              </FormGroup>
            </div>
            <div className="w-full flex mb-4">
              <button className=" mx-auto border bg-[#081F43] hover:text-white hover:bg-blue-900 text-blue-900 px-32 py-4 rounded-lg shadow-md">
                <h2 className="text-xl font-[Gotham-Medium] text-[white]" onClick={()=> getList()  }>Giriş Yap</h2>
              </button>
            </div>
            <div className="w-full bg-[#f8d581] flex py-4 mb-2">
              <div className="flex mx-auto justify-center items-center">
                <h3 className="mr-4 font-[Gotham-Medium]">Henüz bir hesabınız yok mu?</h3>
                <button onClick={()=> props._closeLoginOpenSingUpModal()} className="font-[Gotham-Medium] p-3 border bg-blue-900 hover:text-blue-900 hover:bg-yellow-500 text-white rounded-lg ">
                  <a>Kayıt Ol</a>
                </button>
              </div>
            </div>


          </div>
        )}
      </div>
      {forgotPassword && <ForgotPassword/>}

      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    </div>
</Box>
    </Modal>
  );
};

export default Autharizatiom;
