import React, {Component} from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import Adds from "./Adds/Adds";
import FeaturedAds from "./FeaturedAds/FeaturedAds";
import GetTheApp from "./GetTheApp/GetTheApp";
import PopularJobs from "./PopularJobs/PopularJobs";
import PopluerEmployers from "./PopularJobs/PopluerEmployers";
import SearchingPartEmployer from "./SearchingPart/SearchingPartEmployer";
import SearchingPartJobSeeker from "./SearchingPart/SearchingPartJobSeeker";
import axios from 'axios';
import { withParamsAndNavigate } from "./getParamsAndNavigate.js";
import LoadingSpinner from "../../LoadingSpinner";
import { ToastContainer, toast } from 'react-toastify';
import SuccessProfile from "../MainPage/LogIn/SuccessProfileScreen";
import { sendSmsVerification } from "../verify";

class MainPage extends Component{
  constructor() {
    super()
    this.state = {
      loginData:null,
      listJobSeeker:[],
      listEmployer:[],
      isLoading:true,
      registerData:{},
      otpModalCheck:false,
      otpSuccessProfile:false
    }
}

async componentDidMount() {
  //localStorage.clear();
    if(JSON.parse(localStorage.getItem('dataLogin') != null)){

    this.setState({ loginData: JSON.parse(localStorage.getItem('dataLogin')) })

    if (JSON.parse(localStorage.getItem('dataLogin')).Users_Type_ID==1) {
      await this._getListJobSeeker()

    }  if (JSON.parse(localStorage.getItem('dataLogin')).Users_Type_ID==2) {
      await this._getListEmployer()
     
    }if (JSON.parse(localStorage.getItem('dataLogin')).Users_Type_ID== null) {
      await this._getListEmployer()
     
      this.setState({ otpSuccessProfile: true})
    }
  }else{
    this.setState({isLoading:false })   
  }
}

_alert= () => {

}

_closeProfileModal= async() => {
  this.setState({otpSuccessProfile :false})
}
 _openToast= async(type) => {

  if(type == "patch"){
    this.showToast('GetJob', "Başarıyla Hesabınız Donduruldu!", 'success')
    localStorage.removeItem('dataLogin');
    window.location.reload(true);
    const{params,navigate} = this.props;

     navigate("/")

  }else{
    this.showToast('GetJob', "Başarıyla Hesabınız Silindi!", 'success')
  }
  
}

 _openToastModal= async(type) => {
    this.showToast('GetJob', "Linkiniz başarıyla kopyalandı!", 'success')
}
_getListJobSeeker = async () => {
  try{
    let loginData =  JSON.parse(localStorage.getItem('dataLogin'))

    console.log("lsndflkds",loginData.ID)
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getJobSeekerSearch/select/${loginData.ID}`).then(async(resJobSeeker) => {

    console.log("lsndflkccccds",resJobSeeker.data[0].ID)

   if(resJobSeeker.data.length > 0) {
       await axios.get(`https://getjob.stechomeyazilim.info:5101/getJobSeeker/select/${resJobSeeker.data[0].ID}`).then(async(res) => {

          console.log("resJobSecceker123",res)

            this.setState({listJobSeeker: res.data,isLoading:false })   
           
            await this._getListEmployerCheck()
         
           })
          }
          })
  }
  catch (error) {
   console.log("_getListJobSeeker" + error)
  }
  }

   showToast= (event, value, type) => {
    if(type == "false"){
      toast.error(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
  
    }else{
      toast.success(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      
    }
  
  }

   _successProfile = async(type,ID) => {
    console.log("type123",type)
  

    try{
      this.setState({otpModalCheck:false});
      this.setState({otpSuccessProfile:false})
      let loginData =  JSON.parse(localStorage.getItem('dataLogin'))
  
      const{ navigate } = this.props;

     let user = {
      Users_Type_ID :ID
     }
     
     await axios.patch(`https://getjob.stechomeyazilim.info:5101/usersUpdate/${loginData.ID}`, user)
     .then(async (res) => {


      localStorage.setItem('dataLogin', JSON.stringify({
        ID : loginData.ID,
        Users_NameSurname:loginData.Users_NameSurname,
        Users_Gender:loginData.Users_Gender,
        Users_Birthday:loginData.Users_Birthday,
        Users_Military:loginData.Users_Military,
        Users_Active:loginData.Users_Active,
        Users_Profile_Photo:loginData.Users_Profile_Photo,
        Users_Type_ID:type,
        Users_TelephoneNumber:loginData.Users_TelephoneNumber,
        Users_Password:loginData.Users_Password,
        Users_Email:loginData.Users_Email,
        Users_RegisterType:loginData.Users_RegisterType
      }));

      if(type == 1){
        navigate('/newEmployer', { _item: { ID : ID }})
      }else{
        navigate('/newJobSeeker', { _item: { ID : ID }})
      }
     })
    } catch (error) {
      console.log("_getList" + error)
    }

   
  }

  
_getListEmployer = async () => {
  try {
    let loginData = JSON.parse(localStorage.getItem('dataLogin'))
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getEmployerSearch/select/${loginData.ID}/`).then(async(resEmployer) => {
    
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getEmployer/select/${resEmployer.data[0].ID}/`)
      .then(async(res) => {

        this.setState({ listEmployer: res.data,isLoading:false })
       
         await this._getListJobSeekerCheck()
     
      })
    })
  }
  catch (error) {
    console.log("_getListEmployer" + error)
  }
}

_getListJobSeekerCheck = async () => {
  try{
    let loginData = JSON.parse(localStorage.getItem('dataLogin'))

       await axios.get(`https://getjob.stechomeyazilim.info:5101/getJobSeekerProfile/select/${loginData.ID}`)
        .then((res) => {
          console.log("resdata123",res.data)

          if(res.data.length==0){
            const{params,navigate} = this.props;

            console.log("resdata123",res.data)
            navigate('/newJobSeeker')
          }else{

          }
           })
 }
 catch (error) {
   console.log("_getListJobSeekerCheck" + error)
 }
}


_getListEmployerCheck = async () => {
  try{
   let loginData = JSON.parse(localStorage.getItem('dataLogin'))
       await axios.get(`https://getjob.stechomeyazilim.info:5101/getEmployerProfile/select/${loginData.ID}`)
        .then((res) => {

          if(res.data.length==0){
            const{params,navigate} = this.props;
            navigate('/newEmployer')
          }else{

          }
           })
 }
 catch (error) {
   console.log("_getListEmployerCheck" + error)
 }
}


 _handleSignUp = async() => {
  this.setState({otpModalCheck:false});
  this.setState({otpSuccessProfile : true})
  
  let loginData = JSON.parse(localStorage.getItem('dataLogin'))


  const userData = {  
    Users_TelephoneNumber:loginData.Users_RegisterType == "phone" ? loginData.valuePhone : null,
    Users_Password:loginData.Users_Password,
    Users_Active:true,
    Users_Email:loginData.Users_RegisterType == "mail" ? loginData.Users_Email : null,
    Users_RegisterType:loginData.Users_RegisterType

   };

  axios.post('https://getjob.stechomeyazilim.info:5101/addUser/send', userData).then(async(res) => {
    console.log("res12113",res.data)
    if(res.data.value  == true){
     
      }else{
    

      }
   
  })
}
 _setSmsOtp=async()=>{     
  console.log("daslndla")     
        sendSmsVerification(this.state.registerData.valuePhone).then((sent) => {
            console.log("Sent!",sent);
            if (sent == true){
                this.setState({isLoading: false})

           /*     setData({
                    ...data,
                    otpVisible:true
                                    }) */

                
            }else{
              /*  setData({
                    ...data,
                    alertStatus:true,
                    alertMessage: 'Lütfen telefon numaranızı kontrol ediniz',
                }); */
            }
          });

  }

render(){
  return (
    <div className="" >
      <Navbar _openToast={this._openToast}/>
      <div className="w-full  flex flex-col">
        <div className="max-w-full mt-16 flex flex-col ">
          {this.state.loginData != null && this.state.loginData.Users_Type_ID == 1 ?   <SearchingPartJobSeeker list={this.state.listJobSeeker} />  : this.state.loginData != null && this.state.loginData.Users_Type_ID == 2 ? <SearchingPartEmployer  list={this.state.listEmployer} /> : null}
          <FeaturedAds/>
          {this.state.loginData != null && this.state.loginData.Users_Type_ID == 1 ?   <PopularJobs list={this.state.listJobSeeker} />  : this.state.loginData != null && this.state.loginData.Users_Type_ID == 2 ? <PopluerEmployers  list={this.state.listEmployer} /> : null}
          
          <Adds/>
          <GetTheApp/>
          <Footer/>

        </div>
      </div>
      <LoadingSpinner show={this.state.isLoading} /> 

      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>  

{this.state.loginData!= null ? <SuccessProfile userID= {this.state.loginData.ID} registerData={this.state.loginData} _closeProfileModal={this._closeProfileModal} _successProfile={this._successProfile} _otpSuccessProfileVisible ={this.state.otpSuccessProfile} _alert={this._alert} /> : null}

    </div>
  )
}
};



export default withParamsAndNavigate(MainPage);
