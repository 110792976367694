import React, { useEffect } from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import axios from 'axios';
import FirstBorderJobSeeker from "./FirstBorderJobSeeker";
import PersonelInfoResult from "./PersonelInfoResult";
import { useLocation } from "react-router-dom";
import BottomButtonPanel from "../Jobs/JobsDetail/BottomButtonPanel";
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import LoadingSpinner from "../../LoadingSpinner";
import LeftArrow from "../../assets/Jobs/LeftArrow.svg";
import {useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const EmployeeFormNew = () => {
  const location = useLocation();
  const state = location.state;

  const [list, setList] = React.useState([]);
  const [listLike, setListLike] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [listLiked, setListLiked] = React.useState([]);
  const navigate = useNavigate();

  const _goBack= () => {
    navigate(-1);
  }

  useEffect(() => {
    const _getListLiked= async() => {
      try{
      
        let loginData =  JSON.parse(localStorage.getItem('dataLogin'))

        console.log("123lkl",loginData.ID,state._item.ID)

        await axios.get(`https://getjob.stechomeyazilim.info:5101/getNotificationJobSeeker/select/${loginData.ID}/${state._item.ID}`)
         .then((res) => {
           
          setListLike(res.data)   
          })
  }
  catch (error) {
    console.log("_getListLiked" + error)
  }
    }

    const  _getList= async() => {

      try{
        setLoading(true)
        await axios.get(`https://getjob.stechomeyazilim.info:5101/getJobSeekerProfile/select/${state._item.Users.ID}`)
         .then((res) => {
          setLoading(false)

           setList(res.data)   
          })
  }
  catch (error) {
    setLoading(false)
    console.log("errorccAS" + error)
  }
    }

    const _getListFav= async() => {
      try{
        let loginData = JSON.parse(localStorage.getItem('dataLogin'))

        await axios.get(`https://getjob.stechomeyazilim.info:5101/getListFavJobSeeker/select/${loginData.ID}/${state._item.ID}`)
         .then((res) => {
          setListLiked(res.data)   
          })
  }
  catch (error) {
    console.log("errorccAS" + error)
  }
    }

    _getListFav()

    _getList()
    _getListLiked()

    window.scrollTo(0, 0);

  }, [])


  /* useEffect(() => {
   

    _getList()
  }, []) */

  const showToastLiked= (event, value, type) => {
    if(type == "false"){
      toast.error(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
        setListLiked([{"ID": 10}])

    }else{
      toast.success(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });

        setListLiked([{"ID": 10}])
       // navigate('/')
  
    }
  
  }

  const unlikeChange= async() => {
    try{
    let rspUserDetail =  JSON.parse(localStorage.getItem('dataLogin'))
    var testDateUtc = Moment.utc(new Date());

    var localDate = Moment(testDateUtc).local();
    let data = {
      Users_ID:rspUserDetail.ID,
      Job_Seeker_ID:state._item.ID,
      Created_DateTime:localDate
    }

    await axios.post(`https://getjob.stechomeyazilim.info:5101/postUnlikeJobSeeker/send`, data) .then((res) => {
      showToast('GetJob', "İlanı Beğenmekten Vazgeçtiniz!", 'false')
       })
    //navigate(-1);
  }
  catch (error) {
    setLoading(false)
    console.log("unlikeChange" + error)
  }
  }

 const showToast= (event, value, type) => {
    if(type == "false"){
      toast.error(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
        setListLike([{"ID": 10}])

    }else{
      toast.success(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });

        setListLike([{"ID": 10}])
       // navigate('/')
  
    }
  
  }
  const favChange = async() => {
    console.log("m1ösd123",state._item.ID)

    try {
      setLoading(true)   
     

      console.log("s1dkfn123",state._item)
      var testDateUtc = Moment.utc(new Date());
    
      var localDate = Moment(testDateUtc).local();
      let rspUserDetail =  JSON.parse(localStorage.getItem('dataLogin'))

      let data = {
        Job_Seeker_ID:state._item.ID,
        Users_ID:parseInt(rspUserDetail.ID),
        Created_DateTime:localDate
      }
      
      await axios.post(`https://getjob.stechomeyazilim.info:5101/postLikedFavEmployer/send`, data) .then((res) => {
        setLoading(false)  
        showToastLiked('GetJob', "Başarıyla İş Vereni Beğendiniz!", 'success')
         })
          

    } catch (err) {
      setLoading(false)  
      showToast('GetJob', "Bir Sorun Oluştu!", 'false')

        console.log("likeChange",err)
      }
  }
  
  const likeChange = async() => {
    try {
      setLoading(true)   
     

      console.log("sdkfn123",state._item)
      var testDateUtc = Moment.utc(new Date());
    
      var localDate = Moment(testDateUtc).local();
      let rspUserDetail =  JSON.parse(localStorage.getItem('dataLogin'))

      let data = {
        Notification_Text:'Sizinle Mülakat Yapmak İstiyor',
        Notifacation_Type_ID:5,
        Receiver_Users_ID:state._item.Users_ID,
        Sender_Users_ID: parseInt(rspUserDetail.ID),
        Notification_CreatedDateTime:localDate,
        Job_Seeker_ID:state._item.ID,
        Notification_IsActive:true
      }
      
      await axios.post(`https://getjob.stechomeyazilim.info:5101/insertNotificationJobSeeker/send`, data) .then((res) => {
        setLoading(false)  
      showToast('GetJob', "Başarıyla Talebiniz Gönderildi!", 'success')
         })
          

    } catch (err) {
      setLoading(false)  
      showToast('GetJob', "Bir Sorun Oluştu!", 'false')

        console.log("likeChange",err)
      }
  }

  const _setShow= async () => {

  }
  
console.log("klnsdf",state)
  return (
    <div >
      <Navbar />
      <div className="w-full  bg-[#F7F7F7] flex flex-col justify-between ">
        <div className="max-w-full mt-40 flex flex-col justify-center">
        <div className="flex justify-center items-center">
        <FontAwesomeIcon icon={faAngleLeft} style = {{margin:10}}  size={"xl"} color={"#204686"} />

          {state._item.Users != null ?  <button onClick={()=> _goBack()} className="w-full text-center text-xl text-[#204686] py-3 font-bold">
            {state._item.Users.Users_NameSurname} Detail
            </button> : null}
          </div>
          <div className=" border rounded-2xl bg-white w-3/5 mx-auto mb-28 h-full mt-12 flex  flex-col  ">
          {list.length>0 ? <FirstBorderJobSeeker edit={state.edit} item ={list[0]} /> : null }
            {list.map((item, index) => (<PersonelInfoResult _item={item} />))}
             </div>
          <Footer />
        </div>

        <BottomButtonPanel listLiked={listLiked} favChange={favChange} list={listLike} likeChange={likeChange} unlikeChange={unlikeChange}/>
        <LoadingSpinner show={isLoading} setShow={_setShow}/> 

      </div>
    </div>
  )

};

export default EmployeeFormNew;
