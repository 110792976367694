import React, {Component} from "react";
import EmployerImg from "../../assets/Jobs/Employer.svg";
import MatchCard from "./MatchCard";
import { GrClose } from "react-icons/gr";
import axios from "axios";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '2px solid #000',
    boxShadow: 24,
  };

  class Match extends Component{
    constructor(props) {
      super(props)
      this.state = {
        loginData:null,
        listJobSeeker:[],
        listEmployer:[]
      }
  }
  
async componentDidMount() {
  if(JSON.parse(localStorage.getItem('dataLogin') != null)){
    this.setState({ loginData: JSON.parse(localStorage.getItem('dataLogin')) })

    if (JSON.parse(localStorage.getItem('dataLogin')).Users_Type_ID==1) {
      await this._getListEmployerNotification(JSON.parse(localStorage.getItem('dataLogin')).ID)
      
    } else {
      await this._getListJobSeekerNotification(JSON.parse(localStorage.getItem('dataLogin')).ID)
    }
  }
}

_getListJobSeekerNotification = async (ID) => {
  try{
  
       await axios.get(`https://getjob.stechomeyazilim.info:5101/getJobSeekerNotification/select/${ID}`)
        .then((res) => {
            this.setState({listJobSeeker: res.data })   
           })
  }
  catch (error) {
   console.log("_getListJobSeekerNotification" + error)
  }
  }
  

_getListEmployerNotification = async (ID) => {
  try{

       await axios.get(`https://getjob.stechomeyazilim.info:5101/getEmployerNotification/select/${ID}`)
        .then((res) => {
            this.setState({listEmployer: res.data })   
           })
  }
  catch (error) {
   console.log("_getListEmployerNotification" + error)
  }
  }


render(){
  let filterDataJobSeeker = this.state.listJobSeeker.filter(x=> x.Notifacation_Type_ID == 3 || x.Notifacation_Type_ID == 4)
  let filterDataEmployer = this.state.listEmployer.filter(x=> x.Notifacation_Type_ID == 3 || x.Notifacation_Type_ID == 4)

  return (

    <Modal
    open={this.props.openMatchModalVisible}
    onClose={this.props._closeMatchModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
  <Box className='' sx={style}>

    <div className=" flex justify-center items-center  flex-col">
      <div className="bg-white shadow-2xl flex  flex-col rounded-lg w-[470px] px-2  h-[520px]   ">
        <div className="w-full flex justify-end items-center pl-15">
          <h2 className="w-full text-center text-blue-700 font-bold text-xl border-b-2  ml-3">
            Eşleşme
          </h2>
          <div className="flex justify-end z-50 mr-[-7px] mb-5 ">
            <button
              className="bg-red-600 rounded-full p-2"
              onClick={this.props.handleMatchModal}
            >
              <GrClose className="" />
            </button>
          </div>
        </div>
        <div className=" py-2 w-full flex flex-col  overflow-x-auto ">
        {this.state.loginData != null && this.state.loginData.Users_Type_ID==1 && filterDataEmployer.length > 0 ? 
        filterDataEmployer.map((item, index) => (
            <MatchCard Notifacation_Type_ID={item.Notifacation_Type_ID} time={item.Notification_CreatedDateTime} ImgSource={EmployerImg} name={item.Users.Users_NameSurname} text={item.Notification_Type.Notification_Type_Text}/>
            )):
            this.state.loginData != null ? this.state.loginData.Users_Type_ID==2 && filterDataJobSeeker.length > 0 ? 
            filterDataJobSeeker.map((item, index) => (
            <MatchCard Notifacation_Type_ID={item.Notifacation_Type_ID}  time={item.Notification_CreatedDateTime} ImgSource={EmployerImg} name={item.Users.Employer_Company[0].Job_Employer_CompanyName} text={item.Notification_Type.Notification_Type_Text}/>
            )) : <div className="justify-center items-center w-full"><text className="text-center font-[Gotham-Medium]">Veri Yok!</text> </div> : null
          }
        </div>
      </div>
    </div>

    </Box>
    </Modal>
  )
}
};

export default Match;
