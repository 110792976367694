import React from 'react'
import moment from 'moment'

const MessagesCard = (props) => {
  console.log("klnsd1l23",props.item.Job_Seeker_ID)
  return (
    props._loginData.Users_Type_ID == 1 &&  props.employer == false ?
    <button onClick={()=> props._getListMessage(props.item.Users_NameSurname,props.item.Users_Profile_Photo,props.item.Job_Employer_CompanyName,props.item.Job_Seeker_ID)} className="w-full h-full flex flex-col p-1">
    <div className="bg-blue-50	hover:outline	hover:outline-2 hover:outline-blue-500 w-full flex flex-col  py-1 px-2 rounded-lg hover:bg-blue-200">
      <div className="w-full flex  justify-start ">
        <div className="w-full flex mt-2 px-2 justify-center items-center">
        <img src={'https://getjob.stechomeyazilim.com/Uploads/' +props.item.Users_Profile_Photo} alt="" className="w-12 h-12 rounded-lg " />
          <div className="w-full grid grid-rows-2 gap-2  flex-wrap pl-3 pr- text-[#07043B] text-md">
            <p className='col-span grid'>
              <span className="font-[Gotham-Medium] text-blue-900">{props.item.Users_NameSurname}</span>
              <span className="font-[Gotham] text-[gray]">{props.item.Job_Seeker_ID} No'lu İlan</span>

            </p>

          </div>
        </div>
      </div>
      <div className="w-full ">
        <h2 className="text-center text-blue-400 ">{moment(props.item.Notification_CreatedDateTime).format('LL')}</h2>
      </div>
    </div>
  </button>:
   props._loginData.Users_Type_ID == 1 &&  props.employer == true ?
   <button onClick={()=> props._getListMessage(props.item.Users_NameSurname,props.item.Users_Profile_Photo,props.item.Job_Employer_CompanyName,props.item.Employer_ID)} className="w-full h-full flex flex-col p-1">
   <div className="bg-blue-50	hover:outline	hover:outline-2 hover:outline-blue-500 w-full flex flex-col  py-1 px-2 rounded-lg hover:bg-blue-200">
     <div className="w-full flex  justify-start ">
       <div className="w-full flex mt-2 px-2 justify-center items-center">
         <img src={'https://getjob.stechomeyazilim.com/Uploads/' +props.item.Users_Profile_Photo} alt="" className="w-12 h-12 rounded-lg " />
         <div className="w-full grid grid-rows-2 gap-2  flex-wrap pl-3 pr- text-[#07043B] text-md">
           <p className='col-span grid'>
             <span className="font-[Gotham-Medium] text-blue-900">{props.item.Users_NameSurname}</span>
             <span className="font-[Gotham] text-[gray]">{props.item.Employer_ID} No'lu İlan</span>

           </p>

         </div>
       </div>
     </div>
     <div className="w-full ">
       <h2 className="text-center text-blue-400 ">{moment(props.item.Notification_CreatedDateTime).format('LL')}</h2>
     </div>
   </div>
 </button>
    :
    props.jobSeeker == false ? 
    <button onClick={()=> props._getListMessage(props.item.Users.Employer_Company[0].Job_Employer_CompanyName,props.item.Users.Employer_Company[0].Employer_Company_Image,props.item.Employer_ID,props.item.Receiver_Users_ID,props.item.startDate)} className="w-full h-full flex flex-col p-1">
    <div className="bg-blue-50	hover:outline	hover:outline-2 hover:outline-blue-500 w-full flex flex-col  py-1 px-2 rounded-lg hover:bg-blue-200">
      <div className="w-full flex  justify-start ">
        <div className="w-full flex mt-2 px-2 justify-center items-center">
          <img src={'https://getjob.stechomeyazilim.com/Uploads/' + props.item.Users.Employer_Company[0].Employer_Company_Image} alt="" className="w-12 h-12 rounded-lg " />
          <div className="w-full grid grid-rows-2 gap-2  flex-wrap pl-3 pr- text-[#07043B] text-md">
            <p className='col-span grid'>
              <span className="font-[Gotham-Medium] text-blue-900">{props.item.Users.Employer_Company[0].Job_Employer_CompanyName}</span>
              <span className="font-[Gotham] text-[gray]">{props.item.ID} No'lu İlan</span>

            </p>

          </div>
        </div>
      </div>
      <div className="w-full ">
        <h2 className="text-center text-blue-400 ">{moment(props.item.Notification_CreatedDateTime).format('LL')}</h2>
      </div>
    </div>
  </button>:
    <button onClick={()=> props._getListMessage(props.item.Users.Employer_Company[0].Job_Employer_CompanyName,props.item.Users.Employer_Company[0].Employer_Company_Image,props.item.Job_Seeker_ID,props.item.Receiver_Users_ID,props.item.startDate)} className="w-full h-full flex flex-col p-1">
          <div className="bg-blue-50	hover:outline	hover:outline-2 hover:outline-blue-500 w-full flex flex-col  py-1 px-2 rounded-lg hover:bg-blue-200">
            <div className="w-full flex  justify-start ">
              <div className="w-full flex mt-2 px-2 justify-center items-center">
                <img src={'https://getjob.stechomeyazilim.com/Uploads/' + props.item.Users.Employer_Company[0].Employer_Company_Image} alt="" className="w-12 h-12 rounded-lg " />
                <div className="w-full grid grid-rows-2 gap-2  flex-wrap pl-3 pr- text-[#07043B] text-md">
                  <p className='col-span grid'>
                    <span className="font-[Gotham-Medium] text-blue-900">{props.item.Users.Employer_Company[0].Job_Employer_CompanyName}</span>
                    <span className="font-[Gotham] text-[gray]">{props.item.ID} No'lu İlan</span>

                  </p>

                </div>
              </div>
            </div>
            <div className="w-full ">
              <h2 className="text-center text-blue-400 ">{moment(props.item.Notification_CreatedDateTime).format('LL')}</h2>
            </div>
          </div>
        </button>
  )
}

export default MessagesCard