import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import "./Agora.css";
import { options, rtc } from "./constants";
import AgoraRTC from "agora-rtc-sdk-ng";
import axios from "axios"
import { BsCamera } from "react-icons/bs";
const {RtcTokenBuilder, RtmTokenBuilder, RtcRole, RtmRole} = require('agora-access-token')

function App(props) {
  console.log("onslkjdf11223", props.employerID)

  let channelNameNew = props._companyName !=null ? props._companyName.replace(" ", "_") + props.employerID : null

  const  handleSubmitNew= async() => {

  const appId = '8e909c0ca29c4796b1d322faa1239fbd';
const appCertificate = '7e63582b631e4f11ad0033769ff7440f';
const channelName = `${channelNameNew}`;
const uid = 0;
const role = RtcRole.PUBLISHER;
const expirationTimeInSeconds = 3600
const currentTimestamp = Math.floor(Date.now() / 1000)
const privilegeExpiredTs = currentTimestamp + expirationTimeInSeconds
// Build token with uid
const tokenA = RtcTokenBuilder.buildTokenWithUid(appId, appCertificate, channelName, uid, role, privilegeExpiredTs);
console.log("Token with integer number Uid: " + tokenA);
props.openVideoCall()
handleSubmit(tokenA)
  }

  const  handleSubmit= async(token) => {
    try {
      if (channelNameNew === "") {
        return console.log("Please Enter Channel Name");
      }

      setJoined(true);

      rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });
      const uid = await rtc.client.join(
        token,
        channelNameNew,
        options.token,
        null
      );

      // Create an audio track from the audio captured by a microphone
      rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      // Create a video track from the video captured by a camera
      rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();

      rtc.localVideoTrack.play("local-stream");

      rtc.client.on("user-published", async (user, mediaType) => {
        console.log("buradaasdasd123")
        // Subscribe to a remote user
        await rtc.client.subscribe(user, mediaType);
        console.log("subscribe uccess");
         console.log("user123213",user);

        if (mediaType === "video" || mediaType === "all") {
          // Get `RemoteVideoTrack` in the `user` object.
          const remoteVideoTrack = user.videoTrack;
          console.log(remoteVideoTrack);

          // Dynamically create a container in the form of a DIV element for playing the remote video track.
          const PlayerContainer = React.createElement("div", {
            id: user.uid,
            className: "stream",
          });
          ReactDOM.render(
            PlayerContainer,
            document.getElementById("remote-stream")
          );

          user.videoTrack.play(`${user.uid}`);
        }

        if (mediaType === "audio" || mediaType === "all") {
          // Get `RemoteAudioTrack` in the `user` object.
          const remoteAudioTrack = user.audioTrack;
          // Play the audio track. Do not need to pass any DOM element
          remoteAudioTrack.play();
        }
      });

      rtc.client.on("user-unpublished", (user) => {
        // Get the dynamically created DIV container
        const playerContainer = document.getElementById(user.uid);
        console.log(playerContainer);
        // Destroy the container
        playerContainer.remove();
      });

      // Publish the local audio and video tracks to the channel
      await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);

      console.log("publish success!");
    
    } catch (error) {
      console.error(error);
    } 
  }

  async function handleLeave() {
    try {
      const localContainer = document.getElementById("local-stream");

      rtc.localAudioTrack.close();
      rtc.localVideoTrack.close();

      setJoined(false);
      localContainer.textContent = "";

      // Traverse all remote users
      rtc.client.remoteUsers.forEach((user) => {
        // Destroy the dynamically created DIV container
        const playerContainer = document.getElementById(user.uid);
        playerContainer && playerContainer.remove();
      });
      props.openVideoCall()

      // Leave the channel
      await rtc.client.leave();
    } catch (err) {
      console.error(err);
    }
  }
  const [joined, setJoined] = useState(false);
  const channelRef = useRef("");
  const remoteRef = useRef("");
  const leaveRef = useRef("");

  return (
    <>
      <div className="container">
      
     

      {joined == false ? <button  className=" w-full justify-center items-center"  onClick={handleSubmitNew}>
      <BsCamera color="white" className="flex justify-center items-center w-6 h-6 " />
       <a className="font-[Gotham-Medium] text-[white] ml-2">Görüntülü Görüşmeyi Başlat</a>
         </button> : <button  onClick={handleLeave}>
       <a className="font-[Gotham-Medium] text-[white] ml-2">Görüntülü Görüşmeyi Sonlandır</a>
         </button>}
      
      </div>
      {joined ? (
        <>
          <div id="local-stream" className="stream local-stream"></div>
          <div
            id="remote-stream"
            ref={remoteRef}
            className="w-full"
          ></div>
        </>
      ) : null}
    </>
  );
}

export default App;
