import React from 'react'

const LabelSamp = ({InfoField, FilledField}) => {
  return (
    <form className="w-full flex justify-between my-3">
            <label className="text-sm text-[#868686] text-right">{InfoField}</label>
            <label className="text-[#062CBC] text-sm font-[Gotham-Medium] text-right">{FilledField}</label>
        </form>
  )
}

export default LabelSamp