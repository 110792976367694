import React, { Component, Fragment } from 'react';
import Navbar from "../../Navbar";
import EmployerImg from "../../../assets/Jobs/Employer.svg";
import EmployerJobsCard from "./EmlpoyerJobsCard"
import Footer from "../../Footer";
import axios from 'axios';
import Menu from './Menu';
import Filter from './Filter';
import { useLocation ,useNavigate } from "react-router-dom";

const EmployerJobs = (props) => {
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();

  const [list, setList] = React.useState([]);
  const [filterData, setFilterData] = React.useState([]);

  React.useEffect(() => {
    const _getList= async() => {
      try{
        let loginData = JSON.parse(localStorage.getItem('dataLogin'))
        await axios.get(`https://getjob.stechomeyazilim.info:5101/getEmployerSearch/select/${loginData.ID}`).then(async(resEmployer) => {
        
        await axios.get(`https://getjob.stechomeyazilim.info:5101/getEmployer/select/${resEmployer.data[0].ID}`)
        .then((res) => {
          setList(res.data) 
          //setIsLoading(false)  
         })
        })
 }
 catch (error) {
   console.log("_getList" + error)
 }
   }

   _getList()

   const  _filterList= async() => {
     try{
      
       await axios.get(`https://getjob.stechomeyazilim.info:5101/getSearch/select/${state._item}/employer`)
       .then((res) => {
        console.log("sdlkfnmlk123",res)
        setFilterData(res.data)
          })
 }
 catch (error) {
   console.log("errorccAS" + error)
 }
   }

   if(state!= null){
     _filterList()
   }
 }, []);


const _filterList= async (keyword) => {
 try{
  console.log("123123",keyword)
  
  const filterData = list.filter(documents => {
    return documents.Job.Job_Text.toLowerCase().includes(keyword.toLowerCase())
  })

  setFilterData(filterData)
  console.log("filterData123",filterData)
    
}
catch (error) {
console.log("errorccAS" + error)
}
}

  return (
    <div>
      <Navbar />
      <div className="w-full flex flex-col">
        <div className="max-w-full mt-36 flex flex-col bg-[#F7F7F7] h-full  items-center  ">
          <h3 className="w-full text-center text-xl text-[#204686] py-7 font-bold">
            Jobs
          </h3>

          <div className='bg-[#0D0658] w-1/2 justify-center items-center' >
                <Menu _itemSub={props._itemSub} _subCategoryID={props._subCategoryID} />
                <div   className='bg-[white]'>
                <Filter jobsText={state != null ? state._item : null} filterList = {_filterList}  />
                </div>
                </div>


                <div className="w-5/6 h-full lg:w-3/5 md:w-4/5 p-2 items-center grid md:grid-cols-3 md:grid-rows-3 sm:grid-cols-2 sm:grid-rows-5 grid-rows-9 gap-4">
          {filterData.length > 0 ? filterData.map((item, index) => (
            
            <EmployerJobsCard  item={item} />
           
           )):  list.map((item, index) => (
            
            <EmployerJobsCard item={item} />
           
           ))}
     
          </div>

         
          <Footer/>
        </div>
      </div>
    </div>
  )

};
  


export default EmployerJobs