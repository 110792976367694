import React, {Component} from "react";

import PeoplePart from "./PeoplePart";
import downArrow from "../../../assets/downArrow.svg";
import { Disclosure } from "@headlessui/react";
import axios from 'axios';


  class FeaturedAds extends Component{
    constructor() {
      super()
      this.state = {
        listEmployer:[],
        listJobSeeker:[],
        pageNumberJobSeeker:0,
        pageNumberEmployer:0,
        countJobSeeker:0,
        countEmployer:0
      }
  }
  
  async componentDidMount() {
    
    if(JSON.parse(localStorage.getItem('dataLogin') != null)){
      this.setState({ loginData: JSON.parse(localStorage.getItem('dataLogin')) })
  
      if (JSON.parse(localStorage.getItem('dataLogin')).Users_Type_ID==1) {
        await this._getListJobSeeker()
      } else {
        await this._getListEmployer()
  
      }
    }
  }

_loadMore = (e,type) => {
  e.preventDefault();
  console.log("jnjl12321",this.state.countJobSeeker,this.state.pageNumberJobSeeker)

if(type == "employer"){
  console.log("jnjl12321",this.state.countEmployer,this.state.pageNumberEmployer)
  if(this.state.countEmployer> this.state.pageNumberEmployer) {

    this.setState({pageNumberEmployer: parseInt(this.state.pageNumberEmployer) + 8 },()=> {
        this._getProductsLoadMoreEmployer( this.state.pageNumberEmployer,this.state.categoryID)
    })
 }
}else{
  if(this.state.countJobSeeker> this.state.pageNumberJobSeeker) {

    this.setState({pageNumberJobSeeker: parseInt(this.state.pageNumberJobSeeker) + 8 },()=> {
        this._getProductsLoadMoreJobSeeker( this.state.pageNumberJobSeeker,this.state.categoryID)
    })
 }
}
 
}


_getListJobSeeker = async () => {
  try{
       await axios.get(`https://getjob.stechomeyazilim.info:5101/getJobSeekerPremium/select/${this.state.pageNumberJobSeeker}`)
        .then((res) => {
            this.setState({listJobSeeker: res.data.value, countJobSeeker : res.data["@odata.count"]})   
           })
 }
 catch (error) {
   console.log("errorccAS" + error)
 }
}

_getListEmployer = async () => {
  try{
       await axios.get(`https://getjob.stechomeyazilim.info:5101/getEmployerPremium/select/${this.state.pageNumberEmployer}`)
        .then((res) => {
            this.setState({listEmployer: res.data.value, countEmployer : res.data["@odata.count"]})   
           })
 }
 catch (error) {
   console.log("errorccAS" + error)
 }
}


_getProductsLoadMoreEmployer = async () => {
  try{
 await axios.get(`https://getjob.stechomeyazilim.info:5101/getEmployerPremium/select/${this.state.pageNumberEmployer}`).then((res) => {
          this.setState(prevState => ({
            listEmployer: [...prevState.listEmployer, ...res.data.value]
          }),()=>{
    
          })
      })
 }
 catch (error) {
   
   console.log("_getProductsLoadMoreEmployer" + error)
 }

}

_getProductsLoadMoreJobSeeker = async () => {
  try{
 await axios.get(`https://getjob.stechomeyazilim.info:5101/getJobSeekerPremium/select/${this.state.pageNumberJobSeeker}`).then((res) => {
          this.setState(prevState => ({
            listJobSeeker: [...prevState.listJobSeeker, ...res.data.value]
          }))
      })
 }
 catch (error) {
   
   console.log("_getProductsLoadMoreJobSeeker" + error)
 }

}

render(){
  return (
    <div className="w-full  flex justify-center items-center bg-[#F7F7F7]  ">
      <div className="w-4/6 h-full flex justify-center items-center ">
        <div className="flex flex-col w-full h-full items-center">
          <p className="flex items-center py-5 mt-2  font-bold  font-[Gotham-Medium] text-2xl text-[#081F43]">
            Öne Çıkan Yetenekler
          </p>
          <div className="grid xxs:grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-2">


         {this.state.loginData != null && this.state.loginData.Users_Type_ID == 2 ? this.state.listEmployer.map((item, index) => (
                        <PeoplePart userType={2} People={item.Employer_Company.Employer_Company_Image} PeopleLabel={item.Job.Job_Text} _item={item}/>
           )) :  this.state.listJobSeeker.map((item, index) => (
            <PeoplePart userType={1} People={item.Users.Users_Profile_Photo} PeopleLabel={item.Job.Job_Text} _item={item}/>
            )) }
          </div>
     
          {this.state.loginData != null && this.state.loginData.Users_Type_ID == 2 ? <div>
          {    this.state.countEmployer>this.state.listEmployer.length  ?  <Disclosure>
            {({ open }) => (
              <div >
                <Disclosure.Button onClick={(e)=> this._loadMore(e,'employer')}  className={`${open ? "   absolute md:bottom-[-530px] bottom-[-2180px]  left-1 w-full flex justify-center items-center mx-auto my-3" : ""} "w-full flex justify-center items-center mx-auto my-3 "`}>
                  <div className="flex justify-center border bg-[#081F43] hover:text-blue-900 hover:bg-yellow-500 text-[white] rounded-2xl shadow-lg px-2 py-1  my-3 md:my-0 ">
                    <div className="flex justify-between py-1 ">
                      <h3 className="flex px-4 ml-2 font-[Gotham-Medium] ">See More Sectors</h3>
                      <img
                        src={downArrow}
                        alt=""
                        className={`${
                          open ? "rotate-180 transform" : ""
                        }  px-2`}
                      />
                    </div>
                  </div>
                </Disclosure.Button>
               
              </div>
            )}
                      </Disclosure> : null}
                      </div> :<div>
          {    this.state.countJobSeeker>this.state.listJobSeeker.length  ?  <Disclosure>
            {({ open }) => (
              <div >
                <Disclosure.Button onClick={(e)=> this._loadMore(e,'jobseeker')}  className={`${open ? "   absolute md:bottom-[-530px] bottom-[-2180px]  left-1 w-full flex justify-center items-center mx-auto my-3" : ""} "w-full flex justify-center items-center mx-auto my-3 "`}>
                  <div className="flex justify-center border bg-blue-900 hover:text-blue-900 hover:bg-yellow-500 text-[white] rounded-2xl shadow-lg px-2 py-1  my-3 md:my-0 ">
                    <div className="flex justify-between py-1 ">
                      <h3 className="flex px-4 ml-2 font-[Gotham-Medium] ">See More Sectors</h3>
                      <img
                        src={downArrow}
                        alt=""
                        className={`${
                          open ? "rotate-180 transform" : ""
                        }  px-2`}
                      />
                    </div>
                  </div>
                </Disclosure.Button>
               
              </div>
            )}
                      </Disclosure> : null}
                      </div>}
        </div>
      </div>
    </div>
  )
}
};

export default FeaturedAds;
