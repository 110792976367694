import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { FreeMode, Pagination, Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import SliderCardEmployer from "./SliderCardEmployer";
import downArrow from "../../../assets/downArrow.svg";
import { Component } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";

import { withParamsAndNavigate } from "../../EmployeeForm/getParamsAndNavigate";
import { withTranslation } from 'react-i18next';


class PopularJobs extends Component{
  constructor(props) {
    super(props)
}

async componentDidMount() {
    
  if(JSON.parse(localStorage.getItem('dataLogin') != null)){
    this.setState({ loginData: JSON.parse(localStorage.getItem('dataLogin')) })

      await this._getListJobSeeker(JSON.parse(localStorage.getItem('dataLogin')).ID)

  
  }
}

_getListJobSeeker = async (ID) => {
  try{
    console.log("resdata1213",ID)

       await axios.get(`https://getjob.stechomeyazilim.info:5101/getJobSeekerProfile/select/${ID}`)
        .then((res) => {
          console.log("resdata123",res.data,ID)

          if(res.data.length==0){
            const{params,navigate} = this.props;

            console.log("resdata123",res.data)
            navigate('/newJobSeeker/')
          }else{

          }
           })
 }
 catch (error) {
   console.log("errorccAS" + error)
 }
}

render(){
 
  return (
    <div className="w-full  flex justify-center items-center bg-white   ">
      <div className="w-4/5 2xl:w-4/6  flex justify-center items-center ">
        <div className="flex flex-col w-full items-center pt-12">
          <div className="w-full pt-2 pb-10 justify-center items-center">
            <h3 className="flex  items-center text-[#052A69] justify-center font-bold   text-3xl">
              Popular Employers
            </h3>
          </div>
{this.props.list.length > 0 ?   <div className="w-full  flex px-10  ">
            <Swiper
              freeMode={true}
              grabCursor={true}
              modules={[FreeMode, Pagination, Navigation]}
              className="mySwiper"
              pagination={{
                clickable: true,
              }}
              style={{
                "--swiper-pagination-color": "red",
  "--swiper-pagination-bullet-size": "8px",
  "--swiper-pagination-bullet-width": "20px",
  "--swiper-pagination-bullet-height": "20px",
  "--swiper-pagination-bullet-inactive-color": "white",
  "--swiper-pagination-bullet-inactive-opacity": "0.8",
  "--swiper-pagination-bullet-opacity": "1",
  "--swiper-pagination-bullet-horizontal-gap": "6px",
  "--swiper-pagination-bullet-vertical-gap": "6px",
  "--swiper-navigation-color": "gray",

  "--swiper-navigation-size": "30px",
              }}
              navigation={true}
              loop={true}
              loopFillGroupWithBlank={true}
              breakpoints={{
                480: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                780: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                1280: {
                  slidesPerView: 4,
                  spaceBetween: 15,
                },
                
              }}
            >
              {this.props.list.map((item, index) => (
                      <SwiperSlide className="hover:outline hover:outline-2 hover:outline-blue-900" >

        <SliderCardEmployer item={item} />
        </SwiperSlide>

                ))}
              
            </Swiper>

            
          </div> : null }
          <div className="w-full flex justify-center items-center py-3">
            <Link to="/jobsJobSeeker">
            <div className="flex justify-center border bg-blue-900 hover:text-blue-900 hover:bg-yellow-500 text-[white] rounded-2xl shadow-lg px-2 py-1 my-3 md:my-0">
                <div className="flex justify-between py-1">
                  <h3 className="flex px-4 ml-2 font-[Gotham-Medium]">{this.props.t("seemorejob")}</h3>
                  <img src={downArrow} alt="" className="pr-2" />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
}
export default withTranslation()(withParamsAndNavigate(PopularJobs));
