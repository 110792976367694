import React from 'react'
import Moment from 'moment'

const MatchCard = ({ImgSource,time,name,text}) => {
  return (
    <div className="w-full h-full flex flex-col p-1">
          <div className="bg-blue-100 hover:outline	hover:outline-2 hover:outline-blue-500 w-full flex flex-col  py-1 px-2 rounded-lg hover:bg-blue-50">
            <div className="w-full flex  justify-start ">
              <div className="w-full flex mt-2 px-2">
                <img src={ImgSource} alt="" className="w-16 h-16 rounded-full outline outline-1 outline-blue-500 " />
                <div className="w-full  flex-wrap pl-3  text-[#07043B] text-md ">
                    <span className="font-[Gotham-Medium] text-[#07043B]">{name}</span> 
                    <p className="font-[Gotham-Medium]">{text} İle Hemen iletişime geç!</p>
                </div>
              </div>
            </div>
            <div className="w-full">
              <p className=" text-gray-500 font-[Gotham-Medium] text-xs text-right">{Moment(time).fromNow()}</p>
            </div>
          </div>
        </div>
  )
}

export default MatchCard