import React, { Component } from 'react';
import OtpInput from 'react18-input-otp';


import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { checkVerification } from "../../verify";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '2px solid #000',
  boxShadow: 24,
  alignItems: 'center',
  justifyContent: 'center',
};

class OtpPageModalMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otpCode:null,
      profileScreenVisible:false,
      alertStatus:false,
      alertMessage:"",
      otpCodeInput:null,
      dialogStyle:'',
      isLoading:false,
      timer: 30,
      otp:null
    };
  }


_setSmsOtp= () => {
  this.setState({
    timer:5
  },()=> {
    this.interval = setInterval(
      () => this.setState((prevState)=> ({ timer: prevState.timer - 1 })),
      1000
    )
    this.props._setSmsOtp()
  })

}

handleChangeOtp= (text) => {
  this.setState({
  otp:text
  })
}

_checkVerification= async() => {
  try{       

    checkVerification(this.props.registerData.valuePhone, this.state.otp).then((success) => {
      if (success == false) {
        this.props.showToast('GetJob', "Doğrulama Kodunu Yanlış Girdiniz!", "false")
      }
     else{
     
      this.props.handleSignUp()  
      }
    })

}catch(e) {
  this.setState({
    alertStatus:true,
    alertMessage:"Hata!"
  })
}
   }

    _closeAlert= async() => {
      this.setState({
        alertStatus:false
      })
    }
 

  
  _setOtpCode= async(val)=>{
    this.setState({
      otpCodeInput:val
    })
  }



  render() {
    return (
      <Modal
      open={this.props._otpVerficationMailVisible}
      onClose={this.props._closeLoginModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className='bg-white justify-center items-center ' sx={style}>


<div className='justify-center items-center bg-[#081F43] text-center'>
            <p className='font-[Gotham-Medium] text-white p-5 text-center'>GetJob Doğru Kişiyi, Doğru İşle Buluşturur!</p>
        </div>
                
        <div className='items-center justify-center w-full text-center h-full'>
        <div className='items-center justify-center w-full text-center m-2 flex'>
        <FontAwesomeIcon className='pr-2' icon={ faClock }  size={"xl"} color={'#081F43'} />                            
        <a className='font-[Gotham-Medium] text-center'>00:{this.state.timer}</a>
        </div>

          <a className='font-[Gotham-Medium] text-center p-2'>Doğrulama adresinize gelen doğrulama kodunu giriniz!</a>

          <OtpInput className='w-full text-center m-1 justify-center items-center' 
          autoComplete={true} 
          inputStyle={{backgroundColor:'white',borderRadius:1,borderWidth:2,borderColor:'gray',}} 
          value={this.state.otp} onChange={this.handleChangeOtp} numInputs={6} />
</div>

{this.state.timer == 0 ?
 <button className="p-2" onClick={()=> this._setSmsOtp()} >
 <p className='font-[Gotham-Medium] text-center'>Kodu Tekrar Gönder</p>
</button> : null}

<div className=' text-center w-full flex p-1 justify-center items-center'>
              
<button
                onClick={()=> this._checkVerification()}
                className="bg-[#081F43] rounded-md p-2 justify-center items-center w-1/2 m-1">
                <a className='font-[Gotham-Medium] text-white text-center'>Kodu Onayla</a>
              </button>



              </div>
      </Box>
     </Modal>
    );
  }
}


export default OtpPageModalMail;
