import React , {  useState } from "react";
import PopulerSearchesBox from "./PopulerSearchesBox";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import { Link } from 'react-router-dom';
import "./search.css";
import { withTranslation } from 'react-i18next';
import { faBuilding, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SearchingPart = (props) => {
  const [myOptions, setMyOptions] = useState([])
  const [filterImage, setFilterImage] = useState([])
  const [filterImageLocation, setFilterImageLocation] = useState([])
  const [myOptionsLocation, setMyOptionsLocation] = useState([])

  const [data, setData] = useState([])
  const [dataLocation, setDataLocation] = useState([])

  const getDataFromAPI = (e) => {
    setMyOptions([])
    if(e.target.value.length > 0){
      fetch(`https://getjob.stechomeyazilim.info:5101/getSearch/select/${e.target.value}/employer`).then((response) => {
        return response.json()
      }).then((res) => {
        if(res.length > 0){
          for (var i = 0; i < res.length; i++) {
            // myOptions.push(res[i].Job.Job_Text + res[i].Users.Users_Profile_Photo)
             myOptions.push(res[i].Job.Job_Text)
            setFilterImage(res)
           }
          setMyOptions(myOptions)
          setData(res[0])
        }
       

      })
    }

  }



  const getDataFromAPILocation = (e) => {
    setMyOptionsLocation([])
    setFilterImageLocation([])
    setDataLocation([])

    if(e.target.value.length > 0){
      fetch(`https://getjob.stechomeyazilim.info:5101/getSearchLocation/select/${e.target.value}/employer`).then((response) => {
        return response.json()
      }).then((res) => {



        if(res.length > 0){

console.log("nlkfdj",res.length)
        for (var i = 0; i < res.length; i++) {

         // myOptions.push(res[i].Job.Job_Text + res[i].Users.Users_Profile_Photo)
          myOptionsLocation.push(res[i].Employer_Company.Employer_Company_City)
         setFilterImageLocation(res)
        }
       setMyOptionsLocation(myOptionsLocation)
       setDataLocation(res[0])
      }
      })
    }

  }
    
  return (
  <div className="w-full lg:h-[470px] h-full bg-[#052A69] flex justify-center items-center ">
  <div className="flex justify-center items-center w-3/4 h-3/5  lg:pl-20">
    <div className="w-full h-full flex flex-col justify-start items-start">
      <div className="w-full h-[100px] flex items-end my-2">
        <h3 className="text-[#F2B129] text-3xl  flex  ">
          Find the <span className="mx-3 text-white"> right</span> fit.
        </h3>
      </div>

      <div className="flex items-center  sm:grid-cols-2 sm:grid-rows-5  gap-2 md:grid-rows-1 md:my-2 md:ml-3  w-10/12  sm:flex-row">
      <Autocomplete
    noOptionsText={'Veri Bulunmamaktadır!'}
    fullWidth
    autoComplete
    autoHighlight
    options={myOptions}
    renderOption={option => {
     let ImageFilter = filterImage.length > 0 ? filterImage.filter((x) => x.Job.Job_Text == option.key) : []
      return (
        ImageFilter.length > 0 ?
     <Link to={"/employerDetail/" + (ImageFilter[0].ID)} state= {{ _item: data }} className="bg-[#081F43] w-full flex grid-cols-2 border-2 border-[white]">
          <div className="grid w-1/2 justify-center items-center">
           <p className="text-light-white font-['Gotham-Medium'] text-[white]">{option.key}</p>
           <p  className="font-[Title]  text-center text-[white]" alt="product-img">{ImageFilter[0].Employer_Company.Job_Employer_CompanyName}</p>
           </div>
           <div className="m-1 justify-end items-end rounded-lg bg-white text-right content-end place-items-end w-1/2">
           {props.item.Users.Users_Profile_Photo != null ? <img src={'https://getjob.stechomeyazilim.com/Uploads/' + ImageFilter[0].Users.Users_Profile_Photo} className="p-2 rounded-lg justify-end items-center" alt="product-img" />:
           <div className="bg-[#081F43] flex justify-center items-center p-4 rounded-lg"><FontAwesomeIcon icon={faUser} style={{margin:10}}  size={"5x"} color={"white"} /></div>}
              </div>
         
            
          </Link> : null
      );
  }}
    renderInput={params => <TextField  sx={{
      "& .MuiInputBase-root": {
        color: 'red',
          "& input": {
              textAlign: "center",
              color:'#0a4022',
              fontSize:15
          }
      }
    }}
    placeholder="Search jobs,keywords,companies" className="inputRounded"
    
       {...params} variant="outlined" onChange={getDataFromAPI} />}
/>
  

<Autocomplete
    noOptionsText={'Veri Bulunmamaktadır!'}
    fullWidth
    autoComplete
    autoHighlight
    options={myOptionsLocation}
    renderOption={option1 => {



     let ImageFilter =filterImageLocation.length > 0 ? filterImageLocation.filter((x) => x.Employer_Company.Employer_Company_City == option1.key) : []

      return (

        ImageFilter.length > 0 ?
     <Link to={"/employerDetail/" + (ImageFilter[0].ID)} state= {{ _item: dataLocation }} className="bg-[#081F43] w-full flex grid-cols-2 border-2 border-[white]">
          <div className="grid w-1/2 justify-center items-center">
           <p className="text-light-white font-['Gotham-Medium'] text-[white]">{ImageFilter[0].Job.Job_Text}</p>
           <p className="text-light-white font-['Gotham-Medium'] text-[white]">{ImageFilter[0].Employer_Company.Employer_Company_City}</p>

           <p  className="font-[Title]  text-center text-[white]" alt="product-img">{ImageFilter[0].Employer_Company.Job_Employer_CompanyName}</p>
           </div>
           {ImageFilter[0].Employer_Company.Employer_Company_Image == null ? <div className="m-1 justify-end items-end rounded-lg bg-white text-right content-end place-items-end w-1/2">
              <img src={'https://getjob.stechomeyazilim.com/Uploads/' + ImageFilter[0].Employer_Company.Employer_Company_Image} className="p-2 rounded-lg justify-end items-center" alt="product-img" />
              </div>:<div className="bg-[#081F43] flex justify-center items-center p-4 rounded-lg"><FontAwesomeIcon icon={faBuilding} style={{margin:10}}  size={"5x"} color={"white"} /></div>}
         
            
          </Link> : null
      );
  }}
    renderInput={params => <TextField  sx={{
      "& .MuiInputBase-root": {
        color: 'red',
          "& input": {
              textAlign: "center",
              color:'#0a4022',
              fontSize:15
          }
      }
    }}
    placeholder="Enter Location" className="inputRounded"
    
       {...params} variant="outlined" onChange={getDataFromAPILocation} />}
/>

</div>

     {/* <InputBox /> */}
      <div className="w-full h-full flex flex-col mt-10">
        <div className=" flex  w-full  ">
          <h3 className="text-md text-white mb-3 font-[Gotham-Medium]">{props.t('popularsearch')}</h3>
        </div>
        <div className="w-full h-full flex flex-row py-1 flex-wrap">
        {props.list.slice(0,10).map((item, index) => (
          <PopulerSearchesBox item={item} userType={JSON.parse(localStorage.getItem('dataLogin'))} />
          ))} 
        </div>
      </div>
    </div>
  </div>
</div>
  );
};

export default withTranslation()(SearchingPart);
