import React, { Component } from "react";
import { GrClose } from "react-icons/gr";
import BildirimCard from "./BildirimCard";
import axios from 'axios';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '2px solid #000',
  boxShadow: 24,
};


  class BildirimlerEmployer extends Component {
    constructor() {
        super()
        this.state = {
            list:[]
        }
    }
    
    async componentDidMount() {
      await this._getList()
    }
    
    _getList = async () => {
    try{
      let loginData =  JSON.parse(localStorage.getItem('dataLogin'))
         await axios.get(`https://getjob.stechomeyazilim.info:5101/getNotificationEmployer/select/${loginData.ID}`)
          .then((res) => {
              this.setState({list: res.data })   
             })
    }
    catch (error) {
     console.log("errorccA123S" + error)
    }
    }
 render(){

   return (
    <Modal
    open={this.props.openNotifModalVisible}
    onClose={this.props._closeNotifModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
  <Box className='' sx={style}>
    <div className=" flex justify-center items-center z-40 ">
      <div className="bg-white shadow-2xl flex  flex-col rounded-lg w-[470px] px-2  h-[520px]    ">
        <div className="w-full flex justify-end items-center pl-15">
          <h2 className="w-full text-center text-blue-700 font-bold text-xl border-b-2  ml-3">
            Bildirimler
          </h2>
          <div className="flex justify-end z-50 mr-[-7px] mb-5 ">
            <button
              className="bg-red-600 rounded-full p-2"
              onClick={this.props.handleNotifModal}
            >
              <GrClose className="" />
            </button>
          </div>
        </div>
        {this.state.list.length > 0 ? <div className=" py-2 w-full flex flex-col  overflow-x-auto ">
         
          {this.state.list.map((item, index) => (
        
        this.state.loginData!=null?   <BildirimCard  loginData={this.state.loginData} item={item} />: null ))}
         
          {/* <BildirimCard ImgSource={EmployerImg} ıconSource={<BsPeople className="w-full h-full" />} CompanyName={"Starbucks"} labelBildirim={"Size Bir Süper CV Gönderdi!"} time={"17dk"} /> */}
         
        </div> : <div className=" justify-center items-center text-center font-[Gotham-Medium]">
         
       Veri Yok
       </div>}
      </div>
    </div>
    </Box>
    </Modal>
  )
 }
};

export default BildirimlerEmployer;
