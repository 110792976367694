import React from 'react'

const InputField = ({labelFiled,type,value,handleChange,typeCheck,onBlur}) => {
  return (
    <div class="relative">
            <input
              type={type}
              value={value}
              onChange={e => handleChange(e.target.value,typeCheck)}
              id="floating_outlined"
              className="block px-2.5 pb-2.5 pt-2.5 w-full text-sm  bg-transparent rounded-md border border-gray-300 appearance-none text-[#081F43  focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-4"
              placeholder=" "
              onBlur={onBlur}
            />
            <label
              for="floating_outlined"
              className="absolute text-xs text-[#081F43] dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
            >
              {labelFiled}
            </label>

          </div>
  )
}

export default InputField