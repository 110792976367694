import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navmethod: false,
            selectCategory : '',
            
        };
    }
    // Navigation
   
    render() {
        return (
            <div className="w-full items-center justify-center m-1">
                <a className='w-full items-center justify-center m-1' onClick={this.toggleNav}>
                    <span className='text-[white] font-[Gotham-Medium] sm:text-[12px] md:text-[17px] lg:text-[22px] text-center'>{this.props.t("aradıgınızisler")}</span>
                </a>     
            </div>
        );
    }
}

export default withTranslation()(Menu);