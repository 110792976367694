import React from "react";
import Navbar from "../Navbar";
import MessagesCard from "./MessagesCard";
import MessagesCardRight from "./MessagesCardRight";
import MessagesCardLeft from "./MessagesCardLeft";

import EmployerImg from "../../assets/Jobs/Employer.svg";
import { Component } from "react";
import axios from 'axios';
import { setDoc,collection, addDoc,getDocs,query,where,doc,getDoc,Timestamp, deleteDoc,onSnapshot, orderBy } from "firebase/firestore";
import {db} from '../../firebase';
import { BsPaperclip } from "react-icons/bs";
import Moment from 'moment';
import AgaroJobSeeker from "./AgoroJobSeeker";
import AgaroVoiceJobSeeker from "./AgaroVoiceJobSeeker";


import { faPaperPlane, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class MessagesJobSeeker extends Component {
  constructor() {
    super()
    this.state = {
      listWhatsapp:[],
        todos:null,
        dataMessage:[],
        dataMessageDetail:[],
        message:'',
        jobSeeker:false,
        videoCallVisible:false,
        companyName:null
    }
}

async componentDidMount() {
  await this._getList()
  await this._getList2()

  this.fetchPost();
}



fetchPost = async () => {
       
 // const ref = doc(db, "MessageEmployer", "47")

 let loginData = JSON.parse(localStorage.getItem('dataLogin'))


  const q = query(collection(db, "MessageEmployer"), where("Job_Seeker_User_ID", "==", loginData.ID.toString()));

  const querySnapshot = await getDocs(q);

    const newData = querySnapshot.docs
              .map((doc) => ({...doc.data(), id:doc.id }));
          this.setState({dataMessage : newData});  
          
  /* await getDocs(collection(db, "MessageEmployer"))
      .then((querySnapshot)=>{               

          const newData = querySnapshot.docs
              .map((doc) => ({...doc.data(), id:doc.id }));
          this.setState({dataMessage : newData});  
          }) */

}



_getList = async () => {
try{
let loginData = JSON.parse(localStorage.getItem('dataLogin'))
  this.setState({loginData: localStorage.getItem('dataLogin')}) 

  console.log("öslö123",JSON.parse(localStorage.getItem('dataLogin')))
     await axios.get(`https://getjob.stechomeyazilim.info:5101/getNotificationJobSeekerWhatsapp/select/${loginData.ID}`)
      .then(async(res) => {
          this.setState({listWhatsapp: res.data,jobSeeker:true })  
        
         })
}
catch (error) {
 console.log("errorccAS" + error)
}
}

_getList2 = async () => {
  try{
  let loginData = JSON.parse(localStorage.getItem('dataLogin'))
    this.setState({loginData: localStorage.getItem('dataLogin')}) 
  
   
            
            await axios.get(`https://getjob.stechomeyazilim.info:5101/getNotificationEmployerWhatsappSender/select/${loginData.ID}`)
            .then(async(res1) => {
  
              if(res1.data.length > 0){
                this.setState({listWhatsapp: res1.data,jobSeeker:false })  
              }
  
           })
  }
  catch (error) {
   console.log("errorccAS" + error)
  }
  }

_getListMessage = async (companyName,photo,ID,employerID) => {
  console.log("123nsdlf",employerID)
try {   
  this.setState({
    dataMessageDetail:[],
    photo: photo,
    jobseekerID:ID,
    companyName:companyName,
    employerID:employerID
  })
  let loginData = JSON.parse(localStorage.getItem('dataLogin'))


  let querySearct = (loginData.Users_NameSurname + companyName).toString()

  console.log("querySearct123",ID,querySearct)
  const pq = query(collection(db, "MessageEmployer", ID + "/" + querySearct), orderBy("messageDate", "asc"));

 // const querySnapshot1 = await getDocs(pq);

  const unsubscribe = onSnapshot(pq, (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      
      this.setState(prevState => ({
        dataMessageDetail: [...prevState.dataMessageDetail, doc.data()]
      }))
    })
  })


    
  } catch (e) {
    console.error("Er1rort: ", e);
  }
}
_deleteMessage= async () => {

  console.log("ksdnfjk",this.state.jobseekerID)
/* const docRef = doc(db, "MessageEmployer", this.state.jobseekerID.toString());

deleteDoc(docRef).then(async() => {
  let formUpdate = {
    Notification_IsActive :false
  }
  
  await axios.patch(`https://getjob.stechomeyazilim.info:5101/notifacationEmployerPatch/${null}`, formUpdate)

  
  await this._getList()
  this.fetchPost();
    console.log("Entire Document has been deleted successfully.")
})
.catch(error => {
    console.log(error);
}) */
}

_sendMessage= async () => {
  try {
    let loginData = JSON.parse(localStorage.getItem('dataLogin'))

    let querySearch = (loginData.Users_NameSurname + this.state.companyName).toString()

    this.setState({message:null,dataMessageDetail:[]})

  const docRef = await addDoc(collection(db,"MessageEmployer",  this.state.jobseekerID + "/" + querySearch), {
    customerMessage: this.state.message,
    employerName: this.state.companyName,
    messageDate:Moment().format("YYYY/MM/DD HH:mm:ss"),
    type:"text",
    typeSender:"seeker",
    workerName:loginData.Users_NameSurname
  });

 // await this._getListMessage(this.state.companyName,this.state.photo,this.state.jobseekerID,this.state.employerID)
  } catch (e) {
    console.error("Error adding document: ", e);
  }

}

setMessage= async (value) => {
  this.setState({message:value})
}

_openVideoCall= async () => {
  this.setState({
    videoCallVisible : !this.state.videoCallVisible
  })
}

render(){
    let loginData = JSON.parse(localStorage.getItem('dataLogin'))

  return (
    <div className="flex flex-col">
      <Navbar />
      <div className="w-full h-screen xxs:px-0 sm:px-8 md:px-28 lg:px-48 bg-[#003567] flex ">
        <div className="bg-[#003567] shadow-2xl flex  flex-col  w-full px-2 pt-2 h-screen    ">
          <div className="mt-36 flex bg-white h-4/5 ">
            <div className="w-1/3 border-r-2 overflow-x-auto">
              <h2 className="text-center border-b-2 mb-4 py-2 text-lg">
                Mesajlar
              </h2>
              <div className="w-full flex flex-col ">
              {this.state.listWhatsapp.map((item, index) => (
              <MessagesCardLeft jobSeeker={this.state.jobSeeker} _loginData = {loginData}  item={item} _getListMessage={this._getListMessage}/>
              ))}
               
              </div>
            </div>

            {this.state.dataMessageDetail.length == 0 ? <div className="w-full h-full justify-center items-center text-center font-[Title]">Lütfen Mesaj Seçiniz</div> :
             <div className="w-2/3 overflow-x-auto h-full justify-between">
            <div className="w-full bg-[#081F43]">
                    <AgaroJobSeeker _companyName={this.state.companyName} _companyID={loginData.ID} employerID={this.state.employerID} openVideoCall={this._openVideoCall}/>
                    <AgaroVoiceJobSeeker _companyName={this.state.companyName} _companyID={loginData.ID} employerID={this.state.employerID} openVideoCall={this._openVideoCall}/>

                  </div>

                  <button onClick={()=> this._deleteMessage()} className="flex bg-[#081F43]"> <a className="text-[white]">Sil</a> <FontAwesomeIcon className='flex justify-center items-center w-6 h-6' icon={ faTrash }  size={20} color={'white'} />                            
                  </button>

             {this.state.videoCallVisible == false ?  <div className="">
                  
              {this.state.dataMessageDetail.map((item, index) => (<MessagesCardRight photo={this.state.photo} item={item} _sendMessage = {this._sendMessage}/> ))}
            
            
              </div>: null}

              {this.state.videoCallVisible == false ?  
               <div className="relative">
                <div  className="justify-items-start bg-[white] flex">
              <input
                  type="text"
                  id="inputMessage"
                  value={this.state.message}
                  className="bg-[#081F43] text-[white] justify-items-end justify-end items-end w-full font-[Gotham-Medium] outline-none focus:border-b-2 focus:border-blue-500"
                  placeholder="Lütfen mesajınızı yazınız .."
                  onChange={e => this.setMessage(e.target.value)}
                />

                <button onClick={()=> this._sendMessage()}>

                <FontAwesomeIcon className='flex justify-center items-center w-6 h-6' icon={ faPaperPlane }  size={20} color={'#081F43'} />                            

                </button>


                </div>
              </div> : null }
            </div> }
          </div>
          
        </div>
      </div>
    </div>
  )
}};

export default MessagesJobSeeker;
