import React,{useState} from 'react'
import moment from 'moment'
import { BsFillPersonFill, BsPaperclip } from "react-icons/bs";

const MessagesCard = (props) => {
  const [message, setMessage] = React.useState('');

  return (
     props.item.typeSender=="seeker"? 
    <div class="flex justify-end bg-gray-200 p-1">
  
          <div className="bg-blue-50 hover:outline	hover:outline-2 hover:outline-blue-500 flex flex-col  py-1 px-2 rounded-lg hover:bg-blue-200">
            <div className=" flex  justify-start ">
              <div className=" flex mt-2 px-2 ">
                <img src={'https://getjob.stechomeyazilim.com/Uploads/' + props.photo} alt="" className="w-12 h-12 rounded-lg " />
                <div className=" grid grid-rows-2 gap-2 flex-wrap pl-3 pr- text-[#07043B] text-md">
                  <p className='col-span'>
                    <span className="font-[Gotham-Medium] text-[gray]">{props.item.customerMessage}</span>
                  </p>

                  <div className="w-full ">
              <h2 className="text-left text-[gray] font-[Gotham]">{moment(props.item.messageDate).format('LL')}</h2>
            </div>
                </div>
              </div>
            </div>
           
          </div>
     
        </div>
        :
        <div class="flex justify-start bg-gray-200 p-1">
  
     
        <div className="bg-[#fdf4df] hover:outline	hover:outline-2 hover:outline-blue-500 flex flex-col  py-1 px-2 rounded-lg hover:bg-blue-200">
          <div className=" flex  justify-start ">
            <div className=" flex mt-2 px-2 ">
              <img src={'https://getjob.stechomeyazilim.com/Uploads/' + props.photo} alt="" className="w-12 h-12 rounded-lg " />
              <div className=" grid grid-rows-2 gap-2 flex-wrap pl-3 pr- text-[#07043B] text-md">
                <p className='col-span'>
                  <span className="font-[Gotham-Medium] text-[gray]">{props.item.customerMessage}</span>
                </p>

                <div className="w-full ">
            <h2 className="text-left text-[gray] font-[Gotham]">{moment(props.item.messageDate).format('LL')}</h2>
          </div>
              </div>
            </div>
          </div>
         
        </div>
   
      </div>
  
  )
}

export default MessagesCard