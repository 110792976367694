import React, { useState, useEffect,Component } from "react";
import InputField from "./InputField";
import MultiSelectOption from "./MultiSelectOption";
import SelectOption from "./SelectOption";
import axios from 'axios';
import Autocomplete from "react-google-autocomplete";
import { withParamsAndNavigate } from "./getParamsAndNavigate.js";
import { ToastContainer, toast } from 'react-toastify';
import LoadingSpinner from "../../LoadingSpinner";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import mapStyles from './mapStyles'
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Map(props) {
  const [selectedPark, setSelectedPark] = useState(null);

  useEffect(() => {
    const listener = e => {
      if (e.key === "Escape") {
        setSelectedPark(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

console.log("lksdnkf",props)

  return (
    props.dealerData != null ?
      <GoogleMap
      defaultZoom={4}
      defaultCenter={{ lat: 48.8114633 , lng: 34.8990059}}
      options={{ styles: mapStyles }}
    >
        <Marker
          key={props.dealerData.ID}
          position={{
            lat: props.currentLat,
            lng: props.currentLng
          }}
          onClick={() => {
            setSelectedPark(props.dealerData.Employer_Map);
          }}
          icon={{
            url:  "https://img.icons8.com/color/48/000000/map-pin.png",
            scaledSize: new window.google.maps.Size(50, 50)
          }}
        >

<InfoWindow
          onCloseClick={() => {
            setSelectedPark(null);
          }}
          position={{
            lat: props.currentLat,
            lng: props.currentLng
          }}
        >
          <div>
            <h2 className="font-[Gotham-Medium] ">{props.dealerData.currentCity}</h2>
          </div>
        </InfoWindow>
        </Marker>
    </GoogleMap> : <div><text>Veri Yok!</text> </div>
    
  );
}

class PersonelInformation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Employer_Education:[],
      listDriverLicense:[],
      jobList:[],
      saleryData:[],
      dealerData:[],
      selectedLat:37.8604039,
      selectedLng:32.5372508,
      selectedImageVideo:[],
      isLoading:true,
     defaultProps : {
         center: {
           lat: 37.8604039,
           lng: 32.5372508
         },
         zoom: 11
       },
       showingInfoWindow: false,
     activeMarker: {},
     selectedPlace: {},


      companyName : '',
      sector: '',
      foundationYear: '',
      employeesNumer:'',

      languageData:[],
      mapData:null,
      educationStatus:[],
      jobwayout:[],
      jobListID:0,
      jobList:[],
      jobExpreinceID:null,
      desc:null,
      companyVideo:'',

      listCountries:[],
      listCity:[],
      listDiscrict:[],
      listCityEducation:[],
      selectedImagePhoto:[],
      checkGender:false,
      checkMilitary:false,
      checkDriverLicense:false,
      checkGradient:false,
      checkCv:false,
      checkVideo:false,
      checkDesc:false,
      checkCertifica:false,
      jobModal:[],
      employeeNumber:null,
      foundationYearLength:0,

    }
    
    console.log("lsndf123",this.props.item)
}

onMarkerClick = (props, marker, e) =>{
  console.log("nsldlfk",props, marker, e)
      this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
      })
  }
  
    onClose = props => {
      if (this.state.showingInfoWindow) {
        this.setState({
          showingInfoWindow: false,
          activeMarker: null
        });
      }
    };
  
    _changeMaps= async (lat,long) => {
      this.setState({defaultProps: {
          center: {
            lat: lat,
            lng: long
          },
          zoom: 11
        } ,
        selectedLat:lat,
        selectedLng:long,
  
      
      },()=> {
          console.log("sjndfjkls",this.state.defaultProps)
      })
      }
      _setMap = async (item) => {
  
      this.setState({
          openDetail:true,
          mapDetail:item
      })
  }
      
async componentDidMount() {

  await this._getDetail()
  await this._getJob()
  await this._getCountries()
}

_getCountries= async () => {
  console.log("lnsdkf",this.props.item)
  await axios.get(`https://getjob.stechomeyazilim.info:5101/getCountries/`)
  .then((res) => {
    let listCountriesFilter = res.data.filter(x=> x.name == this.props.item.Employer_Company.Employer_Company_Countries)
    console.log("123123",listCountriesFilter)
    this._getCity(listCountriesFilter[0].iso2,listCountriesFilter[0].name)
    this.setState({countriesCode : listCountriesFilter[0].iso2})
    res.data.map((item, index) => (
    this.setState(prevState => ({
      listCountries: [...prevState.listCountries, {label:item.name,value:item.iso2}]
    }))
    ))
     })

}

_getCity= async (city,cityName) => {
  
  this.setState({
    countries:city,
  })
  await axios.get(`https://getjob.stechomeyazilim.info:5101/getCity/${city}`)
  .then((res) => {
    let listCountriesFilter = res.data.filter(x=> x.name == this.props.item.Employer_Company.Employer_Company_City)
    this._getDistrict(listCountriesFilter[0].iso2)

    res.data.map((item, index) => (
    this.setState(prevState => ({
      listCity: [...prevState.listCity, {label:item.name,value:item.iso2}]
    }))
    ))
     })


     await axios.get(`https://getjob.stechomeyazilim.info:5101/getCity/TR`)
  .then((res) => {
    res.data.map((item, index) => (
    this.setState(prevState => ({
      listCityEducation: [...prevState.listCityEducation, {label:item.name,value:item.iso2}]
    }))
    ))
     })
     
}

_getDistrict= async (ID) => {
  this.setState({
    listDiscrict : []
  })
  await axios.get(`https://getjob.stechomeyazilim.info:5101/getDistrict/${this.state.countriesCode}/${ID}`)
  .then((res) => {
    res.data.map((item, index) => (
    this.setState(prevState => ({
      listDiscrict: [...prevState.listDiscrict, {label:item.name,value:item.name}]
    }))
    ))
     })

}

_getJob= async () => {
  await axios.get(`https://getjob.stechomeyazilim.info:5101/getJob/select`)
  .then((res) => {

    res.data.map((item, index) => (
    this.setState(prevState => ({
      jobList: [...prevState.jobList, {label:item.Job_Text,value:item.ID}]
    }))
    ))
     })

}


 _updateForm = async () => {
  try {
    if(this.state.educationStatus.length>0){
      axios.delete(`https://getjob.stechomeyazilim.info:5101/employerEducationDelete/${this.props.item.ID}`)
    }
    if(this.state.languageData.length>0){
    axios.delete(`https://getjob.stechomeyazilim.info:5101/employerLanguageDelete/${this.props.item.ID}`)
    }
    if(this.state.jobwayout.length>0){
    axios.delete(`https://getjob.stechomeyazilim.info:5101/employerJobWayOutDelete/${this.props.item.ID}`)
  }
   // axios.delete(`https://getjob.stechomeyazilim.info:5101/employerJobModalDelete/${this.props.item.ID}`)



        let mapData = {
          Job_Employer_Map_Lat: this.state.currentLatitude,
          Job_Employer_Map_Lng:  this.state.currentLongitude,
          // Job_Seeker_Map_Distance: this.state.sliderValue,
          Job_Employer_Map_City: this.state.currentCity,
        }
        axios.post('https://getjob.stechomeyazilim.info:5101/insertEmployerMap/send', mapData).then(async (resMap) => {

        this.state.educationStatus.map(async (data) => {
          console.log("sljndlsd",data.value)
          let educationData = {
            Employer_Education_Status: data.value,
            Employer_ID: this.props.item.ID
          }
          axios.post('https://getjob.stechomeyazilim.info:5101/insertEmployerEducation/send', educationData)
        })


        let filterData = { 
          Employer_Filter_Gender:this.state.userGender,
          Employer_Military:this.state.checkMilitary == true ? "Yapildi" : "Yapılmadı",
          Employer_Start_Age:this.state.startAge,
          Employer_Finish_Age:this.state.finishAge,
          Employer_Driving_License:this.state.checkDriverLicense,
          Employer_Gradient:this.state.checkGradient,
          Employer_CV:this.state.checkCv,
          Video_Check:this.state.checkVideo,
          Employer_Filter_Desc:this.state.checkDesc,
          Employer_Certifica:this.state.checkCertifica,
         
        }

        console.log("13kj13kj21313",filterData)
        axios.post('https://getjob.stechomeyazilim.info:5101/insertEmployerFilter/send', filterData).then(async (resFilter) => {

        console.log("12311123",resFilter.data.value[0].ID)
        console.log("12311123",this.props.item.ID)
        console.log("123111123",resMap.data.value[0].ID)

          let employerData = {
            Job_Experience_ID: this.state.jobExpreinceID,
            Employer_Salery_Expection_ID: this.state.jobSaleryID,
            Employer_Desc:this.state.desc,
            Employer_Map_ID:resMap.data.value[0].ID,
           // Job_ID:this.state.jobListID,
            Employer_Filter_ID: resFilter.data.value[0].ID
          }


          await axios.patch(`https://getjob.stechomeyazilim.info:5101/employerPatch/${this.props.item.ID}`, employerData)


        })

      })
       this.state.jobwayout.map(async (data) => {
          console.log("data12123123312", data.label)
          let jobWayData = {
            Employer_JobWay_Text: data.label,
            Employer_ID: this.props.item.ID
          }
          axios.post('https://getjob.stechomeyazilim.info:5101/insertEmployerJobWayOut/send', jobWayData)
        })


        this.state.languageData.map(async (data) => {
          let languageData = {
            Job_Employer_Language: data.value,
            Job_Employer_ID: this.props.item.ID
          }
          axios.post('https://getjob.stechomeyazilim.info:5101/insertEmployerLanguage/send', languageData)
        })

        if( this.state.jobModal.length >0){
          this.state.jobModal.map(async (data) => {
            let jobModalData = {
              Employer_JobModal_Text	: data.label,
              Employer_ID: this.props.item.ID
            }
            axios.post('https://getjob.stechomeyazilim.info:5101/postEmployerJobModal/send', jobModalData)
            })
        
        }
       
        this.props.navigate('/employeeınfo/' + this.props.item.Users_ID , { state: { _item: { ID: this.props.item.Users_ID } } })

        this.showToast('Başarıyla Bilgileriniz Güncellendi', true)
      
  }
  catch (error) {
    console.log("_updateForm1231" + error)
  }
}


  handleChange= async (val,type) => {
    
    if(type=="educationStatus"){
      this.setState({
        educationStatus:val
      })
    }
    if(type=="employeeNumber"){
      this.setState({
        employeeNumber:val.value
      })
    }
    if(type=="employeeNumber"){
      this.setState({
        employeeNumber:val.value
      })
    }
    if(type=="jobModal"){
      this.setState({
        jobModal:val
      })
    }
    if(type=="startAge"){

      if(val<100){
        this.setState({
          startAge:val
        })
      }else{
        this.showToast('GetJob', "3 haneli rakam giremezsiniz!", 'false')
      }
    
    }
    if(type=="finishAge"){
      if(val<100){
      this.setState({
        finishAge:val
      })
    }else{
      this.showToast('GetJob', "3 haneli rakam giremezsiniz!", 'false')

    }
    }
    if(type=="name"){
      this.setState({
        companyName:val
      })
    }
    if(type=="sector"){
      this.setState({
        sector:val
      })
    }
    if(type=="foundationYear"){
      this.setState({
         foundationYearLength : val.length,
        foundationYear:val
      })
    }
    if(type=="employeesNumer"){
      this.setState({
        employeesNumer:val
      })
    }
    console.log("sjdnfjlsdf",val)
    if(type =="jobwayout"){
      this.setState({
        jobwayout:val
      })
    }

    if(type =="language"){
      this.setState({
        languageData:val
      })
    }  
    if(type =="jobs"){
      console.log("kjn",val.value)
      this.setState({
        jobListID:val.value
      })
    }  
    if(type =="desc"){
      console.log("klmdsf1239",val.target.value)
      this.setState({
        desc:val.target.value
      })
    }  
    if(type =="exprience"){
      this.setState({
        jobExpreinceID:val.value
      })
    }  
    if(type =="salery"){
      this.setState({
        jobSaleryID:val.value
      })
    }  
    
    
  }


_getDetail = async () => {
try{

  this.setState({
    employeeNumber:this.props.item.Employer_Company.Employer_Number,
    currentLatitude:this.props.item.Employer_Map.Job_Employer_Map_Lat,
    currentLongitude:this.props.item.Employer_Map.Job_Employer_Map_Lng,
    mapData:{
      ID :   this.props.item.Employer_Map.ID,
          position:{
            lat:   this.props.item.Employer_Map.Job_Employer_Map_Lat,
            lng:   this.props.item.Employer_Map.Job_Employer_Map_Lng
      }},
  currentLatitude :  this.props.item.Employer_Map.Job_Employer_Map_Lat,
  currentLongitude :   this.props.item.Employer_Map.Job_Employer_Map_Lng,
  currentCity :   this.props.item.Employer_Map.Job_Employer_Map_City
})


this.props.item.Employer_Education.map((item, index) => (
  this.setState(prevState => ({
    educationStatus: [...prevState.educationStatus, {label:item.Employer_Education_Status,value:item.ID}]
  }))
  ))
  

  this.props.item.Employer_Language.map((item, index) => (
      this.setState(prevState => ({
        languageData: [...prevState.languageData, {label:item.Job_Employer_Language,value:item.Job_Employer_Language}]
      }))
      ))
      
  this.props.item.Employer_JobWay.map((item, index) => (
    this.setState(prevState => ({
      jobwayout: [...prevState.jobwayout, {label:item.Employer_JobWay_Text,value:item.Employer_JobWay_Text}]
    }))
    ))


    this.props.item.Employer_JobModal.map((item, index) => (
      this.setState(prevState => ({
        jobModal: [...prevState.jobModal, {label:item.Employer_JobModal_Text,value:item.ID}]
      }))
      ))
          
      console.log("slndflk1",this.props.item.Employer_Filter.Employer_Filter_Gender)
        this.setState({
          userGender:this.props.item.Employer_Filter.Employer_Filter_Gender,
          companyPhoto:this.props.item.Employer_Company.Employer_Company_Image,
          selectedImagePhoto: this.props.item.Employer_Company.Employer_Company_Image != null ? [{ ID: parseInt(this.state.selectedImagePhoto.length+1), data: parseInt(this.state.selectedImagePhoto.length+1) + ". Belge", type : 'image' , imageData : 'https://getjob.stechomeyazilim.com/Uploads/' + this.props.item.Employer_Company.Employer_Company_Image,type : "photo"   }] :[] ,
          selectedImageVideo: [{ ID: parseInt(this.state.selectedImageVideo.length+1), data: parseInt(this.state.selectedImageVideo.length+1) + ". Belge", type : 'image' , imageData : 'https://getjob.stechomeyazilim.com/Uploads/' + this.props.item.Employer_Company.Employer_Company_Video,type : "video"   }],
          checkGender :  this.props.item.Employer_Filter.Employer_Filter_Gender == "Kadın" ? true : false,
          startAge: this.props.item.Employer_Filter.Employer_Start_Age ,
          finishAge: this.props.item.Employer_Filter.Employer_Finish_Age,
          checkDriverLicense: this.props.item.Employer_Filter.Employer_Driving_License,
          checkGradient: this.props.item.Employer_Filter.Employer_Gradient,
          checkCv: this.props.item.Employer_Filter.Employer_CV,
          checkVideo: this.props.item.Employer_Filter.Video_Check,
          checkDesc: this.props.item.Employer_Filter.Employer_Filter_Desc,
          checkCertifica: this.props.item.Employer_Filter.Employer_Certifica,
          companyName : this.props.item.Employer_Company.Job_Employer_CompanyName,
          sector:this.props.item.Employer_Company.Job_Employer_SectorName,
          foundationYear: this.props.item.Employer_Company.Employer_Company_Foundation_Year,
      //    foundationYearLength : this.props.item.Employer_Company.Employer_Company_Foundation_Year.length,
          employeesNumer: this.props.item.Employer_Company.Job_Employer_EmployeesNumber,
          employeesOfficalUser:this.props.item.Employer_Company.Employer_Company_OfficalUser,

          employeesTaxNumber:this.props.item.Employer_Company.Employer_Company_Tax_Number,
          employeesNumber:this.props.item.Employer_Company.Employer_Company_PhoneNumber,
          employeesMail:this.props.item.Employer_Company.Employer_Company_Mail,
          jobExpreinceID:this.props.item.Job_Experience_ID,
          jobSaleryID:this.props.item.Employer_Salery_Expection_ID,
          desc:this.props.item.Employer_Desc,
          jobListID:this.props.item.Job_ID,
          countriesName:this.props.item.Employer_Company.Employer_Company_Countries,
          cityName:this.props.item.Employer_Company.Employer_Company_City,
          districtName:this.props.item.Employer_Company.Employer_Company_District,
          isLoading:false
       
     
      })

      
     
        
}
catch (error) {
 console.log("_getDetailError" + error)
}
}

_handleChange= async (value,type) => { 
  console.log("sjkdnfjkl",value.label)
  if(type=="gender"){
    this.setState({userGender:value.label})
  }
  if(type=="employeesTaxNumber"){
    this.setState({employeesTaxNumber:value})
  }
  if(type=="employeesMail"){
    this.setState({employeesMail:value})
  }
  if(type=="employeesNumber"){
    this.setState({employeesNumber:value})
  }
  if(type =="jobwayout"){
    this.setState({jobwayout:value})
  }
  if(type =="educationStatus"){
    this.setState({educationStatus:value})
  }
  if(type =="language"){
    this.setState({languageData:value})
  }  

  if(type =="employeesOfficalUser"){
    this.setState({employeesOfficalUser:value})
  }  

  
  
}

onFileChangePortfolyo = async (e,type) => { 
  let file = e.target.files[0];

  if (file.size > 15e6) {
    window.alert("Lütfen dosya yüklerken 15 MB'ı geçmemeli!");
    return false;
  }else{
    try{
  this.setState({isLoading:true})

  if(type=="photo"){
    let files = e.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    //fileReader.readAsText(files[0]);
  
    fileReader.addEventListener('loadstart', this.changeStatus('Start Loading'));
    fileReader.addEventListener('load', this.changeStatus('Loaded'));
    fileReader.addEventListener('loadend', this.loaded);
    fileReader.addEventListener('progress', this.setProgress);
    fileReader.addEventListener('error', this.errorHandler);
    fileReader.addEventListener('abort', this.changeStatus('Interrupted'));
    
  
    fileReader.onload = (event) => {
  
      console.log("hvhjvj12",event)
      this.setState({
        isPhotoLoading:true,
        isLoading: false,
        selectedImagePhoto: [{ ID: parseInt(this.state.selectedImagePhoto.length+1), data: parseInt(this.state.selectedImagePhoto.length+1) + ". Belge", type : 'image' , imageData : event.target.result,type : files[0].type == "image/png" ? "photo"  :  files[0].type == "application/pdf" ? "document"  :  files[0].type == "video/quicktime" ? "video" :  files[0].type == "video/quicktime" ? "video" : null }]
      })
  
     
    }
  }
  if(type=="video"){
    this.setState({isVideoLoading: true,isLoading:true})
    let files = e.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    //fileReader.readAsText(files[0]);
  
    fileReader.addEventListener('loadstart', this.changeStatus('Start Loading'));
    fileReader.addEventListener('load', this.changeStatus('Loaded'));
    fileReader.addEventListener('loadend', this.loaded);
    fileReader.addEventListener('progress', this.setProgress);
    fileReader.addEventListener('error', this.errorHandler);
    fileReader.addEventListener('abort', this.changeStatus('Interrupted'));
    
  
    fileReader.onload = (event) => {
  
      this.setState({
        isVideoLoading:true,
        isLoading: false,
        selectedImageVideo: [{ ID: parseInt(this.state.selectedImageVideo.length+1), data: parseInt(this.state.selectedImageVideo.length+1) + ". Belge", type : 'image' , imageData : event.target.result,type : files[0].type == "image/png" ? "photo"  :  files[0].type == "application/pdf" ? "document"  :  files[0].type == "video/quicktime" ? "video" :  files[0].type == "video/quicktime" ? "video" : null }]
      })
  
     
    }
  }
    }
  catch (error) {
    this.setState({isLoading:false})
  }
  }


}


saveCheckBox= async (type) => {
  if(type == "gender"){
    this.setState({checkGender: !this.state.checkGender})
  }
  if(type == "military"){
    this.setState({checkMilitary: !this.state.checkMilitary})
  }
  if(type == "driverlicense"){
    this.setState({checkDriverLicense: !this.state.checkDriverLicense})
  }
  if(type == "gradient"){
    this.setState({checkGradient: !this.state.checkGradient})
  }
  if(type == "cv"){
    this.setState({checkCv: !this.state.checkCv})
  }
  if(type == "video"){
    this.setState({checkVideo: !this.state.checkVideo})
  }
  if(type == "desc"){
    this.setState({checkDesc: !this.state.checkDesc})
  }
  if(type == "certifica"){
    this.setState({checkCertifica: !this.state.checkCertifica})
  }
  //this.setState({isLoading: false,companyVideo:res.data})
}


_setShow= async () => {

}

changeStatus = (status) => {
document.getElementById('status').innerHTML = status;
}

enableInputFile(e, id) {
document.getElementById(id).click();
}

setProgress = (e) => {
const fr = e.target;
const loadingPercentage = 100 * e.loaded / e.total;

document.getElementById('progress-bar').value = loadingPercentage;
}

setProgressGradient = (e) => {
const fr = e.target;
const loadingPercentage = 100 * e.loaded / e.total;

document.getElementById('progress-bar-gradient').value = loadingPercentage;
}

loaded = (e) => {
const fr = e.target;
var result = fr.result;

this.changeStatus('Yükleme Tamamlandı!');
console.log('Result:', result);
}

errorHandler = (e) => {
this.changeStatus('Error: ' + e.target.error.name);
}

onSubmit= (type) => {
  this.setState({isLoading: true})
  if(type == "video"){
    const formData = { image: this.state.selectedImageVideo[0].imageData }
    let endpoint = "https://getjob.stechomeyazilim.info:5101/fileUpload/insert/";
     axios.post(endpoint, formData, {
     }).then(async(res) => {

        console.log('Fileploaded!',res.data);

        this.setState({isLoading: false,companyVideo:res.data})

        this.showToast('GetJob', "Başarıyla Görseller Yüklendi!", 'success')

    })
  }
  if(type == "photo"){
    console.log("sdjkfndlwks123",this.state.selectedImagePhoto)
    const formData = { image: this.state.selectedImagePhoto[0].imageData }
    let endpoint = "https://getjob.stechomeyazilim.info:5101/fileUpload/insert/";
     axios.post(endpoint, formData, {
     }).then(async(res) => {

        console.log('Fileploaded!',res.data);

        this.setState({isLoading: false,companyPhoto:res.data})

        this.showToast('GetJob', "Başarıyla Görseller Yüklendi!", 'success')

    })
  }


}

showToast= (event, value, type) => {
  if(type == "false"){
    toast.error(value, {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

  }else{
    toast.success(value, {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
   
  }

}

  render (){ 
  return (
    <div className="px-9 py-9">
      <div>

        <div className="w-full">
        <h3 className="mb-8 text-[#081F43] font-[Gotham-Medium] text-lg text-center">
          İLAN BİLGİLERİ
        </h3>


        <h3 className="mt-6 mb-8 text-[#081F43] font-[Gotham-Medium] text-lg">
          Çalışma Şekli
        </h3>
       
         <div className="w-full">
        <MultiSelectOption  handleChange={this.handleChange}  value={this.state.jobwayout} type={"jobwayout"} labelFiled="Çalışma Şekli" /> 
        </div> 

        <h3 className="mt-6 mb-8 text-[#081F43] font-[Gotham-Medium] text-lg">
         Eğitim Durumu
        </h3>
        {this.state.isLoading == false ?  <MultiSelectOption handleChange={this.handleChange} value={this.state.educationStatus} type={"educationStatus"} selectLabel="Eğitim Durumu" />
        :null}

        <h3 className="mt-6 mb-8 text-[#081F43] font-[Gotham-Medium] text-lg">
          Çalışma Modeli
        </h3>
       
        {this.state.isLoading == false ?  <div className="w-full">
        <MultiSelectOption  handleChange={this.handleChange} value={this.state.jobModal} type={"jobModal"} labelFiled="Çalışma Modeli" /> 
        </div>  : null}

        <h3 className="mt-6 mb-8 text-[#081F43] font-[Gotham-Medium] text-lg">
          Aranılan İşler
        </h3>
        {this.state.jobList.length > 0 ? <div className="w-full">
          <SelectOption fullvalue={this.state.jobList} disabled={true}  handleChange={this.handleChange} value={this.state.jobListID} type={"jobs"}  labelFiled="İşler" />
        </div> : null }

        <h3 className="mt-6 mb-8 text-[#081F43] font-[Gotham-Medium] text-lg">
         Bu Pozisyon İçin Kaç Kişi İşe Alacaksınız?
        </h3>

        {this.state.isLoading == false  ?  <div className="w-full">
          <SelectOption  handleChange={this.handleChange} value={this.state.employeeNumber} type={"employeeNumber"}  labelFiled="Kaç Kişi İşe Alacaksınız" />
        </div> : null}

        <h3 className="mt-6 mb-8 text-[#081F43] font-[Gotham-Medium] text-lg">
        Aranılan Deneyim Yılı
        </h3>
        <div className="w-full">
         {this.state.isLoading == false ? <SelectOption  fullvalue={[{value : "deneme"}]} handleChange={this.handleChange} value={this.state.jobExpreinceID} selectLabel="Aranılan Deneyim Yılı" type={"exprience"}/> : null}
        </div>

        <h3 className="mt-6 mb-8 text-[#081F43] font-[Gotham-Medium] text-lg">
          Ücret
        </h3>
        <div className="w-full">
        {this.state.isLoading == false ? <SelectOption handleChange={this.handleChange} selectLabel="Maaş Beklentisi" value={this.state.jobSaleryID} type={"salery"}/> : null }
        </div>

        <h3 className="mt-6 mb-8 text-[#081F43] font-[Gotham-Medium] text-lg">
          Yabancı Dil
        </h3>
        <div className="w-full">
        <MultiSelectOption  handleChange={this.handleChange} labelFiled="Dil" value={this.state.languageData} type={"language"}  />
        </div>

        <h3 className="mt-8 mb-4 text-[#081F43] font-[Gotham-Medium] text-lg">Ön Yazı(Opsiyonel)</h3>
        <div className="w-full ">
          <textarea
         onChange={(e)=> this.handleChange(e,"desc")}
            className="border-2 border-[#EBECF8] h-[171px] w-full rounded-lg"
            value={this.state.desc}
            placeholder={"Lütfen Ön Yazı Giriniz"}
          />
        </div>

                </div>
       

       <h3 className="mt-8 mb-4 text-[#081F43] font-[Gotham-Medium] text-lg">
          Konumunuz
        </h3>
        
{ this.state.mapData != null ?  <div className="z-11">
<Autocomplete
  apiKey={"AIzaSyCm32U9QFgSBe_8pJG_2Z3G61pL6ipeQtU"}
  style={{ width: "100%" }}

  onPlaceSelected={(place) => {
    console.log("place123", place.address_components[1]);
    this.setState({
      currentLatitude:place.geometry.location.lat(),
      currentLongitude:place.geometry.location.lng(),

      mapData:{
        ID :  1,
            position:{
              lat:  place.geometry.location.lat(),
              lng:  place.geometry.location.lng(),
        },
      currentLatitude :  place.geometry.location.lat(),
      currentLongitude :  place.geometry.location.lng(),
      currentCity : place.address_components[1].short_name
    },
    currentCity : place.address_components[1].short_name


    })
  }}
  options={{
    types: ["(regions)"],
    //componentRestrictions: { country: "tr" },
  }}
  defaultValue="Konya"
/>
</div> : <div><text className="font-[Gotham-Medium]">Veri Bulunmamaktadır!</text></div>}

      </div>

      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>


<h3 className="mt-8 mb-4 text-[#081F43] font-[Gotham-Medium] text-lg">
          Filtre
        </h3>

<FormGroup className="justify-center items-center">
<div className="grid xxs:grid-cols-3 xs:grid-cols-4 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-6">
          {this.state.isLoading == false ? <SelectOption  handleChange={(e)=> this._handleChange(e,"gender")} value={this.state.userGender}  type={"gender"} /> : null }
          {this.state.userGender == "Kadın" ? null :  <FormControlLabel onClick={()=> this.saveCheckBox('military')} control={<Checkbox checked={this.state.checkMilitary}/>} label={this.state.checkMilitary == true ?  "Askerlik" : "Askerlik"}  />}
          <FormControlLabel onClick={()=> this.saveCheckBox("driverlicense")} control={<Checkbox checked={this.state.checkDriverLicense}/>} label={this.state.checkDriverLicense == true ? "Ehliyet" : "Ehliyet"}  />
          <FormControlLabel onClick={()=> this.saveCheckBox("gradient")} control={<Checkbox checked={this.state.checkGradient}/>} label={this.state.checkGradient == true ? "Mezuniyet": "Mezuniyet" } />
          <FormControlLabel onClick={()=> this.saveCheckBox("cv")} control={<Checkbox checked={this.state.checkCv}/>} label={this.state.checkCv == true ? "Cv": "Cv" } />
          <FormControlLabel onClick={()=> this.saveCheckBox("video")} control={<Checkbox checked={this.state.checkVideo}/>} label={this.state.checkVideo == true ? "Videolu Özgeçmiş": "Videolu Özgeçmiş" } />
          <FormControlLabel onClick={()=> this.saveCheckBox("desc")} control={<Checkbox checked={this.state.checkDesc}/>} label={this.state.checkDesc == true ? "Ön Yazı": "Ön Yazı" } />
          <FormControlLabel onClick={()=> this.saveCheckBox("certifica")} control={<Checkbox checked={this.state.checkCertifica}/>} label={this.state.checkCertifica == true ? "Sertifika&Portfolyo": "Sertifika&Portfolyo" } />

          </div>


          <h3 className="mt-3  text-[#081F43] font-[Title] text-lg  ">
          Yaş Aralığı
        </h3>
        
          <div className="flex justify-center items-center w-full">
          <div className="pl-2 w-full">
          <InputField handleChange={(e)=> this.handleChange(e,'startAge')} type="number" typeCheck = {"startAge"} value={this.state.startAge} labelFiled={"Başlangıç"} />
         </div>
          <div className="pl-2 w-full">
          <InputField handleChange={(e)=> this.handleChange(e,'finishAge')} type="number" typeCheck = {"finishAge"} value={this.state.finishAge} labelFiled={"Bitiş"}  />
          </div>
          </div>
        
              </FormGroup>

              <button className="bg-[#284B84] rounded-lg m-1 justify-center w-full items-center flex" onClick={(e)=> this._updateForm(e)}>
          <div className="bg-white rounded-lg justify-center item p-1">
          <FontAwesomeIcon icon={ faSave }   color={'#284B84'}/> 
          </div>  
          <a className="text-center text-white p-2 font-[Gotham-Medium]">Bilgileri Güncelle</a>
          </button>
          
<LoadingSpinner show={this.state.isLoading} setShow={this._setShow}/> 

    </div>
  )
}
};

const MapWrapped = withScriptjs(withGoogleMap(Map));

export default withParamsAndNavigate(PersonelInformation);

