// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDLrq19vmkx6M9cZvR8Yc2TYlm-QxMkhXo",
  authDomain: "getjob-d8aa9.firebaseapp.com",
  projectId: "getjob-d8aa9",
  storageBucket: "getjob-d8aa9.appspot.com",
  messagingSenderId: "399261501542",
  appId: "1:399261501542:web:f073d1b8f7dc13cef3c5a7",
  measurementId: "G-C6QM0C4P0C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore(app);
