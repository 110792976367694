import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  alignItems: 'center',
  justifyContent: 'center',
};

const SuccessProfileScreen = ( props ) => {

    const [counter, setCounter] = React.useState(10);
    const [startCountdown, setStartCountdown] = React.useState(false);
  
    const [dataTelephone, setDataTelephone] = React.useState('');

    React.useEffect(() => {
        if (counter > 0) {

        setStartCountdown(true);
        }
      if (startCountdown) {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  

        if (counter === 0) {
          // countdown is finished
          setStartCountdown(false);
          // update your redux state here
          // updateReduxCounter(0);
        }
        
        return () => clearInterval(timer);
      }
    }, [counter, startCountdown]);



    const [data, setData] = React.useState({
        username: '',
        password: '',
        userMail: '',
        confirm_password: '',
        check_aInputChange: false,
        secureaEntry: true,
        confirm_secureaEntry: true,
        alertStatus:false,
        alertMessage1: '',
        isLoading:false,
        isValidUser: true,
        otpVisible:false,
        alertMessage:'',
        alertStatus:false,
        telephoneNumber: '',
        telephoneNumberFormatted:'',
        userNameSurname:'',
        telephoneNumber:'',
        userPassword:'',
        userPasswordConfirm:'',
        aInputChangePhone: false,
        check_aInputChangeName:false,
        isSelectTypeID:0
    });


    const _closeAlert=async()=>{

        setData({
            ...data,
            alertStatus:false,
        });

      }


    


const _signUpHandleSelect = (value) => {
  console.log("value123213",value)
  setData({
    ...data,
    isSelectTypeID: value,
  })
 }

 const _signUpHandle = async() => {
  console.log("lnsdfl123",data.isSelectTypeID)

  if(data.isSelectTypeID == 0){
    setData({
      ...data,
      isLoading: false,
      alertStatus: true,
      alertMessage: 'Lütfen Üyelik Tipini Seçiniz!'
      })
      //props._alert('Lütfen Üyelik Tipini Seçiniz!')

console.log("klnlknlk")
  }else{
    try {
      const userData = {  
        Users_Type_ID:data.isSelectTypeID,
       }

       console.log("1231239",data)
       
       const registerDataNew = {  
        ID : props.userID,
        Users_Type_ID:data.isSelectTypeID,
        Users_Active: true,
        Users_TelephoneNumber:props.registerData.enablePhone == true ? props.registerData.valuePhone : null,
        Users_Password:props.registerData.password,
        Users_Email:props.registerData.enablePhone == false ? props.registerData.mailAdress : null,
        Users_RegisterType:props.registerData.enablePhone == false ? "mail" : "telephone"
       }

      await axios.patch(`https://getjob.stechomeyazilim.info:5101/usersUpdate/${props.userID}`, userData).then(async(res) => {
       

      console.log("12res",res)
        props._successProfile(data.isSelectTypeID,props.userID)

        localStorage.setItem('dataLogin', JSON.stringify(registerDataNew));

      })
     


    
} catch (err) {
    console.warn("_signUpHandle",err)
}     
  }
             
  
        }


    const signInFunc= async() => {
       
      if(props.registerData.enablePhone == false){

      try {

        
        await axios.get(`https://getjob.stechomeyazilim.info:5101/getUserDetail/select/${props.registerData.mailAdress}/${props.registerData.password}`)
        .then((res) => {
          if(res.data.length == 0) {
  
            setData({ ...data,alertMessage: 'Kullanıcı adı veya şifreniz yanlış',alertStatus:true,isLoading:false });
        } else {
          
          console.log("girdi",JSON.stringify(res.data))
          localStorage.setItem('dataLogin', JSON.stringify(res.data[0]));
          showToast('GetJob', "Başarıyla Giriş Yaptınız!", 'success')
    }
        })

              
          } catch (err) {
            console.log("erccr123",err)
                 setData({ ...data,alertMessage: 'Kullanıcı adı veya şifreniz yanlış',alertStatus:true,isLoading: false });
  
          } 
      }else{
     
        try {

        
          await axios.get(`https://getjob.stechomeyazilim.info:5101/getUserDetail/select/${props.registerData.valuePhone}/${props.registerData.password}`)
          .then((res) => {
            if(res.data.length == 0) {
    
              setData({ ...data,alertMessage: 'Kullanıcı adı veya şifreniz yanlış',alertStatus:true,isLoading:false });
          } else {
            
            console.log("girdi",JSON.stringify(res.data))
            localStorage.setItem('dataLogin', JSON.stringify(res.data[0]));
            showToast('GetJob', "Başarıyla Giriş Yaptınız!", 'success')
      }
          })
  
                
            } catch (err) {
              console.log("erccr123",err)
                   setData({ ...data,alertMessage: 'Kullanıcı adı veya şifreniz yanlış',alertStatus:true,isLoading: false });
    
            } 
      }
  
     }

     const showToast= (event, value, type) => {
      if(type == "false"){
        toast.error(value, {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
          window.location.reload(false);
  
      }else{
        toast.success(value, {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
          window.location.reload(false);
  
      }
    
    }

    return (
      <Modal
      open={props._otpSuccessProfileVisible}
      onClose={props._closeProfileModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box   
  className='bg-white justify-center items-center text-center' sx={style}>

<div className='justify-center items-center'>
<div className='w-full p-2 h-full justify-center items-center text-center bg-[#081F43]'>
            <p className='font-[Gotham-Medium] text-center text-white'>GetJob Doğru Kişiyi, Doğru İşle Buluşturur!</p>
        </div>

  <div className='w-full p-2 items-center justify-center'>
  <div className='items-center justify-center text-center'>
    <div className='items-center justify-center text-center'>
  <FontAwesomeIcon icon={ faCircleCheck }   color={'#081F43'}/>                            
    <a className='font-[Gotham-Medium] text-center pl-2'>Tebrikler!</a>
    </div>
    <a className='font-[Gotham-Medium] text-center'>Hesabın oluşturuldu.</a>
  </div>


<div className='grid'>
    <a  className='font-[Gotham-Medium] text-center'>Hangi konumdasın?</a>
    <a  className='font-[Gotham-Medium] text-center'>Hemen tercihini belirle!</a>
    </div>
  <div >

  <button className='border-2 relative border-[gray] rounded-md' onClick={()=> _signUpHandleSelect(2)} >
  {data.isSelectTypeID == 2 ? 
  <div style = {{position:'absolute',right:-10,top:-10}}>
  <FontAwesomeIcon icon={ faCircleCheck } size="lg"   color={'#E7D36F'}/>                            
  </div> : null}
  <div className='bg-[#081F43]'>
    <a className='font-[Gotham-Medium] text-[white]'>İş Arıyorum</a>
    </div>
    <img  src={require('../../Group_952.png')}  />
  </button>


  <button className='border-2 relative ml-2 border-[gray] rounded-md'  onClick={()=> _signUpHandleSelect(1)}>
  {data.isSelectTypeID == 1 ? 
  <div style = {{position:'absolute',right:-10,top:-10}}>
  <FontAwesomeIcon icon={ faCircleCheck } size="lg"   color={'#E7D36F'}/>                            
  </div> : null}
  <div className='bg-[#081F43]'>
    <a className='font-[Gotham-Medium] text-[white]'>İşverenim</a>
    </div>
    <img  src={require('../../Group_976.png')}  />
  </button>
  </div>


  </div>
  

  
  </div>

  <button onClick={()=> _signUpHandle()} className=" w-full bg-gradient-to-r from-[#081F43] to-[#E7D36F] p-2 items-center text-center justify-center">
<a className='font-[Gotham-Medium] text-white p-5'>
DEVAM ET</a>
</button>
        </Box>


  </Modal>
              );
  };
  
  export default SuccessProfileScreen;
  

  