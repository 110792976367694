import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import locationImage from "../../../assets/locationIcon.svg";
import Logo from "../../../assets/Navbar/logo.png";

const SliderCardJobSeeker = (props) => {
  return (
    <Link to={"/employeeınfo/" + (props.item.Users.ID)} state= {{ _item: props.item }} >
      <div className="flex  hover:outline hover:outline-4 hover:outline-blue-300 flex-col text-center h-full rounded-lg shadow-lg justify-center">
     
      <div  className="h-48 w-full  rounded-2xl p-1 justify-center items-center flex" >
     { props.item.Users.Users_Profile_Photo != null ?  <img
            src={"https://getjob.stechomeyazilim.com/Uploads/" + props.item.Users.Users_Profile_Photo}
            alt=""
            className=" w-full h-full"
          /> : <div className="bg-[#081F43] flex justify-center items-center p-4 rounded-lg"><FontAwesomeIcon icon={faUser} style={{margin:10}}  size={"5x"} color={"white"} /></div>}
        </div> 
        <div className="flex justify-between w-full p-1">
          <div className="flex flex-row w-full h-full">
            <img src={locationImage} alt="" className=" mt-1 w-4 h-4 " />
            <p className="font-bold text-[#052A69] pl-2  font-[Title]">{props.item.Job_Seeker_City}</p>
          </div>
          {props.item.Users.Users_NameSurname != null ?  <div className="flex flex-row w-full h-full justify-end">
            <p className="text-sm text-[#052A69] pl-9  font-[Gotham-Medium]">{props.item.Users.Users_NameSurname}</p>
          </div> : null}
        </div>
        {props.item.Job_Salery_Expection != null ? <div className="flex justify-center bg-blue-50	 w-full h-full">
          <p className="text-sm font-[Gotham-Medium] text-[#052A69] py-2 ">{props.item.Job_Salery_Expection.Job_Salery_Expection_Text}</p>
        </div> : null}
        <div className="bg-[#081F43]  rounded-bl-lg rounded-br-lg py-3 text-white flex justify-center">
       { props.item.Job != null ? <h3 className="font-[Gotham-Medium] flex ">{props.item.Job.Job_Text}</h3>:null}
        </div> 
      </div>
    </Link>
  );
};

export default SliderCardJobSeeker;
