export const rtc = {
  // For the local client
  client: null,
  // For the local audio and video tracks
  localAudioTrack: null,
  localVideoTrack: null,
};

export const options = {
  // Pass your app ID here
  appId: "007eJxTYGi83bhqe8MLL7kTTfMO/D9T8l1Eapa/nsnR51GzOLx/uyooMJhZWCQZGxskm6YYppiYm5lZmpglGxmYGCSnphqkJiclht99mdwQyMgQuMSAkZEBAkF8Zoa81HIGBgCc1CBO",
  // Pass a token if your project enables the App Certificate
  token: null,
};
