import React from 'react'
import { BsPeople } from "react-icons/bs";
import moment from 'moment';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const BildirimCard = (props) => {
  return (
    props.loginData.Users_Type_ID == 2 ? 
    <div className="rounded-lg bg-blue-100 hover:outline	hover:outline-2 hover:outline-blue-500 w-full flex flex-col mx-1 my-1 hover:bg-blue-50">
          <div className=" w-full flex flex-col">
            <div className="w-full flex  justify-start ">
              <div className="w-full flex py-2 px-2">
              { props.item.Users.Users_Profile_Photo != null ? <img src={'https://getjob.stechomeyazilim.com/Uploads/' + props.item.Users.Users_Profile_Photo} alt="" className="w-12 h-12 rounded-full" /> :
              <div className="bg-[#081F43] flex justify-center items-center p-4 rounded-lg"><FontAwesomeIcon icon={faUser} style={{margin:10}}  size={"5x"} color={"white"} /></div>}
                <div className="w-full flex flex-wrap pl-3  text-[#07043B] text-md">
                  <p> 
                    <span className="font-bold">{props.item.Users.Users_NameSurname}</span> {props.item.Notification_Type.Notification_Type_Text}
                  </p>
                  
                </div>
                <div className="w-full ">
              <p className=" text-gray-500 font-[Gotham-Medium] text-xs text-center">{moment(props.item.Notification_CreatedDateTime).fromNow()}</p>
            </div>
                <div className='w-9 h-9 flex justify-center items-center mx-auto my-auto'>
                    {<BsPeople className="w-12 h-12" />}
                </div>
              </div>
            </div>
            
            
          </div>
        </div>:
        <div className="rounded-lg bg-blue-100 hover:outline	hover:outline-2 hover:outline-blue-500 w-full flex flex-col mx-1 my-1 hover:bg-blue-50">
        <div className=" w-full flex flex-col">
          <div className="w-full flex  justify-start ">
            <div className="w-full flex py-2 px-2">
              <img src={'https://getjob.stechomeyazilim.com/Uploads/' + props.item.Users.Users_Profile_Photo} alt="" className="w-12 h-12 rounded-full" />
              <div className="w-full flex flex-wrap pl-3  text-[#07043B] text-md">
                <p> 
                  <span className="font-bold">{props.item.Users.Users_NameSurname}</span> {props.item.Notification_Type.Notification_Type_Text}
                </p>
                
              </div>
              <div className="w-full ">
            <p className=" text-gray-500 font-[Gotham-Medium] text-xs text-center">{moment(props.item.Notification_CreatedDateTime).fromNow()}</p>
          </div>
              <div className='w-9 h-9 flex justify-center items-center mx-auto my-auto'>
                  {<BsPeople className="w-12 h-12" />}
              </div>
            </div>
          </div>
          
          
        </div>
      </div>
  )
}

export default BildirimCard