import React, { Component } from "react";
import { Switch } from "@headlessui/react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faShare, faTrash } from "@fortawesome/free-solid-svg-icons";
import ImageModal from './imageModal'


class FirstBorder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      enabled:false,
      selectedImage:[],
      id:0,
      enabled:false
    }
  }

  _getSetting= async() => {
    try{
      let loginData =  JSON.parse(localStorage.getItem('dataLogin'))
  
     
     await axios.get(`https://getjob.stechomeyazilim.info:5101/getUserNotSetting/select/${loginData.ID}`)
     .then(async (res) => {
      if(res.data.length>0){
        this.setState({enabled: true})
        this.setState({enabledSms: res.data[0].Sms_Notification})
        this.setState({id : res.data[0].ID})
        this.setState({enabledProfile: res.data[0].Profile_Seen})
          this.setState({enabledMail: res.data[0].Mail_Notification})
      }else{
        this.setState({enabled: false})
      }
     })
    } catch (error) {
      console.log("_getList" + error)
    }
  }

  async componentDidMount() {
    await this._getSetting()
  }
  


  onFileChangePortfolyo = async (e,type) => { 
  
    this.setState({isPortfolyoLoading: true})
    let files = e.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    console.log("km1l2312",files[0].type)
    //fileReader.readAsText(files[0]);
  
    fileReader.addEventListener('loadstart', this.changeStatus('Start Loading'));
    fileReader.addEventListener('load', this.changeStatus('Loaded'));
    fileReader.addEventListener('loadend', this.loaded);
    fileReader.addEventListener('progress', this.setProgress);
    fileReader.addEventListener('error', this.errorHandler);
    fileReader.addEventListener('abort', this.changeStatus('Interrupted'));
    

    fileReader.onload = (event) => {
  
      this.setState({
        isGradientLoading:true,
        isLoading: false,
        selectedImage: [{ ID: parseInt(this.state.selectedImage.length+1), data: parseInt(this.state.selectedImage.length+1) + ". Belge", type : 'image' , imageData : event.target.result,type : files[0].type == "image/png" ? "photo"  :  files[0].type == "application/pdf" ? "document"  :  files[0].type == "video/quicktime" ? "video" :  files[0].type == "video/quicktime" ? "video" : null }]
      },()=> {
        this.onSubmit()
      })

     
    }


  
  }

  _deleteUsers = () => {
    try{
    let loginData =  JSON.parse(localStorage.getItem('dataLogin'))
   // axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteUsers(${loginData.ID})`)

     this.props._openToast("delete")
    } catch (error) {
      console.log("_getList" + error)
    }

  }

  _passsiveUsers = async() => {
    try{
    let loginData =  JSON.parse(localStorage.getItem('dataLogin'))

   let user = {
    Users_Active :false
   }
   
   await axios.patch(`https://getjob.stechomeyazilim.info:5101/usersUpdate/${loginData.ID}`, user)
   .then(async (res) => {
    this.props._openToast("patch2")
   })
  } catch (error) {
    console.log("_getList" + error)
  }

  }

 changeStatus = (status) => {
  document.getElementById('status').innerHTML = status;
}

enableInputFile(e, id) {
  document.getElementById(id).click();
}

 setProgress = (e) => {
  const fr = e.target;
  const loadingPercentage = 100 * e.loaded / e.total;

  document.getElementById('progress-bar').value = loadingPercentage;
}

setProgressGradient = (e) => {
  const fr = e.target;
  const loadingPercentage = 100 * e.loaded / e.total;

  document.getElementById('progress-bar-gradient').value = loadingPercentage;
}

 loaded = (e) => {
  const fr = e.target;
  var result = fr.result;

  this.changeStatus('Yükleme Tamamlandı!');
  console.log('Result:', result);
}

 errorHandler = (e) => {
  this.changeStatus('Error: ' + e.target.error.name);
}

  onSubmit= (e) => {
    this.setState({isLoading: true})
   console.log("sdnfsdl",this.state.selectedImage[0])
      const formData = { image: this.state.selectedImage[0].imageData }
      let endpoint = "https://getjob.stechomeyazilim.info:5101/fileUpload/insert/";
       axios.post(endpoint, formData, {
       }).then(async(res) => {

        let data = {
          Employer_Company_Image : res.data[0].fileName
        }
        
       // this.setState({isLoading: false,
        //  allGradient :[{ ID: 0, data: res.data[0].fileName, type : this.state.selectedImageGradient[0].type  }] })
          console.log('Fileploaded!',res.data);

          let dataLogin =  JSON.parse(localStorage.getItem("dataLogin"))


          await axios.patch(`https://getjob.stechomeyazilim.info:5101/employerCompanyPatch/57`, data)
          this.showToast('GetJob', "Başarıyla Görseller Yüklendi!", 'success')
  
      })
    
  }
  showToast= (event, value, type) => {
    if(type == "false"){
      toast.error(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
  
    }else{
      toast.success(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
        const{params,navigate} = this.props;
  
        navigate('/jobSeekerInfo/'  + this.props.item.Users.ID, { state: { _item: { ID : this.props.item.Users_ID } }})
  
  
    }
  
  }

  enableInputFile(e, id) {
    document.getElementById(id).click();
  }

   _shareApp = async() => {
    navigator.clipboard.writeText(window.location.href)
    this.props._openToastModal()
  }
  
  
   _patchOrPostCheck = async(e,type) => {
    console.log("asjndfk",e,type)
    if(type=="mail"){
      this.setState({enabledMail: e},async()=>{
        try{
          let loginData =  JSON.parse(localStorage.getItem('dataLogin'))
      
          console.log("skdjnf",this.state.enabledSms,this.state.enabled,this.state.enabledMail)
          let data = {
            Users_ID:parseInt(loginData.ID),
            Sms_Notification:this.state.enabledSms,
           // Job_Notification:
            Profile_Seen:this.state.enabledProfile,
            Mail_Notification:this.state.enabledMail
          }
          
      
         if(this.state.enabled == true){
          console.log("kjsbdfjk",this.state.id)
          await axios.patch(`https://getjob.stechomeyazilim.info:5101/patchUserNotification/${this.state.id}`, data)
          .then(async (res) => {
           this.props._openToastModal2("patch2")
          })
         }else{
      
          let data = {
            Users_ID:parseInt(loginData.ID),
            Sms_Notification:this.state.enabled,
           // Job_Notification:
            Profile_Seen:this.state.enabledProfile,
            Mail_Notification:this.state.enabledMail
          }
          
          await axios.post(`https://getjob.stechomeyazilim.info:5101/postUsersNotificationSetting/send`, data) .then((res) => {
            this.props._openToastModal2("patch2")
      
          this.setState({enabled : true})
      
             })
              
         }
      
        } catch (error) {
          console.log("_getList" + error)
        }
      })
    }
    if(type=="profile"){
      this.setState({enabledProfile: e},async()=>{
        try{
          let loginData =  JSON.parse(localStorage.getItem('dataLogin'))
      
          console.log("skdjnf",this.state.enabledSms,this.state.enabled,this.state.enabledMail)
          let data = {
            Users_ID:parseInt(loginData.ID),
            Sms_Notification:this.state.enabledSms,
           // Job_Notification:
            Profile_Seen:this.state.enabledProfile,
            Mail_Notification:this.state.enabledMail
          }
          
      
         if(this.state.enabled == true){
          console.log("kjsbdfjk",this.state.id)
          await axios.patch(`https://getjob.stechomeyazilim.info:5101/patchUserNotification/${this.state.id}`, data)
          .then(async (res) => {
           this.props._openToastModal2("patch2")
          })
         }else{
      
          let data = {
            Users_ID:parseInt(loginData.ID),
            Sms_Notification:this.state.enabled,
           // Job_Notification:
            Profile_Seen:this.state.enabledProfile,
            Mail_Notification:this.state.enabledMail
          }
          
          await axios.post(`https://getjob.stechomeyazilim.info:5101/postUsersNotificationSetting/send`, data) .then((res) => {
            this.props._openToastModal2("patch2")
      
          this.setState({enabled : true})
      
             })
              
         }
      
        } catch (error) {
          console.log("_getList" + error)
        }
      })
    }
    if(type=="sms"){
      this.setState({enabledSms: e},async()=>{
        try{
          let loginData =  JSON.parse(localStorage.getItem('dataLogin'))
      
          console.log("skdjnf",this.state.enabledSms,this.state.enabled,this.state.enabledMail)
          let data = {
            Users_ID:parseInt(loginData.ID),
            Sms_Notification:this.state.enabledSms,
           // Job_Notification:
            Profile_Seen:this.state.enabledProfile,
            Mail_Notification:this.state.enabledMail
          }
          
      
         if(this.state.enabled == true){
          console.log("kjsbdfjk",this.state.id)
          await axios.patch(`https://getjob.stechomeyazilim.info:5101/patchUserNotification/${this.state.id}`, data)
          .then(async (res) => {
           this.props._openToastModal2("patch2")
          })
         }else{
      
          let data = {
            Users_ID:parseInt(loginData.ID),
            Sms_Notification:this.state.enabled,
           // Job_Notification:
            Profile_Seen:this.state.enabledProfile,
            Mail_Notification:this.state.enabledMail
          }
          
          await axios.post(`https://getjob.stechomeyazilim.info:5101/postUsersNotificationSetting/send`, data) .then((res) => {
            this.props._openToastModal2("patch2")
      
          this.setState({enabled : true})
      
             })
              
         }
      
        } catch (error) {
          console.log("_getList" + error)
        }
      })
    }
   

  }
  


   _setImageModal=(imgUrl)=> {
    this.setState({imgUrl: imgUrl})
    this.setState({imgVisible : true})
  }
  _closeImageModal=()=> {
    this.setState({imgVisible : false})
  }

  render(){
  return (
    <div className="w-full flex justify-between  border-b-4  border-yellow-500 items-center">
      
      <div className="w-full h-52  bg-gradient-to-r from-[#081F43]   via-[#f8d581] to-white flex  items-center rounded-t-xl  pl-2">
            {this.props.item.Employer_Company.Employer_Company_Image != null ? <button onClick={()=> this._setImageModal(this.props.item.Employer_Company.Employer_Company_Image)} className="flex ">
                <img src={"https://getjob.stechomeyazilim.com/Uploads/"+ this.props.item.Employer_Company.Employer_Company_Image } alt="" className="w-40 h-40 rounded-2xl " />
              </button> : <div className="bg-[#081F43] flex justify-center items-center p-4 rounded-lg"><FontAwesomeIcon icon={faBuilding} style={{margin:10}}  size={"5x"} color={"white"} /></div>}

            </div>

     
      <div className="flex flex-col pt-3 pr-3 ">

        { this.props.edit  ==  false ? null :   
        
        <div className="grid justify-center items-center ">
        <div className="flex justify-center items-center pr-3">
   <Switch
     checked={this.state.enabledProfile}
     onChange={(e)=> this._patchOrPostCheck(e,"profile")}
     className={`${this.state.enabledProfile ? "bg-[green]" : "bg-zinc-500"}
relative inline-flex h-[22px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 mr-3`}
   >
     <span className="sr-only">Use setting</span>
     <span
       aria-hidden="true"
       className={`${
         this.state.enabledProfile ? "translate-x-7 bg-[white]" : "translate-x-0"
       }
pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
     />
   </Switch>
     <p className="w-full text-lg font-[Gilroy-Medium]">Profili Gizle</p>
  
   </div>

<div className="flex justify-center items-center pr-3">
   <Switch
     checked={this.state.enabledSms}
     onChange={(e)=> this._patchOrPostCheck(e,"sms")}
     className={`${this.state.enabledSms ? "bg-[green]" : "bg-zinc-500"}
relative inline-flex h-[22px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 mr-3`}
   >
     <span className="sr-only">Use setting</span>
     <span
       aria-hidden="true"
       className={`${
         this.state.enabledSms ? "translate-x-7 bg-[white]" : "translate-x-0"
       }
pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
     />
   </Switch>
   <text className="font-[Gilroy-Medium]">SMS Bildirim</text>
   </div>

   <div className="flex justify-center items-center pr-3">
   <Switch
     checked={this.state.enabledMail}
     onChange={(e)=> this._patchOrPostCheck(e,"mail")}
     className={`${this.state.enabledMail ? "bg-[green]" : "bg-zinc-500"}
relative inline-flex h-[22px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 mr-3`}>

     <span
       aria-hidden="true"
       className={`${
         this.state.enabledMail ? "translate-x-7 bg-[white]" : "translate-x-0"
       }
pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
     />
   </Switch>

   <text className="font-[Gilroy-Medium]">Mail Bildirim</text>
   </div>
   <button style = {{backgroundColor: '#081F43',borderRadius:10,margin:5}} onClick={()=> this._shareApp()}>
                     <FontAwesomeIcon icon={faShare} style = {{margin:10}}  size={"xl"} color={"#f8d581"} />
                 </button>


   <button onClick={()=> this._passsiveUsers()} className="xxs:hidden sm:hidden mt-2 ml-2 border-2 border-zinc-700 rounded-md flex justify-center items-center text-center bg-[red]" >
     <a className="p-1 text-lg font-[Gilroy-Medium] text-[white]">Hesabımı Dondur</a>
   </button>

   <button onClick={()=> this._deleteUsers()}  className="xxs:hidden sm:hidden mt-2 ml-2 border-2 border-zinc-700 rounded-md flex justify-center items-center text-center" >
   <FontAwesomeIcon className='flex justify-center items-center w-6 h-6' icon={ faTrash }  size={20} color={'red'} />    
     <a className="text-lg font-[Gilroy-Medium] p-1 ">Hesabımı Sil</a>
   </button>

 </div> }
      </div>

     
      <ImageModal _imageUrl={this.state.imgUrl} _imageModalVisible={this.state.imgVisible} _closeImageModal={this._closeImageModal}/>

    </div>
  )
}
}

export default FirstBorder;
