import React from 'react'
import Moment from 'moment'

const StarCard = ({ImgSource,ıconSource,labelBildirim,CompanyName,time,Notifacation_Type_ID,updateEmployer,ID,Job_Employer_CompanyName,Users_NameSurname,Employer_ID,Employer_Company_Image,Users_Profile_Photo,Sender_Users_ID,Users_Type_ID}) => {
  console.log("sklndf", Users_Type_ID)
  return (
    Users_Type_ID == 1 ?
    <div className="w-full h-full flex flex-col p-1 border-b ">
      
          <div className=" w-full flex flex-col  py-1 px-4 rounded-lg ">
            <div className="w-full flex  justify-start ">
              <div className="w-full flex py-2 px-2">
                <img src={ImgSource} alt="" className="w-16 h-16 rounded-full outline outline-1 outline-blue-500 " />
                <div className="w-full flex flex-wrap pl-3  text-[#07043B] text-md">
                  <p>
                    <span className="font-[Gotham-Medium] text-blue-900">{CompanyName}</span> <span  className="font-[Gotham-Medium]">Kişisini Beğenmediniz</span> <span className='text-gray-500 font-[Gotham-Medium] text-xs ml-1'>{Moment(time).fromNow()}</span>
                  </p>
                  
                </div>
                <div className='w-10 h-10 flex justify-center items-center mx-auto my-auto'>
                    {ıconSource}
                </div>
              </div>
            </div>
            
          </div>

        </div>
        :
        <div className="w-full h-full flex flex-col p-1 border-b ">
      
        <div className=" w-full flex flex-col  py-1 px-4 rounded-lg ">
          <div className="w-full flex  justify-start ">
            <div className="w-full flex py-2 px-2">
              <img src={ImgSource} alt="" className="w-16 h-16 rounded-full outline outline-1 outline-blue-500 " />
              <div className="w-full flex flex-wrap pl-3  text-[#07043B] text-md">
                <p>
                  <span className="font-[Gotham-Medium] text-blue-900">{CompanyName}</span> <span  className="font-[Gotham-Medium]">Kişisini Beğenmediniz</span> <span className='text-gray-500 font-[Gotham-Medium] text-xs ml-1'>{Moment(time).fromNow()}</span>
                </p>
                
              </div>
              <div className='w-10 h-10 flex justify-center items-center mx-auto my-auto'>
                  {ıconSource}
              </div>
            </div>
          </div>
          
        </div>

      </div>
  )
}

export default StarCard