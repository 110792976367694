import React, { useEffect } from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import axios from 'axios';
import FirstBorder from "./FirstBorder";
import PersonelInfoResult from "./PersonelInfoResult";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import LoadingSpinner from "../../LoadingSpinner";

const JobSeekerForm = () => {
  const location = useLocation();
  const state = location.state;

  const [list, setList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [toastCheck, setToastCheck] = React.useState([]);

  useEffect(() => {
    const _getList= async() => {
      try{
        await axios.get(`https://getjob.stechomeyazilim.info:5101/getJobSeekerProfile/select/${state._item.ID}`)
        .then((res) => {
           setList(res.data) 
           setIsLoading(false)  
          })
  }
  catch (error) {
    console.log("errorccAS" + error)
  }
    }

    _getList()
    window.scrollTo(0, 0);

  }, [])


  const _openToast= async(type) => {
    setToastCheck(!toastCheck)

    if(type == "patch"){
      showToast('GetJob', "Başarıyla Hesabınız Donduruldu!", 'success')
    }else{
      showToast('GetJob', "Başarıyla Hesabınız Silindi!", 'success')
    }
   

  }

  const _openToastModal= async(type) => {
    setToastCheck(!toastCheck)
      showToast('GetJob', "Linkiniz başarıyla kopyalandı!", 'success')
}
  const showToast= (event, value, type) => {
    if(type == "false"){
      toast.error(value, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        });
  
    }else{
      toast.success(value, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        });
      
    }
  
  }
  return (

    <div >
      <Navbar />
      <div className="w-full  bg-[#F7F7F7] flex flex-col justify-between ">
        <div className="max-w-full mt-40 flex flex-col justify-center">
          <h3 className="text-xl text-center px-auto mt-6 text-blue-800 font-bold ">
            Profil
          </h3>
          <div className=" border rounded-2xl bg-white w-3/5 mx-auto mb-28 h-full mt-12 flex  flex-col  ">
          {list.length>0 ? <FirstBorder _openToastModal={_openToastModal} item ={list[0]} _openToast={_openToast}/> : null }
            {list.map((item, index) => (<PersonelInfoResult _item={item} />))}
          </div>
          <Footer />
        </div>
      </div>

      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>  
<LoadingSpinner show={isLoading} /> 

    </div>
  )
};


export default JobSeekerForm;
