import React,{ useEffect,useState } from "react";
import LabelSamp from "./LabelSamp";
import webImage from "../../assets/webimage.svg";
import facebookImage from "../../assets/facebook.svg";
import twitterImage from "../../assets/twitter.svg";
import InstaImg from "../../assets/Insta.svg";
import moment from 'moment';

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import mapStyles from './mapStyles'
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

function Map(props) {
  const [selectedPark, setSelectedPark] = useState(null);

  useEffect(() => {
    const listener = e => {
      if (e.key === "Escape") {
        setSelectedPark(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

console.log("lksdn123ckf",props.dealerData)
  return (
   props.dealerData != null ?
    <GoogleMap
      defaultZoom={4}
      defaultCenter={{ lat: 48.8114633 , lng: 34.8990059}}
      options={{ styles: mapStyles }}>
        <Marker
          key={props.dealerData.ID}
          position={{
            lat: props.dealerData.position.lat,
            lng:props.dealerData.position.lng
          }}
          onClick={() => {
            setSelectedPark(props.dealerData.Employer_Map);
          }}
          icon={{
            url:  "https://img.icons8.com/color/48/000000/map-pin.png",
            scaledSize: new window.google.maps.Size(50, 50)
          }}
        >

<InfoWindow
          onCloseClick={() => {
            setSelectedPark(null);
          }}
          position={{
            lat: props.dealerData.position.lat,
            lng: props.dealerData.position.lng
          }}
        >
          <div>
            <h2 className="font-[Gotham-Medium] ">{props.dealerData.currentCity}</h2>
          </div>
        </InfoWindow>
        </Marker>

    </GoogleMap> : null 
    
  );
}

const PersonelInfoResult = (props) => {
  const [mapdata1, setMapData] = useState({
    mapDataFetch:null
  })


  console.log("lsndlf",props._item.JobSeeker_Experience)
  useEffect(() => {
    if(props._item.Job_Seeker_Map.length > 0){
      setMapData({
        mapDataFetch:{
          ID :  props._item.ID,
              position:{
                lat:  props._item.Job_Seeker_Map[0].Job_Seeker_Map_Lat,
                lng:  props._item.Job_Seeker_Map[0].Job_Seeker_Map_Lng
          },
          currentCity:  props._item.Job_Seeker_Map[0].Job_Seeker_Map_City,
          currentLatitude : props._item.Job_Seeker_Map[0].Job_Seeker_Map_Lat,
          currentLongitude :  props._item.Job_Seeker_Map[0].Job_Seeker_Map_Lng}}
      )
    }
  },[])


  return (
    <div className="p-9">
      <div>
        
        <h3 className="mb-6 mt-3   text-[#081F43] font-[Gotham-Medium] text-lg text-left ">
          Kişisel Bilgiler
        </h3>
        <LabelSamp InfoField="Ad Soyad" FilledField={props._item.Users.Users_NameSurname} />
        <LabelSamp InfoField="Doğum Tarihi" FilledField={moment(props._item.Users.Users_Birthday).format('LL')} />
        <LabelSamp InfoField="Cinsiyet" FilledField={props._item.Users.Users_Gender} />
      
      
        <LabelSamp InfoField="Ehliyet" FilledField={props._item.Job_Seeker_Driver_License.map((item, index) => (
            <p className="flex-col text-center">{item.Job_Seeker_Driver_License_Text}</p> ))} />
        <LabelSamp InfoField="İl - İlçe" FilledField={ props._item.Job_Seeker_City+ " / " + props._item.Job_Seeker_Dist} />
        {props._item.Users.Users_Gender == "Erkek" ? <LabelSamp InfoField="Askerlik Durumu" FilledField={props._item.Users.Users_Military == true ? "Yapıldı" : "Yapılmadı"} /> : null}
        <div className="w-full border border-b-1 border-x-0 border-t-0  border-[#E5E5E5] mt-7"></div>
        <h3 className="mb-6 mt-3   text-[#081F43] font-[Gotham-Medium] text-lg text-left ">
          Eğitim Durumu
        </h3>
       { props._item.Job_Seeker_Education.length > 0 ? 
       
        props._item.Job_Seeker_Education.map((item, index) => ( 
       
       <div className="border-2 p-2 rounded-lg m-2">
        <LabelSamp  InfoField="Mezuniyet Durumu" FilledField= 
        {<p className="flex-col text-right p-2">{item.Job_Seeker_EducationStatus}</p>} />
        <LabelSamp
          InfoField="Mezun Olunan Okul"
          FilledField={
            <p className="flex-col text-right">{item.Job_Seeker_EducationSchool  }</p> } 
        />
        {item.University_Department !=  null ? <LabelSamp InfoField="Program" FilledField={
            <p className="flex-col text-right">{item.University_Department.name}</p>}  /> : <div className="justify-end items-end  text-sm font-[Gotham-Medium] text-[#062CBC] text-right">Program Bulunmamaktadır.</div>}
        { item.Job_Seeker_GradientDateEducation != null ?  
        <LabelSamp InfoField="Mezuniyet Tarihi" FilledField={<p className="flex-col text-right">{moment(item.Job_Seeker_GradientDateEducation ).format('LL') }</p>}  />: <p className="justify-end items-end  text-sm font-[Gotham-Medium] text-[#062CBC] text-right">Mezuniyet Tarihi Bulunmamaktadır.</p>} 
            { item.Job_Seeker_Education_City !=  null ?  <LabelSamp InfoField="Şehir" FilledField={
            <p className="flex-col text-right">{item.Job_Seeker_Education_City}</p>}  />   : <div className="justify-end items-end text-right text-sm font-[Gotham-Medium] text-[#062CBC]">Şehir Bilgisi Bulunmamaktadır.</div>}
            </div>)) : <div className="  flex-col font-[Gotham-Medium] text-left">Bilgi Girilmedi</div>}

            <div className="w-full border border-b-1 border-x-0 border-t-0  border-[#E5E5E5] mt-7"></div>

        <h3 className="mb-6 mt-3   text-[#081F43] font-[Gotham-Medium] text-lg text-left ">
          Çalışma Geçmişi
        </h3>

        <div className="border-2 p-2 rounded-lg m-2">

       { props._item.JobSeeker_Experience.length > 0 ? 
       
       props._item.JobSeeker_Experience.map((item, index) => ( 
        <div className="m-2">
        <LabelSamp  InfoField="Şirket" FilledField={
        <p className="flex-col text-right">{item.JobSeeker_Experience_Company}</p> } />

<LabelSamp  InfoField="Departman" FilledField={ 
        <p className="flex-col text-right text-[Gilroy-Medium]">{item.JobSeeker_Experience_Department}</p> } />

<LabelSamp  InfoField="Başlangıç Tarihi" FilledField={
        <p className="flex-col text-right text-[Gilroy-Medium]">{moment(item.JobSeeker_Experience_StartDate).format('LL')}</p> } />

<LabelSamp  InfoField="Bitiş Tarihi" FilledField={
        <p className="flex-col text-right text-[Gilroy-Medium]">{moment(item.JobSeeker_Experience_FinishDate).format('LL')}</p> } />

<LabelSamp  InfoField="Ülke" FilledField={
        <p className="flex-col text-right text-[Gilroy-Medium]">{item.JobSeeker_Experience_Country}</p> } />

<LabelSamp  InfoField="İl" FilledField={
        <p className="flex-col text-right text-[Gilroy-Medium]">{item.JobSeeker_Experience_County}</p> } />

<LabelSamp  InfoField="İlçe" FilledField={
        <p className="flex-col text-right text-[Gilroy-Medium]">{item.JobSeeker_Experience_District}</p>} />

<LabelSamp  InfoField="Kısa Açıklama" FilledField={
        <p className="flex-col text-right text-[Gilroy-Medium]">{item.JobSeeker_Experience_Short_Desc}</p>} />

{props._item.JobSeeker_Experience.length > 0 ? <LabelSamp  InfoField="Çalışma Şekli" FilledField={props._item.JobSeeker_Experience.map((item, index) => ( 
       item.Job_WayOfWorking != null ? <p className="flex-col text-right text-[Gilroy-Medium]">{item.Job_WayOfWorking.Job_WayOfWorking_Text}</p> : <p>yok</p>))} /> : null}


<LabelSamp  InfoField="İşi GetJob'dan Buldum" FilledField={
        <p className="flex-col text-right text-[Gilroy-Medium]">{item.Job_IsGetJob == true ? "Evet" : "Hayır"}</p> } />

<LabelSamp  InfoField="Hala Çalışıyorum" FilledField={
        <p className="flex-col text-right text-[Gilroy-Medium]">{item.JobSeeker_Experience_ContinousJob == true ? "Evet" : "Hayır"}</p> } />

            </div> ))
         : <div className=" flex-col font-[Gotham-Medium] text-left">Bilgi Girilmedi</div>}
         </div>
        <div className="w-full border border-b-1 border-x-0 border-t-0  border-[#E5E5E5] mt-7"></div>
        <h3 className="mb-6 mt-3   text-[#081F43] font-[Gotham-Medium] text-lg text-left ">
          İş Bilgileri
        </h3>
        <LabelSamp InfoField="Meslek" FilledField={props._item.Job.Job_Text} />
        <LabelSamp InfoField="İş Deneyim Süresi" FilledField= {props._item.Job_Experience.Job_Experience_Text} />
        <div className="w-full border border-b-1 border-x-0 border-t-0  border-[#E5E5E5] mt-7"></div>
        <h3 className="mb-6 mt-3   text-[#081F43] font-[Gotham-Medium] text-lg text-left ">
          Aranılan İşler
        </h3>
        <LabelSamp
          InfoField="İşler"
          FilledField={props._item.Job_Seeker_Search_Job.map((item, index) => ( 
            <p className="flex-col font-[Gotham-Medium] text-right">{item.Job_Seeker_Search_Job_Text  }</p> ))}
        />
        <div className="w-full border border-b-1 border-x-0 border-t-0  border-[#E5E5E5] mt-7"></div>
        <h3 className="mb-6 mt-3   text-[#081F43] font-[Gotham-Medium] text-lg text-left ">
          Maaş Beklentisi
        </h3>
        <LabelSamp InfoField="Maaş" FilledField={props._item.Job_Salery_Expection.Job_Salery_Expection_Text} />
        <div className="w-full border border-b-1 border-x-0 border-t-0  border-[#E5E5E5] mt-7"></div>
        <h3 className="mb-6 mt-3   text-[#081F43] font-[Gotham-Medium] text-lg text-left ">
          Çalışma Şekli
        </h3>
        {props._item.Job_Seeker_JobWayOut.length > 0 ? <LabelSamp
          InfoField="Çalışma Şekli"
          FilledField={props._item.Job_Seeker_JobWayOut.map((item, index) => ( 
            <p className="flex-col text-right">{item.Job_WayOfWorking.Job_WayOfWorking_Text}</p> ))}
        /> : null}

<div className="w-full border border-b-1 border-x-0 border-t-0  border-[#E5E5E5] mt-7"></div>
        <h3 className="mb-6 mt-3   text-[#081F43] font-[Gotham-Medium] text-lg text-left ">
          Çalışma Modeli
        </h3>
        {props._item.JobSeeker_JobModal.length > 0 ? <LabelSamp
          InfoField="Çalışma Modeli"
          FilledField={props._item.JobSeeker_JobModal.map((item, index) => ( 
            <p className="flex-col text-right">{item.JobSeeker_JobModal_Text}</p> ))}
        /> : null}

        <div className="w-full border border-b-1 border-x-0 border-t-0  border-[#E5E5E5] mt-7"></div>
        <h3 className="mb-6 mt-3   text-[#081F43] font-[Gotham-Medium] text-lg text-left ">
          Yabancı Dil
        </h3>
        <LabelSamp InfoField="Dil" FilledField={props._item.Job_Seeker_Language.map((item, index) => ( 
            <p className="flex-col text-right">{item.Job_Seeker_Language_Text  }</p> ))} />
        <div className="w-full border border-b-1 border-x-0 border-t-0  border-[#E5E5E5] mt-7"></div>
        <h3 className="mb-6 mt-3   text-[#081F43] font-[Gotham-Medium] text-lg text-left ">
          Yetenekler
        </h3>
        <LabelSamp InfoField="Yetenek" FilledField={props._item.Job_Seeker_Ability.map((item, index) => ( 
            <p className="flex-col text-right font-[Gotham-Medium] ">{item.Job_Seeker_Ability_Text  }</p> ))} />
        <div className="w-full border border-b-1 border-x-0 border-t-0  border-[#E5E5E5] mt-7"></div>
        <h3 className="mb-6 mt-3   text-[#081F43] font-[Gotham-Medium] text-lg text-left ">Hobi</h3>
        <LabelSamp InfoField="Hobi" FilledField={props._item.Job_Seeker_Hobby.map((item, index) => ( 
            <p className="flex-col text-right font-[Gotham-Medium]">{item.Job_Seeker_Hobby_Text  }</p> ))} />
        <div className="w-full border border-b-1 border-x-0 border-t-0  border-[#E5E5E5] mt-7"></div>
        <h3 className="mb-6 mt-3   text-[#081F43] font-[Gotham-Medium] text-lg text-left ">
          Linkler
        </h3>
        <div className="w-full flex flex-col">
          <div className="w-full flex flex-row mb-2">
            <div className="flex justify-between items-center mr-8">
              <img src={webImage} alt="" className="p-1" />
              <p className="text-[#868686] text-right font-[Gotham-Medium] ">Websitesi</p>
            </div>
            <div className="flex items-center  w-full justify-end">
            {props._item.Users_SocialMedia.map((item, index) => ( 
               <a target="_blank" href={item.Job_Seeker_SocialMedia_WebSite} className="text-[#062CBC] text-right font-[Gotham-Medium]">
            {item.Job_Seeker_SocialMedia_WebSite}  </a>))}
            </div>
          </div>
          <div className="w-full flex flex-row mb-2">
            <div className="flex justify-between items-center mr-8">
              <img src={facebookImage} alt="" className="p-1" />
              <p className="text-[#435390] text-right font-[Gotham-Medium]">Facebook</p>
            </div>
            <div className="flex items-center  w-full justify-end">
            {props._item.Users_SocialMedia.map((item, index) => ( 
              <a target="_blank"  href={item.Job_Seeker_SocialMedia_Facebook} className="text-[#062CBC] text-right font-[Gotham-Medium]">
            {item.Job_Seeker_SocialMedia_Facebook}  </a>))}
            </div>
          </div>
          <div className="w-full flex flex-row mb-2">
            <div className="flex justify-between items-center mr-8">
              <img src={twitterImage} alt="" className="p-1" />
              <p className="text-[#28A6DE] text-right font-[Gotham-Medium]">Twitter</p>
            </div>
            <div className="flex items-center  w-full justify-end">
            {props._item.Users_SocialMedia.map((item, index) => ( 
              <a target="_blank"  href={item.Job_Seeker_SocialMedia_Twitter} className="text-[#062CBC] text-right font-[Gotham-Medium]">
            {item.Job_Seeker_SocialMedia_Twitter} </a> ))}
            </div>
          </div>
          <div className="w-full flex flex-row mb-2">
            <div className="flex justify-between items-center mr-8 ">
              <img src={InstaImg} alt="" className="p-1" />
              <p className="text-[#8635A3] text-right font-[Gotham-Medium]">Instagram</p>
            </div>
            <div className="flex items-center  w-full justify-end">
            {props._item.Users_SocialMedia.map((item, index) => ( 
              <a target="_blank"  href={item.Job_Seeker_SocialMedia_Instagram} className="text-[#062CBC] text-right font-[Gotham-Medium]">
            {item.Job_Seeker_SocialMedia_Instagram} </a> ))}
            </div>
          </div>
        </div>

        <div className="w-full border border-b-1 border-x-0 border-t-0  border-[#E5E5E5] mt-7"></div>
        <h3 className="mb-6 mt-3   text-[#081F43] font-[Gotham-Medium] text-lg text-left ">Özgeçmiş</h3>
        <div className="flex columns-3  text-center">
        {props._item.Job_Seeker_CV.length == 0 ? <div>Veri Yok</div> : props._item.Job_Seeker_CV.map((item, index) => ( 
          item.Job_Seeker_CV_Type == "video" ?
          <div style={{ height: '50vh', width: '100%',zIndex:10 }} className="border-2 z-10 ex-collection-box mb-xl-20">
             <ReactPlayer controls={true} width="80%" height="80%" url={item.Job_Seeker_CV_Link}/>
             </div> :
                item.Job_Seeker_CV_Type == "document" ? 
                <a target="_blank" className="border-2 m-2 w-40  h-40 justify-center items-center relative" href={"https://getjob.stechomeyazilim.com/Uploads/" +item.Job_Seeker_CV_Link}>
                <button className="absolute -bottom-4 -left-2 bg-[red] rounded-lg">
                <FontAwesomeIcon icon={faMagnifyingGlass} style={{margin:10}}  size={"lg"} color={"white"} />
                </button>
           <a className="text-center justify-center items-center">PDF Görüntüle</a>
            </a>
                :
                <a target="_blank" className="border-2 m-2 relative w-40  h-40" href={"https://getjob.stechomeyazilim.com/Uploads/" +item.Job_Seeker_CV_Link}>
                  <button className="absolute -top-4 -left-2 bg-[#081F43] rounded-lg">
                  <FontAwesomeIcon icon={faMagnifyingGlass} style={{margin:10}}  size={"lg"} color={"white"} />
                  </button>
              <img src={'https://getjob.stechomeyazilim.com/Uploads/' + item.Job_Seeker_CV_Link} alt="" className="w-full h-full" />
              </a>))}
              </div>

        <div className="w-full border border-b-1 border-x-0 border-t-0  border-[#E5E5E5] mt-7"></div>
        <h3 className="mb-6 mt-3   text-[#081F43] font-[Gotham-Medium] text-lg text-left ">Diploma</h3>
        
        {props._item.Job_Seeker_CertificateLink != null ?   <a className="p-2" target="_blank" href={"https://getjob.stechomeyazilim.com/Uploads/" + props._item.Job_Seeker_CertificateLink}>PDF Görüntüle</a> : 
        <div>Link Yok</div> }


        <div className="w-full border border-b-1 border-x-0 border-t-0  border-[#E5E5E5] mt-7"></div>
        <h3 className="mb-6 mt-3   text-[#081F43] font-[Gotham-Medium] text-lg text-left ">Portfolyo / Dosyalar</h3>
        <div className="flex columns-3  text-center" >
        {props._item.Job_Seeker_Document.length == 0 ? <div>Veri Yok</div> : props._item.Job_Seeker_Document.map((item, index) => ( 
          item.Job_Seeker_Document_Type == "video" ?  <div style={{ height: '50vh', width: '100%',zIndex:10 }} className="z-10 ex-collection-box mb-xl-20">
             <ReactPlayer controls={true} width="80%" height="80%" url={item.Job_Seeker_Document_Link}/> 
             </div>:
                item.Job_Seeker_Document_Type == "document" ? 
<a target="_blank" className="border-2 m-2 w-40  h-40 justify-center items-center relative" href={"https://getjob.stechomeyazilim.com/Uploads/" +item.Job_Seeker_Document_Link}>
                <button className="absolute -bottom-4 -left-2 bg-[red] rounded-lg">
                <FontAwesomeIcon icon={faMagnifyingGlass} style={{margin:10}}  size={"lg"} color={"white"} />
                </button>
           <a className="text-center justify-center items-center">PDF Görüntüle</a>
            </a>                :
                <a target="_blank" className="border-2 m-2 justify-center items-center  relative w-40  h-40" href={"https://getjob.stechomeyazilim.com/Uploads/" +item.Job_Seeker_Document_Link}>
                  <button className="absolute -top-4 -left-2 bg-[#081F43] rounded-lg">
                  <FontAwesomeIcon icon={faMagnifyingGlass} style={{margin:10}}  size={"lg"} color={"white"} />
                  </button>
              <img src={'https://getjob.stechomeyazilim.com/Uploads/' + item.Job_Seeker_Document_Link} alt="" className="w-40  h-40 px-2" />
              </a>))}
              </div>
        <div className="w-full border border-b-1 border-x-0 border-t-0  border-[#E5E5E5] mt-7"></div>
        <h3 className="mb-6 mt-3   text-[#081F43] font-[Gotham-Medium] text-lg text-left ">Önyazı</h3>
        {props._item.Job_Seeker_Desc != null ?  <p className="flex-col text-left font-[Gotham-Medium]">{props._item.Job_Seeker_Desc}</p> : 
        <p className="flex-col text-left font-[Gotham-Medium]">Ön Yazı Bulunmamaktadır</p>}
        <div className="w-full border border-b-1 border-x-0 border-t-0  border-[#E5E5E5] mt-7"></div>
      </div>

      <h3 className="mb-6 mt-3   text-[#081F43] font-[Gotham-Medium] text-lg text-left ">Videolu Özgeçmiş</h3>

      {props._item.Job_Seeker_VideoLink != null ?  <div style={{ height: '50vh', width: '100%',zIndex:10 }} className="z-10 ex-collection-box mb-xl-20">
 
      <ReactPlayer controls={true} width="100%" height="100%" url={"https://getjob.stechomeyazilim.com/Uploads/" + props._item.Job_Seeker_VideoLink}/> 
</div>: <div>Video Yok</div>}
        <h3 className="mb-6 mt-3   text-[#081F43] font-[Gotham-Medium] text-lg text-left ">Konumum</h3>
        {props._item.Job_Seeker_Map.length > 0 ?  <div style={{ height: '50vh', width: '100%',zIndex:10 }} className="z-10 ex-collection-box mb-xl-20">

 { mapdata1.mapDataFetch != null ? <MapWrapped
   googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCm32U9QFgSBe_8pJG_2Z3G61pL6ipeQtU&v=3.exp&libraries=geometry,drawing,places}`}
   loadingElement={<div style={{ height: `100%` }} />}
   containerElement={<div style={{ height: `100%` }} />}
   mapElement={<div style={{ height: `100%` }} />}
   dealerData = {mapdata1.mapDataFetch != null ? mapdata1.mapDataFetch : null}
   currentLat={props._item.Job_Seeker_Map[0].Job_Seeker_Map_Lat}
   currentLng={props._item.Job_Seeker_Map[0].Job_Seeker_Map_Lng}
 /> :  <div><a className="font-[Gotham-Medium]">Konum Bulunmamaktadır!</a></div>}
 <div className={"bg-[#081F43] text-center"}>
 <a target={"_blank"} href={"https://www.google.com/maps/search/?api=1&query=" + props._item.Job_Seeker_Map[0].Job_Seeker_Map_Lat + "," + props._item.Job_Seeker_Map[0].Job_Seeker_Map_Lng} className={"text-[Gilroy-Medium] text-[white] p-2 text-center"}>Konumu Görüntüle</a>
 </div>
</div>  : <div><a className="font-[Gotham-Medium]">Konum Bulunmamaktadır!</a></div>}

    </div>
  );
};

export default PersonelInfoResult;


const MapWrapped = withScriptjs(withGoogleMap(Map));

