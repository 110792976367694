import React from "react";
import { useState } from "react";
import { Switch } from "@headlessui/react";
import { BsFillPersonFill } from "react-icons/bs";
import { FaKey } from "react-icons/fa";
import { AiFillEyeInvisible } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./phoneInput.css";
import Autharizatiom from "./Autharizatiom";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';


const LogIn = ( props ) => {
  const [enabled, setEnabled] = useState(false);
  const [valuePhone, setValuePhone] = useState();
  const [openAuth, setOpenAuth] = useState(false);
  const [mailAdress, setMail] = useState();
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();

  
  const [passwordShown, setPasswordShown] = useState();
  const [passwordShownConfirm, setPasswordShownConfirm] = useState();

  const [getData, saveData] = useState(false);

  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '2px solid #000',
    boxShadow: 24,
  };


  const handleAuth = () => {
    setOpenAuth(true);
  };


  const showToast= (event, value, type) => {
    if(type == "false"){
      toast.error(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
  
    }else{
      toast.success(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  
  }

  
  const setChangeConfirm=(value,type)=>{
    setPasswordConfirm(value)
   }

  const setChange=(value,type)=>{
   if(type=="mail"){
    setMail(value)
   }if(type=="password"){
   setPassword(value)
  }
  }

  

  const _setSmsOtp=async()=>{ 
    props._setOtpModalLoading(true)
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getUserDetailCheck/select/${enabled==true ? valuePhone.replace(/\s+/g,"").replace("+9","").replace("(","").replace(")","") : mailAdress}`)
    .then(async(res) => {
      console.log("res12313",res)
      if(res.data.length ==0){
        if(passwordConfirm == password){
          if(getData == true){
            props._setOtpModal(password,valuePhone,enabled,mailAdress)
          } else{
            showToast('GetJob', "Kullanım ve gizlilik koşullarını kabul etmediniz!", 'false')
            props._setOtpModalLoading(false)

          }
        }   
        else{
          showToast('GetJob', "Şifreler Birbiriyle Eşleşmiyor!", 'false')
        }
      }else{
        showToast('GetJob', "Bu kullanıcı sistemde daha önce oluşturulmuştur!", 'false')
        props._setOtpModalLoading(false)


      }

    })

  
       /*   sendSmsVerification(valuePhone).then((sent) => {
              console.log("Sent!",sent);
              if (sent == true){
                  setDataLoading(false)

                  setData({
                      ...data,
                      otpVisible:true,
                        Customer_NameSurname:data.userNameSurname,
                        Customer_TelephoneNumber: dataTelephone,
                        Customer_Password:data.userPassword,
                        Customer_FacebookId: data.facebookId,
                        Customer_Email: data.userMail
                  })

                  
              }else{
                  setData({
                      ...data,
                      alertStatus:true,
                      alertMessage: 'Lütfen telefon numaranızı kontrol ediniz',
                  });
              }
            });
  */
    }

  return (
    
    <Modal
    open={props.openLoginModalVisible}
    onClose={props._closeLoginModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
  <Box  sx={style}>

    <div className=" flex justify-center items-center   z-40">
      <div className={`${openAuth ? "hidden" : "flex flex-col  justify-center items-center" } bg-white shadow-2xl    rounded-lg w-[470px] px-2 pt-2 `}  >
        {!openAuth && (
          <div>
            <div className="flex justify-end z-50 mr-[-40px] ">
              <button
                onClick={props.handleSignUp}
                className="bg-[#f8d581] rounded-full p-2"
              >
                <GrClose className="" />
              </button>
            </div>
            <div className="flex justify-center items-center">
              <Switch
                checked={enabled}
                onChange={setEnabled}
                className={`${enabled ? "bg-[#5494DD]" : "bg-zinc-500"}
    relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={`${
                    enabled ? "translate-x-9 bg-yellow-400" : "translate-x-0"
                  }
      pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
            
            <div className="flex justify-center items-center font-bold mb-1">
              {enabled && <h2 className="font-[Gotham-Medium] ml-2">Telefon Numarası</h2>}
              {!enabled && <h2 className="font-[Gotham-Medium] ml-2">Mail Adresi</h2>}
            </div>
            </div>
            {enabled && (
              <div className="flex w-full  shadow-xl  mb-4  ">
                <div className="w-full py-3 px-2 bg-zinc-200">
                  <PhoneInput
                    className="bg-zinc-200 w-full font-[Gotham-Medium] outline-none focus:border-b-2 focus:border-blue-500 mr-3"
                    placeholder="Telefon Numaranızı Giriniz"
                    value={valuePhone}
                    onChange={setValuePhone}
                  />
                </div>
              </div>
            )}
            {!enabled && (
              <div className="flex w-full  shadow-xl  mb-4 mt-4">
                <div
                  className="w-full flex py-3 bg-zinc-200  justify-center items-center   "
                  onClick={""}
                >
                  <BsFillPersonFill className="ml-3 mr-7 flex justify-center items-center w-7 h-7 " />
                  <input
                    type="email"
                    className="bg-zinc-200 w-full font-[Gotham-Medium] outline-none focus:border-b-2 focus:border-blue-500 mr-3"
                    placeholder="E-posta Adresiniz"
                    value={mailAdress}
                    onChange={e => setChange(e.target.value,"mail")}
                    />
                </div>
              </div>
            )}
            <div className="flex w-full  shadow-xl  mb-4">
              <div
                className="w-full flex py-3 bg-zinc-200  justify-center items-center   "
                onClick={""}
              >
                <FaKey className="ml-3 mr-7 flex justify-center items-center w-6 h-6 fill-[#081F43]" />
                <input
                  type={passwordShown ? "text" : "password"}
                  
                  className="bg-zinc-200 w-full font-[Gotham-Medium] outline-none focus:border-b-2 focus:border-[#081F43] "
                  placeholder="Şifreniz"
                  onChange={e => setChange(e.target.value,"password")}
                />
                <div className=" px-3 flex ">
                  <button onClick={()=> setPasswordShown(!passwordShown)} className="rounded-full font-[Gotham-Medium] hover:bg-white mr-2 justify-center items-center p-1 ">
                    <AiFillEyeInvisible className="w-6 h-6 text-[#081F43]" />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex w-full  shadow-xl  mb-4">
              <div
                className="w-full flex py-3 bg-zinc-200  justify-center items-center   "
                onClick={""}
              >
                <FaKey className="ml-3 mr-7 flex justify-center items-center w-6 h-6 fill-[#081F43] " />
                <input
                 type={passwordShownConfirm ? "text" : "password"}
                 onChange={e => setChangeConfirm(e.target.value,"password")}
                 className="bg-zinc-200 w-full font-[Gotham-Medium] outline-none focus:border-b-2 focus:border-[#081F43] "
                 placeholder="Tekrar Şifre"
                />
                <div className=" px-3 flex ">
                  <button onClick={()=> setPasswordShownConfirm(!passwordShownConfirm)} className="rounded-full hover:bg-white mr-2 justify-center items-center p-1 ">
                    <AiFillEyeInvisible className="w-6 h-6 text-[#081F43]" />
                  </button>
                </div>
              </div>
            </div>
            <div className="w-full flex mb-4">
              <button
                onClick={_setSmsOtp}
                className="mx-auto border bg-[#081F43] hover:[#081F43] hover:bg-[#f8d581] text-[white] px-32 py-4 rounded-lg shadow-md "
              >
                <h2 className="font-[Gotham-Medium]">Kaydı Tamamla</h2>
              </button>
            </div>
            <div className="flex mt-2 text-center justify-center items-center">

            <FormGroup>
                <FormControlLabel  onClick={()=> saveData(!getData)} control={<Checkbox  defaultChecked={getData} checked={getData}/>} label={<a href="https://getjobweb.stechomeyazilim.com/getjob.pdf" target={"_blank"} className="font-[Times] text-center text-sm">{`Kullanım ve gizlilik koşullarını ${getData == true ? "KABUL EDİYORUM" : "KABUL ETMİYORUM"}`}</a>}/>
              </FormGroup>

            
            </div>
            <div className="w-full bg-[#f8d581] flex py-4 mb-2   rounded-lg">
              <div className="flex mx-auto justify-center items-center">
                <h3 className="mr-4 color font-[Gotham-Medium]">Zaten Bir Hesabınız Var Mı?</h3>
                <button className="font-[Gotham-Medium] p-3 border bg-[#081F43] hover:text-white hover:bg-blue-900 text-blue-900 rounded-lg " onClick={handleAuth}>
                <p className=" font-[Gotham-Medium] text-white">Giriş Yap</p>
                </button>
              </div>
            </div>
          </div>
        )}
        
      </div>
      
      {openAuth && <Autharizatiom  />}
    </div>
    <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    </Box>

   
    </Modal>
  );
};

export default LogIn;
