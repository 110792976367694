import React from "react";
import Navbar from "../../Navbar";
import EmployeeImg from "../../../assets/Jobs/Employee.svg";
import EmployeeJobsCard from "./EmployeeJobsCard";
import Footer from "../../Footer";
import axios from 'axios';
import Menu from './Menu';
import Filter from './Filter';
import { useLocation ,useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../LoadingSpinner";


const EmployeeJobs = (props) => {
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();

  const [list, setList] = React.useState([]);
  const [filterData, setFilterData] = React.useState([]);
  const [getLoading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const _getList= async() => {
      try{
        let loginData =  JSON.parse(localStorage.getItem('dataLogin'))

        await axios.get(`https://getjob.stechomeyazilim.info:5101/getJobSeekerSearch/select/${loginData.ID}`).then(async(resJobSeeker) => {
    
        console.log("slndflj",resJobSeeker.data[0])
        if(resJobSeeker.data.length > 0){
          await axios.get(`https://getjob.stechomeyazilim.info:5101/getJobSeeker/select/${resJobSeeker.data[0].ID}`).then((res) => {
            setList(res.data) 
            setLoading(false)
            //setIsLoading(false)  
           })
        }

        })
  }
  catch (error) {
    console.log("_getListerro123r" + error)
  }
    }


    const  _filterList= async() => {
      try{
        await axios.get(`https://getjob.stechomeyazilim.info:5101/getSearch/select/${state._item}/jobSeeker`)
        .then((res) => {
         setFilterData(res.data)
         setLoading(false)
           })
  }
  catch (error) {
    console.log("errorccAS" + error)
  }
    }

    console.log("lsjdnf123",state)
    if(state!= null){
      _filterList()
    }else{
      _getList()
    }

    window.scrollTo(0, 0);

  }, []);


 const _filterList= async (keyword) => {
  try{

        
       const filterData = list.filter(documents => {
        return documents.Job.Job_Text.toLowerCase().includes(keyword.toLowerCase())
      })

      console.log("lsdnfkl",filterData)
      setFilterData(filterData)

       
}
catch (error) {
console.log("errorccAS" + error)
}
}

 
  return (
    <div>
      <Navbar />
      <div className="w-full flex flex-col">
        <div className="max-w-full mt-36 flex flex-col bg-[#F7F7F7] h-full  items-center  ">
          <h3 className="w-full text-center text-xl text-[#204686] py-7 font-bold">
            Jobs
          </h3>

          <div className='bg-[#0D0658] w-1/2 justify-center items-center' >
                <Menu _itemSub={props._itemSub} _subCategoryID={props._subCategoryID} />
                <div   className='bg-[white]'>
                <Filter jobsText={state != null ? state._item : null} filterList = {_filterList}  />
                </div>
                </div>

          <div className="w-5/6 h-full lg:w-3/5 md:w-4/5 p-2 items-center grid md:grid-cols-3 md:grid-rows-3 sm:grid-cols-2 sm:grid-rows-5 grid-rows-9 gap-4">
          {filterData.length > 0 ? filterData.map((item, index) => (
            
            <EmployeeJobsCard EmployeeImgSource={EmployeeImg} item={item} />
           
           )):  list.map((item, index) => (
            
            <EmployeeJobsCard EmployeeImgSource={EmployeeImg} item={item} />
           
           ))}
     
          </div>
          <Footer/>

          <LoadingSpinner show={getLoading} /> 

        </div>
      </div>
    </div>
  )
};

export default EmployeeJobs;
