import React, { useState, useEffect,Component } from "react";
import InputField from "./InputField";
import MultiSelectOption from "./MultiSelectOption";
import SelectOption from "./SelectOption";
import axios from 'axios';
import moment from 'moment'
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraAlt, faEdit,faFile,faGraduationCap,faSave,faTrash, faVideoCamera} from '@fortawesome/free-solid-svg-icons';
import Moment from 'moment';
import Autocomplete from "react-google-autocomplete";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import mapStyles from './mapStyles'
import { ToastContainer, toast } from 'react-toastify';
import LoadingSpinner from "../../LoadingSpinner";
import { withParamsAndNavigate } from "./getParamsAndNavigate.js";
import AllUniversity from './province-universities.json';
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from "@react-pdf-viewer/core"; // install this library
import  AllProgram  from './University_Department.json';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


import ProgressBar from "./ProgressBar";

const testData = [
  { bgcolor: "#6a1b9a", completed: 60 },
  { bgcolor: "#00695c", completed: 30 },
  { bgcolor: "#ef6c00", completed: 53 },
];

function Map(props) {
  const [selectedPark, setSelectedPark] = useState(null);

  useEffect(() => {
    const listener = e => {
      if (e.key === "Escape") {
        setSelectedPark(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

console.log("lksdnckf",props.dealerData.position.lat)
  return (
   props.dealerData != null ?
    <GoogleMap
      defaultZoom={4}
      defaultCenter={{ lat: 48.8114633 , lng: 34.8990059}}
      options={{ styles: mapStyles }}>
        <Marker
          key={props.dealerData.ID}
          position={{
            lat: props.dealerData.position.lat,
            lng:props.dealerData.position.lng
          }}
          onClick={() => {
            setSelectedPark(props.dealerData.Employer_Map);
          }}
          icon={{
            url:  "https://img.icons8.com/color/48/000000/map-pin.png",
            scaledSize: new window.google.maps.Size(50, 50)
          }}
        >

<InfoWindow
          onCloseClick={() => {
            setSelectedPark(null);
          }}
          position={{
            lat: props.dealerData.position.lat,
            lng: props.dealerData.position.lng
          }}
        >
          <div>
            <h2 className="font-[Gotham-Medium] ">{props.dealerData.currentCity}</h2>
          </div>
        </InfoWindow>
        </Marker>

    </GoogleMap> : null 
    
  );
}
class PersonelInformation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      educationProgramId:null,
      listDriverLicense:[],
      jobList:[],
      saleryData:[],
      languageData:[],
      jobSeekerEducation:[],
      jobSeekerAbility:[],
      jobSeekerHobby:[],
      jobSeekerDocument:[],
      jobSeekerCV:[],
      abilityText:'',
      hobbyText:'',
      isLoading:false,
      video:null,
      certificaLink:null,
      jobWayOut:[],
      languageArray:[],
      jobListID:0,
      documentGradient:[],
      documentVideo:[],
      listCity:[],
      listCityEducation:[],
      listCountries:[],
      listDiscrict:[],
      jobExpreinceID:null,
      currentLatitude:null,
      currentLongitude:null,
      mapData:null,
      userGender:null,
      countriesName:null,
      usersMilitaryStatus:null,
      jobSaleryID:null,
      allCV:[],
      allDocument:[],
      isPortfolyoLoading:false,
      selectedImagePortfolyo:[],
      selectedImageGradient:[],
      isGradientLoading:false,
      selectedImageCv:[],
      isCvLoading:false,
      isVideoLoading:false,
      selectedImageVideo:[],
      allGradient:[],
      allVideo:[],
      valueSearchJob:[],
      editOption:null,
      universityDepartmant:[],
      educationInformation: [],
      educationInformationEdit:[],
      allUniversity:AllUniversity,
      universityList:[],
      educationGraID:0,
      check:false,
      selectedImagePhoto:[],
      allPhoto:[],
      educationProgram:null,
      cityNameEducation:null,
      educationGra:null,
      educationSchool:null,
      loadingPercentagePortfolyo:0,
      loadingPercentageVideo:0,
      loadingPercentageCv:0,
      loadingPercentageGradint:0,
      loadingPercentagePhoto:0,

      checkFindGetJob:false,
      checkStillingWork:false,
      historyJobEdit:[],
      listCityHistory:[],
      listDiscrictHistory:[],
      jobModal:[]
        }

    
}



async componentDidMount() {
  await this._getCountries()
  await this._getJob()
}

showToast= (event, value, type) => {
  if(type == "false"){
    toast.error(value, {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

  }else{
    toast.success(value, {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
     
  }

}


_complateJob = async () => {
  try {
    this.setState({
      isLoading: true,
    })

    var testDateUtc = Moment.utc(new Date());

    var localDate = Moment(testDateUtc).local();

    let rspUserDetail =  JSON.parse(localStorage.getItem('dataLogin'))

    let jobData = {
      Job_ID: this.state.jobListID,
      Job_Seeker_CreatedDatetime: localDate,
      Job_Experience_ID: parseInt(this.state.jobExpreinceID),
      Job_Salery_Expection_ID: parseInt(this.state.jobSaleryID),
      Job_Seeker_CertificateLink: this.state.allGradient.length > 0 ? this.state.allGradient[0].data : null,
      Job_Seeker_Desc: this.state.desc,
      Users_ID: parseInt(rspUserDetail.ID),
      Job_Seeker_CheckActive: true,
      Job_Seeker_VideoLink: this.state.allVideo.length > 0 ? this.state.allVideo[0].data : null,
      Job_Seeker_Countries: this.state.countriesName,
      Job_Seeker_City: this.state.cityName,
      Job_Seeker_Dist: this.state.districtName,
      Job_Seeker_Viewed:0
        }

        console.log("jobData123",jobData)

    axios.post('https://getjob.stechomeyazilim.info:5101/jobSeekerInsert/send', jobData).then(async(rsp) => {
    
console.log("smdnfjk",this.state.jobModal)
this.state.jobModal.map(async (data) => {
  let languageData = {
    JobSeeker_JobModal_Text	: data.label,
    Job_Seeker_ID: rsp.data.value[0].ID
  }
  axios.post('https://getjob.stechomeyazilim.info:5101/postJobSeekerJobModal/send', languageData)
  
})

  this.state.languageData.map(async (data) => {
    let languageData = {
      Job_Seeker_Language_Text: data.value,
      Job_Seeker_ID: rsp.data.value[0].ID
    }
    axios.post('https://getjob.stechomeyazilim.info:5101/languageInsert/send', languageData)
    
  })


  this.state.historyJobEdit.map(async (data) => {
    console.log("lkadsnf",data)
     
    let jobSeekerExpreince = {
      JobSeeker_Experience_Company: data.JobSeeker_Experience_Company,
      JobSeeker_Experience_Department:data.JobSeeker_Experience_Department,
      JobSeeker_Experience_StartDate:data.JobSeeker_Experience_StartDate,
      JobSeeker_Experience_FinishDate:data.JobSeeker_Experience_FinishDate,
      JobSeeker_Experience_Country:data.JobSeeker_Experience_Country,
      JobSeeker_Experience_County:data.JobSeeker_Experience_County,
      JobSeeker_Experience_District:data.JobSeeker_Experience_District,
      JobSeeker_Experience_Short_Desc:data.JobSeeker_Experience_Short_Desc,
      Job_WayOfWorking_ID:data.Job_WayOfWorking_ID,
      Job_IsGetJob:data.Job_IsGetJob,
      JobSeeker_ID: rsp.data.value[0].ID,
      JobSeeker_Experience_ContinousJob:data.JobSeeker_Experience_ContinousJob,
    }
    axios.post('https://getjob.stechomeyazilim.info:5101/postJobSeekerExprince/send', jobSeekerExpreince)
    
  })
  
  

  this.state.valueSearchJob.map(async (data) => {
      let dataJob = {
        Job_Seeker_Search_Job_Text: data.label,
        Job_Seeker_ID: rsp.data.value[0].ID
      }
      axios.post('https://getjob.stechomeyazilim.info:5101/insertSearchJob/send', dataJob)
    
    
    })

  this.state.listDriverLicense.map(async (data) => {
    let driverData = {
      Job_Seeker_Driver_License_Text: data.value,
      Job_Seeker_ID: rsp.data.value[0].ID
    }
    axios.post('https://getjob.stechomeyazilim.info:5101/driverLicenseInsert/send', driverData)
  })

  
  this.state.jobWayOut.map(async (data) => {
    let driverData = {
      Job_WayOfWorking_ID: data.value,
      Job_Seeker_ID: rsp.data.value[0].ID
    }
    axios.post('https://getjob.stechomeyazilim.info:5101/addJobWayOut/send', driverData)
  })


let formUpdate = {
  Users_NameSurname: this.state.userName,
  Users_Birthday: this.state.birthdayUser,
  Users_Gender: this.state.userGender,
  Users_Military: this.state.usersMilitaryStatus,
  Users_Profile_Photo: this.state.allPhoto.length > 0 ? this.state.allPhoto[0].data : null,
}

await axios.patch(`https://getjob.stechomeyazilim.info:5101/usersUpdate/${rsp.data.value[0].Users_ID}`, formUpdate)

this.state.jobSeekerHobby.map(async (data) => {
  let dataHobby = {
    Job_Seeker_Hobby_Text: data.Job_Seeker_Hobby_Text,
    Job_Seeker_ID: rsp.data.value[0].ID
  }
  axios.post('https://getjob.stechomeyazilim.info:5101/addJobSeekerHobby/send', dataHobby)

})

this.state.jobSeekerAbility.map(async (data) => {
  let dataHobby = {
    Job_Seeker_Ability_Text: data.Job_Seeker_Ability_Text,
    Job_Seeker_ID: rsp.data.value[0].ID
  }
  axios.post('https://getjob.stechomeyazilim.info:5101/addJobSeekerAbility/send', dataHobby)

})

let socialData = {
  Job_Seeker_SocialMedia_WebSite: this.state.webSite,
  Job_Seeker_SocialMedia_Facebook: this.state.facebook,
  Job_Seeker_SocialMedia_Instagram: this.state.instagram,
  Job_Seeker_SocialMedia_Twitter: this.state.twitter,
  Users_ID: rsp.data.value[0].Users_ID,
  Job_Seeker_ID:rsp.data.value[0].ID
}
axios.post('https://getjob.stechomeyazilim.info:5101/socialMediaInsert/send', socialData)
          
let mapData = {
  Job_Seeker_Map_Lat: this.state.currentLatitude,
  Job_Seeker_Map_Lng: this.state.currentLongitude,
  Job_Seeker_Map_Distance: this.state.sliderValue,
  Job_Seeker_Map_City: this.state.currentCity,
  Job_Seeker_ID :rsp.data.value[0].ID
}
axios.post('https://getjob.stechomeyazilim.info:5101/mapInsert/send', mapData)
       


if (this.state.allDocument.length > 0) {
  this.state.allDocument.map(async (data) => {

    console.log("allDocument12321",data)
    let documentData = {
      Job_Seeker_Document_Link: data.data,
      Job_Seeker_ID:rsp.data.value[0].ID,
      Job_Seeker_Document_Type: data.type
    }

    axios.post('https://getjob.stechomeyazilim.info:5101/insertJobSeekerDocument/send', documentData)

  })
}

console.log("cvdata123",this.state.allCV)


if (this.state.allCV.length > 0) {
  this.state.allCV.map(async (data) => {
    console.log("cvdata123",data)

    let documentData = {
      Job_Seeker_CV_Link: data.data,
      Job_Seeker_ID: rsp.data.value[0].ID,
      Job_Seeker_CV_Type: data.type
    }

    axios.post('https://getjob.stechomeyazilim.info:5101/insertJobSeekerCv/send', documentData)

  })
}


if (this.state.educationInformation.length > 0) {
  this.state.educationInformation.map(async (data) => {
    console.log("data123",data)
    let documentEducation = {
      Job_Seeker_EducationStatus:data.Job_Seeker_EducationStatus,
      Job_Seeker_EducationSchool: data.Job_Seeker_EducationSchool,
      Job_Seeker_Education_City: data.Job_Seeker_Education_City,
      University_Department_ID: data.University_Department_ID,
      Job_Seeker_GradientDateEducation: data.checkData == true ? null : data.Job_Seeker_GradientDateEducation,
      Job_Seeker_ID: rsp.data.value[0].ID
    }

    axios.post('https://getjob.stechomeyazilim.info:5101/insertJobSeekerEducation/send', documentEducation)

  })
}

this.showToast('GetJob', "Bilgiler Başarıyla Güncellendi!", 'success')

 /*  

    if (this.state.valueSearchJobWay.length > 0) {
      this.state.valueSearchJobWay.map(async (data) => {
        let rspJobEducationStatusJobWay = await postJobSeekerJobWay({
          Job_WayOfWorking_ID: data,
          Job_Seeker_ID: rsp.value[0].ID,
        })
        rspJobEducationStatusJobWay = await rspJobEducationStatusJobWay.json()
      })
    }
        
    this.state.selectExperience.map(async (data) => {
    let rspJobExperience = await postJobSeekerExpreince({
      JobSeeker_Experience_Company: data.companyName,
      JobSeeker_Experience_Department: data.companyDepartment,
      JobSeeker_Experience_StartDate: data.tarihValueStartDate,
      JobSeeker_Experience_FinishDate: data.tarihValueFinishDate,
      JobSeeker_Experience_Country: data.countriesNameJE,
      JobSeeker_Experience_County: data.cityNameJe,
      JobSeeker_Experience_District: data.districtNameJE,
      JobSeeker_Experience_Short_Desc: data.shortDesc,
      Job_WayOfWorking_ID: data.valueDropdownJobWayOut,
      Job_IsGetJob: data.checkGetJob,
      JobSeeker_ID: rsp.value[0].ID,
      JobSeeker_Experience_ContinousJob:this.state.checkDateEnd
    })
    rspJobExperience = await rspJobExperience.json()

  })

   

    this.state.valueSearchJob.map(async (data) => {

      console.log("data12321", data)
      let rspJobSeekerAbility = await insertJobSeekerSearchJob({
        Job_Seeker_Search_Job_Text: data,
        Job_Seeker_ID: rsp.value[0].ID
      })
      rspJobSeekerAbility = await rspJobSeekerAbility.json()
    })

  

*/
    if (rsp.data) {
      this.setState({
        isLoading:false,
      })
      const{params,navigate} = this.props;

       navigate('/jobSeekerInfo/'  + rsp.data.value[0].Users_ID, { state: { _item: { ID : rsp.data.value[0].Users_ID } }})


      setTimeout(() => {
        this.setState({
          successPost:true,
          alertMessage:"Tebrikler İlanınız Güncellendi!",
         alertStatus:true
        })
      }, 1100);
    } else {
      this.setState({
        isLoading:false,
      })

      setTimeout(() => {
        this.setState({
          isLoading: false,
        successPost: false,
        alertMessage: "İlan açılırken bir sorun oluştu!",
        alertStatus: true
        })
      }, 1100);

    }
  })
  } catch (err) {
    this.setState({
      isLoading:false,
    })

    setTimeout(() => {
      this.setState({
        alertMessage: "İlan açılırken bir sorun oluştu!" + err.message,
        alertStatus: true
      })
    }, 1100);

    console.log("hataaa", err)
    console.log("_getonlineWork-MyOrdersScreen")
  }
}

_getJob= async () => {
  await axios.get(`https://getjob.stechomeyazilim.info:5101/getJob/select`)
  .then((res) => {

    res.data.map((item, index) => (
    this.setState(prevState => ({
      jobList: [...prevState.jobList, {label:item.Job_Text,value:item.ID}]
    }))
    ))
     })

     await axios.get(`https://getjob.stechomeyazilim.info:5101/getCity/TR`)
     .then((res) => {
       console.log("kjwnfk12",res.data)

       let newCityList = res.data.sort(function (a, b) {
        var atitle = a.name;
        var btitle = b.name;
        var alfabe = "0123456789AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz";
        if (atitle.length === 0 || btitle.length === 0) {
            return atitle.length - btitle.length;
        }
        for (var i = 0; i < atitle.length && i < btitle.length; i++) {
            var ai = alfabe.indexOf(atitle[i].toUpperCase());
            var bi = alfabe.indexOf(btitle[i].toUpperCase());
            if (ai !== bi) {
                return ai - bi;
            }
        }
      })

      
       newCityList.map((item, index) => (
       this.setState(prevState => ({
         listCityEducation: [...prevState.listCityEducation, {label:item.name,value:item.iso2}]
       }))
       ))
        })

}

_getProgram= async () => {
  this.setState({
    universityDepartmant:[]
  })

    let newCityList = AllProgram.University_Department.sort(function (a, b) {
      return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
    })

    newCityList.map((item, index) => (
    this.setState(prevState => ({
      universityDepartmant: [...prevState.universityDepartmant, {label:item.name,value:item.ID}]
    }))))

}



_getCountries= async () => {
  await axios.get(`https://getjob.stechomeyazilim.info:5101/getCountries/`)
  .then((res) => {
    res.data.map((item, index) => (
    this.setState(prevState => ({
      listCountries: [...prevState.listCountries, {label:item.name,value:item.iso2}]
    }))
    ))
     })

}

  _editEduation= async (item) => {
    console.log("lknds11fl",item.Job_Seeker_Education_City)
    this.setState({
      editOption:true,
      educationInformationEdit: [item],
      educationDate:item.Job_Seeker_GradientDateEducation,
      educationProgram:item.programName,
      educationGra:item.Job_Seeker_EducationStatus,
      cityNameEducation:item.Job_Seeker_Education_City,
      educationSchool:item.Job_Seeker_EducationSchool
    },()=> {
      console.log("editOption123",this.state.editOption)
    })
  }

onFileChangeVideo(e) {
  let files = e.target.files;
  let fileReader = new FileReader();
  fileReader.readAsDataURL(files[0]);

  fileReader.onload = (event) => {
      this.setState({
          selectedVideo: event.target.result,
      })
  }
}

onFileChangeCv(e) {
  let files = e.target.files;
  let fileReader = new FileReader();
  fileReader.readAsDataURL(files[0]);

  fileReader.onload = (event) => {
      this.setState({
          selectedCv: event.target.result,
      })
  }
}




onFileChangePortfolyo = async (e,type) => { 
  
  let file = e.target.files[0];

  if (file.size > 15e6) {
    window.alert("Lütfen dosya yüklerken 15 MB'ı geçmemeli!");
    return false;
  }else{
    try{
  let files = e.target.files;
  let fileReader = new FileReader();
  fileReader.readAsDataURL(files[0]);
  
  fileReader.addEventListener('loadstart', this.changeStatus('Yüklemeye Başlıyor',"video"));
  fileReader.addEventListener('load', this.changeStatus('Yükleniyor',"video"));
  fileReader.addEventListener('loadend', (event) => {this.loaded(event,type)});
  fileReader.addEventListener('progress',(event) => {this.setProgress(event,type)});
  fileReader.addEventListener('error', this.errorHandler);
  fileReader.addEventListener('abort', this.changeStatus('Interrupted'));

  if(type=="photo"){
      
    fileReader.onload = (event) => {
  
      console.log("hvhjvj12",event)
      this.setState({
        isPhotoLoading:true,
        isLoading: false,
        selectedImagePhoto: [{ ID: parseInt(this.state.selectedImagePhoto.length+1), data: parseInt(this.state.selectedImagePhoto.length+1) + ". Belge", type : 'image' , imageData : event.target.result,type : files[0].type == "image/png" ? "photo"  :  files[0].type == "application/pdf" ? "document"  :  files[0].type == "video/quicktime" ? "video" :  files[0].type == "video/quicktime" ? "video" : null }]
      })
  
     
    }
  }

  if(type == "video"){
    fileReader.onload = (event) => {


      this.setState({
        isVideoLoading:true,
        isLoading: false,
        selectedImageVideo: [{ ID: parseInt(this.state.selectedImageVideo.length+1), data: parseInt(this.state.selectedImageVideo.length+1) + ". Belge", type : 'image' , imageData : event.target.result, type : files[0].type == "image/png" ? "photo"  :  files[0].type == "application/pdf" ? "document"  :  files[0].type == "video/quicktime" ? "video" :  files[0].type == "video/quicktime" ? "video" : null }]
      })
    }
  }
  if(type == "portfolyo"){
  fileReader.onload = (event) => {
    this.setState(prevState => ({
      isPortfolyoLoading:true,
      isLoading: false,
      selectedImagePortfolyo: [...prevState.selectedImagePortfolyo, { ID: parseInt(this.state.selectedImagePortfolyo.length+1), data: parseInt(this.state.selectedImagePortfolyo.length+1) + ". Belge", type : 'image' , imageData : event.target.result ,type : files[0].type == "image/png" ? "photo"  :  files[0].type == "application/pdf" ? "document"  :  files[0].type == "video/quicktime" ? "video" :  files[0].type == "video/quicktime" ? "video" : files[0].type == "image/webp" ? "photo" : null}]
    }))
  }
}
if(type == "gradient"){
  fileReader.onload = (event) => {
    this.setState({
      selectedImageGradient:[]
    },()=>{
      this.setState({
        isGradientLoading:true,
        isLoading: false,
        selectedImageGradient: [{ ID: parseInt(this.state.selectedImageGradient.length+1), data: parseInt(this.state.selectedImageGradient.length+1) + ". Belge", type : 'image' , imageData : event.target.result,type : files[0].type == "image/png" ? "photo"  :  files[0].type == "application/pdf" ? "document"  :  files[0].type == "video/quicktime" ? "video" :  files[0].type == "video/quicktime" ? "video" : null }]
      })
    })
   
  }
}
if(type == "cv"){
  fileReader.onload = (event) => {

    this.setState(prevState => ({
      isCvLoading:true,
      isLoading: false,
      selectedImageCv: [...prevState.selectedImageCv, { ID: parseInt(this.state.selectedImageCv.length+1), data: parseInt(this.state.selectedImageCv.length+1) + ". Belge", type : 'image' , imageData : event.target.result, type : files[0].type == "image/png" ? "photo"  :  files[0].type == "application/pdf" ? "document"  :  files[0].type == "video/quicktime" ? "video" :  files[0].type == "video/quicktime" ? "video" : null }]
    }))
  }
}

if(type == "photo"){
  fileReader.onload = (event) => {

    this.setState({
      isPhotoLoading:true,
      isLoading: false,
      selectedImagePhoto: [{ ID: parseInt(this.state.selectedImagePhoto.length+1), data: parseInt(this.state.selectedImagePhoto.length+1) + ". Belge", type : 'image' , imageData : event.target.result,type : "photo" }]
    })
  }
}}
catch (error) {
  this.setState({isLoading:false})
}
  }

}

onSubmit(type){
  this.setState({isLoading: true})
  if(type == "photo"){
    console.log("lsdnfjkl123",this.state.selectedImagePhoto[0])
    const formData = { image: this.state.selectedImagePhoto[0].imageData }
    let endpoint = "https://getjob.stechomeyazilim.info:5101/fileUpload/insert/";
     axios.post(endpoint, formData, {
     }).then((res) => {
      this.setState({isLoading: false,
        isPhotoLoading:true,
        allPhoto :[{ ID: 0, data: res.data, type : this.state.selectedImagePhoto[0].type  }] })
        console.log('File uploaded!',res);
        this.showToast('GetJob', "Başarıyla Görseller Yüklendi!", 'success')
    })
  }

  if(type == "portfolyo"){
   
    this.state.selectedImagePortfolyo.map((item, index) => {
      const formData = { image: item.imageData }

      let endpoint = "https://getjob.stechomeyazilim.info:5101/fileUpload/insert/";
       axios.post(endpoint, formData, {
       }).then((res) => {  
        this.setState(prevState => ({
          isLoading: false,
          isPortfolyoLoading:true,
          allDocument: [...prevState.allDocument, { ID: 0, data: res.data, type : item.type  }]
        }))

        console.log("res11123",res.data)
        this.showToast('GetJob', "Başarıyla Görseller Yüklendi!", 'success')
      })
    })
    
  }

  if(type == "cv"){
   
    console.log("slndfl123", this.state.selectedImageCv)
    this.state.selectedImageCv.map((item, index) => {
      const formData = { image: item.imageData }

      let endpoint = "https://getjob.stechomeyazilim.info:5101/fileUpload/insert/";
       axios.post(endpoint, formData, {
       }).then((res) => {  
        this.setState(prevState => ({
          isLoading: false,
          isCvLoading:false,
          allCV: [...prevState.allCV, { ID: 0, data: res.data, type : item.type  }]
        }))

        console.log("ite123123",item)
        this.showToast('GetJob', "Başarıyla Görseller Yüklendi!", 'success')
      })
    })
    
  }

  if(type == "video"){
    const formData = { image: this.state.selectedImageVideo[0].imageData }
    let endpoint = "https://getjob.stechomeyazilim.info:5101/fileUpload/insert/";
     axios.post(endpoint, formData, {
     }).then((res) => {
      this.setState({isLoading: false,
        allVideo :[{ ID: 0, data: res.data, type : this.state.selectedImageVideo[0].type  }] })
        console.log('File uploaded!',res);
        this.showToast('GetJob', "Başarıyla Görseller Yüklendi!", 'success')

    })
  }
 
  if(type == "gradient"){
    const formData = { image: this.state.selectedImageGradient[0].imageData }
    let endpoint = "https://getjob.stechomeyazilim.info:5101/fileUpload/insert/";
     axios.post(endpoint, formData, {
     }).then((res) => {
      this.setState({isLoading: false,
        allGradient :[{ ID: 0, data: res.data, type : this.state.selectedImageGradient[0].type  }] })
        console.log('File uploaded!',res);
        this.showToast('GetJob', "Başarıyla Görseller Yüklendi!", 'success')

    })
  }
 
}


_setShow= async () => {

}

_addHobby= async () => {
  this.setState(prevState => ({
    jobSeekerHobby: [...prevState.jobSeekerHobby, {Job_Seeker_Hobby_Text:this.state.hobbyText,Job_Seeker_ID:1}]
  }))
}

_addAbility= async () => {
  this.setState(prevState => ({
    jobSeekerAbility: [...prevState.jobSeekerAbility, {Job_Seeker_Ability_Text:this.state.abilityText,Job_Seeker_ID:1}]
  }))
}

_getCity= async (city,cityName) => {
  
  this.setState({
    countries:city,
    countriesCode:city,
    countriesName:cityName,

  })
  await axios.get(`https://getjob.stechomeyazilim.info:5101/getCity/${city}`)
  .then((res) => {
    let newCityList = res.data.sort(function (a, b) {
      var atitle = a.name;
      var btitle = b.name;
      var alfabe = "0123456789AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz";
      if (atitle.length === 0 || btitle.length === 0) {
          return atitle.length - btitle.length;
      }
      for (var i = 0; i < atitle.length && i < btitle.length; i++) {
          var ai = alfabe.indexOf(atitle[i].toUpperCase());
          var bi = alfabe.indexOf(btitle[i].toUpperCase());
          if (ai !== bi) {
              return ai - bi;
          }
      }
    })

    newCityList.map((item, index) => (
    this.setState(prevState => ({
      listCity: [...prevState.listCity, {label:item.name,value:item.iso2}]
    }))
    ))
     })
     
}

_deleteEducation= async (itemData) => {
  const filteredData = this.state.educationInformation.filter(item => item.ID !== itemData.ID)
  this.setState({educationInformation:filteredData})
}

_deleteCV= async (itemData) => {

  const filteredData = this.state.selectedImageCv.filter(item => item.ID !== itemData.ID )
  this.setState({selectedImageCv:filteredData})
}

_deletePortfolyo= async (itemData) => {

  const filteredData = this.state.selectedImagePortfolyo.filter(item => item.ID !== itemData.ID )
  this.setState({selectedImagePortfolyo:filteredData})
}

_editEduationFilter= async (e,item) => { 
  e.preventDefault();

  this.setState({editOption:false})

  let newMarkers = this.state.educationInformation.map(el => (
    el.ID===item.ID  ? {...el,
      Job_Seeker_EducationStatus : this.state.educationGra,
      Job_Seeker_EducationSchool:this.state.educationSchool,
      University_Department_ID:this.state.educationProgramId,
      Job_Seeker_GradientDateEducation:this.state.educationDate,
      programName:this.state.educationProgram,
      Job_Seeker_Education_City: this.state.cityNameEducation
    }: el
))


  this.setState({educationInformation : newMarkers,educationGra:null,
    educationSchool:null,
    checkData:false,
    cityNameEducation:null,
    educationDate:null,
    educationProgramId:null
  })
}

_saveEducation= async (e) => { 
  e.preventDefault();

  this.setState({editOption:false})
  this.setState(prevState => ({
    educationInformation: [...prevState.educationInformation,{
      ID : this.state.educationInformation.length + 1,
      Job_Seeker_EducationStatus : this.state.educationGra,
      Job_Seeker_EducationSchool:this.state.educationSchool,
      University_Department_ID:this.state.educationProgramId,
      Job_Seeker_GradientDateEducation:this.state.check == false ? this.state.educationDate : null,
      programName:this.state.educationProgram,
      Job_Seeker_Education_City: this.state.cityNameEducation,
      checkData : this.state.check
    }],
    educationGra:null,
    educationSchool:null,
    cityNameEducation:null,
    educationDate:null,
    educationProgramId:null
  }))
}

saveCheckBox= async () => { 
  this.setState({check:!this.state.check})
}

saveCheckBoxHistory= async (type) => { 
  if(type == "checkFind"){
    this.setState({checkFindGetJob:!this.state.checkFindGetJob})
  }else{
    this.setState({checkStillingWork:!this.state.checkStillingWork})
  }
}


_handleChange= async (value,type) => { 
  console.log("lksnf1112323",value,type)
  
  if(type=="historyJobWayOutWorkink"){
    this.setState({
      Job_WayOfWorking_Text:value.label,
      Job_WayOfWorking_ID : value.value
    })
  }
  if(type=="shortDescHistoryJob"){
    this.setState({
      shortDescHistoryJob:value
    })
  }
  if(type=="companyHistoryJob"){
    this.setState({
      companyHistoryJob:value
    })
  }
  if(type=="departmantHistoryJob"){
    this.setState({
      departmantHistoryJob:value
    })
  }
  if(type=="startDateHistoryJob"){
    this.setState({
      startDateHistoryJob:value
    })
  }
  if(type=="finishDateHistoryJob"){
    this.setState({
      finishDateHistoryJob:value
    })
  }
  if(type=="countriesNameHistoryJob"){
    this.setState({
      countriesNameHistoryJob:value
    })
  }
  if(type=="cityNameHistoryJob"){
    this.setState({
      cityNameHistoryJob:value
    })
  }
  if(type=="districtNameHistoryJob"){
    this.setState({
      districtNameHistoryJob:value.label
    })
  }

  if(type=="jobWayHistoryJob"){
    this.setState({
      jobWayHistoryJob:value
    })
  }

  console.log("asldknaskld",value)
  if(type=="jobModal"){
    this.setState({
      jobModal:value
    })
  }

  if(type =="cityEducation"){

    this.setState({cityNameEducation:value.label})

    let data = this.state.allUniversity.filter(data => data.id == value.value)

  data[0].universities.map((item, index) => (
    this.setState(prevState => ({
      universityList: [...prevState.universityList, {label:item.name,value:item.name}]
    }))
  ))

  this._getProgram()


  } 
  if(type =="date"){

    this.setState({educationDate:value})
  } 

  if(type =="educationgra"){
    this.setState({educationGra:value.label,educationGraID : value.value})
    await this._getJob()
  } 
  if(type =="educationSchool"){
    this.setState({educationSchool:value})
  } 
  if(type =="educationprogram"){
    console.log("ml123value",value)
    this.setState({educationProgram:value.label,educationProgramId:value.value})
  } 
  if(type =="searchJob"){
    this.setState({valueSearchJob:value})
  } 
  if(type =="jobwayout"){
    this.setState({jobWayOut:value})
  }

  
  if(type =="adresstext"){
    this.setState({        
      adresstext:value.target.value  
      })
  }
  if(type =="desc"){
    this.setState({        
      desc:value.target.value  
      })
  }

  if(type =="district"){
    
    this.setState({districtName:value.value})
    console.log()
  }


  if(type =="educationStatus"){
    
    console.log()
  }

  if(type =="education"){
    this.setState({education:value.value})
  }


  if(type =="city"){
    this.setState({cityName:value.value})
  }
  if(type =="military"){
    this.setState({usersMilitaryStatus:value.value})
  }

  if(type =="salery"){
    this.setState({jobSaleryID:value.value})

  }
  if(type =="jobs"){
    //this.setState({jobListID:value.value})
  }
  if(type =="driverlicense"){
    this.setState({listDriverLicense:value})
  }
  if(type =="language"){
    this.setState({languageData:value})
  }
  if(type =="exprience"){

    this.setState({jobExpreinceID:value.value})
  }
  if(type =="job"){
    this.setState({jobListID:value.value})
  }

  if(type =="ability"){
    this.setState({abilityText:value})
  }if(type =="hobby"){
    this.setState({hobbyText:value})
  }
  if(type =="website"){
    this.setState({webSite:value})
  }if(type =="twitter"){
    this.setState({twitter:value})
  }
  if(type =="facebook"){
    this.setState({facebook:value})
  }
  if(type =="instagram"){
    this.setState({instagram:value})
  }
  if(type =="userName"){
    this.setState({userName:value})
  }
  if(type =="birthday"){
    this.setState({birthdayUser:value})
  }
  if(type =="gender"){
    this.setState({userGender:value.value})
  }

}

 changeStatus = (status,type) => {
  if(type == "video"){
    //document.getElementById('status-video').innerHTML = status;
  }
  if(type == "portfolyo"){
    //document.getElementById('status-portfolyo').innerHTML = status;
  }
  if(type == "gradient"){
   // document.getElementById('status-gradient').innerHTML = status;
  }
  if(type == "cv"){
   // document.getElementById('status-cv').innerHTML = status;
  }
  if(type == "photo"){
   //ß document.getElementById('status-photo').innerHTML = status;
  }
}

enableInputFile(e, id) {
  document.getElementById(id).click();
}

 setProgress = (e,type) => {
  const loadingPercentage = 100 * e.loaded / e.total;

  if(type == "portfolyo"){

    this.setState({
      loadingPercentagePortfolyo : loadingPercentage
    })
  }
  if(type == "video"){
    this.setState({
      loadingPercentageVideo : loadingPercentage
    })
  }
  if(type == "cv"){
    this.setState({
      loadingPercentageCv : loadingPercentage
    })
  }
  if(type == "gradient"){
    this.setState({
      loadingPercentageGradint : loadingPercentage
    })
  }
  if(type == "photo"){
    this.setState({
      loadingPercentagePhoto : loadingPercentage
    })
  }

}


 loaded = (e,type) => {
  const fr = e.target;
  var result = fr.result;

  this.changeStatus('Yükleme Tamamlandı!',type);
  //console.log('Result:', result);
}

 errorHandler = (e) => {
  this.changeStatus('Error: ' + e.target.error.name);
}

_getDistrict= async (ID,cityName) => {
  this.setState({
    cityName:cityName,
    listDiscrict : []
  })

  await axios.get(`https://getjob.stechomeyazilim.info:5101/getDistrict/${this.state.countriesCode}/${ID}`)
  .then((res) => {

    let newCityList = res.data.sort(function (a, b) {
      var atitle = a.name;
      var btitle = b.name;
      var alfabe = "0123456789AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz";
      if (atitle.length === 0 || btitle.length === 0) {
          return atitle.length - btitle.length;
      }
      for (var i = 0; i < atitle.length && i < btitle.length; i++) {
          var ai = alfabe.indexOf(atitle[i].toUpperCase());
          var bi = alfabe.indexOf(btitle[i].toUpperCase());
          if (ai !== bi) {
              return ai - bi;
          }
      }
    })

    newCityList.map((item, index) => (
    this.setState(prevState => ({
      listDiscrict: [...prevState.listDiscrict, {label:item.name,value:item.name}]
    }))
    ))
     })

}

_getUniversity= async (city) => {
  this.setState({educationSchool : city.label})
}


_deleteAbility= async (itemData) => {
  const filteredData = this.state.jobSeekerAbility.filter(item => item.Job_Seeker_Ability_Text !== itemData.Job_Seeker_Ability_Text)
  this.setState({jobSeekerAbility: filteredData})
}

_deleteHobby= async (itemData) => {
  const filteredData = this.state.jobSeekerHobby.filter(item => item.Job_Seeker_Hobby_Text !== itemData.Job_Seeker_Hobby_Text)
  this.setState({jobSeekerHobby: filteredData})
}

_saveHistoryJob= async (e) => { 
  e.preventDefault();

  this.setState({editOptionHistory:false})

  this.setState(prevState => ({
    historyJobEdit: [...prevState.historyJobEdit,{
      ID : this.state.historyJobEdit.length + 1,
      JobSeeker_Experience_Company: this.state.companyHistoryJob,
      JobSeeker_Experience_Department:this.state.departmantHistoryJob,
      JobSeeker_Experience_StartDate:this.state.startDateHistoryJob,
      JobSeeker_Experience_FinishDate:this.state.finishDateHistoryJob,
      JobSeeker_Experience_Country:this.state.countriesNameHistoryJob,
      JobSeeker_Experience_County:this.state.cityNameHistoryJob,
      JobSeeker_Experience_District:this.state.districtNameHistoryJob,
      JobSeeker_Experience_Short_Desc:this.state.shortDescHistoryJob,
      Job_WayOfWorking_ID:this.state.Job_WayOfWorking_ID,
      Job_WayOfWorking_Text:this.state.Job_WayOfWorking_Text,

      Job_IsGetJob:this.state.checkFindGetJob,
     // JobSeeker_ID: this.props.item.ID,
      JobSeeker_Experience_ContinousJob:this.state.checkStillingWork
    }],
    companyHistoryJob:null,
    departmantHistoryJob:null,
    startDateHistoryJob:null,
    finishDateHistoryJob:null,
    countriesNameHistoryJob:null,
    cityNameHistoryJob: null,
    districtNameHistoryJob: null,
    shortDescHistoryJob: null,
    historyJobWayOutWorkink:null
    }))
}


_editHistory= async (item) => {
  this.setState({
    editOptionHistory:true,
  
    companyHistoryJob:item.JobSeeker_Experience_Company,
    departmantHistoryJob:item.JobSeeker_Experience_Department,
    startDateHistoryJob:item.JobSeeker_Experience_StartDate,
    finishDateHistoryJob:item.JobSeeker_Experience_FinishDate,
    countriesNameHistoryJob:item.JobSeeker_Experience_Country,
    cityNameHistoryJob: item.JobSeeker_Experience_County,
    districtNameHistoryJob: item.JobSeeker_Experience_District,
    shortDescHistoryJob: item.JobSeeker_Experience_Short_Desc,
    Job_WayOfWorking_ID:item.Job_WayOfWorking_ID,
  })
}


_getCityHistory= async (city,CN) => {
  
  this.setState({
    listCityHistory: [],
    countriesCodeHistory:city,
    countriesNameHistoryJob:CN
  })

  await axios.get(`https://getjob.stechomeyazilim.info:5101/getCity/${city}`)
  .then((res) => {

    let newCityList = res.data.sort(function (a, b) {
      var atitle = a.name;
      var btitle = b.name;
      var alfabe = "0123456789AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz";
      if (atitle.length === 0 || btitle.length === 0) {
          return atitle.length - btitle.length;
      }
      for (var i = 0; i < atitle.length && i < btitle.length; i++) {
          var ai = alfabe.indexOf(atitle[i].toUpperCase());
          var bi = alfabe.indexOf(btitle[i].toUpperCase());
          if (ai !== bi) {
              return ai - bi;
          }
      }
    })

    newCityList.map((item, index) => (
    this.setState(prevState => ({
      listCityHistory: [...prevState.listCityHistory, {label:item.name,value:item.iso2}]
    }))
    ))
     })
     
}

_deletehistoryJob= async (itemData) => {
  const filteredData = this.state.historyJobEdit.filter(item => item.ID !== itemData.ID)
  this.setState({historyJobEdit: filteredData , editOptionHistory :false})
}

_getDistrictHistory= async (ID,city) => {

  console.log("alsdnfn123",this.state.countriesCodeHistory,ID,city)
  this.setState({listDiscrictHistory : [],cityNameHistoryJob:city})

  await axios.get(`https://getjob.stechomeyazilim.info:5101/getDistrict/${this.state.countriesCodeHistory}/${ID}`)
  .then((res) => {
    res.data.map((item, index) => (
    this.setState(prevState => ({
      listDiscrictHistory: [...prevState.listDiscrictHistory, {label:item.name,value:item.name}]
    }))
    ))
     })

}
_editHistoryFilter= async (e,item) => { 
  e.preventDefault();

  this.setState({editOptionHistory:false})

  let newMarkers = this.state.historyJobEdit.map(el => (
    el.ID===item.ID  ? {...el,
      JobSeeker_Experience_Company: this.state.companyHistoryJob,
      JobSeeker_Experience_Department:this.state.departmantHistoryJob,
      JobSeeker_Experience_StartDate:this.state.startDateHistoryJob,
      JobSeeker_Experience_FinishDate:this.state.finishDateHistoryJob,
      JobSeeker_Experience_Country:this.state.countriesNameHistoryJob,
      JobSeeker_Experience_County:this.state.cityNameHistoryJob,
      JobSeeker_Experience_District:this.state.districtNameHistoryJob,
      JobSeeker_Experience_Short_Desc:this.state.shortDescHistoryJob,
      Job_WayOfWorking_ID:this.state.Job_WayOfWorking_ID,
      Job_IsGetJob:this.state.checkFindGetJob,
      //JobSeeker_ID: this.props.item.ID,
      JobSeeker_Experience_ContinousJob:this.state.checkStillingWork
    }: el
))
  this.setState({historyJobEdit : newMarkers,
    companyHistoryJob:null,
    departmantHistoryJob:null,
    startDateHistoryJob:null,
    finishDateHistoryJob:null,
    countriesNameHistoryJob:null,
    cityNameHistoryJob: null,
    districtNameHistoryJob: null,
    shortDescHistoryJob: null,
    historyJobWayOutWorkink:null})
}


  render (){ 
    console.log("l1n23213",this.state.educationGraID, this.state.listCityEducation.length)
  return (
    <div className="px-9 py-9">
      <div>
        <h3 className="mb-6 mt-3   text-blue-900 font-bold text-lg  ">
          Kişisel Bilgiler
        </h3>
        <div className="w-full ">
        <InputField type="text" handleChange={(e)=> this._handleChange(e,"userName")} value={this.state.userName} labelFiled={"Ad Soyad"} />
          <InputField type="date" handleChange={(e)=> this._handleChange(e,"birthday")} value={this.state.birthdayUser} labelFiled={"DG Tarih"} />
           <SelectOption defaultValue={this.state.userGender} handleChange={(e)=> this._handleChange(e,"gender")} value={this.state.userGender} selectLabel={"Cinsiyet"} type={"gender"} /> 
           {this.state.userGender == "Erkek" ?  <SelectOption handleChange={(e)=> this._handleChange(e,"military")} value={this.state.usersMilitaryStatus} selectLabel={"Askerlik"} type={"military"}/> : null }
          {this.state.listCountries.length > 0  ?  <SelectOption   fullvalue={this.state.listCountries} handleChange={(e)=> this._getCity(e.value,e.label)} value={this.state.countriesName} selectLabel={"Ülke"} type={"countries"}  />: null}
          {this.state.listCity.length > 0  ? <SelectOption fullvalue={this.state.listCity} handleChange={(e)=> this._getDistrict(e.value,e.label)}  value={this.state.cityName} selectLabel={"İl"} type={"city"}  /> : null}
          {this.state.listDiscrict.length > 0  ? <SelectOption fullvalue={this.state.listDiscrict}  handleChange={(e)=> this._handleChange(e,"district")} value={this.state.districtName} selectLabel={"İlçe"} type={"district"}/> : null }
           <MultiSelectOption handleChange={this._handleChange} value={this.state.listDriverLicense} labelFiled="Ehliyet Bilgileri"  type={"driverlicense"}  /> 
        </div>
       {/* { this.state.editOption == false ? <div>
          
             <button className="bg-[gray]">
             <FontAwesomeIcon icon={ faCirclePlus } className="p-2"  size={30} color={'white'}/>                            
          </button>
       </div> : null} */}
        { this.state.editOption == false ? 
        <div>
          <div className="flex grid-rows-4 grid-flow-col gap-2">

        {this.state.educationInformation.map((item, index) => {
          console.log("item12313123",item)
          return(
          <div className="border-2 relative">
             <button onClick={()=> this._editEduation(item)} className="absolute -top-2 -right-2  cursor-pointer bg-[#F0F4FF] text-[#284B84] translate-x-[2px] border-1 px-2 rounded-md border-[#707070] ">
             <FontAwesomeIcon icon={ faEdit }  size={30} color={'#081F43'}/>                            
          </button>
          <button onClick={()=> this._deleteEducation(item)} className="absolute -top-2 right-8  cursor-pointer bg-[red] text-[#284B84] translate-x-[2px] border-1 px-2 rounded-md border-[#707070] ">
             <FontAwesomeIcon icon={ faTrash }  size={30} color={'white'}/>                            
          </button>

            <div className="bg-[#081F43]">
           <h3 className="p-2 text-[white] font-[Times] text-center">Eğitim Durumu</h3>
           </div>
         <div className="bg-[white]">
         <h3 className="p-2 text-blue-900 font-[Gotham-Medium] text-center border-b-2">{item.Job_Seeker_EducationStatus}</h3>
         <h3 className="p-2 text-blue-900 font-[Gotham-Medium] text-center border-b-2">{item.Job_Seeker_EducationSchool}</h3>
         <h3 className="p-2 text-blue-900 font-[Gotham-Medium] text-center border-b-2">{item.programName}</h3>
         <h3 className="p-2 text-blue-900 font-[Gotham-Medium] text-center border-b-2">{item.Job_Seeker_Education_City}</h3>
         {item.checkData == true ?  <h3 className="p-2 text-blue-900 font-[Gotham-Medium] text-center border-b-2">Mezun Olmadım</h3> :  <h3 className="p-2 text-blue-900 font-[Gotham-Medium] text-center border-b-2">{ moment(item.Job_Seeker_GradientDateEducation).format('DD-MM-YYYY')}</h3>}
         </div>
        </div>
        )})}
</div> 

<div >
<SelectOption handleChange={(e)=> this._handleChange(e,"educationgra")} value={this.state.educationGra} type={"educationgra"} selectLabel="Mezuniyet Durumu" />
{this.state.listCityEducation.length > 0  ?  <SelectOption fullvalue={this.state.listCityEducation} handleChange={(e)=> this._handleChange(e,"cityEducation")}  value={this.state.cityNameEducation} selectLabel={"Mezuniyet İl Seçiniz"} type={"cityEducation"}/>  : null}          
{this.state.educationGraID <= 2 ? <InputField type="text" handleChange={(e)=> this._handleChange(e,"educationSchool")} value={this.state.educationSchool} labelFiled={"Mezun Olunan Okul"} /> : null }
{this.state.educationGraID > 2 && this.state.universityList.length > 0  ? <SelectOption fullvalue={this.state.universityList} handleChange={(e)=> this._getUniversity(e)}  value={this.state.educationSchool} selectLabel={"Mezuniyet"} type={"highSchool"}/>: null}
{ this.state.educationGraID > 2  ? <SelectOption handleChange={this._handleChange} value={this.state.educationProgram} fullvalue={this.state.universityDepartmant} type={"educationprogram"} selectLabel="Program" /> : null}
{this.state.check == true ? null : <InputField handleChange={(e)=> this._handleChange(e,"date")} value={this.state.educationDate}  type="date" labelFiled="Mezuniyet Tarihi" />}
          <FormGroup>
          <FormControlLabel  onClick={()=> this.saveCheckBox()} control={<Checkbox checked={this.state.check}/>} label="Mezun Olmadım"  />
              </FormGroup>
          <button className="bg-[#284B84] rounded-lg m-1 justify-center items-center" onClick={(e)=> this._saveEducation(e)}>
          <FontAwesomeIcon icon={ faSave }  className={"p-1"} color={'white'}/>   
          <a className="text-center text-white p-2 font-[Gotham-Medium]">Yeni Bilgileri Kaydet</a></button>
          </div> 
        </div> 
        
      :  
      
      this.state.editOption == true ?     
       <div>
      <div className="flex grid-rows-4 grid-flow-col gap-2">

    {this.state.educationInformation.map((item, index) => (
      <div className="border-2 relative">
         <button onClick={()=> this._editEduation(item)} className="absolute -top-2 -right-2  cursor-pointer bg-[#F0F4FF] text-[#284B84] translate-x-[2px] border-1 px-2 rounded-md border-[#707070] ">
         <FontAwesomeIcon icon={ faEdit }  size={30} color={'#081F43'}/>                            

      </button>

      <button onClick={()=> this._deleteEducation(item)} className="absolute -top-2 right-8  cursor-pointer bg-[red] text-[#284B84] translate-x-[2px] border-1 px-2 rounded-md border-[#707070] ">
             <FontAwesomeIcon icon={ faTrash }  size={30} color={'white'}/>                            
          </button>

        <div className="bg-[#081F43]">
       <h3 className="p-2 text-[white] font-[Times] text-center">Eğitim Durumu</h3>
       </div>
     <div className="bg-[white]">
     <h3 className="p-2 text-blue-900 font-[Gotham-Medium] text-center border-b-2">{item.Job_Seeker_EducationStatus}</h3>
     <h3 className="p-2 text-blue-900 font-[Gotham-Medium] text-center border-b-2">{item.Job_Seeker_EducationSchool}</h3>
     <h3 className="p-2 text-blue-900 font-[Gotham-Medium] text-center border-b-2">{item.programName}</h3>
     <h3 className="p-2 text-blue-900 font-[Gotham-Medium] text-center border-b-2">{item.Job_Seeker_Education_City}</h3>
     {item.checkData == true ?  <h3 className="p-2 text-blue-900 font-[Gotham-Medium] text-center border-b-2">Mezun Olmadım</h3> :  <h3 className="p-2 text-blue-900 font-[Gotham-Medium] text-center border-b-2">{ moment(item.Job_Seeker_GradientDateEducation).format('DD-MM-YYYY')}</h3>}
     </div>
    </div>
    ))}
</div> 
<div className="grid w-full h-full">
      {this.state.educationInformationEdit.map((item, index) => (
        <div>
         {this.state.educationGra != null ? <SelectOption handleChange={(e)=> this._handleChange(e,"educationgra")} defaultValue={this.state.educationGra}  value={this.state.educationGra} type={"educationgra"} selectLabel="Mezuniyet Durumu" /> : null}
        {this.state.listCityEducation.length > 0  ?  <SelectOption fullvalue={this.state.listCityEducation} handleChange={(e)=> this._handleChange(e,"cityEducation")}  value={this.state.cityNameEducation} selectLabel={"Mezuniyet İl Seçiniz"} type={"cityEducation"}/>  : null}          
        {this.state.educationGraID <= 2 && this.state.educationSchool != null ? <InputField type="text" handleChange={(e)=> this._handleChange(e,"educationSchool")}  defaultValue={this.state.educationSchool} value={this.state.educationSchool} labelFiled={"Mezun Olunan Okul"} /> : null }
        {this.state.educationGraID > 2 && this.state.universityList.length > 0  && this.state.educationSchool != null ? <SelectOption fullvalue={this.state.universityList} handleChange={(e)=> this._getUniversity(e)}  defaultValue={this.state.educationSchool} value={this.state.educationSchool} selectLabel={"Mezuniyet"} type={"highSchool"}/>: null}

        {this.state.universityDepartmant.length > 0 && this.state.educationGraID > 2 && this.state.educationProgram != null ? <SelectOption handleChange={this._handleChange} value={this.state.educationProgram} defaultValue={this.state.educationProgram} fullvalue={this.state.universityDepartmant} type={"educationprogram"} selectLabel="Program" /> : null}
        {this.state.check == true ? null : <InputField handleChange={(e)=> this._handleChange(e,"date")} value={this.state.educationDate}  type="date" labelFiled="Mezuniyet Tarihi" />}
        <FormGroup>
        <FormControlLabel    onClick={()=> this.saveCheckBox()} control={<Checkbox checked={this.state.check}/>} label="Mezun Olmadım"  />
              </FormGroup>
        <button className="text-center bg-[#284B84] rounded-lg m-1" onClick={(e)=> this._editEduationFilter(e,item)}>
        <FontAwesomeIcon icon={ faSave }  className={"p-1"} color={'white'}/>   
        <a className="text-center text-white p-2 font-[Times]">Bilgileri Güncelle</a></button>
        </div>
      )
      )}
      </div> 
    </div>
      :
      <div>
        <h3 className="mt-6 mb-3 text-blue-900 font-bold text-lg">
        Eğitim Bilgileri
      </h3>
       <div className="w-full ">
       <div className="flex grid-rows-4 grid-flow-col gap-2">

     {this.state.educationInformation.map((item, index) => (
          <div className="border-2 relative">
             <button onClick={()=> this._editEduation(item)} className="absolute -top-2 -right-2  cursor-pointer bg-[#F0F4FF] text-[#284B84] translate-x-[2px] border-1 px-2 rounded-md border-[#707070] ">
             <FontAwesomeIcon icon={ faEdit }  size={30} color={'#081F43'}/>                            

          </button>

          <button onClick={()=> this._deleteEducation(item)} className="absolute -top-2 right-8  cursor-pointer bg-[red] text-[#284B84] translate-x-[2px] border-1 px-2 rounded-md border-[#707070] ">
             <FontAwesomeIcon icon={ faTrash }  size={30} color={'white'}/>                            
          </button>

            <div className="bg-[#081F43]">
           <h3 className="p-2 text-[white] font-[Times] text-center">Eğitim Durumu</h3>
           </div>
         <div className="bg-[white]">
         <h3 className="p-2 text-blue-900 font-[Gotham-Medium] text-center border-b-2">{item.Job_Seeker_EducationStatus}</h3>
         <h3 className="p-2 text-blue-900 font-[Gotham-Medium] text-center border-b-2">{item.Job_Seeker_EducationSchool}</h3>
         {item.University_Department != null ? <h3 className="p-2 text-blue-900 font-[Gotham-Medium] text-center border-b-2">{item.University_Department.name}</h3> : null}
         <h3 className="p-2 text-blue-900 font-[Gotham-Medium] text-center border-b-2">{item.Job_Seeker_Education_City}</h3>
         {item.checkData == true ?  <h3 className="p-2 text-blue-900 font-[Gotham-Medium] text-center border-b-2">Mezun Olmadım</h3> :  <h3 className="p-2 text-blue-900 font-[Gotham-Medium] text-center border-b-2">{ moment(item.Job_Seeker_GradientDateEducation).format('DD-MM-YYYY')}</h3>}
         </div>
        </div>
        ))}
        </div>
          
          <SelectOption handleChange={(e)=> this._handleChange(e,"educationgra")} value={this.state.educationGra} type={"educationgra"} selectLabel="Mezuniyet Durumu" />
          {this.state.listCityEducation.length > 0  ?  <SelectOption fullvalue={this.state.listCityEducation} handleChange={(e)=> this._handleChange(e,"cityEducation")}  value={this.state.cityNameEducation} selectLabel={"Mezuniyet İl Seçiniz"} type={"cityEducation"}/>  : null}          
          {this.state.educationGraID <= 2 ? <InputField type="text" handleChange={(e)=> this._handleChange(e,"educationSchool")} value={this.state.educationSchool} labelFiled={"Mezun Olunan Okul"} /> : null }
          {this.state.educationGraID > 2 && this.state.universityList.length > 0  ? <SelectOption fullvalue={this.state.universityList} handleChange={(e)=> this._getUniversity(e)}  value={this.state.educationSchool} selectLabel={"Mezuniyet"} type={"highSchool"}/>: null}

          {this.state.universityDepartmant.length > 0 && this.state.educationGraID > 2 ? <SelectOption handleChange={this._handleChange} value={this.state.educationProgram} fullvalue={this.state.universityDepartmant} type={"educationprogram"} selectLabel="Program" /> : null}
          {this.state.check == true ? null : <InputField handleChange={(e)=> this._handleChange(e,"date")} value={this.state.educationDate}  type="date" labelFiled="Mezuniyet Tarihi" />}
          <FormGroup>
          <FormControlLabel onClick={()=> this.saveCheckBox()} control={<Checkbox checked={this.state.check}/>} label="Mezun Olmadım"  />
              </FormGroup>
          <button className="text-center bg-[#284B84] rounded-lg m-1 justify-center items-center flex w-full" onClick={(e)=> this._saveEducation(e)}>
          <FontAwesomeIcon icon={ faSave } size={"xl"}  className={"p-1"} color={'white'}/>   
          <a className="text-center text-white p-2 font-[Gotham-Medium]">Bilgileri Kaydet</a></button>
        </div>
        </div> }

        <h3 className="mt-6 mb-3 text-blue-900 font-bold text-lg">
         Geçmiş İş Deneyimi
        </h3>
       
       
        <div className="flex xxs:grid-rows-2 sm:grid-rows-3 md:grid-rows-4 lg:rid-rows-4 grid-flow-col gap-1">

        {this.state.historyJobEdit.map((item, index) => {
          console.log("sjdfjl",item)
          return(
          <div className="w-1/2 bg-[#081F43] rounded-lg relative">
            
            <button onClick={()=> this._deletehistoryJob(item)}  className="absolute -top-4 -right-4  cursor-pointer bg-[#f8d581] text-[#284B84] translate-x-[2px] border-1 px-2 rounded-md border-[#707070] ">
            <FontAwesomeIcon icon={ faTrash } className="p-2" size={"xl"} color={'red'}/>   
            </button>

            <button onClick={()=> this._editHistory(item)} className="absolute top-8 -right-4  cursor-pointer bg-[#F0F4FF] text-[#284B84] translate-x-[2px] border-1 px-2 rounded-md border-[#707070] ">
             <FontAwesomeIcon icon={ faEdit } className="p-2" size={"xl"} color={'#081F43'}/>                            
          </button>

                <div className="border-b-2 border-[white]">
                <h3 className="p-2 text-[white] font-[Times] text-center">{item.JobSeeker_Experience_Company}</h3>
                </div>
                <div className="border-b-2 border-[white]">
                <h3 className="p-2 text-[white] font-[Times] text-center">{item.JobSeeker_Experience_Department}</h3>
                </div>

                <div className="border-b-2 border-[white]">
                <h3 className="p-2 text-[white] font-[Times] text-center">{moment(item.JobSeeker_Experience_StartDate).format('LL')}</h3>
                </div>

                <div className="border-b-2 border-[white]">
                <h3 className="p-2 text-[white] font-[Times] text-center">{moment(item.JobSeeker_Experience_FinishDate).format('LL')}</h3>
                </div>

                <div className="flex border-b-2 justify-center items-center">
                {item.JobSeeker_Experience_Country != null ? <div className=" border-[white]">
                <h3 className="p-2 text-[white] font-[Times] text-center">{item.JobSeeker_Experience_Country}</h3>
                </div> : <div className=" border-[white]">
                <h3 className="p-2 text-[white] font-[Times] text-center">Ülke Yok</h3>
                </div>}

                {item.JobSeeker_Experience_Country != null ?  <div className=" border-[white]">
                <h3 className="p-2 text-[white] font-[Times] text-center">{item.JobSeeker_Experience_County}</h3>
                </div>: <div className=" border-[white]">
                <h3 className="p-2 text-[white] font-[Times] text-center">Şehir Yok</h3>
                </div>}

                {item.JobSeeker_Experience_Country != null ?  <div className="border-[white]">
                <h3 className="p-2 text-[white] font-[Times] text-center">{item.JobSeeker_Experience_District}</h3>
                </div> : <div className=" border-[white]">
                <h3 className="p-2 text-[white] font-[Times] text-center">İlçe Yok</h3>
                </div>}
                </div>

                {item.JobSeeker_Experience_Short_Desc != null ? <div className="border-b-2 border-[white]">
                <h3 className="p-2 text-[white] font-[Times] text-center">{item.JobSeeker_Experience_Short_Desc}</h3>
                </div> : <div className="border-b-2 border-[white]">
                <h3 className="p-2 text-[white] font-[Times] text-center">Kısa Açıklama Yok</h3>
                </div> }

                {item.Job_WayOfWorking_ID != null ? <div className="border-b-2 border-[white]">
                <h3 className="p-2 text-[white] font-[Times] text-center">{item.Job_WayOfWorking_Text}</h3>
                </div> :  <div className="border-b-2 border-[white]">
                <h3 className="p-2 text-[white] font-[Times] text-center">Boş</h3>
                </div>}

                {item.Job_IsGetJob == 1 ? <div className="border-b-2 border-[white]">
                <h3 className="p-2 text-[white] font-[Times] text-center">İşi GetJob'dan Buldum</h3>
                </div> : <div className="border-b-2 border-[white]">
                <h3 className="p-2 text-[white] font-[Times] text-center">İşi GetJob'dan Bulmadım</h3>
                </div>}

                {item.JobSeeker_Experience_ContinousJob == 1 ?  <div className="border-b-2 border-[white]">
                <h3 className="p-2 text-[white] font-[Times] text-center">Hala Çalışıyorum</h3>
                </div> : <div className="border-b-2 border-[white]">
                <h3 className="p-2 text-[white] font-[Times] text-center">Çalışmıyorum</h3>
                </div>}

                </div>
       
            )})}
            </div>
            
        { this.state.editOptionHistory == true ? this.state.historyJobEdit.map((item, index) => (
               <div className="w-full">
               <InputField type="text" handleChange={(e)=> this._handleChange(e,"companyHistoryJob")} value={this.state.companyHistoryJob} labelFiled={"Şirket"} />
               <InputField type="text" handleChange={(e)=> this._handleChange(e,"departmantHistoryJob")} value={this.state.departmantHistoryJob} labelFiled={"Departman"} />
               <InputField type="date" handleChange={(e)=> this._handleChange(e,"startDateHistoryJob")} value={moment(this.state.startDateHistoryJob).format('YYYY-MM-DD')} labelFiled={"Başlangıç Tarihi"} />
               <InputField type="date" handleChange={(e)=> this._handleChange(e,"finishDateHistoryJob")} value={moment(this.state.finishDateHistoryJob).format('YYYY-MM-DD')} labelFiled={"Bitiş Tarihi"} />
               {this.state.historyJobWayOutWorkink != null ? <SelectOption handleChange={(e)=> this._handleChange(e,"historyJobWayOutWorkink")} selectLabel="Çalışma Şekli" value={this.state.historyJobWayOutWorkink} type={"historyJobWayOutWorkink"}/>  : null}
       
               {this.state.listCountries.length > 0  ?  <SelectOption   fullvalue={this.state.listCountries} handleChange={(e)=> this._getCityHistory(e.value,e.label)} value={this.state.countriesNameHistoryJob} selectLabel={"Ülke"} type={"countries"}  />: null}
                 {this.state.listCityHistory.length > 0  ? <SelectOption fullvalue={this.state.listCityHistory} handleChange={(e)=> this._getDistrictHistory(e.value,e.label)}  value={this.state.cityNameHistoryJob} selectLabel={"İl"} type={"city"}  /> : null}
                 {this.state.listDiscrictHistory.length > 0  ? <SelectOption fullvalue={this.state.listDiscrictHistory}  handleChange={(e)=> this._handleChange(e,"districtNameHistoryJob")} value={this.state.districtNameHistoryJob} selectLabel={"İlçe"} type={"district"}/> : null }
                 <InputField type="text" handleChange={(e)=> this._handleChange(e,"shortDescHistoryJob")} value={this.state.shortDescHistoryJob} labelFiled={"Kısa Açıklama"} />
       
                 <FormControlLabel    onClick={()=> this.saveCheckBoxHistory('checkFind')} control={<Checkbox checked={this.state.checkFindGetJob}/>} label="İşi GetJob'dan Buldum"  />
                 <FormControlLabel    onClick={()=> this.saveCheckBoxHistory('checkStill')} control={<Checkbox checked={this.state.checkStillingWork}/>} label="Hala Çalışıyorum"  />
       
                 <button className="bg-[#284B84] rounded-lg m-1  w-full justify-center items-center" onClick={(e)=> this._editHistoryFilter(e,item)}>
          <FontAwesomeIcon icon={ faSave }  className={"p-1"} color={'white'}/>   
          <a className=" text-white p-2 font-[Gotham-Medium] text-center">Çalışma Geçmişini Güncelle</a></button>


               </div>
       
            )) : 
            <div className="w-full">
               <InputField type="text" handleChange={(e)=> this._handleChange(e,"companyHistoryJob")} value={this.state.companyHistoryJob} labelFiled={"Şirket"} />
               <InputField type="text" handleChange={(e)=> this._handleChange(e,"departmantHistoryJob")} value={this.state.departmantHistoryJob} labelFiled={"Departman"} />
               <InputField type="date" handleChange={(e)=> this._handleChange(e,"startDateHistoryJob")} value={moment(this.state.startDateHistoryJob).format('YYYY-MM-DD')} labelFiled={"Başlangıç Tarihi"} />
               <InputField type="date" handleChange={(e)=> this._handleChange(e,"finishDateHistoryJob")} value={moment(this.state.finishDateHistoryJob).format('YYYY-MM-DD')} labelFiled={"Bitiş Tarihi"} />
              <SelectOption handleChange={(e)=> this._handleChange(e,"historyJobWayOutWorkink")} selectLabel="Çalışma Şekli" value={this.state.historyJobWayOutWorkink} type={"historyJobWayOutWorkink"}/> 
       
               {this.state.listCountries.length > 0  ?  <SelectOption   fullvalue={this.state.listCountries} handleChange={(e)=> this._getCityHistory(e.value,e.label)} value={this.state.countriesNameHistoryJob} selectLabel={"Ülke"} type={"countries"}  />: null}
                 {this.state.listCityHistory.length > 0  ? <SelectOption fullvalue={this.state.listCityHistory} handleChange={(e)=> this._getDistrictHistory(e.value,e.label)}  value={this.state.cityNameHistoryJob} selectLabel={"İl"} type={"city"}  /> : null}
                 {this.state.listDiscrictHistory.length > 0 ? <SelectOption fullvalue={this.state.listDiscrictHistory}  handleChange={(e)=> this._handleChange(e,"districtNameHistoryJob")} value={this.state.districtNameHistoryJob} selectLabel={"İlçe"} type={"district"}/> : null }
                 <InputField type="text" handleChange={(e)=> this._handleChange(e,"shortDescHistoryJob")} value={this.state.shortDescHistoryJob} labelFiled={"Kısa Açıklama"} />
       
                 <FormControlLabel    onClick={()=> this.saveCheckBoxHistory('checkFind')} control={<Checkbox checked={this.state.checkFindGetJob}/>} label="İşi GetJob'dan Buldum"  />
                 <FormControlLabel    onClick={()=> this.saveCheckBoxHistory('checkStill')} control={<Checkbox checked={this.state.checkStillingWork}/>} label="Hala Çalışıyorum"  />
       
                 <button className="bg-[#284B84] rounded-lg m-1  w-full justify-center items-center" onClick={(e)=> this._saveHistoryJob(e)}>
          <FontAwesomeIcon icon={ faSave }  className={"p-1"} color={'white'}/>   
          <a className=" text-white p-2 font-[Gotham-Medium] text-center">Çalışma Geçmişini Kaydet</a></button>
          
               </div>
            }

        <h3 className="mt-6 mb-3 text-blue-900 font-bold text-lg">
          İş Bilgileri
        </h3>
        <div className="w-full">
        {this.state.jobList.length > 0 ? <SelectOption fullvalue={this.state.jobList} handleChange={this._handleChange} value={this.state.jobListID} selectLabel="Meslek" type={"job"}/> : null}
          <SelectOption  fullvalue={[{value : "deneme"}]} handleChange={this._handleChange} value={this.state.jobExpreinceID} selectLabel="İş Deneyim Süresi" type={"exprience"}/> 
        </div>
        <h3 className="mt-6 mb-3 text-blue-900 font-bold text-lg">
          Aranılan İşler
        </h3>
        {this.state.jobList.length > 0 ? <div className="w-full">
        <MultiSelectOption fullData={this.state.jobList}  handleChange={this._handleChange}  value={this.state.valueSearchJob} type={"searchJob"} labelFiled="Aranılan İşler" /> 
        </div> : null }
        <h3 className="mt-6 mb-3 text-blue-900 font-bold text-lg">
          Maaş Beklentisi
        </h3>
        <div className="w-full">
       <SelectOption handleChange={this._handleChange} selectLabel="Maaş" value={this.state.jobSaleryID} type={"salery"}/> 
        </div>
        <h3 className="mt-6 mb-3 text-blue-900 font-bold text-lg">
          Çalışma Şekli
        </h3>
       
         <div className="w-full">
        <MultiSelectOption  handleChange={this._handleChange}  value={this.state.jobWayOut} type={"jobwayout"} labelFiled="Çalışma Şekli" /> 
        </div> 
       
        <h3 className="mt-6 mb-3 text-blue-900 font-bold text-lg">
          Çalışma Modeli
        </h3>
       
         <div className="w-full">
        <MultiSelectOption  handleChange={this._handleChange}  value={this.state.jobModal} type={"jobModal"} labelFiled=" Çalışma Modeli" /> 
        </div> 
        
        <h3 className="mt-6 mb-3 text-blue-900 font-bold text-lg">
          Yabancı Dil
        </h3>
        <div className="w-full">
        <MultiSelectOption  handleChange={this._handleChange} labelFiled="Dil" value={this.state.languageData} type={"language"}  />
        </div>
        
        <div className="relative ">
          <h3 className="mt-6 mb-5 text-blue-900 font-bold text-lg">
            Yetenekler
          </h3>
          <InputField handleChange={(e)=> this._handleChange(e,"ability")} type="text" labelFiled="Yeteneklerinizi Yazın" />
          <button onClick={()=> this._addAbility()} className="absolute top-14 right-2.5 cursor-pointer bg-[#F0F4FF] text-[#284B84] translate-x-[2px] border-1 px-2 rounded-md border-[#707070] ">
            Ekle
          </button>
          <div className="grid xxs:grid-cols-3 sm:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 relative">
          {this.state.jobSeekerAbility.map((item, index) => (
            <div className="m-2  relative"><a className="p-2 text-center bg-[#284B84] text-white font-[Gotham-Medium]">{item.Job_Seeker_Ability_Text}</a>
            <button onClick={()=> this._deleteAbility(item)}  className="absolute -top-4 -left-4  cursor-pointer bg-[#f8d581] text-[#284B84] translate-x-[2px] border-1 px-2 rounded-md border-[#707070] ">
            <FontAwesomeIcon icon={ faTrash }  size={20} color={'red'}/>   
            </button>
            </div>          ))}
            </div>
        </div>
        <div className="relative ">
          <h3 className="mt-6 mb-5 text-blue-900 font-bold text-lg">Hobiler</h3>
          <InputField  handleChange={(e)=> this._handleChange(e,"hobby")} type="text" labelFiled="Hobilerinizi Yazın" />
          <button onClick={()=> this._addHobby()} className="absolute top-14 right-2.5  cursor-pointer bg-[#F0F4FF] text-[#284B84] translate-x-[2px] border-1 px-2 rounded-md border-[#707070] ">
            Ekle
          </button>
          <div className="grid xxs:grid-cols-3 sm:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 relative">
          {this.state.jobSeekerHobby.map((item, index) => (
 <div className="m-2  relative"><a className="p-2 text-center bg-[#284B84] text-white font-[Gotham-Medium]">{item.Job_Seeker_Hobby_Text}</a>
 <button onClick={()=> this._deleteHobby(item)}  className="absolute -top-4 -left-4  cursor-pointer bg-[#f8d581] text-[#284B84] translate-x-[2px] border-1 px-2 rounded-md border-[#707070] ">
 <FontAwesomeIcon icon={ faTrash }  size={20} color={'red'}/>                            
</button>
 </div>          ))}
        </div>
        </div>
        <h3 className="mt-6 mb-3 text-blue-900 font-bold text-lg">Linkler</h3>
        <div className="w-full">
          <InputField handleChange={(e)=> this._handleChange(e,"website")} type="text" value={this.state.webSite} labelFiled={"Website"} />
          <InputField handleChange={(e)=> this._handleChange(e,"facebook")} type="text" value={this.state.facebook}  labelFiled={"Facebook"} />
          <InputField handleChange={(e)=> this._handleChange(e,"twitter")} type="text" value={this.state.twitter}  labelFiled={"Twitter"} />
          <InputField handleChange={(e)=> this._handleChange(e,"instagram")} type="text" value={this.state.instagram}  labelFiled={"Instagram"} />
        </div>

        <h3 className="mt-8 mb-4 text-blue-900 font-bold text-lg">Ön Yazı</h3>
        <div className="w-full ">
          <textarea
         onChange={(e)=> this._handleChange(e,"desc")}
            className="border-2 border-[#EBECF8] h-[171px] w-full rounded-lg"
            value={this.state.desc}
            placeholder={"Lütfen Ön Yazı Giriniz"}
          />
        </div>

        <h3 className="mt-6 mb-3 text-blue-900 font-bold text-lg font-[Gotham]">
          Portfolyo / Dosyalar
        </h3>
      
        <div className="w-full  bg-white p-2 rounded-md border-2 border-[#EBECF8] relative items-center text-center">
          <div className="w-full h-full bg-zinc-200   rounded-md relative grid ">
          <button className="flex justify-center items-center" onClick={e => this.enableInputFile(e, "portfolyo")}>

          <div  style={{ borderWidth: 0.3, borderColor: '#081F43', justifyContent: 'center', alignItems: 'center', borderRadius: 110 }}>
                  <FontAwesomeIcon icon={faFile} size={"xl"} color={'#081F43'} style={{ margin: 20 }} />
                </div>


          
             <p className="flex justify-center items-center text-[#081F43] text-lg hover:bg-white border-0 rounded-lg p-2 font-[Times]">Portfolyo/Dosya Ekle</p>
          
            </button>

            <input
             key={"portfolyo"}
             type="file"
             id={"portfolyo"}
             className="hidden"
             onChange={e=> this.onFileChangePortfolyo(e,"portfolyo")}
             data-name={"portfolyo"}
             accept="image/png, image/jpeg,image/webp, application/pdf,video/mp4,video/x-m4v,video/*"/>

          <ProgressBar value="0" max="100" key={1} bgcolor={'#081F43'} completed={this.state.loadingPercentagePortfolyo}  />


          <div className="justify-center items-center  grid xxs:xl:grid-cols-3 md:xl:grid-cols-4 lg:grid-cols-4 xl: grid-cols-5 gap-2">
            {this.state.selectedImagePortfolyo.map((item, index) => ( 
              <div className="bg-white border-2 m-1 rounded-md  relative grid ">

<button onClick={()=> this._deletePortfolyo(item)}  className="absolute -top-4 -right-4  cursor-pointer bg-[#f8d581] text-[#284B84] translate-x-[2px] border-1 px-2 rounded-md border-[#707070] z-2">
            <FontAwesomeIcon icon={ faTrash } className="p-2" size={"xl"} color={'red'}/>   
            </button>


              <text className=" p-1 font-[Gotham-Medium]">{item.data}</text>
                {item.type == "video" ? <ReactPlayer controls={true} width="80%" height="80%" url={item.imageData}/> :
                item.type == "document" ? 
                <div className="">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                 <Viewer fileUrl={item.imageData}/> 
                 </Worker> </div>
                                 :
             <div className="justify-center items-center w-full">
            <img src={item.imageData} alt="" className="w-full px-2" />
            </div>}
            </div>
             ))}
          {this.state.isPortfolyoLoading == true && this.state.selectedImagePortfolyo.length > 0 ? <button type="submit" className="bg-[#081F43] p-2 text-white rounded-lg" onClick={()=>this.onSubmit("portfolyo")}>Dosyaları Yükle</button>: null}

              </div>
          </div>
        

        </div>


        <h3 className="mt-6 mb-3 text-blue-900 font-bold text-lg">
          Diploma
        </h3>
        <div className="w-full bg-white p-2 rounded-md border-2 border-[#EBECF8] relative">
     
          <div className="w-full h-full bg-zinc-200 grid  rounded-md relative ">
          <button className="flex justify-center items-center" onClick={e => this.enableInputFile(e, "gradient")}>

          <div  style={{ borderWidth: 0.3, borderColor: '#081F43', justifyContent: 'center', alignItems: 'center', borderRadius: 110 }}>
                  <FontAwesomeIcon icon={faGraduationCap} size={"xl"} color={'#081F43'} style={{ margin: 20 }} />
                </div>
                

             <p className="flex justify-center items-center text-[#081F43] text-lg hover:bg-white border-0 rounded-lg p-2 font-[Times]">Diploma Ekle</p>

            </button>

            <input
              key={"gradient"}
              type="file"
              id={"gradient"}
              className="hidden"
              onChange={e=> this.onFileChangePortfolyo(e,"gradient")}
              data-name={"gradient"}
              accept="image/png, image/jpeg,image/webp, application/pdf,video/mp4"/>
                       

         <ProgressBar value="0" max="100" key={1} bgcolor={'#081F43'} completed={this.state.loadingPercentageGradint}  />

            <div className="flex justify-center items-center">
            {this.state.selectedImageGradient.map((item, index) => ( 
              <div className="bg-white border-2 m-1 rounded-md  relative grid w-1/2">
                <text className=" p-1 font-[Gotham-Medium] text-center">{item.data}</text>
                {item.type == "video" ? <ReactPlayer controls={true} width="80%" height="80%" url={item.imageData}/> :
                item.type == "document" ? 
                <div className="w-96 h-96">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                 <Viewer fileUrl={item.imageData}/> 
                 </Worker> </div>                :
                              <div className="justify-center items-center w-full">
            <img src={item.imageData} alt="" className="w-full px-2" />
            </div>}
            </div>
             ))}
              {this.state.isGradientLoading == true &&  this.state.selectedImageGradient.length == 1 ? <button type="submit" className="bg-[#081F43] p-2 text-white rounded-lg  text-center" onClick={()=>this.onSubmit("gradient")}>Dosyaları Yükle</button>: null}

              </div>


          </div>
        
        
        </div>
      

        <h3 className="mt-8 mb-4 text-blue-900 font-bold text-lg">Özgeçmiş</h3>
     
          <div className="w-full  bg-white border-2 border-[#EBECF8] p-1  rounded-md justify-center items-center ">
  
            <div className="w-full bg-zinc-200 h-full  justify-center items-center border-2 rounded-md">
            <button className="flex justify-center items-center w-full" onClick={e => this.enableInputFile(e, "cv")}>


            <div  style={{ borderWidth: 0.3, borderColor: '#081F43', justifyContent: 'center', alignItems: 'center', borderRadius: 110 }}>
                  <FontAwesomeIcon icon={faFile} size={"xl"} color={'#081F43'} style={{ margin: 20 }} />
                </div>

              <p className="flex justify-center items-center text-[#081F43] text-lg hover:bg-white border-0 rounded-lg p-2 font-[Times]">
                  CV Ekle
                </p>

              </button>


              <input
              key={"cv"}
              type="file"
              id={"cv"}
              className="hidden"
              onChange={e=> this.onFileChangePortfolyo(e,"cv")}
              data-name={"cv"}
              accept="image/png, image/jpeg,image/webp, application/pdf,video/mp4,video/x-m4v,video/*"/>
                       

          <ProgressBar value="0" max="100" key={1} bgcolor={'#081F43'} completed={this.state.loadingPercentageCv}  />


          <div className=" justify-center items-center  grid xxs:xl:grid-cols-3 md:xl:grid-cols-4 lg:grid-cols-4 xl: grid-cols-5 gap-2">

            {this.state.selectedImageCv.map((item, index) => ( 
              <div className="bg-white border-2 m-1 rounded-md  relative grid ">

<button onClick={()=> this._deleteCV(item)}  className="absolute -top-4 -right-4  cursor-pointer bg-[#f8d581] text-[#284B84] translate-x-[2px] border-1 px-2 rounded-md border-[#707070] z-2">
            <FontAwesomeIcon icon={ faTrash } className="p-2" size={"xl"} color={'red'}/>   
            </button>

                <text className=" p-1 font-[Gotham-Medium] text-center">{item.data}</text>
                {item.type == "video" ? <ReactPlayer controls={true} width="80%" height="80%" url={item.imageData}/> :
                item.type == "document" ? 
                <div className="">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                 <Viewer fileUrl={item.imageData}/> 
                 </Worker> </div>
                                 :
                                 <div className="justify-center items-center w-full">
                                 <img src={item.imageData} alt="" className="w-full px-2" />
            </div>}
            </div>
             ))}
            {this.state.isCvLoading == true && this.state.selectedImageCv.length >0 ? <button type="submit" className="bg-[#081F43] p-2 text-white rounded-lg" onClick={()=>this.onSubmit("cv")}>CV'yi Yükle</button>: null}

              </div>

    
            </div>

          </div>

        <h3 className="mt-8 mb-4 text-blue-900 font-bold text-lg">
          Profil Fotoğrafı
        </h3>
          <div className="w-full  bg-white border-2 border-[#EBECF8] p-1  justify-center items-center text-center m-1">
            <div className="w-full h-full bg-zinc-200 border rounded-md  justify-center items-center p-2">
            <button className="flex items-center justify-center w-full" onClick={e => this.enableInputFile(e, "photo")}>


                <div  style={{ borderWidth: 0.3, borderColor: '#081F43', justifyContent: 'center', alignItems: 'center', borderRadius: 100 }}>
                  <FontAwesomeIcon icon={faCameraAlt} size={"xl"} color={'#081F43'} style={{ margin: 20 }} />
                </div>
                           <p className="flex justify-center items-center text-[#081F43] text-lg hover:bg-white border-0 rounded-lg p-2 font-[Times]">
                  Profil Fotoğrafı Ekle
                </p>
  
              </button>
             

              <input
              key={"photo"}
              type="file"
              id={"photo"}
              className="hidden"
              onChange={e=> this.onFileChangePortfolyo(e,"photo")}
              data-name={"photo"}
              accept="image/png, image/jpeg,image/webp"/>
                       

           <ProgressBar value="0" max="100" key={1} bgcolor={'#081F43'} completed={this.state.loadingPercentageCv}  />
       

            <div className="flex justify-center items-center ">
            {this.state.selectedImagePhoto.map((item, index) => ( 
              <div className="bg-white border-2 border-[#081F43] m-1 rounded-xl justify-center items-center text-center">
                <text className=" p-1 font-[Gotham-Medium] text-center">Profil Fotoğrafı</text>
                {item.type == "video" ? <ReactPlayer controls={true} width="100%" height="100%" url={item.imageData}/> :
                item.type == "document" ? 
                <div className="w-96 h-96">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                 <Viewer fileUrl={item.imageData}/> 
                 </Worker> </div>             :
                <div  className="w-40 justify-center items-center">
                <img src={item.imageData} alt="" className="px-2" />
                                 </div>}
            </div>
             ))}
                           {this.state.isPhotoLoading == true && this.state.allPhoto.length < 1? <button type="submit" className="bg-[#081F43] p-2 text-white rounded-lg justify-center items-center" onClick={()=>this.onSubmit("photo")}>Fotoğrafı Sisteme Yükle</button>: null}

              </div>


                          </div>
          </div>

        <h3 className="mt-8 mb-4 text-blue-900 font-bold text-lg">
          Videolu Özgeçmiş
        </h3>
          <div className="w-full  bg-white border-2 border-[#EBECF8] p-1 justify-center items-center">
            <div className="w-full h-full bg-zinc-200 border rounded-md justify-center items-center">
            <button className="flex items-center justify-center w-full" onClick={e => this.enableInputFile(e, "video")}>

            <div  style={{ borderWidth: 0.3, borderColor: '#081F43', justifyContent: 'center', alignItems: 'center', borderRadius: 110 }}>
                  <FontAwesomeIcon icon={faVideoCamera} size={"xl"} color={'#081F43'} style={{ margin: 20 }} />
                </div>
                
          
             <p className="flex justify-center items-center text-[#081F43] text-lg hover:bg-white border-0 rounded-lg p-2 font-[Times]">
                  Video Ekle
                </p>
             
              </button>
              <input
              key={"video"}
              type="file"
              id={"video"}
              className="hidden"
              onChange={e=> this.onFileChangePortfolyo(e,"video")}
              data-name={"video"}
              accept="video/mp4,video/x-m4v,video/*"/>
                       

             <ProgressBar value="0" max="100" key={1} bgcolor={'#081F43'} completed={this.state.loadingPercentageVideo}  />


            <div className="flex  justify-center items-center">
            {this.state.selectedImageVideo.map((item, index) => ( 
              <div className="bg-white border-2 m-1 rounded-md justify-center items-center">
                <text className=" p-1 font-[Gotham-Medium] text-center">{item.data}</text>
                {item.type == "video" ? <ReactPlayer controls={true} width="100%" height="80%" url={item.imageData}/> :
                item.type == "document" ? 
                <div className="w-96 h-96">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                 <Viewer fileUrl={item.imageData}/> 
                 </Worker> </div>:
                              <div className="justify-center items-center w-full">
                  <img src={item.imageData} alt="" className="w-full px-2" />
                  </div>}
            </div>
             ))}

{this.state.isVideoLoading == true ? <button type="submit" className="bg-[#081F43] p-2 text-white rounded-lg" onClick={()=>this.onSubmit("video")}>Video'yu Yükle</button>: null}

              </div>

                          </div>


          </div>

        
        <h3 className="mt-8 mb-4 text-blue-900 font-bold text-lg">
          Konumunuz
        </h3>
        
        <div className="z-11">
        <Autocomplete
  apiKey={"AIzaSyCm32U9QFgSBe_8pJG_2Z3G61pL6ipeQtU"}
  style={{ width: "100%" }}
  placeholder="Şuan ki konumunuzu ekleyin"
  onPlaceSelected={(place) => {
    console.log("place123", place.address_components[1]);
    this.setState({
      currentLatitude:place.geometry.location.lat(),
      currentLongitude:place.geometry.location.lng(),

      mapData:{
        ID :  1,
            position:{
              lat:  place.geometry.location.lat(),
              lng:  place.geometry.location.lng(),
        },
      currentLatitude :  place.geometry.location.lat(),
      currentLongitude :  place.geometry.location.lng(),
      currentCity : place.address_components[1].short_name
    },
    currentCity : place.address_components[1].short_name


    })
  }}
  options={{
    types: ["(regions)"],
    //componentRestrictions: { country: "tr" },
  }}
 
/>
</div>
{ this.state.mapData == null ? null : <div style={{ height: '80vh', width: '100%',zIndex:10 }} className="z-10 ex-collection-box mb-xl-20">

<MapWrapped
   googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCm32U9QFgSBe_8pJG_2Z3G61pL6ipeQtU&v=3.exp&libraries=geometry,drawing,places}`}
   loadingElement={<div style={{ height: `100%` }} />}
   containerElement={<div style={{ height: `100%` }} />}
   mapElement={<div style={{ height: `100%` }} />}
   dealerData = {this.state.mapData != null ? this.state.mapData : null}
   currentLat={this.state.currentLatitude}
   currentLng={this.state.currentLongitude}
 />
</div> }
      </div>

      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>


      <div className="justify-center items-center m-2 w-full bg-[#081F43] text-center">
                   <button type="submit" className=" p-2 text-white rounded-lg text-center font-[Times]" onClick={()=>this._complateJob()}>Bilgileri KAYDET</button>
                </div>

               <LoadingSpinner show={this.state.isLoading} setShow={this._setShow}/> 

    </div>
  )
}
};

const MapWrapped = withScriptjs(withGoogleMap(Map));

export default withParamsAndNavigate(PersonelInformation);
