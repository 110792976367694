import React, { useEffect } from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import axios from 'axios';
import FirstBorderEmployee from "./FirstBorderEmployee";
import EmployerInfoResult from "./EmployerInfoResult";
import EmployerInfoResultCompany from "./EmployerInfoResultCompany";

import { useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import LoadingSpinner from "../../LoadingSpinner";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}




const EmployeeForm = () => {
  const location = useLocation();
  const state = location.state;

  const [list, setList] = React.useState([]);
  const [toastCheck, setToastCheck] = React.useState([]);

  const [isLoading, setLoading] = React.useState(true);
  const [getTab, setTab] = React.useState(0);

  const navigate = useNavigate()

  const _openToast= async(type) => {
    setToastCheck(!toastCheck)

    if(type == "patch"){
      showToast('GetJob', "Başarıyla Hesabınız Donduruldu!", 'success')
      localStorage.removeItem('dataLogin');
      navigate("/")

    }
    if(type == "patch2"){
      showToast('GetJob', "Başarıyla Hesabınız Donduruldu!", 'success')
    }
    else{
      showToast('GetJob', "Başarıyla Hesabınız Silindi!", 'success')
      localStorage.removeItem('dataLogin');
      navigate("/")

    }
   

  }

  const _openToastModal2= async(type) => {
    setToastCheck(!toastCheck)
      showToast('GetJob', "Başarıyla işleminiz gerçekleştirildi!", 'success')
}

  const _openToastModal= async(type) => {
    setToastCheck(!toastCheck)
      showToast('GetJob', "Linkiniz başarıyla kopyalandı!", 'success')
}

const _getList= async() => {
  try{
           await axios.get(`https://getjob.stechomeyazilim.info:5101/getEmployerProfile/select/${state._item.ID}`)
            .then((res) => {
              console.log("lsdnflj123", res.data)
               setList(res.data) 
               setLoading(false)  
               })
     }
     catch (error) {
       console.log("_getList" + error)
     }
    }

const _deleteToast= async(item) => {
  setToastCheck(!toastCheck)
  
  axios.delete(`https://getjob.stechomeyazilim.info:5101/employerDelete/${item.ID}`)

    showToast('GetJob', "İlanınız Başarıyla Silindi!", 'success')

    _getList()
}

  const showToast= (event, value, type) => {
    if(type == "false"){
      toast.error(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
  
    }else{
      toast.success(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      
    }
  
  }
  
  useEffect(() => {
    const _getList= async() => {
      try{
               await axios.get(`https://getjob.stechomeyazilim.info:5101/getEmployerProfile/select/${state._item.ID}`)
                .then((res) => {
                  console.log("lsdnflj123", res.data)
                   setList(res.data) 
                   setLoading(false)  
                   })
         }
         catch (error) {
           console.log("_getList" + error)
         }
    }

    window.scrollTo(0, 0);
    _getList()

   if(location.pathname != null){
    _getList()
   }
  }, [location])



const _setShow= async () => {

}

const handleChange = (event, newValue) => {
  setTab(newValue);
  console.log("lsdnf",newValue)
}


  return (
    <div >
      <Navbar />
      <div className="w-full  bg-[#F7F7F7] flex flex-col justify-between ">
        <div className="max-w-full mt-40 flex flex-col justify-center">
          <h3 className="text-xl text-center px-auto mt-6 text-blue-800 font-bold ">
            Profil
          </h3>
          <div className=" border rounded-2xl bg-white w-3/5 mx-auto mb-28 h-full mt-12 flex  flex-col  ">
          {list.length>0 ? <FirstBorderEmployee _openToastModal2={_openToastModal2} _openToastModal={_openToastModal} edit={state.edit} item ={list[0]} _openToast={_openToast} /> : null }
          <EmployerInfoResultCompany _item={list[getTab]} />

          <div className="flex flex-col py-3 px-2 w-full">
          <h3 className="text-[#071E44] text-sm pt-3 font-[Gotham-Medium]">İLANLAR</h3>
        </div>

          <Box sx={{ width: '100%',height:'100%' }}>
            <Box className="w-full">
        <Tabs  variant="scrollable"
  scrollButtons="auto"
  value={getTab} onChange={handleChange} >
           {list.length> 0 ? list.map((item, index) => (<Tab label={item.Job.Job_Text}  />)):null}
        </Tabs>
        <TabPanel value={getTab} index={getTab}>
     
         <EmployerInfoResult _showToast={()=> _deleteToast(list[getTab])} _item={list[getTab]} />
          
      </TabPanel>
      </Box>
      </Box>
          </div>
          <Footer />
        </div>
      </div>

      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>  

<LoadingSpinner show={isLoading} setShow={_setShow}/> 


    </div>
  )
};

export default EmployeeForm;
