import React, { useState, useEffect,Component } from "react";
import InputField from "./InputField";

import axios from 'axios';


import { withParamsAndNavigate } from "./getParamsAndNavigate.js";
import { ToastContainer, toast } from 'react-toastify';
import LoadingSpinner from "../../LoadingSpinner";
import ReactPlayer from 'react-player';
import { MdSlowMotionVideo } from "react-icons/md";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import mapStyles from './mapStyles'
import ProgressBar from "../NewForm/ProgressBar";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectOption from "./SelectOption";

function Map(props) {
  const [selectedPark, setSelectedPark] = useState(null);

  useEffect(() => {
    const listener = e => {
      if (e.key === "Escape") {
        setSelectedPark(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

console.log("lksdnkf",props)

  return (
    props.dealerData != null ?
      <GoogleMap
      defaultZoom={4}
      defaultCenter={{ lat: 48.8114633 , lng: 34.8990059}}
      options={{ styles: mapStyles }}
    >
        <Marker
          key={props.dealerData.ID}
          position={{
            lat: props.currentLat,
            lng: props.currentLng
          }}
          onClick={() => {
            setSelectedPark(props.dealerData.Employer_Map);
          }}
          icon={{
            url:  "https://img.icons8.com/color/48/000000/map-pin.png",
            scaledSize: new window.google.maps.Size(50, 50)
          }}
        >

<InfoWindow
          onCloseClick={() => {
            setSelectedPark(null);
          }}
          position={{
            lat: props.currentLat,
            lng: props.currentLng
          }}
        >
          <div>
            <h2 className="font-[Gotham-Medium] ">{props.dealerData.city}</h2>
          </div>
        </InfoWindow>
        </Marker>
    </GoogleMap> : <div><text>Veri Yok!</text> </div>
    
  );
}

class PersonelInformation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listDriverLicense:[],
      jobList:[],
      saleryData:[],
      dealerData:[],
      selectedLat:37.8604039,
      selectedLng:32.5372508,
      selectedImageVideo:[],
      isLoading:false,
     defaultProps : {
         center: {
           lat: 37.8604039,
           lng: 32.5372508
         },
         zoom: 11
       },
       showingInfoWindow: false,
     activeMarker: {},
     selectedPlace: {},


      companyName : '',
      sector: '',
      foundationYear: '',
      employeesNumer:'',

      languageData:[],
      mapData:null,
      educationStatus:[],
      jobwayout:[],
      jobListID:0,
      jobList:[],
      jobExpreinceID:null,
      desc:null,
      companyVideo:'',

      listCountries:[],
      listCity:[],
      listDiscrict:[],
      listCityEducation:[],
      selectedImagePhoto:[],
      checkGender:false,
      checkMilitary:false,
      checkDriverLicense:false,
      checkGradient:false,
      checkCv:false,
      checkVideo:false,
      checkDesc:false,
      checkCertifica:false,
      jobModal:[],
      employeeNumber:null,
      foundationYearLength:0,
      loadingPercentageVideo:0,
      loadingPercentagePhoto:0,
    }
    
    console.log("lsndf123",this.props.item)
}

onMarkerClick = (props, marker, e) =>{
  console.log("nsldlfk",props, marker, e)
      this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
      })
  }
  
    onClose = props => {
      if (this.state.showingInfoWindow) {
        this.setState({
          showingInfoWindow: false,
          activeMarker: null
        });
      }
    };
  
    _changeMaps= async (lat,long) => {
      this.setState({defaultProps: {
          center: {
            lat: lat,
            lng: long
          },
          zoom: 11
        } ,
        selectedLat:lat,
        selectedLng:long,
  
      
      },()=> {
          console.log("sjndfjkls",this.state.defaultProps)
      })
      }
      _setMap = async (item) => {
  
      this.setState({
          openDetail:true,
          mapDetail:item
      })
  }
      
async componentDidMount() {

  await this._getDetail()
  await this._getJob()
  await this._getCountries()
}

_getCountries= async () => {
  console.log("lnsdkf",this.props.item)
  await axios.get(`https://getjob.stechomeyazilim.info:5101/getCountries/`)
  .then((res) => {
    let listCountriesFilter = res.data.filter(x=> x.name == this.props.item.Employer_Company.Employer_Company_Countries)
    console.log("123123",listCountriesFilter)
    this._getCity(listCountriesFilter[0].iso2,listCountriesFilter[0].name)
    this.setState({countriesCode : listCountriesFilter[0].iso2})
    res.data.map((item, index) => (
    this.setState(prevState => ({
      listCountries: [...prevState.listCountries, {label:item.name,value:item.iso2}]
    }))
    ))
     })

}

_getCity= async (city,cityName) => {
  
  this.setState({
    countries:city,
  })
  await axios.get(`https://getjob.stechomeyazilim.info:5101/getCity/${city}`)
  .then((res) => {
    let listCountriesFilter = res.data.filter(x=> x.name == this.props.item.Employer_Company.Employer_Company_City)
    this._getDistrict(listCountriesFilter[0].iso2)

    res.data.map((item, index) => (
    this.setState(prevState => ({
      listCity: [...prevState.listCity, {label:item.name,value:item.iso2}]
    }))
    ))
     })


     await axios.get(`https://getjob.stechomeyazilim.info:5101/getCity/TR`)
  .then((res) => {
    res.data.map((item, index) => (
    this.setState(prevState => ({
      listCityEducation: [...prevState.listCityEducation, {label:item.name,value:item.iso2}]
    }))
    ))
     })
     
}

_getDistrict= async (ID) => {
  this.setState({
    listDiscrict : []
  })
  await axios.get(`https://getjob.stechomeyazilim.info:5101/getDistrict/${this.state.countriesCode}/${ID}`)
  .then((res) => {
    res.data.map((item, index) => (
    this.setState(prevState => ({
      listDiscrict: [...prevState.listDiscrict, {label:item.name,value:item.name}]
    }))
    ))
     })

}

_getJob= async () => {
  await axios.get(`https://getjob.stechomeyazilim.info:5101/getJob/select`)
  .then((res) => {

    res.data.map((item, index) => (
    this.setState(prevState => ({
      jobList: [...prevState.jobList, {label:item.Job_Text,value:item.ID}]
    }))
    ))
     })

}


_updateForm = async () => {
  this.setState({isLoading:true})
  try {

    let companyData = { 
      Employer_Company_AddressText: this.state.Employer_Company_AddressText,
      Job_Employer_CompanyName : this.state.companyName,
      Job_Employer_SectorName: this.state.sector,
      Job_Employer_EmployeesNumber: this.state.employeesNumer,
      Employer_Company_Tax_Number : this.state.employeesTaxNumber,
      Employer_Company_Image:this.state.companyPhoto,
      Employer_Company_Video:this.state.companyVideo,
      Employer_Company_PhoneNumber:this.state.employeesNumber,
   //   Employer_Company_AddressText:
      Employer_Company_Countries:this.state.countriesName,
      Employer_Company_City:this.state.cityName,
      Employer_Company_District:this.state.districtName,
    //  Employer_Company_Countries_Iso:
   //   Employer_Company_City_Iso:
    //  Employer_Company_District_Id:
      Employer_Company_OfficalUser:this.state.employeesOfficalUser,
      Employer_Company_Mail:this.state.employeesMail,

      Employer_Company_Foundation_Year : this.state.foundationYear,
      Employer_Number: this.state.employeeNumber
    }
  
    await axios.patch(`https://getjob.stechomeyazilim.info:5101/employerCompanyPatch/${this.props.item.Employer_Company.ID}`, companyData)
      .then(async (res) => {
        this.setState({isLoading:false})

        this.props.navigate('/employeeınfo/' + this.props.item.Users_ID , { state: { _item: { ID: this.props.item.Users_ID } } })
        this.showToast('Başarıyla Bilgileriniz Güncellendi', true)
      })
  }
  catch (error) {
    console.log("_updateForm1231" + error)
  }
}


  handleChange= async (val,type) => {

    console.log("aljksndfl",val.target.value)
    if(type=="Employer_Company_AddressText"){
      this.setState({
        Employer_Company_AddressText:val.target.value
      })
    }
    if(type=="employeesNumber"){
      this.setState({
        employeesNumber:val
      })
    }
    if(type=="employeesMail"){
      this.setState({
        employeesMail:val
      })
    }
    
    if(type=="employeesOfficalUser"){
      this.setState({
        employeesOfficalUser:val
      })
    }

    if(type=="employeesTaxNumber"){
      this.setState({
        employeesTaxNumber:val
      })
    }

    if(type=="employeeNumber"){
      this.setState({
        employeeNumber:val
      })
    }
    if(type=="jobModal"){
      this.setState({
        jobModal:val
      })
    }
  
    if(type=="name"){
      this.setState({
        companyName:val
      })
    }
    if(type=="sector"){
      this.setState({
        sector:val
      })
    }
    if(type=="foundationYear"){
      this.setState({
         foundationYearLength : val.length,
        foundationYear:val
      })
    }
    if(type=="employeesNumer"){
      this.setState({
        employeesNumer:val
      })
    }
    if(type =="jobwayout"){
      this.setState({
        jobwayout:val
      })
    }
    if(type =="educationStatus"){
      this.setState({
        educationStatus:val
      })
    }
   
    if(type =="jobs"){
      this.setState({
        jobListID:val
      })
    }  
    if(type =="desc"){
      this.setState({
        desc:val.target.value
      })
    }  
    if(type =="exprience"){
      this.setState({
        jobExpreinceID:val
      })
    }  
  
    
  }


_getDetail = async () => {
try{

  console.log("klsdnfl12",this.props.item.Employer_Map)
  this.setState({
    companyVideo:this.props.item.Employer_Company.Employer_Company_Video,
    employeeNumber:this.props.item.Employer_Company.Employer_Number,
    currentLatitude:this.props.item.Employer_Map.Job_Employer_Map_Lat,
    currentLongitude:this.props.item.Employer_Map.Job_Employer_Map_Lng,
    Employer_Company_AddressText:this.props.item.Employer_Company.Employer_Company_AddressText,
    mapData:{
      ID :   this.props.item.Employer_Map.ID,
          position:{
            lat:   this.props.item.Employer_Map.Job_Employer_Map_Lat,
            lng:   this.props.item.Employer_Map.Job_Employer_Map_Lng
      }},
  currentLatitude :  this.props.item.Employer_Map.Job_Employer_Map_Lat,
  currentLongitude :   this.props.item.Employer_Map.Job_Employer_Map_Lng,
  city :   this.props.item.Employer_Map.Job_Employer_Map_City
})

  this.props.item.Employer_Language.map((item, index) => (
      this.setState(prevState => ({
        languageData: [...prevState.languageData, {label:item.Job_Employer_Language,value:item.Job_Employer_Language}]
      }))
      ))
      
  this.props.item.Employer_JobWay.map((item, index) => (
    this.setState(prevState => ({
      jobwayout: [...prevState.jobwayout, {label:item.Employer_JobWay_Text,value:item.Employer_JobWay_Text}]
    }))
    ))


    this.props.item.Employer_JobModal.map((item, index) => (
      this.setState(prevState => ({
        jobModal: [...prevState.jobModal, {label:item.Employer_JobModal_Text,value:item.ID}]
      }))
      ))
          
      console.log("slndflk",this.props.item.Employer_Company.Employer_Company_Video)
        this.setState({
          companyPhoto:this.props.item.Employer_Company.Employer_Company_Image,
          selectedImagePhoto: this.props.item.Employer_Company.Employer_Company_Image != null ? [{ ID: parseInt(this.state.selectedImagePhoto.length+1), data: parseInt(this.state.selectedImagePhoto.length+1) + ". Belge", type : 'image' , imageData : 'https://getjob.stechomeyazilim.com/Uploads/' + this.props.item.Employer_Company.Employer_Company_Image,type : "photo"   }] :[] ,
          selectedImageVideo: this.props.item.Employer_Company.Employer_Company_Video != null ? [{ ID: parseInt(this.state.selectedImageVideo.length+1), data: parseInt(this.state.selectedImageVideo.length+1) + ". Belge", type : 'image' , imageData : 'https://getjob.stechomeyazilim.com/Uploads/' + this.props.item.Employer_Company.Employer_Company_Video,type : "video"   }] : [],
          checkGender :  this.props.item.Employer_Filter.Employer_Filter_Gender == "Kadın" ? true : false,
          startAge: this.props.item.Employer_Filter.Employer_Start_Age ,
          finishAge: this.props.item.Employer_Filter.Employer_Finish_Age,
          checkDriverLicense: this.props.item.Employer_Filter.Employer_Driving_License,
          checkGradient: this.props.item.Employer_Filter.Employer_Gradient,
          checkCv: this.props.item.Employer_Filter.Employer_CV,
          checkVideo: this.props.item.Employer_Filter.Video_Check,
          checkDesc: this.props.item.Employer_Filter.Employer_Filter_Desc,
          checkCertifica: this.props.item.Employer_Filter.Employer_Certifica,
          companyName : this.props.item.Employer_Company.Job_Employer_CompanyName,
          sector:this.props.item.Employer_Company.Job_Employer_SectorName,
          foundationYear: this.props.item.Employer_Company.Employer_Company_Foundation_Year,
      //    foundationYearLength : this.props.item.Employer_Company.Employer_Company_Foundation_Year.length,
          employeesNumer: this.props.item.Employer_Company.Job_Employer_EmployeesNumber,
          employeesOfficalUser:this.props.item.Employer_Company.Employer_Company_OfficalUser,

          employeesTaxNumber:this.props.item.Employer_Company.Employer_Company_Tax_Number,
          employeesNumber:this.props.item.Employer_Company.Employer_Company_PhoneNumber,
          employeesMail:this.props.item.Employer_Company.Employer_Company_Mail,
          jobExpreinceID:this.props.item.Job_Experience_ID,
          jobSaleryID:this.props.item.Employer_Salery_Expection_ID,
          desc:this.props.item.Employer_Desc,
          jobListID:this.props.item.Job_ID,
          countriesName:this.props.item.Employer_Company.Employer_Company_Countries,
          cityName:this.props.item.Employer_Company.Employer_Company_City,
          districtName:this.props.item.Employer_Company.Employer_Company_District,
         /*  mapData:{
            ID :   this.props.item.Employer_Map.ID,
                position:{
                  lat:   this.props.item.Employer_Map.Job_Employer_Map_Lat,
                  lng:   this.props.item.Employer_Map.Job_Employer_Map_Lng
            }},
        currentLatitude :  this.props.item.Employer_Map.Job_Employer_Map_Lat,
        currentLongitude :   this.props.item.Employer_Map.Job_Employer_Map_Lng,*/
      

     
      })

      
     
        
}
catch (error) {
 console.log("_getDetailError" + error)
}
}

_handleChange= async (value,type) => { 
  console.log("value123",value)
  if(type=="gender"){
    this.setState({userGender:value.label})
  }
  if(type=="employeesTaxNumber"){
    this.setState({employeesTaxNumber:value})
  }
  if(type=="employeesMail"){
    this.setState({employeesMail:value})
  }
  if(type=="employeesNumber"){
    this.setState({employeesNumber:value})
  }
  if(type =="jobwayout"){
    this.setState({jobwayout:value})
  }
  if(type =="educationStatus"){
    this.setState({educationStatus:value})
  }
  if(type =="language"){
    this.setState({languageData:value})
  }  

  if(type =="employeesOfficalUser"){
    this.setState({employeesOfficalUser:value})
  }  

  
  
}

onFileChangePortfolyo = async (e,type) => { 

  let file = e.target.files[0];

  if (file.size > 15e6) {
    window.alert("Lütfen dosya yüklerken 15 MB'ı geçmemeli!");
    return false;
  }else{
  this.setState({isLoading:true})

  if(type=="photo"){
    let files = e.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    //fileReader.readAsText(files[0]);
  
    fileReader.addEventListener('loadstart', this.changeStatus('Yüklemeye Başlıyor',"video"));
    fileReader.addEventListener('load', this.changeStatus('Yükleniyor',"video"));
    fileReader.addEventListener('loadend', (event) => {this.loaded(event,type)});
    fileReader.addEventListener('progress',(event) => {this.setProgress(event,type)});
    fileReader.addEventListener('error', this.errorHandler);
    fileReader.addEventListener('abort', this.changeStatus('Interrupted'));
    
  
    fileReader.onload = (event) => {
  
      this.setState({
        isPhotoLoading:true,
        isLoading: false,
        selectedImagePhoto: [{ ID: parseInt(this.state.selectedImagePhoto.length+1), data: parseInt(this.state.selectedImagePhoto.length+1) + ". Belge", type : 'image' , imageData : event.target.result,type : files[0].type == "image/png" ? "photo"  :  files[0].type == "application/pdf" ? "document"  :  files[0].type == "video/quicktime" ? "video" :  files[0].type == "video/quicktime" ? "video" : null }]
       
      })
  
     
    }
  }
  if(type=="video"){
    this.setState({isVideoLoading: true,isLoading:true})
    let files = e.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    //fileReader.readAsText(files[0]);
  
    fileReader.addEventListener('loadstart', this.changeStatus('Start Loading'));
    fileReader.addEventListener('load', this.changeStatus('Loaded'));
    fileReader.addEventListener('loadend', this.loaded);
    fileReader.addEventListener('progress', this.setProgress);
    fileReader.addEventListener('error', this.errorHandler);
    fileReader.addEventListener('abort', this.changeStatus('Interrupted'));
    
  
    fileReader.onload = (event) => {
  
      this.setState({
        isVideoLoading:true,
        isLoading: false,
        selectedImageVideo: [{ ID: parseInt(this.state.selectedImageVideo.length+1), data: parseInt(this.state.selectedImageVideo.length+1) + ". Belge", type : 'image' , imageData : event.target.result,type : files[0].type == "image/png" ? "photo"  :  files[0].type == "application/pdf" ? "document"  :  files[0].type == "video/quicktime" ? "video" :  files[0].type == "video/quicktime" ? "video" : null }]
      })
  
     
    }
  }

  }


}


saveCheckBox= async (type) => {
  if(type == "gender"){
    this.setState({checkGender: !this.state.checkGender})
  }
  if(type == "military"){
    this.setState({checkMilitary: !this.state.checkMilitary})
  }
  if(type == "driverlicense"){
    this.setState({checkDriverLicense: !this.state.checkDriverLicense})
  }
  if(type == "gradient"){
    this.setState({checkGradient: !this.state.checkGradient})
  }
  if(type == "cv"){
    this.setState({checkCv: !this.state.checkCv})
  }
  if(type == "video"){
    this.setState({checkVideo: !this.state.checkVideo})
  }
  if(type == "desc"){
    this.setState({checkDesc: !this.state.checkDesc})
  }
  if(type == "certifica"){
    this.setState({checkCertifica: !this.state.checkCertifica})
  }
  //this.setState({isLoading: false,companyVideo:res.data})
}


_setShow= async () => {

}

changeStatus = (status) => {
//document.getElementById('status').innerHTML = status;
}

enableInputFile(e, id) {
document.getElementById(id).click();
}

setProgress = (e,type) => {
  const loadingPercentage = 100 * e.loaded / e.total;

  if(type == "portfolyo"){

    this.setState({
      loadingPercentagePortfolyo : loadingPercentage
    })
    document.getElementById('progress-bar-portfolyo').value = loadingPercentage;
  }
  if(type == "video"){
    this.setState({
      loadingPercentageVideo : loadingPercentage
    })
    document.getElementById('progress-bar-video').value = loadingPercentage;
  }
  if(type == "cv"){
    this.setState({
      loadingPercentageCv : loadingPercentage
    })
    document.getElementById('progress-bar-cv').value = loadingPercentage;
  }
  if(type == "gradient"){
    this.setState({
      loadingPercentageGradint : loadingPercentage
    })
    document.getElementById('progress-bar-gradient').value = loadingPercentage;
  }
  if(type == "photo"){
    this.setState({
      loadingPercentagePhoto : loadingPercentage
    })
    document.getElementById('progress-bar-photo').value = loadingPercentage;
  }
}

setProgressGradient = (e) => {
const fr = e.target;
const loadingPercentage = 100 * e.loaded / e.total;

document.getElementById('progress-bar-gradient').value = loadingPercentage;
}

loaded = (e) => {
const fr = e.target;
var result = fr.result;

this.changeStatus('Yükleme Tamamlandı!');
console.log('Result:', result);
}

errorHandler = (e) => {
this.changeStatus('Error: ' + e.target.error.name);
}

onSubmit= (type) => {
  this.setState({isLoading: true})
  if(type == "video"){
    const formData = { image: this.state.selectedImageVideo[0].imageData }
    let endpoint = "https://getjob.stechomeyazilim.info:5101/fileUpload/insert/";
     axios.post(endpoint, formData, {
     }).then(async(res) => {


        this.setState({isLoading: false,companyVideo:res.data})

        this.showToast('GetJob', "Başarıyla Görseller Yüklendi!", 'success')

    })
  }
  if(type == "photo"){

    console.log("sldfnl",this.state.selectedImagePhoto[0].imageData)
    const formData = { image: this.state.selectedImagePhoto[0].imageData }
    let endpoint = "https://getjob.stechomeyazilim.info:5101/fileUpload/insert/";
     axios.post(endpoint, formData, {
     }).then(async(res) => {


      console.log("sldfnl", res.data)
        this.setState({isLoading: false,companyPhoto:res.data})

        this.showToast('GetJob', "Başarıyla Görseller Yüklendi!", 'success')

    })
  }


}

showToast= (event, value, type) => {
  if(type == "false"){
    toast.error(value, {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

  }else{
    toast.success(value, {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
   
  }

}

  render (){ 
  return (
    <div className="px-9 py-9">
      <div>
        <h3 className="mb-6 mt-3  text-[#081F43] font-[Gotham-Medium] text-lg  ">
          Firma Bilgileri
        </h3>
        <div className="w-full">
          <InputField handleChange={(e)=> this.handleChange(e,'name')} type="text" typeCheck = {"name"} value={this.state.companyName} labelFiled={"Firma Adı"} />
          <InputField  handleChange={(e)=> this.handleChange(e,'sector')} type="text" typeCheck = {"sector"} value={this.state.sector} labelFiled={"Sektör"} />
          <InputField  handleChange={(e)=> this.handleChange(e,'foundationYear')} type="number" typeCheck = {"foundationYear"} disabled={this.state.foundationYearLength > 4 ? true : false} maxLength = "4"  value={this.state.foundationYear} labelFiled={"Kuruluş Yılı"} />
          <InputField  handleChange={(e)=> this.handleChange(e,'employeesNumer')} type="number" typeCheck = {"employeesNumer"}  disabled={this.state.foundationYearLength > 4 ? true : false} maxLength = "4" value={this.state.employeesNumer} labelFiled={"Çalışan Sayısı"} />

          <InputField  handleChange={(e)=> this.handleChange(e,'employeesNumber')} type="number" typeCheck = {"employeesNumber"}  value={this.state.employeesNumber} labelFiled={"Telefon Numarası"} />
          <InputField  handleChange={(e)=> this.handleChange(e,'employeesMail')} type="employeesMail" typeCheck = {"employeesMail"}  value={this.state.employeesMail} labelFiled={"Mail Adresi"} />
          {this.state.employeesTaxNumber != null ? <InputField  handleChange={(e)=> this.handleChange(e,'employeesOfficalUser')} type="employeesOfficalUser" typeCheck = {"employeesOfficalUser"}  value={this.state.employeesOfficalUser} labelFiled={"Yetkili Kişi"} /> : null}
          <InputField  handleChange={(e)=> this.handleChange(e,'employeesTaxNumber')} type="number" typeCheck = {"employeesTaxNumber"}  value={this.state.employeesTaxNumber} labelFiled={"Vergi Numarası"} />

   
          <h3 className="mt-8 mb-4 text-[#081F43] font-[Gotham-Medium] text-lg">
          Kurumsal Logo
        </h3>

                <div className="w-full">
          <div className="w-full  bg-white  justify-center items-center text-center">
            <div className="grid w-full h-full bg-zinc-200 border rounded-md ">
            <button className="flex justify-center items-center" onClick={e => this.enableInputFile(e, "photo")}>

                <MdSlowMotionVideo className="flex justify-center items-center w-14 h-14" />
             
             <p className="flex justify-center items-center text-[#081F43] text-lg hover:bg-white border-0 rounded-lg p-2 font-[Title]">
                  Fotoğraf Ekle
                </p>
             
              </button>
              <input
              key={"photo"}
              type="file"
              id={"photo"}
              className="hidden"
              onChange={e=> this.onFileChangePortfolyo(e,"photo")}
              data-name={"video"}
              accept="image/png, image/jpeg,image/webp"/>
                       

                 <ProgressBar value="0" max="100" key={1} bgcolor={'#081F43'} completed={this.state.loadingPercentagePhoto}  />



            <div className="flex justify-center items-center">
            {this.state.selectedImagePhoto.map((item, index) => ( 
              <div className="bg-white border-2 m-1 rounded-md ">
                <text className=" p-1 font-[Gotham-Medium]">{item.data}</text>
                <img src={item.imageData} alt="" className="w-20 px-2" />
            </div>
             ))}
              </div>
              {this.state.isPhotoLoading == true ? <button type="submit" className="bg-[#081F43] p-2 text-white rounded-lg" onClick={()=>this.onSubmit("photo")}>Dosyaları Yükle</button>: null}

                          </div>


          </div>


        </div>

        <h3 className="mt-8 mb-4 text-[#081F43] font-[Gotham-Medium] text-lg">
          Kurumsal Video
        </h3>
        <div className="w-full">
          <div className="w-full  bg-white  justify-center items-center text-center">
            <div className="grid w-full h-full bg-zinc-200 border rounded-md  ">
            <button className="flex justify-center items-center" onClick={e => this.enableInputFile(e, "video")}>

                <MdSlowMotionVideo className="flex justify-center items-center w-14 h-14" />
              
             <p className="flex justify-center items-center text-[#081F43] text-lg hover:bg-white border-0 rounded-lg p-2 font-[Title]">
                  Video Ekle
                </p>
             
              </button>
              <input
              key={"video"}
              type="file"
              id={"video"}
              className="hidden"
              onChange={e=> this.onFileChangePortfolyo(e,"video")}
              data-name={"video"}
              accept="video/mp4,video/x-m4v,video/*"/>
                       

             <ProgressBar value="0" max="100" key={1} bgcolor={'#081F43'} completed={this.state.loadingPercentageVideo}  />



            <div className="flex w-full">
            {this.state.selectedImageVideo.map((item, index) => ( 
              <div className="bg-white border-2 m-1 rounded-md w-full">
                <text className=" p-1 font-[Gotham-Medium]">{item.data}</text>
                {item.type == "video" ?
                 <div style={{ height: '50vh', width: '100%',zIndex:10 }} className="z-10 ex-collection-box mb-xl-20"> <ReactPlayer controls={true} width="100%" height="90%" url={item.imageData}/></div> :
                item.type == "document" ? 
                 null :
            <img src={item.imageData} alt="" className="w-20 px-2" />}
            </div>
             ))}
              </div>
              
              {this.state.isVideoLoading == true ? <button type="submit" className="bg-[#081F43] p-2 text-white rounded-lg" onClick={()=>this.onSubmit("video")}>Dosyaları Yükle</button>: null}

     </div>


          </div>


        </div>
        
        <h3 className="mt-8 mb-4 text-[#081F43] font-[Gotham-Medium] text-lg">Firma Adresiniz</h3>
        <div className="w-full ">
          <textarea
         onChange={(e)=> this.handleChange(e,"Employer_Company_AddressText")}
            className="border-2 border-[#EBECF8] h-[171px] w-full rounded-lg"
            value={this.state.Employer_Company_AddressText}
            placeholder={"Firma Adresinizi Giriniz"}
          />
        </div>

        {this.state.listCountries.length > 0  ?  <SelectOption   fullvalue={this.state.listCountries} handleChange={(e)=> this._getCity(e.value,e.label)} value={this.state.countriesName} selectLabel={"Ülke"} type={"countries"}  />: null}
          {this.state.listCity.length > 0  ? <SelectOption fullvalue={this.state.listCity} handleChange={(e)=> this._getDistrict(e.value)}  value={this.state.cityName} selectLabel={"İl"} type={"city"}  /> : null}
          {this.state.listDiscrict.length > 0  ? <SelectOption fullvalue={this.state.listDiscrict}  handleChange={(e)=> this._handleChange(e,"district")} value={this.state.districtName} selectLabel={"İlçe"} type={"district"}/> : null }


      </div>

      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>

<button className="bg-[#284B84] rounded-lg m-1 justify-center w-full items-center flex" onClick={(e)=> this._updateForm(e)}>
          <div className="bg-white rounded-lg justify-center item p-1">
          <FontAwesomeIcon icon={ faSave }   color={'#284B84'}/> 
          </div>  
          <a className="text-center text-white p-2 font-[Gotham-Medium]">Bilgileri Kaydet</a>
          </button>

<LoadingSpinner show={this.state.isLoading} setShow={this._setShow}/> 

    </div>
    </div>
  )
}
};

const MapWrapped = withScriptjs(withGoogleMap(Map));

export default withParamsAndNavigate(PersonelInformation);

