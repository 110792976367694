import React, { useState, useRef, useEffect } from "react";
import { FiPlus } from "react-icons/fi";
import Footer from "./Footer";
import Navbar from "./Navbar";
import axios from 'axios';
import { Alert } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

export default function App() {
  const [active, setActive] = useState(false);
  const [list, setList] = useState([]);
  const [data, setData] = useState({
    nameSurname:null,
    emailAdress:null,
    phoneNumber:null,
    subject:null,
    messageText:null
  });
  const FORM_ENDPOINT = ""; // TODO - fill on the later step

  const contentRef = useRef(null);
  const { pathname } = useLocation();

  React.useEffect(() => {
    const _getList= async() => {
      try{
        await axios.get(`https://getjob.stechomeyazilim.info:5101/getSSS/select`)
        .then((res) => {
           setList(res.data) 
           //setIsLoading(false)  
          })
  }
  catch (error) {
    console.log("_getListerro123r" + error)
  }
    }
    window.scrollTo(0, 0);
    
    _getList()
  
  },[pathname])
  
  useEffect(() => {
    

    if(list.length > 0) 
    { contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : "0px";}
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };

 const _postMessage= () => {
  showToast('GetJob', "Mesajınız başarıyla tarafımıza iletildi!", 'false')

 }

 

 const showToast= (event, value, type) => {
  if(type == "false"){
    toast.error(value, {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

  }else{
    toast.success(value, {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
      window.location.reload(false);

  }

}

  const onMessageChange= (event,type) => {
if(type == "mail"){
 setData({
  ...data,
  emailAdress: event.target.value 
 })
}
if(type == "phone"){
  setData({
    ...data,
    phoneNumber: event.target.value 
   })
}
if(type == "subject"){
  setData({
    ...data,
    subject: event.target.value 
   })
}
if(type == "name"){
  setData({
    ...data,
    nameSurname: event.target.value 
   })
}
if(type == "name"){
  setData({
    ...data,
    messageText: event.target.value 
   })
}
}
  return (

 <div  className="grid  h-full w-full">
      <Navbar />
      <div className="w-full flex flex-col mt-[145px] justify-center items-center p-2">
      {list.map((item, index) => (
        <button
            className={`m-2 p-2 question-section ${active}`}
            onClick={toggleAccordion}
          >
            <div>
              <div className="question-align">
                <h4 className="question-style">
                  {item.SSS_Title}
                </h4>
                <FiPlus
                  className={active ? `question-icon rotate` : `question-icon`}
                />
              </div>
              <div
                ref={contentRef}
                className={active ? `answer answer-divider` : `answer`}
              >
                <p>{item.SSS_Desc}</p>
              </div>
            </div>
          </button>
           
           ))
}
         
<div className="w-full">
                    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3147.5287839317607!2d32.5545263153334!3d37.91807547973371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d08fde0028003f%3A0x9a34903fadff627e!2sBuhara%20Baharat!5e0!3m2!1str!2str!4v1670849603678!5m2!1str!2str" className="border-0 w-screen	" allowFullScreen />
                </div>
              

  <div className="border-2 border-[#081F43] items-center justify-center rounded-lg w-1/2 m-10">
                        <div className="bg-[#081F43] justify-center items-center w-full">
                            <h6 className="text-light-green text-[30px] text-center  font-[Gotham-Medium] text-[white]">İletişim Formu</h6>
                            <h3 className="text-light-green text-[10px] text-center  font-[Gotham-Medium] text-[white]">GETJOB</h3>
                        </div>
                      
    <form
      action={FORM_ENDPOINT}
      onSubmit={_postMessage}
      method="POST"
      target="_blank"
    >
      <div className="m-5">
        <input
          type="text"
          placeholder="Adınız"
          name="name"
          className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white  rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
          required
        />
      </div>
      <div className="m-5">
        <input
          type="email"
          placeholder="Mail Adresiniz"
          name="email"
          className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
          required
        />
      </div>
      <div className="m-5">
        <textarea
          placeholder="Mesajınız"
          name="message"
          className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
          required
        />
      </div>
      <div className="m-5 justify-center items-center text-center">
        <button onClick={()=> _postMessage()}
          className="bg-[#081F43] text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="submit"
        >
          Gönder
        </button>
      </div>
    </form>
</div>

       
                
        </div>
        <Footer/>

        <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      </div>
   
  );
}
