import React,{ useEffect,useState } from "react";
import ReactPlayer from 'react-player';

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import mapStyles from './mapStyles'

function Map(props) {
  const [selectedPark, setSelectedPark] = useState(null);

  useEffect(() => {
    const listener = e => {
      if (e.key === "Escape") {
        setSelectedPark(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

  return (
   props.dealerData != null ?
    <GoogleMap
      defaultZoom={4}
      defaultCenter={{ lat: 48.8114633 , lng: 34.8990059}}
      options={{ styles: mapStyles }}>
        <Marker
          key={props.dealerData.ID}
          position={{
            lat: props.dealerData.position.lat,
            lng:props.dealerData.position.lng
          }}
          onClick={() => {
            setSelectedPark(props.dealerData.Employer_Map);
          }}
          icon={{
            url:  "https://img.icons8.com/color/48/000000/map-pin.png",
            scaledSize: new window.google.maps.Size(50, 50)
          }}
        >

<InfoWindow
          onCloseClick={() => {
            setSelectedPark(null);
          }}
          position={{
            lat: props.dealerData.position.lat,
            lng: props.dealerData.position.lng
          }}
        >
          <div>
            <h2 className="font-[Gotham-Medium] ">{props.dealerData.currentCity}</h2>
          </div>
        </InfoWindow>
        </Marker>

    </GoogleMap> : null 
    
  );
}
const CompanyEdit = (props) => {
  const [mapdata1, setMapData] = useState({
    mapDataFetch:null
  })

  useEffect(() => {
    if(props._item != null){
      setMapData({
        mapDataFetch:{
          ID :  props._item.ID,
              position:{
                lat:  props._item.Employer_Map.Job_Employer_Map_Lat,
                lng:  props._item.Employer_Map.Job_Employer_Map_Lng
          },
          currentCity:  props._item.Employer_Map.Job_Employer_Map_City,
          currentLatitude : props._item.Employer_Map.Job_Employer_Map_Lat,
          currentLongitude :  props._item.Employer_Map.Job_Employer_Map_Lng}}
      )
    }
  },[])

  return (
    props._item != null ?<div className="w-full flex flex-col">
    <div className="max-w-full mt-1 flex flex-col bg-[#F7F7F7] h-full  items-center  ">
      
       
        <div className="w-full bg-[#081F43] pl-3 flex flex-col text-white">
          <h2 className="text-xl  pt-2 font-bold ">{props._item.Employer_Company.Job_Employer_CompanyName}</h2>
          <p className="text-xs pb-2">{props._item.Employer_Company.Job_Employer_Company_FoundingYear}</p>
        </div>
        <div className="flex flex-col py-3 px-2 w-full">
          <h3 className="text-[#071E44] text-sm pt-3 font-[Gotham-Medium]">Firma Bilgileri</h3>
        </div>
        <div className="w-full flex flex-col px-2 pb-2 ">
          <div className="w-full flex justify-between text-xs py-1">
            <p className="text-[#868686] font-[Gotham-Medium]">Firma Adı</p>
            <p className="text-[#062CBC] font-[Gotham-Medium]">{props._item.Employer_Company.Job_Employer_CompanyName}</p>
          </div>
          <div className="w-full flex justify-between text-xs py-1">
            <p className="text-[#868686] font-[Gotham-Medium]">Sektör</p>
            <p className="text-[#062CBC] font-[Gotham-Medium]">{props._item.Employer_Company.Job_Employer_SectorName}</p>
          </div>
          <div className="w-full flex justify-between text-xs py-1">
            <p className="text-[#868686] font-[Gotham-Medium]">Kuruluş Yılı</p>
            <p className="text-[#062CBC] font-[Gotham-Medium]">{props._item.Employer_Company.Employer_Company_Foundation_Year}</p>
          </div>
          <div className="w-full flex justify-between text-xs py-1">
            <p className="text-[#868686] font-[Gotham-Medium]">Çalışan Sayısı</p>
            <p className="text-[#062CBC] font-[Gotham-Medium]">{props._item.Employer_Company.Job_Employer_EmployeesNumber}</p>
          </div>


          <div className="w-full flex justify-between text-xs py-1">
            <p className="text-[#868686] font-[Gotham-Medium]">Yetkili Kişi</p>
            <p className="text-[#062CBC] font-[Gotham-Medium]">{props._item.Employer_Company.Employer_Company_OfficalUser}</p>
          </div>

          <div className="w-full flex justify-between text-xs py-1">
            <p className="text-[#868686] font-[Gotham-Medium]">Yetkili Mail</p>
            <p className="text-[#062CBC] font-[Gotham-Medium]">{props._item.Employer_Company.Employer_Company_Mail}</p>
          </div>

          <div className="w-full flex justify-between text-xs py-1">
            <p className="text-[#868686] font-[Gotham-Medium]">Yetkili Telefon</p>
            <p className="text-[#062CBC] font-[Gotham-Medium]">{props._item.Employer_Company.Job_Employer_EmployeesNumber}</p>
          </div>

          <div className="w-full flex justify-between text-xs py-1">
            <p className="text-[#868686] font-[Gotham-Medium]">Vergi No</p>
            <p className="text-[#062CBC] font-[Gotham-Medium]">{props._item.Employer_Company.Employer_Company_Tax_Number}</p>
          </div>

          <div className="w-full flex justify-between text-xs py-1">
            <p className="text-[#868686] font-[Gotham-Medium]">Adres Bilgileri</p>
            <p className="text-[#062CBC] font-[Gotham-Medium]">{props._item.Employer_Company.Employer_Company_AddressText}</p>
          </div>
          
          <div className="w-full flex justify-between text-xs py-1">

          <p className="text-[#868686] font-[Gotham-Medium]">Konum Bilgileri</p>

<div className="flex">
            <p className="text-[#062CBC] font-[Gotham-Medium]">{props._item.Employer_Company.Employer_Company_Countries}</p>
            <p className="text-[#062CBC] font-[Gotham-Medium] pl-2">{props._item.Employer_Company.Employer_Company_City}</p>
            <p className="text-[#062CBC] font-[Gotham-Medium] pl-2">{props._item.Employer_Company.Employer_Company_District}</p>
            </div>

          </div>

          <h3 className="text-[#071E44] text-sm pt-3 pb-2 font-[Gotham-Medium]">Video</h3>

{props._item.Employer_Company.Employer_Company_Video != null ?   <div style={{ height: '50vh', width: '100%',zIndex:10 }} className="z-10 ex-collection-box mb-xl-20">
  <ReactPlayer controls={true} width="100%" height="100%" url={"https://getjob.stechomeyazilim.com/Uploads/"  + props._item.Employer_Company.Employer_Company_Video}/>
  </div> : <p className="text-[Gotham-Medium]">Video Yok</p>}

        </div>





      </div>
    
  
  </div> : null
  );
};

export default CompanyEdit;


