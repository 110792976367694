import React, { useState, useEffect,Component } from "react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import LoadingSpinner from "../../../LoadingSpinner";
import { FaKey } from "react-icons/fa";
import { AiFillEyeInvisible } from "react-icons/ai";
import { withParamsAndNavigate } from "../getParamsAndNavigate.js";

class PersonelInformation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPassword:null,
password:null,
checkPassword:null,
currentpasswordVisible:false,
passwordVisible:false,
checkPasswordVisible:false
    }
}


async componentDidMount() {
  await this._getDetail()
}

_getDetail = async () => {
  try{
  
    let loginData =  JSON.parse(localStorage.getItem('dataLogin'))
        
    console.log("lknaslkf123",loginData.Users_Password)
  
          this.setState({
            Users_Password : loginData.Users_Password,
            Users_Email:loginData.Users_Email,     
        })
          
  }
  catch (error) {
   console.log("_getDetailError" + error)
  }
  }

_openPassword= async (type) => {
  console.log("kj13bktype",type)
  if(type == "password"){
    this.setState({
    passwordVisible : !this.state.passwordVisible
    })
  }
  if(type == "currentpassword"){
    this.setState({
      currentpasswordVisible : !this.state.currentpasswordVisible
      })
  }
  if(type == "checkpassword"){
    this.setState({
      checkPasswordVisible : !this.state.checkPasswordVisible
      })
  }

}

_patchPassword= async () => {
  let loginData =  JSON.parse(localStorage.getItem('dataLogin'))
      
  console.log("lknaslkf123",loginData)

  if(this.state.currentPassword != null){
  if(loginData.Users_Password != this.state.currentPassword){
    this.showToast('GetJob', "Güncel Şifrenizi Yanlış Girdiniz!", 'false')
  }else{
    let formUpdate = {
      Users_Password : this.state.password,
      Users_Email:this.state.mailAdress,
    }
    axios.patch(`https://getjob.stechomeyazilim.info:5101/usersUpdate/${loginData.ID}`, formUpdate).then(async() => {
      this.showToast('GetJob', "Başarıyla Profiliniz Güncellendi!", 'success')

      localStorage.setItem('dataLogin', JSON.stringify({
        ID : loginData.ID,
        Users_NameSurname:loginData.Users_NameSurname,
        Users_Gender:loginData.Users_Gender,
        Users_Birthday:loginData.Users_Birthday,
        Users_Military:loginData.Users_Military,
        Users_Active:loginData.Users_Active,
        Users_Profile_Photo:loginData.Users_Profile_Photo,
        Users_Type_ID:loginData.Users_Type_ID,
        Users_TelephoneNumber:loginData.Users_TelephoneNumber,
        Users_Password:this.state.password,
        Users_Email:loginData.Users_Email,
        Users_RegisterType:loginData.Users_RegisterType
      }));

      
    })
  }  
}else{
  this.showToast('GetJob', "Güncel Şifrenizi Girmediniz!", 'false')
}

}

_setShow= async () => {

}

changeStatus = (status) => {
document.getElementById('status').innerHTML = status;
}


setProgress = (e) => {
const fr = e.target;
const loadingPercentage = 100 * e.loaded / e.total;

document.getElementById('progress-bar').value = loadingPercentage;
}

setProgressGradient = (e) => {
const fr = e.target;
const loadingPercentage = 100 * e.loaded / e.total;

document.getElementById('progress-bar-gradient').value = loadingPercentage;
}

loaded = (e) => {
const fr = e.target;
var result = fr.result;

this.changeStatus('Yükleme Tamamlandı!');
console.log('Result:', result);
}

errorHandler = (e) => {
this.changeStatus('Error: ' + e.target.error.name);
}

onSubmit= (type) => {
  this.setState({isLoading: true})
  if(type == "video"){
    const formData = { image: this.state.selectedImageVideo[0].imageData }
    let endpoint = "https://getjob.stechomeyazilim.info:5101/fileUpload/insert/";
     axios.post(endpoint, formData, {
     }).then(async(res) => {

        console.log('Fileploaded!',res.data);

        this.setState({isLoading: false,companyVideo:res.data})

        this.showToast('GetJob', "Başarıyla Görseller Yüklendi!", 'success')

    })
  }
  if(type == "photo"){
    console.log("sdjkfndlwks123",this.state.selectedImagePhoto)
    const formData = { image: this.state.selectedImagePhoto[0].imageData }
    let endpoint = "https://getjob.stechomeyazilim.info:5101/fileUpload/insert/";
     axios.post(endpoint, formData, {
     }).then(async(res) => {

        console.log('Fileploaded!',res.data);

        this.setState({isLoading: false,companyPhoto:res.data})

        this.showToast('GetJob', "Başarıyla Görseller Yüklendi!", 'success')

    })
  }


}

showToast= (event, value, type) => {
  if(type == "false"){
    toast.error(value, {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

  }else{
    toast.success(value, {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
      this.props.navigate(-1)
  }

}

  render (){ 
  return (
    <div className="px-9 py-9">
    

        <h3 className="mb-6 mt-3  text-[#081F43] font-bold text-lg  ">
          Kişisel Bilgiler
        </h3>
        <div className="w-full">
        <div className="flex w-full  shadow-xl  m-1 p-1">
              <div
                className="w-full flex py-3 bg-zinc-200  justify-center items-center   "
              >
                <FaKey className="ml-3 mr-7 flex justify-center items-center w-6 h-6 fill-green-600" />
                <input
                  type={this.state.currentpasswordVisible== true ? "text" : "password"}
                  value={this.state.currentPassword}
                  className="bg-zinc-200 w-full font-[Gotham-Medium]  outline-none focus:border-b-2 focus:border-blue-500 "
                  placeholder="Güncel Şifreniz"
                  onChange={e => this.setState({currentPassword : e.target.value})}
                />
                <button type="button" onClick={()=> this._openPassword("currentpassword")}  className=" px-3 flex ">
                  <div className="rounded-full hover:bg-white mr-2 justify-center items-center p-1 ">
                    <AiFillEyeInvisible className="w-6 h-6" />
                  </div>
                </button>
              </div>
            </div>
            
            <div className="flex w-full  shadow-xl  m-1 p-1">
              <div
                className="w-full flex py-3 bg-zinc-200  justify-center items-center   "
              >
                <FaKey className="ml-3 mr-7 flex justify-center items-center w-6 h-6 fill-green-600" />
                <input
                  type={this.state.passwordVisible== true ? "text" : "password"}
                  value={this.state.password}
                  className="bg-zinc-200 w-full font-[Gotham-Medium]  outline-none focus:border-b-2 focus:border-blue-500 "
                  placeholder="Yeni Şifreniz"
                  onChange={e => this.setState({password : e.target.value})}
                />
                <button onClick={()=> this._openPassword("password")} className=" px-3 flex ">
                  <div className="rounded-full hover:bg-white mr-2 justify-center items-center p-1 ">
                    <AiFillEyeInvisible className="w-6 h-6" />
                  </div>
                </button>
              </div>
            </div>

            <div className="flex w-full  shadow-xl  m-1 p-1">
              <div
                className="w-full flex py-3 bg-zinc-200  justify-center items-center   "
              >
                <FaKey className="ml-3 mr-7 flex justify-center items-center w-6 h-6 fill-green-600" />
                <input
                  type={this.state.checkPasswordVisible== true ? "text" : "password"}
                  value={this.state.checkPassword}
                  className="bg-zinc-200 w-full font-[Gotham-Medium]  outline-none focus:border-b-2 focus:border-blue-500 "
                  placeholder="Yeni Şifreniz Tekrar"
                  onChange={e => this.setState({checkPassword : e.target.value})}
                />
                <button onClick={()=> this._openPassword("checkpassword")}  className=" px-3 flex ">
                  <div className="rounded-full hover:bg-white mr-2 justify-center items-center p-1 ">
                    <AiFillEyeInvisible className="w-6 h-6" />
                  </div>
                </button>
              </div>
            </div>


            <div className="flex w-full  shadow-xl  m-1 p-1">
              <div
                className="w-full flex py-3 bg-zinc-200  justify-center items-center   "
              >
                <FaKey className="ml-3 mr-7 flex justify-center items-center w-6 h-6 fill-green-600" />
                <input
                  type={"text"}
                  value={this.state.mailAdress}
                  className="bg-zinc-200 w-full font-[Gotham-Medium]  outline-none focus:border-b-2 focus:border-blue-500 "
                  placeholder="Mail Adresiniz"
                  onChange={e => this.setState({mailAdress : e.target.value})}
                />
              
              </div>
            </div>
          
{/*           <InputField  handleChange={(e)=> this.handleChange(e,'sector')} type="text" typeCheck = {"mailAdress"} value={this.state.mailAdress} labelFiled={"Mail Adresiniz"} />
 */}          
       
          <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
</div>
<LoadingSpinner show={this.state.isLoading} setShow={this._setShow}/> 

<button onClick={()=> this._patchPassword()}  className="bg-[#081F43] mt-3 flex w-full rounded-md justify-center items-center">
                  <a className="font-[Title] p-1 text-[white] text-center">Bilgileri Güncelle</a>
                </button>

    </div>
  )
  }
};


export default withParamsAndNavigate(PersonelInformation);
