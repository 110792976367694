import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { HashRouter } from "react-router-dom";
import App from "./App";

import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
});
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <HashRouter>
  
  <I18nextProvider i18n={i18next}>
    <App />
    <WhatsAppWidget
			phoneNo="+905326208810"
			position="right"
			widgetWidth="300px"
			widgetWidthMobile="260px"
			autoOpen={false}
			autoOpenTimer={5000}
			messageBox={true}
			messageBoxTxt="Merhaba, bize ne sormak istersiniz?"
			iconSize="40"
			iconColor="white"
			iconBgColor="green"
			headerIcon="https://getjobweb.stechomeyazilim.com/static/media/logo.5695ba09a97168d908bb350b90c426f9.svg"
			headerIconColor="#c5aa77"
			headerTxtColor="white"
			headerBgColor="#081F43"
			headerTitle="GetJob"
			headerCaption="Online"
			bodyBgColor="#bbb"
			chatPersonName="Destek"
			chatMessage={<>Merhaba 👋 <br /><br /> Size Nasıl Yardımcı Olabilirim?</>}
			footerBgColor="#999"
			placeholder="Mesaj Türü.."
			btnBgColor="#0a4022"
			btnTxt="Konuşmaya Başla"
			btnTxtColor="white"
		/>
		</I18nextProvider>
  </HashRouter>
);