import React from 'react'
import Moment from 'moment'

const StarCard = ({ImgSource,ıconSource,labelBildirim,CompanyName,time,Notifacation_Type_ID,updateEmployer,ID,Job_Employer_CompanyName,Users_NameSurname,Employer_ID,Employer_Company_Image,Users_Profile_Photo,Sender_Users_ID}) => {
  return (
    <div className="w-full h-full flex flex-col p-1 border-b ">
      
          <div className=" w-full flex flex-col  py-1 px-4 rounded-lg ">
            <div className="w-full flex  justify-start ">
              <div className="w-full flex py-2 px-2">
                <img src={ImgSource} alt="" className="w-16 h-16 rounded-full outline outline-1 outline-blue-500 " />
                <div className="w-full flex flex-wrap pl-3  text-[#07043B] text-md">
                  <p>
                    <span className="font-[Gotham-Medium] text-blue-900">{CompanyName}</span> <span  className="font-[Gotham-Medium]">{labelBildirim}</span> <span className='text-gray-500 font-[Gotham-Medium] text-xs ml-1'>{Moment(time).fromNow()}</span>
                  </p>
                  
                </div>
                <div className='w-10 h-10 flex justify-center items-center mx-auto my-auto'>
                    {ıconSource}
                </div>
              </div>
            </div>
            
          </div>
         { Notifacation_Type_ID == 2 || Notifacation_Type_ID == 5 ?
          <div className='flex justify-center items-center '>
            <button onClick={()=> updateEmployer(true,ID,Job_Employer_CompanyName,Users_NameSurname,Employer_ID,Employer_Company_Image,Users_Profile_Photo,Sender_Users_ID)} className='py-1 px-5 rounded-lg bg-blue-900 mr-4 text-white hover:bg-blue-200 hover:outline  hover:outline-2  hover:outline-blue-900 hover:text-blue-900'>Kabul Et</button>
            <button onClick={()=> updateEmployer(false,ID,Job_Employer_CompanyName,Users_NameSurname,Employer_ID,Employer_Company_Image,Users_Profile_Photo,Sender_Users_ID)} className='py-1 px-5 rounded-lg bg-red-700 mr-4 text-white hover:bg-red-200 hover:outline  hover:outline-2  hover:outline-red-700 hover:text-red-700 '>Reddet</button>
          </div> : null}
        </div>
  )
}

export default StarCard