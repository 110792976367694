import "./App.css";
import React from "react";

import MainPage from "./components/MainPage/MainPage";

import EmployeeFormSettings from "./components/EmployeeForm/EmployeeFormSettings";
import EmployerFormSettings from "./components/EmployeeForm/EmployerFormSettings";

import CompanyEdit from "./components/EmployeeForm/CompanyEdit";
import EmployerFormSettingsMailPassword from "./components/EmployeeForm/EmployerFormSettingsMailPassword";
import EmmployeeForm from "./components/EmployeeForm/EmployeeForm";
import EmmployeeFormNew from "./components/EmployeeForm/EmmployeeFormNew";


import JobSeekerForm from "./components/EmployeeForm/JobSeekerForm";
import Courses from "./components/Courses/Courses";
import EmployeeJobs from "./components/Jobs/EmployeeJobs/EmployeeJobs";
import EmployerJobs from "./components/Jobs/EmployerJobs/EmployerJobs";
import BlogDetail from "./components/Blog/BlogDetail/BlogDetail";
import JobsDetail from "./components/Jobs/JobsDetail/JobsDetail";
import MessagesEmployer from "./components/Messages/MessagesEmployer";
import MessagesJobSeeker from "./components/Messages/MessagesJobSeeker";

import EmployeeNewForm from "./components/NewForm/EmployeeNewForm";
import EmployerNewForm from "./components/NewForm/EmployerNewForm";
import EmployerNewForm2 from "./components/NewForm/EmployerNewForm2";

import SSS from "./components/SSS";
import { Routes, Route } from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";


import 'moment/locale/tr';

const resources = {
  en: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
      "findright": "Find the right fit",
      "popularsearch" : "Popular Searches",
      "önecikanyet" : "Featured Talents",
      "isbul" : "Find a Job",
      "yetenekler" : "Abilities",

      "kayitol" : "Register",
      "giris" : "Login",
      "profilgit" : "Go to Profile",
      "sss" : "Faqs",
      "mailsifredeg" : "Change Mail&Password",
      "hesapdüzenle" : "Edit My Account",
      "bildirim" : "Notification",
      "hesapdondur" : "Freeze My Account",
      "hesapsil" : "Delete My Account",
      "cikis" : "Log out",
      "seemorejob" : "See More",
      "aradıgınızisler" : "Aradığınız İşler",
      "arıyor" : "Finding",

      "firmabilgileri" : "Firma Bilgileri",
      "firmaadi" : "Firma Adı",
      "sektor" : "Sektör",
      "kurulusyili" : "Kuruluş Yılı",
      "calisansayisi" : "Çalışan Sayısı",
      "sirketnumarasi" : "Şirket Numarası",
      "sirketmail" : "Şirket Mail",
      "sirketcalisansayi" : "Şirket Çalışan Sayısı",
      "yetkilikisi" : "Yetkili Kişi",

      "gereklisartlar" : "Gerekli Şartlar",
      "aranilanpozisyon" : "Aranılan Pozisyon",
      "dil" : "Dil",

      "meslek" : "Meslek",
      "deneyimyili" : "Deneyim yılı",
      "maas" : "Maaş",
      "egitimdurumu" : "Eğitim Durumu",

      "calismasekli" : "Çalışma Şekli",
      "onyazi" : "Ön Yazı",
      "calismamodeli" : "Çalışma Modeli",
      "konumum" : "Konumum",

      "basvurdunuz" : "BAŞVURDUNUZ",
      "Benzerisler" : "Benzer işlere başvurmak için tıklayınız",

      "profiligizle" : "Profili Gizle",
      "smsbildirim" : "SMS Bildirim",
      "mailbildirim" : "Mail Bildirim",
      "kisielbilgiler" : "Kişisel Bilgiler",
      "adsoyad" : "Ad Soyad",
      "dogumtarihi" : "Doğum Tarihi",
      "cinsiyet" : "Cinsiyet",
      "ehliyet" : "Ehliyet",
      "ililce" : "İl - İlçe",
      "askerlikdurumu" : "Askerlik Durumu",
      "egitimdurumu" : "Eğitim Durumu",
      "bilgigirilmedi" : "Bilgi Girilmedi",
      "calismagecmisi" : "Çalışma Geçmişi",
      "firmabilgileri" : "Firma Bilgileri",
      "firmaadi" : "Firma Adı",
      "sektor" : "Sektör",
      "kurulusyasi" : "Kuruluş Yılı",
      "calisansayisi" : "Çalışan Sayısı",
      "yetkilikisi" : "Yetkili Kişi",
      "yetkilimail" : "Yetkili Mail",
      "yetkilitelefon" : "Yetkili Telefon",

      "vergino" : "Vergi No",
      "adresbilgileri" : "Adres Bilgileri",
      "bilgileriduzenle" : "Bilgileri Düzenle",
      "sil" : "Sil",


      "greklisartlar" : "Gerekli Şartlar",
      "aranilanpozisyonlar" : "Aranılan Pozisyon",
      "kackisiisealacaksin" : "Kaç Kişi İşe Alacaksınız?",
      "dil" : "Dil",
      "meslek" : "Meslek",
      "deneyimyili" : "Deneyim Yılı",
      "maas" : "Maaş",
      "egitimdurumu" : "Eğitim Durumu",
      "calismasekli" : "Çalışma Şekli",
      "calismaturu" : "Çalışma Türü",
      "onyazi" : "Ön Yazı",
      "filtre" : "Filtre",
      "cinsiyet" : "Cinsiyet",
      "askerlikdurumu" : "Askerlik Durumu",
      "baslangicyasi" : "Başlangıç Yaşı",
      "bitisyasi" : "Bitiş Yaşı",
      "ehliyerdurumu" : "Ehliyet Durumu",
      "mezuniyetdurumu" : "Mezuniyet Durumu",
      "video" : "Video",
      "aciklama" : "Açıklama",
      "sertifika" : "Sertifika",
      "videoyok" : "Video Yok",
      "konumugoruntule" : "Konumu Görüntüle",
      "courses" : "Courses"

    }
  },
  tr: {
    translation: {
      "Welcome to React": "HG and react-i18next",
      "findright": "Doğru uyumu bulun",
      "popularsearch" : "Populer Aramalar",
      "önecikanyet" : "Featured Talents",
      "isbul" : "İş Bul",
      "yetenekler" : "Yetenekler",

      "kayitol" : "Kayıt Ol",
      "giris" : "Giriş Yap",
      "profilgit" : "Profile Git",
      "sss" : "SSS",
      "mailsifredeg" : "Mail&Şifre Değiştir",
      "hesapdüzenle" : "Hesabımı Düzenle",
      "bildirim" : "Bildirim",
      "hesapdondur" : "Hesabımu Dondur",
      "hesapsil" : "Hesabımı Sil",
      "cikis" : "Çıkış Yap",

      "seemorejob" : "Daha Fazla İşveren",
      "aradıgınızisler" : "Aradığınız İşler",
      "arıyor" : "Arıyor",

      "firmabilgileri" : "Firma Bilgileri",
      "firmaadi" : "Firma Adı",
      "sektor" : "Sektör",
      "kurulusyili" : "Kuruluş Yılı",
      "calisansayisi" : "Çalışan Sayısı",
      "sirketnumarasi" : "Şirket Numarası",
      "sirketmail" : "Şirket Mail",
      "sirketcalisansayi" : "Şirket Çalışan Sayısı",
      "yetkilikisi" : "Yetkili Kişi",

      "gereklisartlar" : "Gerekli Şartlar",
      "aranilanpozisyon" : "Aranılan Pozisyon",
      "dil" : "Dil",

      "meslek" : "Meslek",
      "deneyimyili" : "Deneyim yılı",
      "maas" : "Maaş",
      "egitimdurumu" : "Eğitim Durumu",

      "calismasekli" : "Çalışma Şekli",
      "onyazi" : "Ön Yazı",
      "calismamodeli" : "Çalışma Modeli",
      "konumum" : "Konumum",

      "basvurdunuz" : "BAŞVURDUNUZ",
      "Benzerisler" : "Benzer işlere başvurmak için tıklayınız",

      "profiligizle" : "Profili Gizle",
      "smsbildirim" : "SMS Bildirim",
      "mailbildirim" : "Mail Bildirim",
      "kisielbilgiler" : "Kişisel Bilgiler",
      "adsoyad" : "Ad Soyad",
      "dogumtarihi" : "Doğum Tarihi",
      "cinsiyet" : "Cinsiyet",
      "ehliyet" : "Ehliyet",
      "ililce" : "İl - İlçe",
      "askerlikdurumu" : "Askerlik Durumu",
      "egitimdurumu" : "Eğitim Durumu",
      "bilgigirilmedi" : "Bilgi Girilmedi",
      "calismagecmisi" : "Çalışma Geçmişi",

      "firmabilgileri" : "Firma Bilgileri",
      "firmaadi" : "Firma Adı",
      "sektor" : "Sektör",
      "kurulusyasi" : "Kuruluş Yılı",
      "calisansayisi" : "Çalışan Sayısı",
      "yetkilikisi" : "Yetkili Kişi",
      "yetkilimail" : "Yetkili Mail",
      "yetkilitelefon" : "Yetkili Telefon",

      "vergino" : "Vergi No",
      "adresbilgileri" : "Adres Bilgileri",
      "bilgileriduzenle" : "Bilgileri Düzenle",
      "sil" : "Sil",


      "greklisartlar" : "Gerekli Şartlar",
      "aranilanpozisyonlar" : "Aranılan Pozisyon",
      "kackisiisealacaksin" : "Kaç Kişi İşe Alacaksınız?",
      "dil" : "Dil",
      "meslek" : "Meslek",
      "deneyimyili" : "Deneyim Yılı",
      "maas" : "Maaş",
      "egitimdurumu" : "Eğitim Durumu",
      "calismasekli" : "Çalışma Şekli",
      "calismaturu" : "Çalışma Türü",
      "onyazi" : "Ön Yazı",
      "filtre" : "Filtre",
      "cinsiyet" : "Cinsiyet",
      "askerlikdurumu" : "Askerlik Durumu",
      "baslangicyasi" : "Başlangıç Yaşı",
      "bitisyasi" : "Bitiş Yaşı",
      "ehliyerdurumu" : "Ehliyet Durumu",
      "mezuniyetdurumu" : "Mezuniyet Durumu",
      "video" : "Video",
      "aciklama" : "Açıklama",
      "sertifika" : "Sertifika",
      "videoyok" : "Video Yok",
      "konumugoruntule" : "Konumu Görüntüle",
      "courses" : "Kurslar"



    },
  }
}
function App() {

  React.useEffect(() => {
    const installLanguage = async() => {
      let dataLanguage =  localStorage.getItem("language")
    
      if(dataLanguage==null){
        
        i18n.use(initReactI18next).init({
          resources,
          lng: "tr"
        });
      }else{
        i18n.use(initReactI18next).init({
          resources :resources ,
          lng: dataLanguage
        });
      }
    }

    installLanguage()

  }, [])




  return (
    <Routes >
      
      <Route exact path="/" element={<MainPage/>}/>
      <Route path="/employeeınfo/:id" element={<EmmployeeForm/>}/>
      
      <Route path="/employeeinfo/:id" element={<EmmployeeFormNew/>}/>

      <Route path="/jobSeekerInfo/:id" element={<JobSeekerForm/>}/>
      <Route path="/settings/:id" element={<EmployeeFormSettings/>}/>
      <Route path="/settingemployee/:id" element={<EmployerFormSettings/>}/>
      <Route path="/companyEdit/:id" element={<CompanyEdit/>}/>

      <Route path="/settingemployeeMailAndPassword/:id" element={<EmployerFormSettingsMailPassword/>}/>
      <Route path="/blog" element={<BlogDetail/>}/>
      <Route path="/courses" element={<Courses/>}/>
      <Route path="/employerDetail/:id" element={<JobsDetail/>}/>
      <Route path="/jobs" element={<EmployeeJobs/>}/>
      <Route path="/jobsJobSeeker" element={<EmployerJobs/>}/>

       <Route path="/messagesEmployer" element={<MessagesEmployer/>}/> 
        <Route path="/messagesJobSeeker" element={<MessagesJobSeeker/>}/> 
      <Route path="/employee" element={<EmployeeJobs/>}/>

      <Route path="/newJobSeeker" element={<EmployeeNewForm/>}/>
      <Route path="/newEmployer" element={<EmployerNewForm/>}/>
      <Route path="/newEmployer2" element={<EmployerNewForm2/>}/>

      <Route path="/sss" element={<SSS/>}/>


    </Routes>
  );
}
export default App;
