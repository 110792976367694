import React, { useState } from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";


import EditPasswordEmployer from "./Edit/EditPasswordEmployer";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from "react-router-dom";

const EmployeeFormSettings = (props) => {
  const [form, setForm] = useState({})
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state;

  const showToast = (value, type) => {
    if (type == "false") {
      toast.error(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // window.location.reload(false);

    } else {
      toast.success(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // window.location.reload(false);
      //  navigate(-1);
    }
  }
  const _closeForm = async () => {
    navigate(-1);
  }


  const _updateForm = async () => {
    setLoading(true)
    try {
      axios.delete(`https://getjob.stechomeyazilim.info:5101/employerEducationDelete/${state._item.ID}`)
      axios.delete(`https://getjob.stechomeyazilim.info:5101/employerLanguageDelete/${state._item.ID}`)
      axios.delete(`https://getjob.stechomeyazilim.info:5101/employerJobWayOutDelete/${state._item.ID}`)

      console.log("ln12l3123", form.Employer_Education, form.Employer_JobWay, form.Employer_Language)



      await axios.patch(`https://getjob.stechomeyazilim.info:5101/employerCompanyPatch/${state._item.Employer_Company.ID}`, form.companyData)
        .then(async (res) => {


          let mapData = {
            Job_Seeker_Map_Lat: form.Job_Employer_Map_Lat,
            Job_Seeker_Map_Lng: form.Job_Employer_Map_Lng,
            // Job_Seeker_Map_Distance: this.state.sliderValue,
            Job_Employer_Map_City: 'Konya',
          }

          axios.post('https://getjob.stechomeyazilim.info:5101/insertEmployerMap/send', mapData)

          form.Employer_Education.map(async (data) => {
            let educationData = {
              Employer_Education_Status: data.value,
              Employer_ID: state._item.ID
            }
            axios.post('https://getjob.stechomeyazilim.info:5101/insertEmployerEducation/send', educationData)
          })




          axios.post('https://getjob.stechomeyazilim.info:5101/insertEmployerFilter/send', form.filterData).then(async (resFilter) => {

            let employerData = {
              Job_Experience_ID: form.Employer.jobExpreinceID,
              Employer_Salery_Expection_ID: form.Employer.jobSaleryID,
              Employer_Desc: form.Employer.desc,
              Job_ID: form.Employer.jobListID,
              Employer_Filter_ID: resFilter.data.value[0].ID
            }

            await axios.patch(`https://getjob.stechomeyazilim.info:5101/employerPatch/${state._item.ID}`, employerData)


          })

          form.Employer_JobWay.map(async (data) => {
            console.log("data12312", data.label)
            let jobWayData = {
              Employer_JobWay_Text: data.label,
              Employer_ID: state._item.ID
            }
            axios.post('https://getjob.stechomeyazilim.info:5101/insertEmployerJobWayOut/send', jobWayData)
          })


          form.Employer_Language.map(async (data) => {
            let languageData = {
              Job_Employer_Language: data.value,
              Job_Employer_ID: state._item.ID
            }
            axios.post('https://getjob.stechomeyazilim.info:5101/insertEmployerLanguage/send', languageData)
          })


          navigate('/employeeınfo/' +  state._item.Users_ID, { state: { _item: { ID: state._item.Users_ID } } })

          setLoading(false)
          showToast('Başarıyla Bilgileriniz Güncellendi', true)
        })
    }
    catch (error) {
      console.log("_updateForm1231" + error)
    }
  }

  const _updateFormProps = async (patchData) => {
    setForm(patchData)

  }
  return (

    <div>
      <Navbar />
      <div className="w-full  bg-[#F7F7F7] flex flex-col justify-between">
        <div className="max-w-full mt-40 flex flex-col justify-center">
          <h3 className="text-xl text-center px-auto mt-6 text-blue-800 font-bold ">
            Profil Bilgilerisini Düzenle
          </h3>
          <div className=" border rounded-2xl bg-white w-3/5 mx-auto h-full mb-28 mt-12 flex  flex-col  ">
            <EditPasswordEmployer item={state._item} _patchForm={_updateFormProps} />
          </div>
          <Footer />
        </div>
      </div>


    </div>
  );
};

export default EmployeeFormSettings;
