import React from 'react'
import ProfiePhoto from "../../assets/ProfilePhoto.svg";

import eye from "../../assets/eye.svg";
import { Switch } from "@headlessui/react";
import { useState } from "react";
import Logo from "../../assets/Navbar/logo.svg";

const FirstBorderSettings = () => {
    const [enabled, setEnabled] = useState(false);
  return (
    <div className="w-full flex justify-center  border-b-4  border-yellow-500  items-center">
      <div className="py-4 pl-6">
      <img src={Logo} alt="/" className="w-15" />
      </div>
      <div className="flex flex-col pt-3 pr-3 justify-end mb-1">
        
       
      </div>
    </div>
  )
}

export default FirstBorderSettings