import React, { Component } from 'react';
import OtpInput from 'react18-input-otp';


import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '2px solid #000',
  boxShadow: 24,
  alignItems: 'center',
  justifyContent: 'center',
};

class OtpPageModalMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otpCode:null,
      profileScreenVisible:false,
      alertStatus:false,
      alertMessage:"",
      otpCodeInput:null,
      dialogStyle:'',
      isLoading:false,
      timer: 30,
      otp:null
    };
  }


_setSmsOtp= () => {
  this.setState({
    timer:5
  },()=> {
    this.interval = setInterval(
      () => this.setState((prevState)=> ({ timer: prevState.timer - 1 })),
      1000
    )
    this.props._setSmsOtp()
  })

}

handleChangeOtp= (text) => {
  this.setState({
  otp:text
  })
}



    _closeAlert= async() => {
      this.setState({
        alertStatus:false
      })
    }
 

  
  _setOtpCode= async(val)=>{
    this.setState({
      otpCodeInput:val
    })
  }



  render() {
    return (
      <Modal
      open={this.props._imageModalVisible}
      onClose={this.props._closeImageModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className='bg-white justify-center items-center ' sx={style}>

      

<div className='justify-center items-center bg-[#081F43] text-center'>
            <p className='font-[Gotham-Medium] text-white p-5 text-center'>GetJob Doğru Kişiyi, Doğru İşle Buluşturur!</p>
        </div>
                
        <img
              src={"https://getjob.stechomeyazilim.com/Uploads/" + this.props._imageUrl}
              alt=""
              className="  flex justify-center items-center w-full"
            />


      </Box>
     </Modal>
    );
  }
}


export default OtpPageModalMail;
