import React, { useState } from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";

import PersonelInformationEmployer from "./PersonelInformationEmployer";
import FirstBorderSettings from "./FirstBorderSettings";
import UpdateCancelButton from "./UpdateCancelButton";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from "react-router-dom";

const EmployeeFormSettings = (props) => {
  const [form, setForm] = useState({})
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state;

  const showToast = (value, type) => {
    if (type == "false") {
      toast.error(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // window.location.reload(false);

    } else {
      toast.success(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // window.location.reload(false);
      //  navigate(-1);
    }
  }
  const _closeForm = async () => {
    navigate(-1);
  }


  const _updateFormProps = async (patchData) => {
    setForm(patchData)
  }
  return (

    <div>
      <Navbar />
      <div className="w-full  bg-[#F7F7F7] flex flex-col justify-between">
        <div className="max-w-full mt-40 flex flex-col justify-center">
          <h3 className="text-xl text-center px-auto mt-6 text-blue-800 font-bold ">
            İlan Bilgilerini Düzenle
          </h3>
          <div className=" border rounded-2xl bg-white w-3/5 mx-auto h-full mb-28 mt-12 flex  flex-col  ">
            <FirstBorderSettings item={state._item} />
            <PersonelInformationEmployer item={state._item} _patchForm={_updateFormProps} />
          </div>
          <Footer />
        </div>
      </div>


    </div>
  );
};

export default EmployeeFormSettings;
