import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import "./Agora.css";
import { options, rtc } from "./constants";
import AgoraRTC from "agora-rtc-sdk-ng";
import axios from "axios"
import { BsCamera } from "react-icons/bs";
import Moment from 'moment';

const {RtcTokenBuilder, RtmTokenBuilder, RtcRole, RtmRole} = require('agora-access-token')

function App(props) {

  let channelNameNew = props._companyName.replace(" ", "_") + props._companyID

  const  handleSubmitNew= async() => {
  const appId = '8e909c0ca29c4796b1d322faa1239fbd';
const appCertificate = '7e63582b631e4f11ad0033769ff7440f';
const channelName = `${channelNameNew}`;
const uid = 0;
const role = RtcRole.PUBLISHER;
const expirationTimeInSeconds = 3600
const currentTimestamp = Math.floor(Date.now() / 1000)
const privilegeExpiredTs = currentTimestamp + expirationTimeInSeconds
// Build token with uid
const tokenA = RtcTokenBuilder.buildTokenWithUid(appId, appCertificate, channelName, uid, role, privilegeExpiredTs);
console.log("Token with integer number Uid: " + tokenA);
props.openVideoCall()
handleSubmit(tokenA)
  }

  const  handleSubmit= async(token) => {

    try {
      if (channelNameNew === "") {
        return console.log("Please Enter Channel Name");
      }


      setJoined(true);

      rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
      const uid = await rtc.client.join(
        token,
        channelNameNew,
        options.token,
        null
      );

      // Create an audio track from the audio captured by a microphone
      rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      // Create a video track from the video captured by a camera
   //   rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();

     // rtc.localVideoTrack.play("local-stream");

      rtc.client.on("user-published", async (user, mediaType) => {
       
        // Subscribe to a remote user
        await rtc.client.subscribe(user, mediaType);

        if (mediaType === "audio") {
          // Get `RemoteAudioTrack` in the `user` object.
          const remoteAudioTrack = user.audioTrack;
          // Play the audio track. Do not need to pass any DOM element
          remoteAudioTrack.play();

          remoteAudioTrack.remoteUid=user.uid;
          remoteAudioTrack.remoteAudioTrack = user.audioTrack;
        }
      });

      rtc.client.on("user-unpublished", (user) => {
        // Get the dynamically created DIV container
        const playerContainer = document.getElementById(user.uid);
        console.log(playerContainer);
        // Destroy the container
        playerContainer.remove();
      });

      // Publish the local audio and video tracks to the channel
      await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);

      console.log("publish success!");
    
    } catch (error) {
      console.error(error);
    } 
  }

  async function handleLeave() {
    try {
      const localContainer = document.getElementById("local-stream");

      rtc.localAudioTrack.close();
      //await AgoraRTC.leave();

      
      setJoined(false);
      localContainer.textContent = "";

      // Traverse all remote users
      rtc.client.remoteUsers.forEach((user) => {
        // Destroy the dynamically created DIV container
        const playerContainer = document.getElementById(user.uid);
        playerContainer && playerContainer.remove();
      });
      props.openVideoCall()

      // Leave the channel
      await rtc.client.leave();
    } catch (err) {
      console.error(err);
    }
  }
  const [joined, setJoined] = useState(false);
  const channelRef = useRef("");
  const remoteRef = useRef("");
  const leaveRef = useRef("");


  var localAppoinment =  Moment(props._startDate).format('YYYY-MM-DD HH:mm')
  var dateAndTime = Moment()
  var lastHours =  Moment(props._startDate).add(30,'minute').format('YYYY-MM-DD HH:mm')
  let aktif = Moment(dateAndTime).isBetween(localAppoinment, lastHours); // true

  
	const Ref = useRef(null);

	// The state for our timer
	const [timer, setTimer] = useState('00:00:00');


	const getTimeRemaining = (e) => {
		const total = Date.parse(e) - Date.parse(new Date());
		const seconds = Math.floor((total / 1000) % 60);
		const minutes = Math.floor((total / 1000 / 60) % 60);
		const hours = Math.floor((total / 1000 / 60 / 60) % 24);
		return {
			total, hours, minutes, seconds
		};
	}


	const startTimer = (e) => {
		let { total, hours, minutes, seconds }
					= getTimeRemaining(e);
		if (total >= 0) {

			// update the timer
			// check if less than 10 then we need to
			// add '0' at the beginning of the variable
			setTimer(
				(hours > 9 ? hours : '0' + hours) + ':' +
				(minutes > 9 ? minutes : '0' + minutes) + ':'
				+ (seconds > 9 ? seconds : '0' + seconds)
			)
		}
	}


	const clearTimer = (e) => {

		// If you adjust it you should also need to
		// adjust the Endtime formula we are about
		// to code next	
		setTimer('00:00:10');

		// If you try to remove this line the
		// updating of timer Variable will be
		// after 1000ms or 1sec
		if (Ref.current) clearInterval(Ref.current);
		const id = setInterval(() => {
			startTimer(e);
		}, 1000)
		Ref.current = id;
	}

	const getDeadTime = () => {
		let deadline = new Date();

		// This is where you need to adjust if
		// you entend to add more time
		deadline.setSeconds(deadline.getSeconds() + 10);
		return deadline;
	}

	// We can use useEffect so that when the component
	// mount the timer will start as soon as possible

	// We put empty array to act as componentDid
	// mount only
	useEffect(() => {
		clearTimer(getDeadTime());
	}, []);

	// Another way to call the clearTimer() to start
	// the countdown is via action event from the
	// button first we create function to be called
	// by the button
	const onClickReset = () => {
		clearTimer(getDeadTime());
	}

 
  return (
    
    <>
      <div className="container">
      
     

      {joined == false  ? <button  className=" w-full flex justify-center items-center"  onClick={handleSubmitNew}>
      <BsCamera color="white" className="flex justify-center items-center w-6 h-6 " />
       <a className="font-[Gotham-Medium] text-[white] ml-2">Sesli Görüşmeyi Başlat</a>
         </button>:  joined == true ? <button  onClick={handleLeave}>
       <a className="font-[Gotham-Medium] text-[white] ml-2">Sesli Görüşmeyi Sonlandır</a>
         </button>: <div className="text-[white] font-[Title]">Süreniz Bitmiştir</div>}
      
      </div>
      {joined ? (
       <div  className="w-full h-full justify-center items-center">
       <div className="font-[Title] text-[white] text-center">{timer}</div>
    </div>
      ) : null}
    </>
  );
}

export default App;
