import React from "react";

import eye from "../../assets/eye.svg";
import { Switch } from "@headlessui/react";
import { useState } from "react";
import { Link } from 'react-router-dom';
import { faShare, faTrash, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import {useNavigate } from "react-router-dom";
import ImageModal from './imageModal'

const FirstBorder = (props) => {
  const [enabled, setEnabled] = useState(false);
  const [enabledSms, setEnabledSms] = useState(false);
  const [enabledMail, setEnabledMail] = useState(false);
  const [id, setEnabledID] = useState(false);
  const navigate = useNavigate();

  const [check, setCheck] = useState(false);


  const [getImageVisible, setImageModalVisible] = useState(false);
  const [getImageUrl, setImageUrl] = useState();

  React.useEffect(() => {
    const _getSetting= async() => {
      try{
        let loginData =  JSON.parse(localStorage.getItem('dataLogin'))
    
       
       await axios.get(`https://getjob.stechomeyazilim.info:5101/getUserNotSetting/select/${loginData.ID}`)
       .then(async (res) => {
        console.log("lsndflk",res)
        if(res.data.length>0){
          setCheck(true)
          setEnabledSms(res.data[0].Sms_Notification)
          setEnabledID(res.data[0].ID)
          setEnabled(res.data[0].Profile_Seen)
          setEnabledMail(res.data[0].Mail_Notification)
        }else{
          setCheck(false)
        }
       })
      } catch (error) {
        console.log("_getList" + error)
      }
    }

    _getSetting()

  }, [])


  const _deleteUsers = () => {
    try{
    let loginData =  JSON.parse(localStorage.getItem('dataLogin'))
   // axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteUsers(${loginData.ID})`)

     props._openToast("delete")
    } catch (error) {
      console.log("_getList" + error)
    }

  }
  const _shareApp = async() => {
    navigator.clipboard.writeText(window.location.href)
    props._openToastModal()
  }
  
  
  const _patchOrPostCheck = async(e,type) => {
    console.log("asjndfk",e)
    if(type=="mail"){
      setEnabledMail(e)
    }
    if(type=="profile"){
      setEnabled(e)
    }
    if(type=="sms"){
      setEnabledSms(e)
    }
    try{
    let loginData =  JSON.parse(localStorage.getItem('dataLogin'))

    console.log("skdjnf",enabledSms,enabled,enabledMail)
    let data = {
      Users_ID:parseInt(loginData.ID),
      Sms_Notification:enabledSms,
     // Job_Notification:
      Profile_Seen:enabled,
      Mail_Notification:enabledMail
    }
    

   if(check == true){
    console.log("kjsbdfjk",id)
    await axios.patch(`https://getjob.stechomeyazilim.info:5101/patchUserNotification/${id}`, data)
    .then(async (res) => {
     props._openToast("patch")
    })
   }else{

    let data = {
      Users_ID:parseInt(loginData.ID),
      Sms_Notification:enabledSms,
     // Job_Notification:
      Profile_Seen:enabled,
      Mail_Notification:enabledMail
    }
    
    await axios.post(`https://getjob.stechomeyazilim.info:5101/postUsersNotificationSetting/send`, data) .then((res) => {
     
    
       })
        
   }

  } catch (error) {
    console.log("_getList" + error)
  }

  }
  
  const _setImageModal=(imgUrl)=> {
    setImageUrl(imgUrl)
    setImageModalVisible(true)
  }

  const _passsiveUsers = async() => {
    try{
    let loginData =  JSON.parse(localStorage.getItem('dataLogin'))

   let user = {
    Users_Active :false
   }
   
   await axios.patch(`https://getjob.stechomeyazilim.info:5101/usersUpdate/${loginData.ID}`, user)
   .then(async (res) => {
    props._openToast("patch")
   })
  } catch (error) {
    console.log("_getList" + error)
  }

  }

  const _closeImageModal= () => {
    setImageModalVisible(false)
  }
  
  const _goBack= () => {
    navigate(-1);
  }

  return (
    <div className="w-full flex justify-between  border-b-4  border-yellow-500 items-center">
      

          <div className="w-full h-52  bg-gradient-to-r from-[#081F43]   via-[#f8d581] to-white flex pl-2 items-center rounded-t-xl  ">
            {props.item.Users.Users_Profile_Photo != null ? <button onClick={()=> _setImageModal(+ props.item.Users.Users_Profile_Photo)} className="flex  ">
                <img src={"https://getjob.stechomeyazilim.com/Uploads/"+ props.item.Users.Users_Profile_Photo } alt="" className="w-40 h-40 rounded-2xl " />
              </button> :  <div className="bg-[#081F43] flex justify-center items-center p-4 rounded-lg"><FontAwesomeIcon icon={faUser} style={{margin:10}}  size={"5x"} color={"white"} /></div>}

              <p className="pl-2 font-[Gilroy-Medium] text-white">{props.item.Users.Users_NameSurname}</p>
            </div>


      <div className="flex flex-col pt-3 pr-3 ">
     
       { props.edit  ==  false ? null :   
       <div className="flex justify-center items-center ">

          <button style = {{backgroundColor: '#081F43',borderRadius:50,margin:5}} onClick={()=> _shareApp()}>
                            <FontAwesomeIcon icon={faShare} style = {{margin:10}}  size={"xl"} color={"#f8d581"} />
                        </button>


          <button onClick={()=> _passsiveUsers()} className="xxs:hidden sm:hidden mt-2 ml-2 border-2 border-zinc-700 rounded-md flex justify-center items-center text-center bg-[red]" >
            <a className="p-1 text-lg font-[Gilroy-Medium] text-[white]">Hesabımı Dondur</a>
          </button>

          <button onClick={()=> _deleteUsers()}  className="xxs:hidden sm:hidden mt-2 ml-2 border-2 border-zinc-700 rounded-md flex justify-center items-center text-center" >
          <FontAwesomeIcon className='flex justify-center items-center w-6 h-6' icon={ faTrash }  size={20} color={'red'} />    
            <a className="text-lg font-[Gilroy-Medium] p-1 ">Hesabımı Sil</a>
          </button>

        </div>}
      </div>

      <ImageModal _imageUrl={getImageUrl} _imageModalVisible={getImageVisible} _closeImageModal={_closeImageModal}/>

    </div>
  );
};

export default FirstBorder;
