import React,{ useEffect,useState } from "react";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import mapStyles from './mapStyles'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPaperPlane, faRocket, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Map(props) {
  const [selectedPark, setSelectedPark] = useState(null);

  useEffect(() => {
    const listener = e => {
      if (e.key === "Escape") {
        setSelectedPark(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

console.log("lksdn123ckf",props.dealerData)
  return (
   props.dealerData != null ?
    <GoogleMap
      defaultZoom={4}
      defaultCenter={{ lat: 48.8114633 , lng: 34.8990059}}
      options={{ styles: mapStyles }}>
        <Marker
          key={1}
          position={{
            lat: props.dealerData.Job_Employer_Map_Lat,
            lng:props.dealerData.Job_Employer_Map_Lng
          }}
          onClick={() => {
            setSelectedPark(props.dealerData.Employer_Map);
          }}
          icon={{
            url:  "https://img.icons8.com/color/48/000000/map-pin.png",
            scaledSize: new window.google.maps.Size(50, 50)
          }}
        >

<InfoWindow
          onCloseClick={() => {
            setSelectedPark(null);
          }}
          position={{
            lat: props.dealerData.Job_Employer_Map_Lat,
            lng: props.dealerData.Job_Employer_Map_Lng
          }}
        >
          <div>
            <h2 className="font-[Gotham-Medium] ">{props.dealerData.Job_Employer_Map_City}</h2>
          </div>
        </InfoWindow>
        </Marker>

    </GoogleMap> : null 
    
  );
}
const PersonelInfoResult = (props) => {
  const [mapdata1, setMapData] = useState({
    currentCity:null
  })

  const _delete = () => {
  
  //    axios.delete(`https://getjob.stechomeyazilim.info:5101/employerEducationDelete/${state._item.ID}`)
   //  axios.delete(`https://getjob.stechomeyazilim.info:5101/employerLanguageDelete/${state._item.ID}`)
   //  axios.delete(`https://getjob.stechomeyazilim.info:5101/employerJobWayOutDelete/${state._item.ID}`)
   //  axios.delete(`https://getjob.stechomeyazilim.info:5101/employerDelete/${state._item.ID}`)
 
    props._showToast()

  //  axios.delete(`https://getjob.stechomeyazilim.info:5101/employerJobWayOutDelete/${state._item.ID}`)


  }
  
  useEffect(() => {

  },[])

  console.log("ksldfk",props._item)
  return (
   props._item != null ?
   
    <div className="w-full  grid bg-[#F7F7F7] h-full ">
               

      <div className=" w-full">
      <button onClick={()=> _delete()} className="bg-blue-900 justify-center items-center flex w-full border-b-2 border-b-[white]">

      <p className="text-[#f8d581] font-[Gotham-Medium] m-2">İLANI ÖNE ÇIKAR</p>

      <div className="rounded-full bg-[white] m-2">
      <FontAwesomeIcon icon={faRocket} style = {{margin:10}}  size={"2x"} color={"#081F43"} />
      </div>
      </button>    



<div className="flex border-b-2 border-b-[white]">
      <Link  className="bg-[#081F43] justify-center items-center flex w-full " to={"/settingemployee/" + (props._item.ID)} state= {{ _item : props._item }}  >
      <FontAwesomeIcon icon={faEdit} style = {{margin:10}}  size={"2x"} color={"white"} />
            <a className="font-[Gotham-Medium] text-left text-white">Bilgileri Düzenle</a>
          </Link>
 

       <button onClick={()=> _delete()} className="bg-[#f8d581] justify-center items-center flex w-full">
      <FontAwesomeIcon icon={faTrash} style = {{margin:10}}  size={"2x"} color={"#081F43"} />
      <p className="text-[#081F43] font-[Gotham-Medium]">Sil</p>
      </button>    
      </div>
      </div>

      <div className="w-full flex flex-col px-2 pb-2 ">
      <div className="w-full flex justify-between text-xs py-1">
      <h3 className="text-[#868686] font-[Gotham-Medium]">Görüntülenme</h3>
      <p className="text-[#062CBC] font-[Gotham-Medium]">0</p>

      </div>
      </div>
      <div className="w-full flex flex-col px-2 pb-2 ">
      <div className="w-full flex justify-between text-xs py-1">
      <h3 className="text-[#868686] font-[Gotham-Medium]">Başvuru İsteği</h3>
      <p className="text-[#062CBC] font-[Gotham-Medium]">0</p>
      </div>
      </div>
      
      <div className="w-full flex flex-col px-2 pb-2 ">
      <div className="w-full flex justify-between text-xs py-1">
        <h3 className="text-[#868686] font-[Gotham-Medium]">Eşleşme</h3>
        <p className="text-[#062CBC] font-[Gotham-Medium]">0</p>

        </div>
        </div>

        <div className="w-full flex flex-col px-2 pb-2 ">
        <div className="w-full flex justify-between text-xs py-1">
        <h3 className="text-[#868686] font-[Gotham-Medium]">Mülakat</h3>
        <p className="text-[#062CBC] font-[Gotham-Medium]">0</p>

        </div>

        </div>


        <div className="flex flex-col pb-3 pt-1 px-2 w-full">
          <h3 className="text-[#071E44] text-sm pt-3 font-[Gotham-Medium]">Gerekli Şartlar</h3>
        </div>
        <div className="w-full flex flex-col px-2 pb-2 ">
          <div className="w-full flex justify-between text-xs py-1">
            <p className="text-[#868686] font-[Gotham-Medium]">Aranılan Pozisyon</p>
            {props._item.Job.Job_Text != null ? <p className="text-[#062CBC] font-[Gotham-Medium]">{props._item.Job.Job_Text}</p> : null}
          </div>

          <div className="w-full flex justify-between text-xs py-1  pb-6">
            <p className="text-[#868686] font-[Gotham-Medium]">Kaç Kişi İşe Alacaksınız?</p>
            <p className="text-[#062CBC] font-[Gotham-Medium]">{props._item.Employer_Company.Employer_Number}</p>
          </div>

          <div className="w-full flex justify-between text-xs py-1">
         
            <p className="text-[#868686] font-[Gotham-Medium]">Dil</p>
            <div className="flex-col text-center">

         {props._item.Employer_Language.map((item, index) => (
        
        <p className="text-[#062CBC] font-[Gotham-Medium]">{item.Job_Employer_Language}</p> ))}
                      </div>
          </div>
          {props._item.Job != null ? <div className="w-full flex justify-between text-xs py-1">
            <p className="text-[#868686] font-[Gotham-Medium]">Meslek</p>
            {props._item.Job_Text != null ? <p className="text-[#062CBC] font-[Gotham-Medium]">{props._item.Job.Job_Text}</p> : null}
          </div> : null}
         { props._item.Job_Experience != null ?  <div className="w-full flex justify-between text-xs py-1">
            <p className="text-[#868686] font-[Gotham-Medium]">Deneyim yılı </p>
            <p className="text-[#062CBC] font-[Gotham-Medium]">{props._item.Job_Experience.Job_Experience_Text}</p>
          </div> : null}
          { props._item.Job_Salery_Expection != null ?<div className="w-full flex justify-between text-xs py-1">
            <p className="text-[#868686] font-[Gotham-Medium]">Maaş</p>
            <p className="text-[#062CBC] font-[Gotham-Medium]">{props._item.Job_Salery_Expection.Job_Salery_Expection_Text}</p>
          </div> : null}
          <div className="w-full flex justify-between text-xs py-1">
            <p className="text-[#868686] font-[Gotham-Medium]" >Eğitim Durumu</p>
            
          <div className="flex-col text-center">
            
            {props._item.Employer_Education.map((item, index) => (
    
    <p className="text-[#062CBC] font-[Gotham-Medium] text-right">{item.Employer_Education_Status}</p> ))}

        </div></div>
          <div className="w-full flex justify-between text-xs py-1 pb-6">
            <p className="text-[#868686] font-[Gotham-Medium]">Çalışma Şekli</p>
            <div className="flex-col text-center font-[Gotham-Medium]">
            {props._item.Employer_JobWay.map((item, index) => (
    
    <p className="text-[#062CBC] font-[Gotham-Medium]">{item.Employer_JobWay_Text}</p> ))}
          </div>
        </div>

        <div className="w-full flex justify-between text-xs py-1  pb-6">
            <p className="text-[#868686] font-[Gotham-Medium]">Çalışma Türü</p>
           { props._item.Employer_JobModal.length > 0 ? <div className="flex-col text-center font-[Gotham-Medium]">
            {props._item.Employer_JobModal.map((item, index) => (
    
    <p className="text-[#062CBC] font-[Gotham-Medium] text-right">{item.Employer_JobModal_Text}</p> ))}
          </div> : <div className="text-[#062CBC] font-[Gotham-Medium]">Veri Yok</div>}
        </div>

        <h3 className="text-[#071E44] text-sm pt-3 pb-2 font-[Gotham-Medium]">Ön Yazı</h3>

        <p className="text-[#071E44] text-xs border-b-2 pb-6 font-[Gotham-Medium]">
          {props._item.Employer_Desc}
          </p>

        </div>

        <h3 className="text-[#071E44] text-sm pt-3 pb-2 font-[Gotham-Medium]">Filtre</h3>

       
        <div className="grid">
        <div className="flex">
          <p className="text-[#071E44] text-xs  pb-6 font-[Gotham-Medium]  ml-2">
          Cinsiyet:
          </p>

          <p className="text-[#868686] text-xs  pb-6 font-[Gotham-Medium]  ml-2">
          {props._item.Employer_Filter.Employer_Filter_Gender}
          </p>
          
          <p className="text-[#071E44] text-xs pb-6 font-[Gotham-Medium]  ml-2">
          Askerlik Durumu:
          </p>

          <p className="text-[#868686] text-xs pb-6 font-[Gotham-Medium]  ml-2">
          {props._item.Employer_Filter.Employer_Military}
          </p>

          <div className="flex ml-2">

          <p className="text-[#071E44] text-xs  pb-6 font-[Gotham-Medium]  ml-2">
          Başlangıç Yaşı:
          </p>

          <p className="text-[#868686] text-xs pb-6 font-[Gotham-Medium]">
          {props._item.Employer_Filter.Employer_Start_Age}
          </p>

          <p className="text-[#071E44] text-xs pb-6 font-[Gotham-Medium]  ml-2">
          Bitiş Yaşı:
          </p>

          <p className="text-[#868686] text-xs pb-6 font-[Gotham-Medium] pl-2">
          {props._item.Employer_Filter.Employer_Finish_Age}
          </p>
          
          {props._item.Employer_Filter.Employer_Driving_License != null ?
          <div> 
            <p className="text-[#071E44] text-xs  pb-6 font-[Gotham-Medium]  ml-2">
                    Ehliyet Durumu:
                    </p>
          <p className="text-[#868686] text-xs  pb-6 font-[Gotham-Medium]  ml-2">
           {props._item.Employer_Filter.Employer_Driving_License}
          </p> </div>: <p>Yok</p>
          }

<p className="text-[#071E44] text-xs  pb-6 font-[Gotham-Medium]  ml-2">
Mezuniyet Durumu:
                    </p>

       {props._item.Employer_Filter.Employer_Gradient  == true ? <p className="text-[#868686] text-xs pb-6 font-[Gotham-Medium]  ml-2">
         Var</p>:<p className="text-[#868686] text-xs  pb-6 font-[Gotham-Medium]  ml-2">
         Yok</p>}


          <p className="text-[#071E44] text-xs  pb-6 font-[Gotham-Medium]  ml-2">
          CV:
                    </p>

       {props._item.Employer_Filter.Employer_CV  == true ? <p className="text-[#868686] text-xs  pb-6 font-[Gotham-Medium]  ml-2">
         Var</p>:<p className="text-[#868686] text-xs  pb-6 font-[Gotham-Medium]  ml-2">
         Yok</p>}

      

          <p className="text-[#071E44] text-xs  pb-6 font-[Gotham-Medium]  ml-2">
          Video:
                    </p>

         {props._item.Employer_Filter.Video_Check  == true ? <p className="text-[#868686] text-xs pb-6 font-[Gotham-Medium]  ml-2">
         Var</p>:<p className="text-[#868686] text-xs  pb-6 font-[Gotham-Medium]  ml-2">
         Yok</p>}
          </div>
          </div>

          <div className="flex">
          <p className="text-[#071E44] text-xs  pb-6 font-[Gotham-Medium]  ml-2">
          Açıklama:
                    </p>

        {props._item.Employer_Filter.Employer_Filter_Desc  == true ? <p className="text-[#868686] text-xs  pb-6 font-[Gotham-Medium]  ml-2">
         Var</p>:<p className="text-[#868686] text-xs pb-6 font-[Gotham-Medium]  ml-2">
         Yok</p>}


          <p className="text-[#071E44] text-xs  pb-6 font-[Gotham-Medium]  ml-2">
          Sertifika:
                    </p>

                    {props._item.Employer_Filter.Employer_Certifica  == true ? <p className="text-[#868686] text-xs  pb-6 font-[Gotham-Medium]  ml-2">
         Var</p>:<p className="text-[#868686] text-xs  pb-6 font-[Gotham-Medium]  ml-2">
         Yok</p>}
         </div>
        </div>
        


          <h3 className="text-[#071E44] text-sm pt-3 pb-2 font-[Gotham-Medium]">Konum</h3>

{props._item.Employer_Map != null ?   <div style={{ height: '50vh', width: '100%',zIndex:10,marginTop:5 }} className="z-10 ex-collection-box mb-xl-20">

<MapWrapped
   googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCm32U9QFgSBe_8pJG_2Z3G61pL6ipeQtU&v=3.exp&libraries=geometry,drawing,places}`}
   loadingElement={<div style={{ height: `100%` }} />}
   containerElement={<div style={{ height: `100%` }} />}
   mapElement={<div style={{ height: `100%` }} />}
   dealerData = {props._item.Employer_Map}
   currentLat={props._item.Employer_Map.Job_Employer_Map_Lat}
   currentLng={props._item.Employer_Map.Job_Employer_Map_Lng}
 />
 <div className={"bg-[#081F43] text-center"}>
 <a target={"_blank"} href={"https://www.google.com/maps/search/?api=1&query=" + props._item.Employer_Map.Job_Employer_Map_Lat + "," + props._item.Employer_Map.Job_Employer_Map_Lng} className="text-[Gilroy-Medium] text-[white] p-10 text-center ">Konumu Görüntüle</a>
 </div>
</div>  : <div><a className="font-[Gotham-Medium]">Konum Bulunmamaktadır!</a></div>}

     
    
  
  </div> : null
  );
};

export default PersonelInfoResult;


const MapWrapped = withScriptjs(withGoogleMap(Map));
