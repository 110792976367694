import { faBuilding, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const FirstBorderSettings = (props) => {

    return (
    props.item.Users!= null ? props.item.Users.Users_Type_ID == 1 ?
    <div className=" w-full flex border-b-4  border-yellow-500 items-center justify-center">

<div className="w-full h-52  bg-gradient-to-r from-[#081F43]   via-[#f8d581] to-white flex  items-center rounded-t-xl  pl-2">
            {props.item.Employer_Company.Employer_Company_Image != null ?
                <img src={"https://getjob.stechomeyazilim.com/Uploads/"+ props.item.Employer_Company.Employer_Company_Image } alt="" className="w-40 h-40 rounded-2xl " /> : 
                <div className="bg-[#081F43] flex justify-center items-center p-4 rounded-lg"><FontAwesomeIcon icon={faBuilding} style={{margin:10}}  size={"5x"} color={"white"} /></div>}

            </div>
    
  </div>:
  <div className="w-full h-52  bg-gradient-to-r from-[#081F43]   via-[#f8d581] to-white flex  items-center rounded-t-xl  pl-2">
      <div className="rounded-lg flex justify-center items-center">
      {props.item.Users.Users_Profile_Photo == null ?  <div className="bg-[#081F43] flex justify-center items-center p-4 rounded-lg"><FontAwesomeIcon icon={faUser} style={{margin:10}}  size={"5x"} color={"white"} /></div> :  <img src={'https://getjob.stechomeyazilim.com/Uploads/' + props.item.Users.Users_Profile_Photo} className="w-32 h-32 rounded-lg p-1" alt="" />}
        <span className="font-[Gotham]">{props.item.Users.Users_NameSurname}</span>

      </div>
    
    </div> : null
  )
}

export default FirstBorderSettings