
const BASE_URL = 'https://getjob.stechomeyazilim.info:5101/verify/send' 
const BASE_URLChechk = 'https://getjob.stechomeyazilim.info:5101/checkVerify/send' 

export const sendSmsVerification = async (phoneNumber) => {
    console.log("1231asd",BASE_URL)
 try {
   const data = JSON.stringify({
     to: phoneNumber,
     channel: "sms",
   });

   const response = await fetch(`${BASE_URL}`, {
     method: "POST",
     headers: {
       "Content-Type": "application/json",
     },
     body: data,
   });

   const json = await response.json();
   return json.success;
 } catch (error) {
   console.error(error);
   return false;
 }
};

export const checkVerification = async (phoneNumber, code) => {
 try {
   const data = JSON.stringify({
     to: phoneNumber,
     code: code,
   });

   const response = await fetch(`${BASE_URLChechk}`, {
     method: "POST",
     headers: {
       "Content-Type": "application/json",
     },
     body: data,
   });

   const json = await response.json();
   return json.success;
 } catch (error) {
   console.error(error);
   return false;
 }
};

