import React, {Component} from "react";
import { GrClose } from "react-icons/gr";
import StarCardEmployerHistory from "./StarCardEmployerHistory";
import StarCardJobSeekerHistory  from "./StarCardJobSeekerHistory";
import StarCardEmployerHistoryShort  from "./StarCardEmployerHistoryShort";
import StarCardEmployerHistoryPassive  from "./StarCardEmployerHistoryPassive";
import StarCardEmployerHistoryShortList  from "./StarCardEmployerHistoryShortList";
import StarCardEmployerHistoryUnlike  from "./StarCardEmployerHistoryUnlike";
import StarCardEmployerCancel  from "./StarCardEmployerCancel";


import { BsPeople } from "react-icons/bs";
import Modal from '@mui/material/Modal';

import axios from 'axios';
import { setDoc,collection, addDoc,doc } from "firebase/firestore";
import {db} from '../../firebase';
import Moment from 'moment';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '2px solid #000',
  boxShadow: 24,
};

  class Star extends Component{
    constructor(props) {
      super(props)
      this.state = {
        loginData:null,
        listJobSeeker:[],
        listEmployer:[],
        setTab:0,
        listEmployerUnliked:[],
        lisJobSeekerUnliked:[],

        listLikedFavEmployer:[],
        listLikedFavJobSeeker:[],
        listLikedJobSeeker:[],
        listEmployerUnlikedPassive:[]
      }
  }
  

   handleChange = (event, newValue) => {
    this.setState({setTab: newValue});
  }

async componentDidMount() {
  if(JSON.parse(localStorage.getItem('dataLogin') != null)){
    this.setState({ loginData: JSON.parse(localStorage.getItem('dataLogin')) })

    if (JSON.parse(localStorage.getItem('dataLogin')).Users_Type_ID==1) {
      await this._getListEmployerNotification(JSON.parse(localStorage.getItem('dataLogin')).ID)
      await this._getListEmployerNotificationUnlike(JSON.parse(localStorage.getItem('dataLogin')).ID)

      await this._getListFavEmployer(JSON.parse(localStorage.getItem('dataLogin')).ID)


    }   if (JSON.parse(localStorage.getItem('dataLogin')).Users_Type_ID==2) {
      await this._getListJobSeekerNotification(JSON.parse(localStorage.getItem('dataLogin')).ID)
      await this._getListJobSeekerNotificationUnlike(JSON.parse(localStorage.getItem('dataLogin')).ID)
      await this._getListJobSeekerUnlike(JSON.parse(localStorage.getItem('dataLogin')).ID)

      
      await this._getListFavJobSeeker(JSON.parse(localStorage.getItem('dataLogin')).ID)
      await this._getListFavShortList(JSON.parse(localStorage.getItem('dataLogin')).ID)


    }
  }
}

_getListJobSeekerNotification = async (ID) => {
  try{
  
       await axios.get(`https://getjob.stechomeyazilim.info:5101/getJobSeekerHistory/select/${ID}`)
        .then((res) => {
            this.setState({listJobSeeker: res.data })   
           })
  }
  catch (error) {
   console.log("_getListJobSeekerNotification" + error)
  }
  }
  
  updateEmployer = async (type,ID,companyName,jobSeekerName,employerId,companyPhoto,jobSeekerPhoto,jobSeekerId) => {
    console.log("fsafa",type,ID,companyName,jobSeekerName,employerId,companyPhoto,jobSeekerPhoto,jobSeekerId)
    try{

      let loginData = JSON.parse(localStorage.getItem('dataLogin'))

      let querySearch = (jobSeekerName+companyName).toString()
  
      this.setState({message:null})
  
      const docRefNew = await  setDoc(doc(db,"MessageEmployer",  employerId.toString()), {
        Employer_ID: loginData.ID,
        Job_Employer_ID: employerId ,
        Job_Seeker_User_ID:jobSeekerId,
        employerName:companyName,
        endMessage:"Size Mülakat Talebi Gönderdi",
        finishDate:Moment().format("YYYY/MM/DD HH:mm:ss"),
        startDate:Moment().format("YYYY/MM/DD HH:mm:ss"),
        updateTime:Moment().format("YYYY/MM/DD HH:mm:ss"),
        userEmployerPhoto:companyPhoto,
        userJobSeekerPhoto:jobSeekerPhoto,
        workerName:jobSeekerName
      });


    const docRef = await addDoc(collection(db,"MessageEmployer",  employerId + "/" + querySearch), {
      customerMessage: "Size Mülakat Talebi Gönderdi",
      employerName: companyName,
      messageDate:Moment().format("YYYY/MM/DD HH:mm:ss"),
      type:"text",
      typeSender:"employer",
      workerName: jobSeekerName
    });

      if(type == true){
        let patchData={
          Notifacation_Type_ID : 3
        }
        await axios.patch(`https://getjob.stechomeyazilim.info:5101/notifacationEmployerPatch/${ID}`, patchData)
        .then((res) => {
          this._getListEmployerNotification(JSON.parse(localStorage.getItem('dataLogin')).ID)
           })

      }else{
        let patchData={
          Notifacation_Type_ID : 4
        }
        await axios.patch(`https://getjob.stechomeyazilim.info:5101/notifacationEmployerPatch/${ID}`, patchData)
        .then((res) => {
          console.log("res1211231",res)
          this._getListEmployerNotification(JSON.parse(localStorage.getItem('dataLogin')).ID)
           })
      } 
    }
    catch (error) {
     console.log("_getListEmployerNotification" + error)
    }
    }

    updateJobSeeker = async (type,ID,companyName,company,jobSeekerID) => {
      console.log("lsndfk123",company)
          try{
            if(type == true){

             let loginData = JSON.parse(localStorage.getItem('dataLogin'))

              let querySearch = (loginData.Users_NameSurname + companyName).toString()
          
              this.setState({message:null})
          
              const docRefNew = await  setDoc(doc(db,"MessageEmployer",  jobSeekerID.toString()), {
                Employer_ID: company.Users_ID,
                Job_Employer_ID: jobSeekerID ,
                Job_Seeker_User_ID:loginData.ID,
                employerName:company.Job_Employer_CompanyName,
                endMessage:"Sizinle Mülakat Yapmak İstiyor",
                finishDate:Moment().format("YYYY/MM/DD HH:mm:ss"),
                startDate:Moment().format("YYYY/MM/DD HH:mm:ss"),
                updateTime:Moment().format("YYYY/MM/DD HH:mm:ss"),
                userEmployerPhoto:company.Employer_Company_Image,
                userJobSeekerPhoto:loginData.Users_Profile_Photo,
                workerName:loginData.Users_NameSurname
              });


            const docRef = await addDoc(collection(db,"MessageEmployer",  jobSeekerID + "/" + querySearch), {
              customerMessage: "Sizinle Mülakat Yapmak İstiyor",
              employerName: companyName,
              messageDate:Moment().format("YYYY/MM/DD HH:mm:ss"),
              type:"text",
              typeSender:"seeker",
              workerName:loginData.Users_NameSurname
            });
          
     /*       await this._getListMessage(this.state.companyName,this.state.photo,this.state.jobseekerID)
      
   */
              let patchData={
                Notifacation_Type_ID : 3
              }
              await axios.patch(`https://getjob.stechomeyazilim.info:5101/notifacationJobSeekerPatch/${ID}`, patchData)
              .then((res) => {

                this._getListJobSeekerNotification(JSON.parse(localStorage.getItem('dataLogin')).ID)
                 })

            }else{
              let patchData={
                Notifacation_Type_ID : 4
              }
              await axios.patch(`https://getjob.stechomeyazilim.info:5101/notifacationJobSeekerPatch/${ID}`, patchData)
              .then((res) => {
                console.log("res1211231",res)
                this._getListJobSeekerNotification(JSON.parse(localStorage.getItem('dataLogin')).ID)
                 })
            }
          
          
          }
          catch (error) {
           console.log("updateJobSeeker" + error)
          }
          }

  _getListFavShortList= async (ID) => {
    try{
      let loginData = JSON.parse(localStorage.getItem('dataLogin'))

      await axios.get(`https://getjob.stechomeyazilim.info:5101/getListFavEmployer/select/${loginData.ID}`)
       .then((res) => {
        this.setState({listLikedJobSeeker: res.data })   
        })
}
catch (error) {
  console.log("errorccAS" + error)
}
        }

  _getListFavEmployer= async (ID) => {
      try{
                 await axios.get(`https://getjob.stechomeyazilim.info:5101/getListFavJobSeekerAll/select/${ID}`)
                  .then((res) => {
                    console.log("jkn12j3",res.data)
                      this.setState({listLikedFavEmployer: res.data })   
                      console.log("kb1kj312",res.data)
                     })
            }
            catch (error) {
              this.setState({listLikedFavEmployer: [] })   
             console.log("_getListEmployerNotification" + error)
            }
          }

  _getListFavJobSeeker = async (ID) => {
  try{
       await axios.get(`https://getjob.stechomeyazilim.info:5101/getListFavEmployer/select/${ID}`)
        .then((res) => {
            this.setState({listLikedFavJobSeeker: res.data })   
           })
  }
  catch (error) {
    this.setState({listLikedFavJobSeeker: [] })   
   console.log("_getListEmployerNotification" + error)
  }
  }


_getListEmployerNotification = async (ID) => {
  try{
       await axios.get(`https://getjob.stechomeyazilim.info:5101/getEmployerHistory/select/${ID}`)
        .then((res) => {
            this.setState({listEmployer: res.data })   
            console.log("kb1kj312",res.data)
           })
  }
  catch (error) {
    this.setState({listEmployer: [] })   
   console.log("_getListEmployerNotification" + error)
  }
  }
  

  _getListJobSeekerNotificationUnlike= async (ID) => {
    try{
           await axios.get(`https://getjob.stechomeyazilim.info:5101/getJobSeekerNotificationPassive/select/${ID}`)
          .then((res) => {
              this.setState({listEmployerUnlikedPassive: res.data })   
             })
    }
    catch (error) {
      this.setState({listEmployerUnliked: [] })   
     console.log("_getListEmployerNotification" + error)
    }
    }


  _getListJobSeekerUnlike= async (ID) => {
    try{
         await axios.get(`https://getjob.stechomeyazilim.info:5101/getJobSeekerUnliked/select/${ID}`)
          .then((res) => {
              this.setState({listEmployerUnliked: res.data })   
             })
    }
    catch (error) {
      this.setState({listEmployerUnliked: [] })   
     console.log("_getListEmployerNotification" + error)
    }
    }

  _getListEmployerNotificationUnlike = async (ID) => {
    try{
           await axios.get(`https://getjob.stechomeyazilim.info:5101/getEmployerUnliked/select/${ID}`)
          .then((res) => {
              this.setState({lisJobSeekerUnliked: res.data })   
             })
    }
    catch (error) {
      this.setState({lisJobSeekerUnliked: [] })   
     console.log("_getListEmployerNotification" + error)
    }
    }

  

  render(){
  return (
    <Modal
    open={this.props.openStartModalVisible}
    onClose={this.props._closeHistoryModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
<Box className='' sx={style}>

    <div className=" flex justify-center items-center  flex-col z-40 ">
      <div className="bg-white shadow-2xl flex  flex-col rounded-lg w-[470px] px-2  h-[520px]    ">
        <div className="w-full flex justify-end items-center pl-15">
          <h2 className="w-full text-center text-blue-700 font-bold text-xl border-b-2 py-3 ml-3">
         Başvurularım
          </h2>
          <div className="flex justify-end z-50 mr-[-7px] mb-5 ">
            <button
              className="bg-[#f8d581] rounded-full p-2"
              onClick={this.props._closeHistoryModal}>
              <GrClose className="" />
            </button>
          </div>
        </div>
        <div className="  w-full flex flex-col  overflow-x-auto ">
       

        {this.state.loginData != null && this.state.loginData.Users_Type_ID==1  ?
          
          <Box sx={{ width: '100%',height:'100%' }}>
      <Box sx={{ borderBottom: 1 }}>
        <Tabs  variant="scrollable"
  scrollButtons="auto"
  aria-label="scrollable auto tabs example"
  value={this.state.setTab} onChange={this.handleChange} >
          <Tab label="Başvurduklarım"  />
          <Tab label="Reddedilenler"  />
          <Tab label="İlgilenmedeklerim"  />
          <Tab label="Kısa Liste"  />

        </Tabs>
      </Box>
      <TabPanel value={this.state.setTab} index={0}>
      {this.state.listEmployer.map((item, index) => (
          <StarCardEmployerHistory
          ImgSource={'https://getjob.stechomeyazilim.com/Uploads/' + item.Users_Profile_Photo}
          ıconSource={<BsPeople className="w-full h-full" />}
          CompanyName={item.Users_NameSurname}
          labelBildirim={item.Notification_Text}
          time={item.Notification_CreatedDateTime}
          ID={item.ID}
          Notifacation_Type_ID={item.Notifacation_Type_ID}
          updateEmployer={this.updateEmployer}
          Job_Employer_CompanyName={item.Job_Employer_CompanyName}
          Users_NameSurname={item.Users_NameSurname}
          Employer_ID={item.Employer_ID}
          Employer_Company_Image={item.Employer_Company_Image}
          Users_Profile_Photo={item.Users_Profile_Photo}
          Sender_Users_ID={item.Sender_Users_ID}
        />
            ))}
      </TabPanel>
      <TabPanel value={this.state.setTab} index={1}>
      {this.state.lisJobSeekerUnliked.map((item, index) => (
           <StarCardEmployerCancel
           ImgSource={'https://getjob.stechomeyazilim.com/Uploads/' + item.Job_Seeker.Users.Users_Profile_Photo}
           ıconSource={<BsPeople className="w-full h-full" />}
           CompanyName={item.Job_Seeker.Users.Users_NameSurname}
           labelBildirim={item.Notification_Text}
           time={item.Notification_CreatedDateTime}
           ID={item.ID}
           Notifacation_Type_ID={item.Notifacation_Type_ID}
           updateEmployer={this.updateEmployer}
           Job_Employer_CompanyName={item.Job_Employer_CompanyName}
           Users_NameSurname={item.Job_Seeker.Users.Users_NameSurname}
           Employer_ID={item.Employer_ID}
           Employer_Company_Image={item.Employer_Company_Image}
           Users_Profile_Photo={item.Users_Profile_Photo}
           Sender_Users_ID={item.Sender_Users_ID}
         />
            ))}
      </TabPanel>
   
      <TabPanel value={this.state.setTab} index={2}>
      {this.state.lisJobSeekerUnliked.map((item, index) => (
           <StarCardEmployerHistoryUnlike
           ImgSource={'https://getjob.stechomeyazilim.com/Uploads/' + item.Job_Seeker.Users.Users_Profile_Photo}
           ıconSource={<BsPeople className="w-full h-full" />}
           CompanyName={item.Job_Seeker.Users.Users_NameSurname}
           labelBildirim={item.Notification_Text}
           time={item.Notification_CreatedDateTime}
           ID={item.ID}
           Notifacation_Type_ID={item.Notifacation_Type_ID}
           Users_Type_ID={item.Users_Type_ID}
           updateEmployer={this.updateEmployer}
           Job_Employer_CompanyName={item.Job_Employer_CompanyName}
           Users_NameSurname={item.Job_Seeker.Users.Users_NameSurname}
           Employer_ID={item.Employer_ID}
           Employer_Company_Image={item.Employer_Company_Image}
           Users_Profile_Photo={item.Users_Profile_Photo}
           Sender_Users_ID={item.Sender_Users_ID}
         />
            ))}
      </TabPanel>
      <TabPanel value={this.state.setTab} index={3}>
      {this.state.listLikedFavEmployer.length > 0 ? this.state.listLikedFavEmployer.map((item, index) => (
           <StarCardEmployerHistoryShortList
           ImgSource={'https://getjob.stechomeyazilim.com/Uploads/' +item.Job_Seeker.Users.Users_Profile_Photo}
           ıconSource={<BsPeople className="w-full h-full" />}
           CompanyName={item.Job_Seeker.Users.Users_NameSurname}
           labelBildirim={item.Notification_Text}
           time={item.Created_DateTime}
           ID={item.ID}
           Notifacation_Type_ID={item.Notifacation_Type_ID}
           updateEmployer={this.updateEmployer}
           Job_Employer_CompanyName={item.Job_Employer_CompanyName}
           Users_NameSurname={item.Users_NameSurname}
           Employer_ID={item.Employer_ID}
           Employer_Company_Image={item.Employer_Company_Image}
           Users_Profile_Photo={item.Users_Profile_Photo}
           Sender_Users_ID={item.Sender_Users_ID}
         />
            )) : null}
      </TabPanel>
    </Box>


          :
          this.state.loginData != null && this.state.loginData.Users_Type_ID==2 ?
          <Box sx={{ width: '100%',height:'100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs  variant="scrollable"
  scrollButtons="auto"
  aria-label="scrollable auto tabs example"
  value={this.state.setTab} onChange={this.handleChange} >          
          <Tab label="Başvurduklarım"  />
          <Tab label="Reddedilenler"  />
          <Tab label="İlgilenmedeklerim"  />
          <Tab label="Kısa Liste"  />
        </Tabs>
      </Box>
      <TabPanel value={this.state.setTab} index={0}>
      {this.state.listJobSeeker.map((item, index) => (
            <StarCardJobSeekerHistory
            ImgSource={'https://getjob.stechomeyazilim.com/Uploads/' + item.Users.Employer_Company[0].Employer_Company_Image}
              ıconSource={<BsPeople className="w-full h-full" />}
              CompanyName={item.Users.Employer_Company[0].Job_Employer_CompanyName}
              labelBildirim={item.Notification_Text}
              time={item.Created_DateTime}
              ID={item.ID}
              employerDetail={item.Users.Employer_Company[0]}
              Job_Seeker_ID={item.Job_Seeker_ID}
              Notifacation_Type_ID={item.Notifacation_Type_ID}
              updateJobSeeker={this.updateJobSeeker}
            />
            )) }
      </TabPanel>
      <TabPanel value={this.state.setTab} index={1}>
      {this.state.listEmployerUnlikedPassive.map((item, index) => {
       return(
            item.Users.Employer_Company.length != 0 ? 
            <StarCardJobSeekerHistory
            ImgSource={'https://getjob.stechomeyazilim.com/Uploads/' + item.Users.Employer_Company[0].Employer_Company_Image}
              ıconSource={<BsPeople className="w-full h-full" />}
              CompanyName={item.Users.Employer_Company[0].Job_Employer_CompanyName}
              labelBildirim={item.Notification_Text}
              time={item.Created_DateTime}
              ID={item.ID}
              employerDetail={item.Users.Employer_Company[0]}
              Job_Seeker_ID={item.Job_Seeker_ID}
              Notifacation_Type_ID={item.Notifacation_Type_ID}
              updateJobSeeker={this.updateJobSeeker}
            /> : <div>eksik içerik</div>
            )})}
      </TabPanel>
      {this.state.listEmployerUnliked.length > 0 ?
       <TabPanel value={this.state.setTab} index={2}>
      {this.state.listEmployerUnliked.map((item, index) => {
        return(
           <StarCardEmployerHistoryPassive
           ImgSource={'https://getjob.stechomeyazilim.com/Uploads/' + item.Users_Profile_Photo}
           ıconSource={<BsPeople className="w-full h-full" />}
           CompanyName={item.Users_NameSurname}
           labelBildirim={item.Notification_Text}
           time={item.Created_DateTime}
           ID={item.ID}
           Notifacation_Type_ID={item.Notifacation_Type_ID}
           updateEmployer={this.updateEmployer}
           Job_Employer_CompanyName={item.Job_Employer_CompanyName}
           Users_NameSurname={item.Users_NameSurname}
           Employer_ID={item.Employer_ID}
           Employer_Company_Image={item.Employer_Company_Image}
           Users_Profile_Photo={item.Users_Profile_Photo}
           Sender_Users_ID={item.Sender_Users_ID}
         />
            )})}
      </TabPanel> : null }
      {this.state.listLikedJobSeeker.length > 0 ?
       <TabPanel value={this.state.setTab} index={3}>
      {this.state.listLikedJobSeeker.map((item, index) => {
        return(
           <StarCardEmployerHistoryShort
           ImgSource={'https://getjob.stechomeyazilim.com/Uploads/' + item.Users_Profile_Photo}
           ıconSource={<BsPeople className="w-full h-full" />}
           CompanyName={item.Users_NameSurname}
           labelBildirim={item.Notification_Text}
           time={item.Created_DateTime}
           ID={item.ID}
           Notifacation_Type_ID={item.Notifacation_Type_ID}
           updateEmployer={this.updateEmployer}
           Job_Employer_CompanyName={item.Job_Employer_CompanyName}
           Users_NameSurname={item.Users_NameSurname}
           Employer_ID={item.Employer_ID}
           Employer_Company_Image={item.Employer_Company_Image}
           Users_Profile_Photo={item.Users_Profile_Photo}
           Sender_Users_ID={item.Sender_Users_ID}
         />
            )})}
      </TabPanel> : null }
    </Box>

      : <div className="justify-center items-center font-[Gotham-Medium] text-center">Veri Bulunmamaktadır!</div>
          }
        
       
        
        </div>
      </div>
    </div>
    </Box>
    </Modal>
  )
}
};

export default Star;
