import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from 'react-router-dom';
import Logo from "../../../assets/Navbar/logo.png";


const EmployeeJobsCard = (props) => { 
  return (
    <Link to={"/employeeinfo/" + (props.item.Users.ID)} state= {{ _item : props.item, edit:false }} >
    <div className="bg-slate-200 flex justify-center items-center border rounded-xl flex-col shadow-lg ">
      <div className="relative flex justify-center items-center  rounded-xl flex-col shadow-lg w-full ">
        <div className="w-4/5 flex justify-center items-center p-4">
        { props.item.Users.Users_Profile_Photo != null ?  <img
            src={"https://getjob.stechomeyazilim.com/Uploads/" + props.item.Users.Users_Profile_Photo}
            alt=""
            className=" w-full h-full"
          /> :  <div className="bg-[#081F43] flex justify-center items-center p-4 rounded-lg"><FontAwesomeIcon icon={faUser} style={{margin:10}}  size={"5x"} color={"white"} /></div>}
        </div> 

        <div className="w-full flex flex-col pl-4 py-2 text-white bg-[#0D0658] rounded-bl-xl rounded-br-xl">
          <h3 className="text-center font-[Gotham]">{props.item.Users.Users_NameSurname}</h3>
          {props.item.Job != null ? <p className=" flex-wrap text-center font-[Gotham-Medium]">{props.item.Job.Job_Text}</p> : null}
        </div>

        <div className="absolute top-0 right-0 p-2 text-white bg-[#0D0658] rounded-bl-xl">
            <p className=" flex-wrap">%{props.item.Score}</p> 
        </div>
      </div>
    </div></Link>
  );
};

export default EmployeeJobsCard;
