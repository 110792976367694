import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from 'react-router-dom';
import Logo from "../../../assets/Navbar/logo.png";

const PeoplePart = (props) => {
  console.log("sjdfnjlk",props._item)
  return (
    props.userType == 1 ?
    <Link  to={"/employeeinfo/"  + (props._item.ID)} state= {{ _item : props._item }} className="border rounded-2xl shadow-lg  hover:bg-blue-200 grid items-center  w-full">
      <div  className="h-48 w-full  rounded-2xl p-1 justify-center items-center flex">
{      props.People == null ? <div className="bg-[#081F43] flex justify-center items-center p-4 rounded-lg"><FontAwesomeIcon icon={faUser} style={{margin:10}}  size={"5x"} color={"white"} /></div> :  <img src={'https://getjob.stechomeyazilim.com/Uploads/' +props.People} alt="" className="rounded-2xl  w-full h-full" w-full h-full />}        
      </div>
      <div className="flex items-center justify-center bg-[#052A69] w-full p-1">
      <h3 className="p-1 flex text-center  text-[white] font-[Gotham-Medium]">
          {props.PeopleLabel}
        </h3>
      </div>
    </Link> : 
    props._item.Employer_Company.Job_Employer_CompanyName != null ? <Link   to={"/employerDetail/" + (props._item.Employer_Company.Job_Employer_CompanyName).replace(/\s+/g, '-')} state= {{ _item : props._item }} className="border rounded-2xl shadow-lg  hover:bg-blue-200 grid items-center  w-full">
     <div  className="h-48 w-full  rounded-2xl p-1  justify-center items-center flex">
{      props.People == null ?<div className="bg-[#081F43] flex justify-center items-center p-4 rounded-lg"><FontAwesomeIcon icon={faUser} style={{margin:10}}  size={"5x"} color={"white"} /></div>:  <img src={'https://getjob.stechomeyazilim.com/Uploads/' +props.People} alt="" className="rounded-2xl  w-full h-full" w-full h-full />}        
     </div>
     <div className="flex items-center justify-center bg-[#052A69] w-full p-1">
     <h3 className="p-1 flex text-center  text-[white] font-[Gotham-Medium]">
         {props.PeopleLabel}
       </h3>
     </div>

   </Link> :null
  );
};

export default PeoplePart;
