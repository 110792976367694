import React from "react";
import Navbar from "../Navbar";
import MessagesCard from "./MessagesCard";
import MessagesCardRightEmployer from "./MessagesCardRightEmployer";
import MessagesCardLeft from "./MessagesCardLeft";
import Agora from "./Agora";
import AgoraVoiceEmployer from "./AgoraVoiceEmployer";

import EmployerImg from "../../assets/Jobs/Employer.svg";
import { Component } from "react";
import axios from 'axios';
import { setDoc,collection, addDoc,getDocs,query,where,doc,getDoc,Timestamp, deleteDoc,onSnapshot, orderBy, updateDoc } from "firebase/firestore";
import {db} from '../../firebase';
import { BsPaperclip } from "react-icons/bs";
import Moment from 'moment';
import { faPaperPlane, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class MessagesEmployer extends Component {
  constructor() {
    super()
    this.state = {
      listWhatsapp:[],
        todos:null,
        dataMessage:[],
        dataMessageDetail:[],
        message:'',
        userDetail:{},
        videoCallVisible:false,
        employer:false
    }
}

 messagesEndRef = React.createRef()

async componentDidMount() {
  await this._getList()
  await this._getList2()

  this.fetchPost();
  this.scrollToBottom()

}
componentDidUpdate () {
this.scrollToBottom()
}
scrollToBottom = () => {
  this.messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
}


fetchPost = async () => {
       
 // const ref = doc(db, "MessageEmployer", "47")

 let loginData = JSON.parse(localStorage.getItem('dataLogin'))

 this.setState({dataMessage:[]})
  const q = query(collection(db, "MessageEmployer"), where("Employer_ID", "==", loginData.ID));


  const querySnapshot = await getDocs(q);


    const newData = querySnapshot.docs
              .map((doc) => ({...doc.data(), id:doc.id }));
          this.setState({dataMessage : newData,_startDate:newData[0].startDate});  

  /* await getDocs(collection(db, "MessageEmployer"))
      .then((querySnapshot)=>{               

          const newData = querySnapshot.docs
              .map((doc) => ({...doc.data(), id:doc.id }));
          this.setState({dataMessage : newData});  
          }) */

}



_getList = async () => {
try{
let loginData = JSON.parse(localStorage.getItem('dataLogin'))
  this.setState({loginData: localStorage.getItem('dataLogin')}) 

  console.log("asbjkasd",loginData.ID)
     await axios.get(`https://getjob.stechomeyazilim.info:5101/getNotificationEmployerWhatsapp/select/${loginData.ID}`)
      .then(async(res) => {
          this.setState({listWhatsapp: res.data,employer:true })  
          
          await axios.get(`https://getjob.stechomeyazilim.info:5101/getEmployerProfile/select/${loginData.ID}`)
          .then((res) => {
            this.setState({userDetail : res.data[0]})   
             })

       //   await this._getListMessage(res.data[0])
         })

}
catch (error) {
 console.log("errorccAS" + error)
}
}

_getList2 = async () => {
  try{
  let loginData = JSON.parse(localStorage.getItem('dataLogin'))
    this.setState({loginData: localStorage.getItem('dataLogin')}) 
  
   await axios.get(`https://getjob.stechomeyazilim.info:5101/getNotificationEmployerSendWhatsapp/select/${loginData.ID}`)
           .then(async(res) => {
            this.setState({listWhatsapp: res.data,employer:false })  
  
          })
  }
  catch (error) {
   console.log("errorccAS" + error)
  }
  }

_deleteMessage= async () => {

  console.log("ksdnfjk",this.state.jobseekerID)
/* const docRef = doc(db, "MessageEmployer", this.state.employerId.toString());

deleteDoc(docRef)
.then(async() => {
  await this._getList()
  this.fetchPost();
  let formUpdate = {
    Notification_IsActive :false
  }
  
 // await axios.patch(`https://getjob.stechomeyazilim.info:5101/notifacationJobSeekerPatch/${null}`, formUpdate)

  
    console.log("Entire Document has been deleted successfully.")
}) */
.catch(error => {
    console.log(error);
})
}

_getListMessage = async (employerName,photo,companyName,ID) => {
try {   
  this.setState({
    dataMessageDetail:[],
    photo: photo,
    employerId:ID,
    employerName:employerName,
    companyName:companyName,
  })

  let querySearct = (employerName + companyName).toString()

  console.log("ln1l2k31",ID,querySearct)
  const pq = query(collection(db, "MessageEmployer", ID + "/" + querySearct), orderBy("messageDate", "asc"));

  //const querySnapshot1 = await getDocs(pq);
  const unsubscribe = onSnapshot(pq, (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      this.setState(prevState => ({
        dataMessageDetail: [...prevState.dataMessageDetail, doc.data()]
      }))
    });
  });
  } catch (e) {
    console.error("Er1rort: ", e);
  }
}

_openVideoCall= async () => {
  this.setState({
    videoCallVisible : !this.state.videoCallVisible
  })

  console.log("jknskjdf",this.state.employerId)
  const docRef = doc(db, "MessageEmployer", this.state.employerId);

const data = {
  startDate: Moment().format("YYYY/MM/DD HH:mm:ss"),
  finishDate: Moment().add(30,'minute').format("YYYY/MM/DD HH:mm:ss")
};

updateDoc(docRef, data)
.then(docRef => {
console.log("A New Document Field has been added to an existing document");
})

}

_sendMessage= async () => {
  try {

    let querySearch = (this.state.employerName + this.state.companyName).toString()

    this.setState({message:null,dataMessageDetail:[]})

  const docRef = await addDoc(collection(db,"MessageEmployer",  this.state.employerId + "/" + querySearch), {
    customerMessage: this.state.message,
    employerName: this.state.companyName,
    messageDate:Moment().format("YYYY/MM/DD HH:mm:ss"),
    type:"text",
    typeSender:"employer",
    workerName:this.state.employerName
  });

//  await this._getListMessage(this.state.employerName,this.state.photo,this.state.companyName,this.state.employerId)
  } catch (e) {
    console.error("Error adding document: ", e);
  }

}

setMessage= async (value) => {
  this.setState({message:value})
}

  render(){
    let loginData = JSON.parse(localStorage.getItem('dataLogin'))

  return (
    <div className="flex flex-col">
      <Navbar />
      <div className="w-full h-screen xxs:px-0 sm:px-8 md:px-28 lg:px-48 bg-[#003567] flex ">
        <div className="bg-[#003567] shadow-2xl flex  flex-col  w-full px-2 pt-2 h-screen    ">
          <div className="mt-36 flex bg-white h-4/5 ">
            <div  className="w-1/3 border-r-2">
              <h2 className="text-center border-b-2 mb-4 py-2 text-lg">
                Mesajlar
              </h2>
              <div className="w-full flex flex-col ">
              {this.state.listWhatsapp.map((item, index) => (
              <MessagesCardLeft employer={this.state.employer} _loginData={loginData} item={item} _getListMessage={this._getListMessage}/>
              ))}
               
              </div>
            </div>

            {this.state.dataMessageDetail.length == 0 ? 
            <div className="w-full h-full justify-center items-center text-center font-[Title]">Lütfen Mesaj Seçiniz</div> : 
            <div ref={this.messagesEndRef} className="w-2/3 overflow-x-auto">
            <div className="w-full bg-[#081F43] justify-center items-center">
                    <Agora _companyName={this.state.companyName} _startDate={this.state._startDate} _companyID={loginData.ID}  openVideoCall={this._openVideoCall}/>
                    {this.state.videoCallVisible == false ?  
                    <AgoraVoiceEmployer _companyName={this.state.companyName} _startDate={this.state._startDate} _companyID={loginData.ID}  openVideoCall={this._openVideoCall}/>
                    :null}

                  </div>
                  <button onClick={()=> this._deleteMessage()} className="flex p-1 bg-[#081F43]"> <a className="text-[white] font-[Title]">Sil</a> <FontAwesomeIcon className='flex justify-center items-center w-6 h-6' icon={ faTrash }  size={20} color={'white'} />                            
                  </button>
             {this.state.videoCallVisible == false ?  
             <div>
              {this.state.dataMessageDetail.map((item, index) => (<MessagesCardRightEmployer photo={this.state.photo} item={item} _sendMessage = {this._sendMessage}/> ))}
            </div>: null}

             {this.state.videoCallVisible == false ?
              <div className="relative">
                <div  className="justify-items-start bg-[white] flex">
              <input
                  type="text"
                  id="inputMessage"
                  value={this.state.message}
                  className="bg-[#081F43] text-[white] justify-items-end justify-end items-end w-full font-[Gotham-Medium] outline-none focus:border-b-2 focus:border-blue-500"
                  placeholder="Lütfen mesajınızı yazınız .."
                  onChange={e => this.setMessage(e.target.value)}
                />

                <button onClick={()=> this._sendMessage()}>

                <BsPaperclip className="flex justify-center items-center w-6 h-6" />
                </button>

                </div>
              </div> : null}
            </div>}
          </div>
        </div>
      </div>
    </div>
  )
}};

export default MessagesEmployer;
