import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from 'react-router-dom';

const EmployerJobsCard = (props) => {
  return (
    <Link to={("/employerDetail/" + props.item.Employer_Company.Job_Employer_CompanyName).replace(/\s+/g, '-')} state={ { _item : props.item }} >
    <div className="bg-slate-200 flex justify-center items-center border rounded-xl flex-col shadow-lg ">
      <div className="relative flex justify-center items-center  rounded-xl flex-col shadow-lg w-full ">
        <div className="w-4/6 flex justify-center items-center p-4">
        {props.item.Employer_Company.Employer_Company_Image != null ? <img
            src={'https://getjob.stechomeyazilim.com/Uploads/' + props.item.Employer_Company.Employer_Company_Image}
            alt=""
            className="bg-transparent mt-5 drop-shadow-2xl  rounded-xl "
          />: <div className="bg-[#081F43] flex justify-center items-center p-4 rounded-lg"><FontAwesomeIcon icon={faBuilding} style={{margin:10}}  size={"5x"} color={"white"} /></div>}
        </div> 


        <div className="w-full flex flex-col pl-4 py-2 text-white bg-[#0D0658] rounded-bl-xl rounded-br-xl">
          <h3 className="text-xl font-bold flex-wrap">{props.item.Employer_Company.Job_Employer_CompanyName}</h3>
          {props.item.Job != null ?  <p className=" flex-wrap">{props.item.Job.Job_Text}, arıyor</p> : null}
        </div>

        <div className="absolute top-0 right-0 p-2 text-white bg-[#0D0658] rounded-bl-xl">
           <p className=" flex-wrap">%{props.item.Score}</p> 
        </div>
      </div>
    </div></Link>
    
  );
};

export default EmployerJobsCard;
