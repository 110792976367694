import React, {useEffect} from "react";
import Logo from "../assets/Navbar/logo.svg";
import whiteLogo from "../assets/Navbar/whiteLogo.svg";
import starIcon from "../assets/Navbar/starIcon.svg";
import handShakeIcon from "../assets/Navbar/handShakeIcon.svg";
import messageIcon from "../assets/Navbar/messageIcon.svg";
import bellIcon from "../assets/Navbar/bellIcon.svg";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
import LogIn from "./MainPage/LogIn/LogIn";
import Autharizatiom from "./MainPage/LogIn/Autharizatiom";
import Match from "./Match/Match";
import BildirimlerJobSeeker from "./Bildirimler/BildirimlerJobSeeker";
import BildirimlerEmployer from "./Bildirimler/BildirimlerEmployer";
import Star from "./Star/Star";
import OneCikar from "./MainPage/OneCikar/OneCikar";
import { Link } from 'react-router-dom';
import OtpPageModal from "./MainPage/LogIn/OtpPageModal";
import OtpPageModalMail from "./MainPage/LogIn/OtpPageModalMail";
import SuccessProfile from "./MainPage/LogIn/SuccessProfileScreen";
import { useNavigate } from 'react-router-dom'
import { sendSmsVerification } from "./verify";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import History from "./History/History";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import Turkish from "../turkey.png";
import English from "../united-kingdom.png";

import "./navbar.css";
import LoadingSpinner from "../LoadingSpinner";


const Navbar = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate()

  const [language, setLanguage] = useState("tr");
  const [nav, setNav] = useState(true);
  const handleNav = () => {
    setNav(!nav);
  };

  const resources = {
    en: {
      translation: {
        "Welcome to React": "Welcome to React and react-i18next",
        "findright": "Find the right fit",
        "popularsearch" : "Popular Searches",
        "önecikanyet" : "Featured Talents",
        "isbul" : "Find a Job",
        "yetenekler" : "Abilities",
  
        "kayitol" : "Register",
        "giris" : "Login",
        "profilgit" : "Go to Profile",
        "sss" : "Faqs",
        "mailsifredeg" : "Change Mail&Password",
        "hesapdüzenle" : "Edit My Account",
        "bildirim" : "Notification",
        "hesapdondur" : "Freeze My Account",
        "hesapsil" : "Delete My Account",
        "cikis" : "Log out",
        "seemorejob" : "See More",
        "aradıgınızisler" : "Aradığınız İşler",
        "arıyor" : "Finding",
  
        "firmabilgileri" : "Firma Bilgileri",
        "firmaadi" : "Firma Adı",
        "sektor" : "Sektör",
        "kurulusyili" : "Kuruluş Yılı",
        "calisansayisi" : "Çalışan Sayısı",
        "sirketnumarasi" : "Şirket Numarası",
        "sirketmail" : "Şirket Mail",
        "sirketcalisansayi" : "Şirket Çalışan Sayısı",
        "yetkilikisi" : "Yetkili Kişi",
  
        "gereklisartlar" : "Gerekli Şartlar",
        "aranilanpozisyon" : "Aranılan Pozisyon",
        "dil" : "Dil",
  
        "meslek" : "Meslek",
        "deneyimyili" : "Deneyim yılı",
        "maas" : "Maaş",
        "egitimdurumu" : "Eğitim Durumu",
  
        "calismasekli" : "Çalışma Şekli",
        "onyazi" : "Ön Yazı",
        "calismamodeli" : "Çalışma Modeli",
        "konumum" : "Konumum",
  
        "basvurdunuz" : "BAŞVURDUNUZ",
        "Benzerisler" : "Benzer işlere başvurmak için tıklayınız",
  
        "profiligizle" : "Profili Gizle",
        "smsbildirim" : "SMS Bildirim",
        "mailbildirim" : "Mail Bildirim",
        "kisielbilgiler" : "Kişisel Bilgiler",
        "adsoyad" : "Ad Soyad",
        "dogumtarihi" : "Doğum Tarihi",
        "cinsiyet" : "Cinsiyet",
        "ehliyet" : "Ehliyet",
        "ililce" : "İl - İlçe",
        "askerlikdurumu" : "Askerlik Durumu",
        "egitimdurumu" : "Eğitim Durumu",
        "bilgigirilmedi" : "Bilgi Girilmedi",
        "calismagecmisi" : "Çalışma Geçmişi",
        "firmabilgileri" : "Firma Bilgileri",
        "firmaadi" : "Firma Adı",
        "sektor" : "Sektör",
        "kurulusyasi" : "Kuruluş Yılı",
        "calisansayisi" : "Çalışan Sayısı",
        "yetkilikisi" : "Yetkili Kişi",
        "yetkilimail" : "Yetkili Mail",
        "yetkilitelefon" : "Yetkili Telefon",
  
        "vergino" : "Vergi No",
        "adresbilgileri" : "Adres Bilgileri",
        "bilgileriduzenle" : "Bilgileri Düzenle",
        "sil" : "Sil",
  
  
        "greklisartlar" : "Gerekli Şartlar",
        "aranilanpozisyonlar" : "Aranılan Pozisyon",
        "kackisiisealacaksin" : "Kaç Kişi İşe Alacaksınız?",
        "dil" : "Dil",
        "meslek" : "Meslek",
        "deneyimyili" : "Deneyim Yılı",
        "maas" : "Maaş",
        "egitimdurumu" : "Eğitim Durumu",
        "calismasekli" : "Çalışma Şekli",
        "calismaturu" : "Çalışma Türü",
        "onyazi" : "Ön Yazı",
        "filtre" : "Filtre",
        "cinsiyet" : "Cinsiyet",
        "askerlikdurumu" : "Askerlik Durumu",
        "baslangicyasi" : "Başlangıç Yaşı",
        "bitisyasi" : "Bitiş Yaşı",
        "ehliyerdurumu" : "Ehliyet Durumu",
        "mezuniyetdurumu" : "Mezuniyet Durumu",
        "video" : "Video",
        "aciklama" : "Açıklama",
        "sertifika" : "Sertifika",
        "videoyok" : "Video Yok",
        "konumugoruntule" : "Konumu Görüntüle",
        "courses" : "Courses",
        "profil": "Profile"
  
      }
    },
    tr: {
      translation: {
        "Welcome to React": "HG and react-i18next",
        "findright": "Doğru uyumu bulun",
        "popularsearch" : "Populer Aramalar",
        "önecikanyet" : "Featured Talents",
        "isbul" : "İş Bul",
        "yetenekler" : "Yetenekler",
  
        "kayitol" : "Kayıt Ol",
        "giris" : "Giriş Yap",
        "profilgit" : "Profile Git",
        "sss" : "SSS",
        "mailsifredeg" : "Mail&Şifre Değiştir",
        "hesapdüzenle" : "Hesabımı Düzenle",
        "bildirim" : "Bildirim",
        "hesapdondur" : "Hesabımı Dondur",
        "hesapsil" : "Hesabımı Sil",
        "cikis" : "Çıkış Yap",
  
        "seemorejob" : "Daha Fazla İşveren",
        "aradıgınızisler" : "Aradığınız İşler",
        "arıyor" : "Arıyor",
  
        "firmabilgileri" : "Firma Bilgileri",
        "firmaadi" : "Firma Adı",
        "sektor" : "Sektör",
        "kurulusyili" : "Kuruluş Yılı",
        "calisansayisi" : "Çalışan Sayısı",
        "sirketnumarasi" : "Şirket Numarası",
        "sirketmail" : "Şirket Mail",
        "sirketcalisansayi" : "Şirket Çalışan Sayısı",
        "yetkilikisi" : "Yetkili Kişi",
  
        "gereklisartlar" : "Gerekli Şartlar",
        "aranilanpozisyon" : "Aranılan Pozisyon",
        "dil" : "Dil",
  
        "meslek" : "Meslek",
        "deneyimyili" : "Deneyim yılı",
        "maas" : "Maaş",
        "egitimdurumu" : "Eğitim Durumu",
  
        "calismasekli" : "Çalışma Şekli",
        "onyazi" : "Ön Yazı",
        "calismamodeli" : "Çalışma Modeli",
        "konumum" : "Konumum",
  
        "basvurdunuz" : "BAŞVURDUNUZ",
        "Benzerisler" : "Benzer işlere başvurmak için tıklayınız",
  
        "profiligizle" : "Profili Gizle",
        "smsbildirim" : "SMS Bildirim",
        "mailbildirim" : "Mail Bildirim",
        "kisielbilgiler" : "Kişisel Bilgiler",
        "adsoyad" : "Ad Soyad",
        "dogumtarihi" : "Doğum Tarihi",
        "cinsiyet" : "Cinsiyet",
        "ehliyet" : "Ehliyet",
        "ililce" : "İl - İlçe",
        "askerlikdurumu" : "Askerlik Durumu",
        "egitimdurumu" : "Eğitim Durumu",
        "bilgigirilmedi" : "Bilgi Girilmedi",
        "calismagecmisi" : "Çalışma Geçmişi",
  
        "firmabilgileri" : "Firma Bilgileri",
        "firmaadi" : "Firma Adı",
        "sektor" : "Sektör",
        "kurulusyasi" : "Kuruluş Yılı",
        "calisansayisi" : "Çalışan Sayısı",
        "yetkilikisi" : "Yetkili Kişi",
        "yetkilimail" : "Yetkili Mail",
        "yetkilitelefon" : "Yetkili Telefon",
  
        "vergino" : "Vergi No",
        "adresbilgileri" : "Adres Bilgileri",
        "bilgileriduzenle" : "Bilgileri Düzenle",
        "sil" : "Sil",
  
  
        "greklisartlar" : "Gerekli Şartlar",
        "aranilanpozisyonlar" : "Aranılan Pozisyon",
        "kackisiisealacaksin" : "Kaç Kişi İşe Alacaksınız?",
        "dil" : "Dil",
        "meslek" : "Meslek",
        "deneyimyili" : "Deneyim Yılı",
        "maas" : "Maaş",
        "egitimdurumu" : "Eğitim Durumu",
        "calismasekli" : "Çalışma Şekli",
        "calismaturu" : "Çalışma Türü",
        "onyazi" : "Ön Yazı",
        "filtre" : "Filtre",
        "cinsiyet" : "Cinsiyet",
        "askerlikdurumu" : "Askerlik Durumu",
        "baslangicyasi" : "Başlangıç Yaşı",
        "bitisyasi" : "Bitiş Yaşı",
        "ehliyerdurumu" : "Ehliyet Durumu",
        "mezuniyetdurumu" : "Mezuniyet Durumu",
        "video" : "Video",
        "aciklama" : "Açıklama",
        "sertifika" : "Sertifika",
        "videoyok" : "Video Yok",
        "konumugoruntule" : "Konumu Görüntüle",
        "courses" : "Kurslar",
        "profil": "Profil"
  
  
  
      },
    }
  }
  const [signUpModal, setsignUpModal] = useState(false);
  const [logInModal, setLogInModal] = useState(false);
  const [otpModalCheck, setOtpModal] = useState(false);
  const [otpModalCheckMail, setOtpModalMail] = useState(false);

  const [otpSuccessProfile, setOtpSuccessProfile] = useState(false);
  const [registerData, setRegisterData] = useState({});
  const [historyModal, setHistory] = useState(false);

  const [isLoading, setDataLoading] = useState(false);

  const [checkDisplayMenu, setDisplayMenu] = useState(false);

  
  const showDropdownMenu= (event) => {
    event.preventDefault();
    setDisplayMenu(!checkDisplayMenu)
    document.querySelector('#dropdown').addEventListener('click', hideDropdownMenu);
  
  }

  const hideDropdownMenu= () => {
    setDisplayMenu(!checkDisplayMenu)
      document.querySelector('#dropdown').removeEventListener('click', hideDropdownMenu);

  }

  const _close = () => {
    otpModalCheck(false);
  };

  const handleSignUp = () => {
    setsignUpModal(false);
  };
  const handleLogIn = () => {
    setLogInModal(false);
  };

  const _handleSignUp = async() => {
    setOtpModal(false);
    setOtpModalMail(false);

    setOtpSuccessProfile(true)
    
    const userData = {  
      Users_TelephoneNumber:registerData.enablePhone == true ? registerData.valuePhone.replace(/\s+/g,"").replace("+9","").replace("(","").replace(")","") : null,
      Users_Password:registerData.password,
      Users_Active:true,
      Users_Email:registerData.enablePhone == false ? registerData.mailAdress : null,
      Users_RegisterType:registerData.enablePhone == false ? "mail" : "telephone"
     };

    axios.post('https://getjob.stechomeyazilim.info:5101/addUser/send', userData).then(async(res) => {
      console.log("res12113",res.data)
      if(res.data.value  == true){
        await signInFunc();
        }else{
        setData({
        ...data,
        isLoading: false,
        alertStatus: true,
        alertMessage: 'Bu telefon veya mail adresi kullanıyor, lütfen tekrar kontrol ediniz!'
        })
        
        }
     
    })
  }


  const [data, setData] = React.useState({
    username: '',
    password: '',
    userMail: '',
    userID:null,
    telephoneNumber: '',
    telephoneNumberFormatted:'',
    userNameSurname:'',
    telephoneNumber:'',
   isSelectTypeID:0
});

const showToast= (event, value, type) => {
  if(type == "false"){
    toast.error(value, {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
      setDataLoading(false)

  }else{
    toast.success(value, {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

  }


}
  const signInFunc= async() => {
       
    if(registerData.enablePhone == false){

    try {

      await axios.get(`https://getjob.stechomeyazilim.info:5101/getUserDetail/select/${registerData.mailAdress}/${registerData.password}`)
      .then((res) => {
        if(res.data.length == 0) {

          setData({ ...data,alertMessage: 'Kullanıcı adı veya şifreniz yanlış',alertStatus:true,isLoading:false });
      } else {
        setData({
          ...data,
          userID : JSON.stringify(res.data[0].ID)
        })

        localStorage.setItem('dataLogin', JSON.stringify(res.data[0]));
        showToast('GetJob', "Başarıyla Sisteme Kayıt Oldunuz, Lütfen Formu Tamamlayanız!", 'success')
  }
      })

            
        } catch (err) {
          console.log("erccr123",err)
          showToast('GetJob', "Kayıt Olurken Bir Hata Oluştu!", 'false')

               setData({ ...data,alertMessage: 'Kullanıcı adı veya şifreniz yanlış',alertStatus:true,isLoading: false });

        } 
    }else{
   
      try {

      
        await axios.get(`https://getjob.stechomeyazilim.info:5101/getUserDetail/select/${registerData.valuePhone.replace(/\s+/g,"").replace("+9","").replace("(","").replace(")","")}/${registerData.password}`)
        .then((res) => {
          if(res.data.length == 0) {
  
            setData({ ...data,alertMessage: 'Kullanıcı adı veya şifreniz yanlış',alertStatus:true,isLoading:false });
        } else {
          setData({
            ...data,
            userID : JSON.stringify(res.data[0].ID)
          })

          console.log("girdi",JSON.stringify(res.data))
          localStorage.setItem('dataLogin', JSON.stringify(res.data[0]));
        showToast('GetJob', "Başarıyla Sisteme Kayıt Oldunuz, Lütfen Formu Tamamlayanız!", 'success')
    }
        })

              
          } catch (err) {
            console.log("erccr123",err)
                 setData({ ...data,alertMessage: 'Kullanıcı adı veya şifreniz yanlış',alertStatus:true,isLoading: false });
  
          } 
    }

   }

  const _setSmsOtp=async(password,valuePhone,enablePhone,mailAdress)=>{     
    console.log("daslndla",registerData)   
  
    if(enablePhone == true){
      sendSmsVerification(valuePhone).then((sent) => {
        if (sent == true){
            setOtpModal(true)
       /*     setData({
                ...data,
                otpVisible:true
                                }) */

            
        }else{
          /*  setData({
                ...data,
                alertStatus:true,
                alertMessage: 'Lütfen telefon numaranızı kontrol ediniz',
            }); */
        }
      });
    }else{

    }
    setDataLoading(false)

 
    }
  
    const _setMailOtp=async(password,valuePhone,enablePhone,mailAdress)=>{     
      try { 

        let data = {
          UserName: 'Sayın Kullanıcı',
          MailAdress:mailAdress
        }

      axios.post('https://getjob.stechomeyazilim.info:5101/sendMailCode/send', data).then(async(res) => {
  
      if(res.data.Cikti == 'pending'){
        setOtpModalMail(true)
        console.log("res1burada2321")

      
      }else{
        showToast('GetJob', "Bir Hata Oluştu", 'false')

      }

      setDataLoading(false)

    })
  } catch(e) {
    console.log("asdad",e.message)
  
  }
      }
    
  const _successProfile = (type,ID) => {
    console.log("type123",type)
    setOtpModal(false);
    setOtpModalMail(false);
    setOtpSuccessProfile(false)
    if(type == 1){
      navigate('/newEmployer', { _item: { ID : ID }})
    }else{
      navigate('/newJobSeeker', { _item: { ID : ID }})
    }
  };
    
  const _alert = () => {
    showToast('GetJob', "Lütfen Üyelik Tipini Seçiniz", 'false')
  };
 

  const [ LogOutModal, setLogOutModal] = useState(false);
  const handleLogOut = () => {
    setLogOutModal(false);
  };

  const _deleteAuth = async() => {
    setLogOutModal(false);
    localStorage.removeItem('dataLogin');
    
    //window.location.reload(false);
    navigate("/")
    _getData()
  }

  
  const _closeStarModal = async() => {
    setOpenStar(false)
  }

  const _closeMatchModal = async() => {
    setOpenMatch(false)
  }

  const _closeHistoryModal = async() => {
    setHistory(false)
  }

  const _closeProfileModal = async() => {
    setOtpSuccessProfile(false)
  }
  
  const _closeNotifModal = async() => {
    setOpenNotif(false)
  }
  
  const _closeAuthModal = async() => {
    setLogInModal(false)
  }

  const _setOtpModalLoading= async(val) => {
    setDataLoading(val)
  }


  const _closeLoginModal = async() => {
    setsignUpModal(false);
  }

  const _closeLoginOpenSingUpModal = async() => {
    setLogInModal(false)
    setsignUpModal(true);
  }

  
  const _setOtpModal = async(password,valuePhone, enablePhone ,mailAdress) => {
    let regData = {password,valuePhone,enablePhone,mailAdress}
    setRegisterData(regData)
    setsignUpModal(false);
   if(enablePhone == true){
   
    _setSmsOtp(password,valuePhone,enablePhone,mailAdress)
  }else{
    _setMailOtp(password,valuePhone,enablePhone,mailAdress)

  }
  }

  

  const [openMatch, setOpenMatch] = useState(false);
  const handleMatchModal = () => {
    setOpenMatch(false);
  };
  const [openNotif, setOpenNotif] = useState(false);
  const handleNotifModal = () => {
    setOpenNotif(false);
  }; 
  const [openStar, setOpenStar] = useState(false);
  const handleStarModal = () => {
    setOpenStar(false);
  };

  const handleHistoryModal = () => {
    setHistory(false);
  };
  

  const [openOneCikar, setOpenOneCikar] = useState(false);
  const handleOneCikarModal = () => {
    setOpenOneCikar(false);
  };

  const [loginData, setLoginData] = useState(null);
  const [loginDataEmployer, setProfileEmployer] = useState(null);
  const [getProfileJobSeeker, setProfileJobSeeker] = useState(null);
  const [getProfileEmployer, setProfileEmployerr] = useState(null);

  useEffect(() => {
    const _getList= async() => {
      let dataLanguage =  localStorage.getItem("language")
      if(dataLanguage == null){
        setLanguage("tr");
      }else{
        setLanguage(dataLanguage);
      }

      if(JSON.parse(localStorage.getItem('dataLogin')).Users_Type_ID == 2){
        setLoginData(JSON.parse(localStorage.getItem('dataLogin')))

        try{
          await axios.get(`https://getjob.stechomeyazilim.info:5101/getJobSeekerProfile/select/${JSON.parse(localStorage.getItem('dataLogin')).ID}`)
          .then((res) => {
            setProfileJobSeeker(res.data[0])
            })
    }
    catch (error) {
      console.log("errorccAS" + error)
    }

      
      }else{
        try{
          setLoginData(JSON.parse(localStorage.getItem('dataLogin')))
  
          await axios.get(`https://getjob.stechomeyazilim.info:5101/getEmployerProfile/select/${JSON.parse(localStorage.getItem('dataLogin')).ID}`)
          .then((res) => {
            setProfileEmployerr(res.data[0])
            setProfileEmployer(res.data[0].Employer_Company.Job_Employer_CompanyName)
             })
  
    }
    catch (error) {
      console.log("errorccAS" + error)
    }
      }
     
    }



    _getList()
      },[]);

      const  _getData= () => {
        setLoginData(JSON.parse(localStorage.getItem('dataLogin')))
      }


      const _deleteUsers = () => {
        try{
        let loginData =  JSON.parse(localStorage.getItem('dataLogin'))
        axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteUsers(${loginData.ID})`)
    
        props._openToast("delete")
        } catch (error) {
          console.log("_getList" + error)
        }
    
      }
    
      const _passsiveUsers = async() => {
        try{
        let loginData =  JSON.parse(localStorage.getItem('dataLogin'))
    
       let user = {
        Users_Active :false
       }
       
       await axios.patch(`https://getjob.stechomeyazilim.info:5101/usersUpdate/${loginData.ID}`, user)
       .then(async (res) => {
        props._openToast("patch")
       })
      } catch (error) {
        console.log("_getList" + error)
      }
    
      }

      const handleLangChange = evt => {
        console.log("nsdfkl",evt)
        const lang = evt;
        console.log(lang);
        setLanguage(lang);

   i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: resources,
    lng: lang, // if you're using a language detector, do not define the lng option
    fallbackLng: lang,

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  })

  localStorage.setItem('language', lang);

      }

  return (
    <div className="w-screen h-[145px] z-30 bg-white fixed  top-0  ">
      <div className=" flex justify-between items-center w-full h-full ">
        <div className="grid grid-rows-2 w-full h-full border border-t-8 border-b-0">
          <div className="flex items-center h-[100px] justify-between">
            <Link to={"/"}>
              <img src={Logo} alt="/" className="w-15 h-9  ml-8 " />
            </Link>

            <div className="px-10 md:hidden flex " onClick={handleNav}>
              {!nav ? (
                <AiOutlineClose size={23} />
              ) : (
                <AiOutlineMenu size={23} />
              )}
            </div>
            <div className=" mr-11 hidden md:flex justify-center items-center">
            {loginData != null && loginData.Users_Type_ID == 1 ?   <Link to={"/newEmployer2"}  state={{_item : getProfileEmployer}}>
                <div className="px-8 py-1 rounded-xl border bg-blue-900 hover:text-white hover:bg-blue-900 text-[white] ">
                <p className="font-[Gotham-Medium]">Yeni İlan Aç</p>
                </div>
              </Link> : null}
             
             
              {loginData == null ? 
              <div>
               <button onClick={() => setsignUpModal(true)}>
               <div className="px-8 py-1 rounded-xl border bg-blue-900 hover:text-blue-900 hover:bg-yellow-500 text-white ">
               <p className="font-[Gotham-Medium]">{t('kayitol')}</p>
               </div>
             </button>
              <button onClick={() => setLogInModal(true)}>
                <div className="px-8 py-1 rounded-xl border bg-blue-900 hover:text-blue-900 hover:bg-yellow-500 text-white ">
                <p className="font-[Gotham-Medium]">{t('giris')}</p>
                </div>
              </button> </div>: null
               }
             
           
              
             {loginData != null ? 
             <div className="flex">
             <button onClick={() => setOpenStar(true)}>
                <img
                  src={starIcon}
                  alt=""
                  className="px-2 w-10 h-10 mr-4 rounded-full hover:bg-zinc-500"
                />
              </button>
              <button onClick={() => setOpenMatch(true)}>
                <img
                  src={handShakeIcon}
                  alt=""
                  className="px-2 w-10 h-10 mr-4 rounded-full hover:bg-zinc-500"
                />
              </button>
              { loginData != null ? loginData.Users_Type_ID== 1 ?
              <Link to="/messagesEmployer">
                <img
                  src={messageIcon}
                  alt=""
                  className="px-2 w-10 h-10 mr-4 rounded-full hover:bg-zinc-500"
                />
              </Link> :  <Link to="/messagesJobSeeker">
                <img
                  src={messageIcon}
                  alt=""
                  className="px-2 w-10 h-10 mr-4 rounded-full hover:bg-zinc-500"
                />
              </Link>
              : null}
              <button
                onClick={() => {
                  setOpenNotif(true);
                }}
              >
                <img
                  src={bellIcon}
                  alt=""
                  className="px-2 w-10 h-10 mr-4 rounded-full hover:bg-zinc-500"
                />
              </button>
             
             
              {loginData.Users_Type_ID == 2 ?
            <button onClick={()=> setHistory(true)} className="flex items-center">               

          <FontAwesomeIcon className="px-1 w-6 h-6 rounded-full hover:bg-zinc-500" icon={ faPaperPlane }  size={12} color={'#081F43'} />    


              </button>:
               <button onClick={()=> setHistory(true)} className="flex items-center">               

               <FontAwesomeIcon className="px-1 w-6 h-6 rounded-full hover:bg-zinc-500" icon={ faPaperPlane }  size={12} color={'#081F43'} />    
     
     
                   </button>}

              </div> : null }


      {loginData != null && loginData.Users_Type_ID == 2 ?    
      <div  className="dropdown" >
         <div className="button cursor-pointer" onClick={showDropdownMenu}>{loginData.Users_NameSurname}</div>

          { checkDisplayMenu ? (
          <ul className="cursor-pointer">
        <Link  to={"/jobSeekerInfo/"  + loginData.ID} state={{_item : loginData}}  className="flex  hover:bg-blue-900 hover:text-[white]  p-2">               
        <p className="font-[Gotham-Medium] text-left">{t('profilgit')}</p>
                
              </Link>

              <Link  to={"/sss/"}  className="flex  hover:bg-blue-900 hover:text-[white]  p-2">               
                <p className="font-[Gotham-Medium] text-left">{t('sss')}</p>
              </Link>
          <div className="  ">
          <Link to={"/settingemployeeMailAndPassword/" + (loginData.ID)} state= {{ _item : loginData }}  className="p-2 hover:bg-blue-900 hover:text-[white]  flex " >
            <a className="font-[Gotham-Medium] text-left">{t('mailsifredeg')}</a>
          </Link>

          <Link to={"/settings/" + (loginData.ID)} state= {{ _item : getProfileJobSeeker }} className="p-2 hover:bg-blue-900 hover:text-[white] flex " >
            <a className="font-[Gotham-Medium] text-left">{t('hesapdüzenle')}</a>
          </Link>

          <p  className="p-2 hover:bg-blue-900 hover:text-[white] flex " >
            <a className="font-[Gotham-Medium] text-left">{t('bildirim')}</a>
          </p>

          <button onClick={()=> _passsiveUsers()} className=" w-full flex p-2 hover:bg-blue-900 hover:text-[white]" >
            <a className="font-[Gotham-Medium] text-left">{t('hesapdondur')}</a>
          </button>


          <button onClick={()=> _deleteUsers()}  className="mt-2 p-2 flex  hover:bg-blue-900 hover:text-[white] w-full" >
            <a className="font-[Gotham-Medium] text-left">{t('hesapsil')}</a>
          </button>
        </div> 

              <button className="w-full px-8 py-1 mt-1  bg-[#f8d581] hover:text-[white] hover:bg-blue-900 text-[#081F43]"  onClick={() => _deleteAuth(false)}>
                  <p className="font-[Gotham-Medium]">{t('cikis')}</p>
                
              </button>

         

          </ul>
        ):
        (
          null
        )
        }
       </div>  : loginData != null && loginData.Users_Type_ID == 1 ?     
        <div  className="dropdown" >
         <div className="button cursor-pointer" onClick={showDropdownMenu}>{loginDataEmployer}</div>

          { checkDisplayMenu ? (
          <ul className="cursor-pointer">
       <Link  to={"/employeeınfo/" + (loginData.ID)} state = {{ _item : loginData }}  className="flex  hover:bg-blue-900 hover:text-[white]  p-2">               
        <p className="font-[Gotham-Medium] text-left">Profile Git</p>
                
              </Link>
       
              <Link  to={"/sss/"}  className="flex  hover:bg-blue-900 hover:text-[white]  p-2">               
                <p className="font-[Gotham-Medium] text-left">SSS</p>
              </Link>


              <Link to={"/companyEdit/" + (loginData.ID)} state= {{ _item : getProfileEmployer }} className="p-2 hover:bg-blue-900 hover:text-[white] flex " >
            <a className="font-[Gotham-Medium] text-left">Firma Bilgilerini Düzenle</a>
          </Link>
          
          <Link to={"/settingemployeeMailAndPassword/" + (loginData.ID)} state= {{ _item : loginData }}  className="p-2 hover:bg-blue-900 hover:text-[white]  flex " >
            <a className="font-[Gotham-Medium] text-left">Mail&Şifre Değiştir</a>
          </Link>


          <button onClick={()=> _passsiveUsers()} className=" w-full flex p-2 hover:bg-blue-900 hover:text-[white]" >
            <a className="font-[Gotham-Medium] text-left">Hesabımı Dondur</a>
          </button>



          <button onClick={()=> _deleteUsers()}  className="mt-2 p-2 flex  hover:bg-blue-900 hover:text-[white] w-full" >
            <a className="font-[Gotham-Medium] text-left">Hesabımı Sil</a>
          </button>
              <button className="w-full px-8 py-1 mt-1  bg-[#f8d581] hover:text-[white] hover:bg-blue-900 text-[#081F43]"  onClick={() => _deleteAuth(false)}>
                  <p className="font-[Gotham-Medium]">Log Out</p>
                
              </button>
          </ul>
        ):
        (
          null
        )
        }
       </div> : null}

       <div className="justify-center items-center flex pl-1">
       {language == "tr" ? 
       <button onClick={()=> handleLangChange("en")} className="justify-center items-center flex">
       <img src={Turkish} alt="/" className="w-15 h-10   " />
       <text className="font-[Times] pl-2">Türkçe</text>
       </button> : language == "en" ? 
       <button onClick={()=> handleLangChange("tr")} className="justify-center items-center flex">
       <img src={English} alt="/" className="w-15 h-10  " />
       <text className="font-[Times] pl-2">English</text>
       </button> : <button onClick={()=> handleLangChange("en")} className="justify-center items-center flex">
       <img src={Turkish} alt="/" className="w-15 h-10   " />
       <text className="font-[Times] pl-2">Türkçe</text>
       </button>}

      
       </div>
            </div>
            
      

          
            <div
              className={
                !nav
                  ? "fixed left-0 top-0 w-[60%] md:w-[20%]  h-full border-r bg-[#081F43] border-[#4E63B6] z-40 ease-in-out duration-300 "
                  : "fixed left-[-100%] "
              }
            >
              
              <div className = "border-b-2 border-b-white justify-center items-center w-full">
              <Link to={"/"} >
                <img
                  src={whiteLogo}
                  alt="/"
                  className="w-full h-10 justify-center items-center m-2"
                />
              </Link>
              </div>
              {/* MOBİL MENÜ */}

            { loginData != null ? loginData.Users_Type_ID== 1 ?
              <Link className="flex justify-center items-center bg-[#f8d581] border-b-white" to="/messagesEmployer">
              
                 <a
                  className="rounded-lg text-[#081F43]  font-[Times]  hover:bg-blue-900 hover:text-white text-center p-2">
                  Mesajlar
                </a>

              </Link> :  
              <Link className="bg-[#f8d581] w-full flex justify-center items-center border-b-2 border-b-white" to="/messagesJobSeeker">
              <a
                  className=" text-[#081F43] font-[Times]  hover:bg-blue-900 hover:text-white p-2">
                  Mesajlar
                </a>
              </Link>
              : null}
              
            {loginData != null ? loginData.Users_Type_ID == 2 ?
            <Link className="bg-[#f8d581] w-full flex justify-center items-center border-b-2 border-b-white" to="/jobsJobSeeker">
                <a className=" text-[#081F43] font-[Times]  hover:bg-blue-900 hover:text-white p-2">

                  Jobs
                </a>
              </Link> :  <Link className="bg-[#f8d581] w-full flex justify-center items-center border-b-2 border-b-white" to="/jobs">
                 <img
                  src={messageIcon}
                  alt=""
                  className="px-2 w-10 h-10 mr-4 rounded-full hover:bg-zinc-500"
                />
                 <a className=" text-[#081F43] font-[Times]  hover:bg-blue-900 hover:text-white p-2">
                      
                  Yetenekler
                </a>
              </Link> : null}

              {loginData != null && loginData.Users_Type_ID == 1 ?  
              <Link className="border-b-2 border-b-white bg-[#f8d581] w-full flex justify-center items-center" to={"/employeeınfo/" + (loginData.ID)} state= {{ _item: { ID: loginData.ID} , edit : true }}>
                 <a className=" text-[#081F43] font-[Times]  hover:bg-blue-900 hover:text-white p-2">
                  {t('profil')}
                </a>
              </Link> : loginData != null && loginData.Users_Type_ID == 2 ?<Link className="border-b-2 border-b-white bg-[#f8d581] w-full flex justify-center items-center" to={"/jobSeekerInfo/" + (loginData.ID)} state= {{ _item: { ID: loginData.ID} , edit : true }}>
              <a className=" text-[#081F43] font-[Times]  hover:bg-blue-900 hover:text-white p-2">
                  Profile
                </a>
              </Link> : 
              <div className="grid w-full bg-[#f8d581] ">
               <button className="p-2  w-full border-b-2 border-b-white" onClick={() => setLogInModal(true)}>
             
               <p className="font-[Gotham-Medium] text-[#081F43]">{t('giris')}</p>
          
             </button>
              <button className="p-2 w-full " onClick={() => setLogInModal(true)}>
               
                <p className="font-[Gotham-Medium] text-[#081F43]">{t('kayitol')} </p>
               
              </button> </div>
              }  

{loginData != null ?           
          
          <button className="bg-[#f8d581] w-full flex justify-center items-center" to="/jobsJobSeeker"  onClick={() => _deleteAuth(false)}>
                  <p className="font-[Gotham-Medium] p-2">Log Out</p>
               
              </button>
             : null}

                  {/* MOBİL MENÜ bitiş*/}
          
          
            </div>
            
          </div>
          


          <div className=" w-full  bg-[#F2F2F2]  relative bottom-0 mt-7 flex items-center grid-cols-2">
           
           
            {loginData != null ? loginData.Users_Type_ID == 2 ?
            <Link to="/jobsJobSeeker">
                <a
                  className=" px-5 py-2 font-[Times]
           rounded-lg text-slate-500 text-xs  hover:bg-blue-900 hover:text-white"
                >
                  {t('isbul')} 
                </a>
              </Link> :  
              
              <Link to="/jobs">
                <a
                  className=" px-5 py-2 
           rounded-lg text-slate-500 text-xs font-[Times]  hover:bg-blue-900 hover:text-white"
                >
                   {t('yetenekler')} 
                  
                </a>
              </Link> : null}


              <Link to={"/courses"}>

                <a
                  className="px-5 py-2 font-[Times]
             rounded-lg text-slate-500 text-xs  hover:bg-blue-900 hover:text-white "
                >
                  {t('courses')}
                </a>
              </Link>

              <Link to={"/blog"}>

                <a
                  className=" px-5 py-2 font-[Times]
             rounded-lg text-slate-500 text-xs  hover:bg-blue-900 hover:text-white "
                >
                  Blog
                </a>
              </Link>

              
 
         
       
          </div>

          
        </div>
      </div>
      {openOneCikar && <OneCikar handleOneCikarModal={handleOneCikarModal} />}
       <Star openStartModalVisible= {openStar} _closeStarModal={_closeStarModal} handleStarModal={handleStarModal} />
      {loginData != null ? loginData.Users_Type_ID== 1 ?  <BildirimlerEmployer openNotifModalVisible= {openNotif} _closeNotifModal={_closeNotifModal} handleNotifModal={handleNotifModal} />  : <BildirimlerJobSeeker openNotifModalVisible= {openNotif} _closeNotifModal={_closeNotifModal}  handleNotifModal={handleNotifModal} /> : null}  
      {LogOutModal && setLogOutModal}
       <Autharizatiom _closeLoginOpenSingUpModal={_closeLoginOpenSingUpModal} openAuthModalVisible= {logInModal} _closeAuthModal={_closeAuthModal} handleLogIn={handleLogIn} />
       <LogIn _setOtpModalLoading={_setOtpModalLoading} _setOtpModal={_setOtpModal} openLoginModalVisible={signUpModal}  _closeLoginModal={_closeLoginModal} handleSignUp={handleSignUp} />
       <Match openMatchModalVisible= {openMatch} _closeMatchModal={_closeMatchModal} handleMatchModal={handleMatchModal} />

       <OtpPageModal showToast={showToast} _setSmsOtp={_setSmsOtp} registerData={registerData} handleSignUp={_handleSignUp} close={_close} _otpVerficationMailVisible ={otpModalCheck} />
       <OtpPageModalMail showToast={showToast} _setSmsOtp={_setSmsOtp} registerData={registerData} handleSignUp={_handleSignUp} close={_close} _otpVerficationMailVisible ={otpModalCheckMail} />

       <SuccessProfile userID= {data.userID} registerData={registerData} _closeProfileModal={_closeProfileModal} _successProfile={_successProfile} _otpSuccessProfileVisible ={otpSuccessProfile} _alert={_alert} />
       <History openStartModalVisible= {historyModal} _closeHistoryModal={_closeHistoryModal} handleHistoryModal={handleHistoryModal} />
       <LoadingSpinner show={isLoading} /> 

    </div>
  );
};
 
export default Navbar;
