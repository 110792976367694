import React from "react";
import getjoblogo from "../assets/getjoblogo.svg";
import twtlogo from '../assets/tiwtternocolor.svg';
import fcblogo from '../assets/facebooknocolor.svg';
import instalogo from '../assets/instanolcolor.svg';
import linkedinlogo from '../assets/linkedinnocolor.svg';
import ytblogo from '../assets/ytbnocolor.svg';
import tiktoklogo from '../assets/tiktok.svg';





const Footer = () => {
  return (
    <div className="w-full h-[400px] bg-[#081251]  flex flex-col text-white z-20">
      <div className="w-full h-full pl-20 pt-10 flex flex-row">
        <div className="w-32 justify-center items-center mr-8 hidden md:flex">
          <img
            src={getjoblogo}
            alt=""
            className="w-24 h-20  justify-center items-center"
          />
        </div>
        <div className="w-full h-full flex justify-between md:pl-16 md:pr-24 pr-3">
          <div className="flex flex-col text-left md:mr-0 mr-2">
            <h3 className=" mb-3 font-[Gotham-Medium] text-center border-b-2">JOBS</h3>
            <a href="/" className="text-xs mb-1 font-[Gotham-Medium] text-center">
              Contact Us
            </a>
            <a href="/" className="text-xs mb-1 font-[Gotham-Medium] text-center">
              Job search
            </a>
            <a href="/" className="text-xs mb-1 font-[Gotham-Medium] text-center">
              Browse jobs
            </a>
            <a href="/" className="text-xs mb-1 font-[Gotham-Medium] text-center">
              Browse locations
            </a>
            <a href="/" className="text-xs mb-1 font-[Gotham-Medium] text-center">
              Popular searches
            </a>
            <a href="/" className="text-xs mb-1 font-[Gotham-Medium] text-center">
              Career advice
            </a>
            <a href="/" className="text-xs mb-1 font-[Gotham-Medium] text-center">
              Help
            </a>
          </div>
          <div className="flex flex-col text-left">
            <h3 className=" mb-3 font-[Gotham-Medium]  border-b-2">RECRUITER</h3>
            <a href="/" className="text-xs mb-1 font-[Gotham-Medium] text-center">
              Recruiter site
            </a>
            <a href="/" className="text-xs mb-1 font-[Gotham-Medium] text-center">
              Post a job
            </a>
            <a href="/" className="text-xs mb-1 font-[Gotham-Medium] text-center">
              CV Search
            </a> 
            <a href="/" className="text-xs mb-1 font-[Gotham-Medium] text-center">
              Recruiter Advice
            </a>
          </div>
          <div className="flex flex-col  font-[Gotham-Medium] text-center ">
            <h3 className=" mb-3  border-b-2">COURSES</h3>
            <a href="/" className="text-xs mb-1 font-[Gotham-Medium]">
              All Courcess
            </a>
            <a href="/" className="text-xs mb-1 font-[Gotham-Medium]">
              Works
            </a>
          </div>
          <div className="flex flex-col text-cente font-[Gotham-Medium]">
            <h3 className=" mb-3  border-b-2 text-center">BLOG</h3>
            <a href="/" className="text-xs mb-1 font-[Gotham-Medium] text-center">
              Popular Articles
            </a>
            <a href="/" className="text-xs mb-1 font-[Gotham-Medium] text-center">
              Last Articles
            </a>
          </div>
        </div>
      </div>
      <div className="w-full h-[70px] mt-3 border border-t-1 border-x-0 border-b-0 border-[#707070] px-32 py-5">
        <div className="w-full flex justify-between items-center">
          <div >
            <p className="text-[#707070] text-sm font-[Gotham-Medium]">© 2022 BY GETJOB</p>
          </div>
          <div className="flex flex-row justify-between">
              <a target={"_blank"}  href="https://twitter.com/GetjobHR"><img src={twtlogo} alt="" className="mx-2 w-5 h-5"/></a>
              <a target={"_blank"}  href="https://www.facebook.com/getjobtr"><img src={fcblogo} alt="" className="mx-2 w-5 h-5"/></a> 
              <a target={"_blank"}  href="https://www.instagram.com/getjobtr/?igshid=YmMyMTA2M2Y="><img src={instalogo} alt="" className="mx-2 w-5 h-5"/></a>
              <a target={"_blank"}  href="https://www.linkedin.com/company/getjobtr/"><img src={linkedinlogo} alt="" className="mx-2 w-5 h-5"/></a>
              <a target={"_blank"}  href="https://www.youtube.com/channel/UCKrTrQjFpoBIZLTP76ldMKQ"><img src={ytblogo} alt="" className="mx-2 w-5 h-5"/></a>
              <a target={"_blank"} href="https://www.tiktok.com/@getjobhr"><img src={tiktoklogo} alt="" className="mx-2 w-5 h-5"/></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
