import React from 'react'
import StarIcon from "../../../assets/Jobs/StarIcon.svg";
import Like from "../../../assets/Jobs/Like.svg";
import Unlike from "../../../assets/Jobs/Unlike.svg";
import Heart from "../../../assets/Jobs/heart.png";
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';


const BottomButtonPanel = (props) => {
  let loginData =  JSON.parse(localStorage.getItem('dataLogin'))

  console.log("11232132",loginData)
  return (
    <div className="w-screen   bg-white fixed flex  bottom-0 justify-center items-center border-2 py-1 z-10 text-center">
      <div className="flex justify-center items-center  text-center">
      {props.list.length == 0 ?   <button onClick={()=> props.unlikeChange()}>
          <div className="flex justify-center items-center  ">
                <img src={Unlike} alt="" />
          </div>
        </button> : null }

        <button>
          <div className="flex justify-center items-center  ">
                <img src={StarIcon} alt="" />
          </div>
        </button>
        {props.listLiked.length == 0 ?  <button onClick={()=> props.favChange()}>
        <div  className="flex justify-center items-center border-2 rounded-full ">
        <FontAwesomeIcon icon={faHeart} style = {{margin:10}}  size={"2x"} color={"red"} />
        </div> 
      </button>  :  <div  className="flex justify-center items-center border-2 rounded-full bg-[#081F43]">
        <FontAwesomeIcon icon={faHeart} style = {{margin:10}}  size={"2x"} color={"white"} />
        </div>  }

        {props.list.length == 0 ? 
        <button onClick={()=> props.likeChange()}>
        <div  className="flex justify-center items-center  ">
              <img src={Like} alt="" />
        </div> 
      </button> : 
      <div className="flex justify-center items-center font-[Gotham-Medium] text-center">
      <img src={Like} className="text-center opacity-[0.3] " />
      <div className="grid">
              <text className='font-[Gotham-Medium] text-[#081F43]'>BAŞVURDUNUZ</text>
              {loginData.Users_Type_ID == 1 ? <Link to = "/jobsJobSeeker"><text className='font-[Gotham-Medium]  text-[#f8d581]'>Benzer yeteneklere başvurmak için tıklayınız</text>
             </Link>  : <Link to = "/"><text className='font-[Gotham-Medium]  text-[#f8d581]'>Benzer işlere başvurmak için tıklayınız</text>
             </Link>}
</div>
             
        </div> }
      </div>
    </div>
  )
}

export default BottomButtonPanel