const ProgressBar = (props) => {
  const { bgcolor, completed } = props;

  const containerStyles = {
    height: 35,
    backgroundColor: "#f8d581",
    borderRadius: 5,
    margin: 20
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'center',
    justifyContent: 'center', alignItems: 'center'
    }

  const labelStyles = {
    padding: 5,
    color: completed == 100 ? 'white' : '#081F43',
    fontWeight: 'bold',
    justifyContent: 'center', alignItems: 'center'
  }

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{`${completed}%`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;