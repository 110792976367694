import React , {  useState} from "react";
import InputBox from "./InputBox";
import PopulerSearchesBox from "./PopulerSearchesBox";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import { Link } from 'react-router-dom';
import "./search.css";
import { withTranslation } from 'react-i18next';

const SearchingPart = (props) => {
  const [myOptions, setMyOptions] = useState([])
  const [filterImage, setFilterImage] = useState([])

  const [myOptionsLocation, setMyOptionsLocation] = useState([])
  const [filterImageLocation, setFilterImageLocation] = useState([])
  const [data, setData] = useState([])
  const [dataLocation, setDataLocation] = useState([])

  
  const getDataFromAPI = (e) => {
    setMyOptions([])
    if(e.target.value.length > 0){
      fetch(`https://getjob.stechomeyazilim.info:5101/getSearch/select/${e.target.value}/jobseeker`).then((response) => {
        return response.json()
      }).then((res) => {
        console.log("c21321321" ,res)
        for (var i = 0; i < res.length; i++) {
         // myOptions.push(res[i].Job.Job_Text + res[i].Users.Users_Profile_Photo)
          myOptions.push(res[i].Job.Job_Text)
         setFilterImage(res)
        }
       setMyOptions(myOptions)
       setData(res[0])

      })
    }

  }


  const getDataFromAPILocation = (e) => {
 
 
    setMyOptionsLocation([])
    if(e.target.value.length > 0){
      fetch(`https://getjob.stechomeyazilim.info:5101/getSearchLocation/select/${e.target.value}/jobseeker`).then((response) => {
        return response.json()
      }).then((res) => {
        console.log("c21321321location" ,res)
        for (var i = 0; i < res.length; i++) {
         // myOptions.push(res[i].Job.Job_Text + res[i].Users.Users_Profile_Photo)
         myOptionsLocation.push(res[i].Job_Seeker_City)
         setFilterImageLocation(res)
        }
        setMyOptionsLocation(myOptionsLocation)
       setDataLocation(res[0])

      })
    }

  }

    
  return (
    <div className="w-full lg:h-[470px] h-full bg-[#052A69] flex justify-center items-center ">
    <div className="flex justify-center items-center w-3/4 h-3/5  lg:pl-20">
      <div className="w-full h-full flex flex-col justify-start items-start">
        <div className="w-full h-[100px] flex items-end my-2">
          <h3 className="text-[#F2B129] text-3xl  flex  ">
            Find the <span className="mx-3 text-white"> right</span> fit.
          </h3>
        </div>
  
        <div className="flex items-center  sm:grid-cols-2 sm:grid-rows-5  gap-2 md:grid-rows-1 md:my-2 md:ml-3  w-10/12  sm:flex-row">
        <Autocomplete
    noOptionsText={'Veri Bulunmamaktadır!'}
    fullWidth
    autoComplete
    autoHighlight
    options={myOptions}
    renderOption={option => {
     let ImageFilter = filterImage.filter((x) => x.Job.Job_Text == option.key)

      return (
        <Link to={"/employeeinfo/" + (ImageFilter[0].ID)} state= {{ _item: data }} className="bg-[#081F43] w-full flex grid-cols-2 border-2 border-[white]">
        <div className="grid w-1/2 justify-center items-center">
         <p className="text-light-white font-['Gotham-Medium'] text-[white]">{option.key}</p>
         <p  className="font-[Title]  text-center text-[white]" alt="product-img">{ImageFilter[0].Users.Users_NameSurname}</p>
         {ImageFilter[0].Job_Seeker_Map.length > 0 ? <p  className="font-[Title]  text-center text-[white]" alt="product-img">{ImageFilter[0].Job_Seeker_Map.Job_Seeker_Map_City}</p> : null}

         </div>
         <div className="m-1 justify-end items-end rounded-lg bg-white text-right content-end place-items-end w-1/2">
            <img src={'https://getjob.stechomeyazilim.com/Uploads/' + ImageFilter[0].Users.Users_NameSurname} className="p-2 rounded-lg justify-end items-center" alt="product-img" />
            </div>
       
          
        </Link>
      );
  }}
    renderInput={params => <TextField  sx={{
      "& .MuiInputBase-root": {
        color: 'red',
          "& input": {
              textAlign: "center",
              color:'#0a4022',
              fontSize:15
          }
      }
    }}
    placeholder="Search talent,keywords,title" className="inputRounded"
    
       {...params} variant="outlined" onChange={getDataFromAPI} />}
/>
  

  <Autocomplete
    noOptionsText={'Veri Bulunmamaktadır!'}
    fullWidth
    autoComplete
    autoHighlight
    options={myOptionsLocation}
    renderOption={option => {
      console.log("ImageFilt11er123",filterImageLocation,option.key)

     let ImageFilter = filterImageLocation.filter((x) => x.Job_Seeker_City == option.key)

      return (
        <Link to={"/employeeinfo/" + (ImageFilter[0].ID)} state= {{ _item: data }} className="bg-[#081F43] w-full flex grid-cols-2 border-2 border-[white]">
        <div className="grid w-1/2 justify-center items-center">
         <p className="text-light-white font-['Gotham-Medium'] text-[white]">{ImageFilter[0].Job.Job_Text}</p>
         <p  className="font-[Title]  text-center text-[white]" alt="product-img">{ImageFilter[0].Users.Users_NameSurname}</p>
        <p  className="font-[Title]  text-center text-[white]" alt="product-img">{ImageFilter[0].Job_Seeker_City}</p> 
         </div>
         <div className="m-1 justify-end items-end rounded-lg bg-white text-right content-end place-items-end w-1/2">
            <img src={'https://getjob.stechomeyazilim.com/Uploads/' + ImageFilter[0].Users.Users_Profile_Photo} className="p-2 rounded-lg justify-end items-center" alt="product-img" />
            </div>
       
          
        </Link>
      );
  }}
    renderInput={params => <TextField  sx={{
      "& .MuiInputBase-root": {
        color: 'red',
          "& input": {
              textAlign: "center",
              color:'#0a4022',
              fontSize:15
          }
      }
    }}
    placeholder="Enter Location" className="inputRounded"
    
       {...params} variant="outlined" onChange={getDataFromAPILocation} />}
/>

</div>

     {/* <InputBox /> */}
      <div className="w-full h-full flex flex-col mt-10">
        <div className=" flex  w-full  ">
          <h3 className="text-md text-white mb-3 font-[Title]">{props.t('popularsearch')}</h3>
        </div>
        <div className="w-full h-full flex flex-row py-1 flex-wrap">
        {props.list.slice(0,10).map((item, index) => (
          <PopulerSearchesBox item={item} userType={JSON.parse(localStorage.getItem('dataLogin'))} />
          ))} 
        </div>
      </div>
    </div>
  </div>
</div>
  );
};

export default withTranslation()(SearchingPart);
