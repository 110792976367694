import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import locationImage from "../../../assets/locationIcon.svg";
const SliderCardJobSeeker = (props) => {
  console.log("sldnfk",props.item)
  return (
    <Link to={"/employeeınfo/" + (props.item.Users.ID)} state= {{ _item: props.item , edit : false }} >
      <div className="flex  hover:outline hover:outline-4 hover:outline-blue-300 flex-col text-center rounded-lg shadow-lg justify-center">
        <div className="pb-2">
        {props.item.Users.Users_Profile_Photo != null ? <img
            src={"https://getjob.stechomeyazilim.com/Uploads/" + props.item.Users.Users_Profile_Photo}
            alt=""
            className="flex justify-center rounded-tl-lg rounded-tr-lg"
          /> : <div className="bg-[#081F43] flex justify-center items-center p-4 rounded-lg"><FontAwesomeIcon icon={faUser} style={{margin:10}}  size={"5x"} color={"white"} /></div>}
        </div>
        {props.item.Employer_Map != null ? <div className="justify-between grid grid-rows-2 gap-2  w-full mt-1">
          <div className="flex flex-row w-full h-full">
            <img src={locationImage} alt="" className="ml-3 mt-1 w-4 h-4 " />
            <p className="font-bold text-[#052A69] pl-2 ">{props.item.Employer_Map.Job_Employer_Map_City}</p>
          </div>
          <div className="flex flex-row w-full h-full">
            <p className="text-sm text-[#052A69] pl-9 font-bold ">{props.item.Employer_Company.Job_Employer_CompanyName}</p>
          </div>
        </div> : null}
        {props.item.Job_Salery_Expection != null ? <div className="flex justify-center bg-blue-50	 w-full h-full">
          <p className="text-sm font-bold  text-balck py-2 ">{props.item.Job_Salery_Expection.Job_Salery_Expection_Text}</p>
        </div> : null}
        <div className="bg-[#081F43]  rounded-bl-lg rounded-br-lg py-3 text-white flex justify-center">
        {props.item.Job != null ? <h3 className="font-bold flex ">{props.item.Job.Job_Text}</h3> : null}
        </div>
      </div>
    </Link>
  );
};

export default SliderCardJobSeeker;
