import React from "react";
import { Link } from "react-router-dom";
import searchwithcolor from "../../../assets/searchwithcolor.svg";

const PopulerSearchesBox = (props) => {
  return (
    props.userType != null &&  props.userType.Users_Type_ID == 2 ? 
    <Link to={"/jobsJobSeeker/"} state= {{ _item: props.item.Job.Job_Text }} className="h-9 py-1 flex flex-wrap  ">
      <button className="h-full w-full flex justify-center items-center">
        <div className="bg-[#0F3B86] flex justify-between  items-center mr-2 lg:px-2 rounded-sm  h-full hover:bg-blue-400 mb-7">
          <img
            src={searchwithcolor}
            alt=""
            className="w-4 h-4 flex justify-center items-center mr-2"
          />
          <p className="text-white w-full text-sm pr-2 font-[Gotham-Medium]">{props.item.Job.Job_Text}</p>
        </div>
      </button>
    </Link>  : props.userType != null &&  props.userType.Users_Type_ID == 1  ?
     <Link to={"/jobs/"} state= {{ _item: props.item.Job.Job_Text }} className="h-9 py-1 flex flex-wrap  ">
     <button className="h-full w-full flex justify-center items-center">
       <div className="bg-[#0F3B86] flex justify-between  items-center mr-2 lg:px-2 rounded-sm  h-full hover:bg-blue-400 mb-7">
         <img
           src={searchwithcolor}
           alt=""
           className="w-4 h-4 flex justify-center items-center mr-2"
         />
         <p className="text-white w-full text-sm pr-2 font-[Gotham-Medium]">{props.item.Job.Job_Text}</p>
       </div>
     </button>
   </Link> : null
  );
};

export default PopulerSearchesBox;
